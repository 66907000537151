import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const initialState = {
    gseGeneralInfoColumns: [ 
        { title: 'Airport', field: 'airportCode', checked: true },
        { title: 'ID', field: 'info', checked: true },
        { title: 'Equipment type', field: 'equipmentType', checked: true },
        { title: 'Vehicle type', field: 'vehicleType', checked: true },        
        { title: 'Manufacturer', field: 'manufacturer', checked: true },
        { title: 'Model', field: 'model', checked: true }
    ],
    selectedGseId:null,
    editGseId:null
}

const toggleFieldCheckAll = (state, action) => {
    let gseGeneralInfoColumns = [...state.gseGeneralInfoColumns];

    if (action.checkedAllFields){
        for (let i = 3; i < gseGeneralInfoColumns.length; i++){
            gseGeneralInfoColumns[i].checked = false;
        }
    } else {
        for (let i = 0; i < gseGeneralInfoColumns.length; i++){
            gseGeneralInfoColumns[i].checked = true;
        }
    }

    return updateObject(state, {gseGeneralInfoColumns:gseGeneralInfoColumns});
}

const toggleFieldCheck = (state, action) => {
    let gseGeneralInfoColumns = [...state.gseGeneralInfoColumns];

    if (gseGeneralInfoColumns[action.index].checked === true){
        gseGeneralInfoColumns[action.index].checked = false;
    } else {
        gseGeneralInfoColumns[action.index].checked = true;
    }


    return updateObject(state, {gseGeneralInfoColumns:gseGeneralInfoColumns});
}

const updateGseDataProperty = (state, action) => {
    return updateObject(state, {[action.property]:action.value});
}


const resetGseData = (state) => {
    const resetState = {...initialState};
    return resetState;
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.TOGGLE_FIELD_CHECK_ALL: return toggleFieldCheckAll(state,action);
        case actionTypes.TOGGLE_FIELD_CHECK: return toggleFieldCheck(state,action);
        case actionTypes.UPDATE_GSE_DATA_PROPERTY: return updateGseDataProperty(state,action);
        case actionTypes.RESET_GSE_DATA: return resetGseData(state);
        default: return state;
    }
};

export default reducer;