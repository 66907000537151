import React from 'react';
import Aux from 'react-aux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import classes from './Admin.module.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimaryInput from '../../components/UI/PrimaryInput/PrimaryInput';
import PrimaryDatePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatePickerInput';

const AdminEditSim = (props) => {
    const [selectedData, setValue] = React.useState(props.selectedData);

    const handleChange = (property, value) => {
        if (property === 'purchasedDate' && moment.isMoment(value)) {
            value = value.format('YYYY-MM-DDTHH:mm:ss');
        }

        let updatedData = { ...selectedData };
        updatedData[property] = value;

        setValue(updatedData);
    };

    return (
        <Aux>
            <div className={classes.ModalButtonsWrapper}>
                <PrimaryButton clicked={() => props.saveSim(selectedData)}>
                    <FontAwesomeIcon icon={faSave} />
                    Save
                </PrimaryButton>
                <div className={classes.CancelButton} onClick={() => props.cancel()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>

            <div className={classes.EditRowsWrapper}>
                <div className={classes.EditRow}>
                    <PrimaryInput label='ID' type='text' value={selectedData.id} name='id' disabled />
                </div>

                <div className={classes.EditRow}>
                    <PrimaryInput
                        label='Serial number'
                        type='text'
                        value={selectedData.serialNo}
                        name='serialNo'
                        changed={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </div>

                <div className={classes.EditRow}>
                    <PrimaryDatePicker
                        label='Purchase date'
                        value={selectedData.purchasedDate ? new Date(selectedData.purchasedDate) : null}
                        dateChanged={(value) => handleChange('purchasedDate', value)}
                        name='purchasedDate'
                        maxDate={new Date()}
                    />
                </div>

                <div className={classes.EditRow}>
                    <PrimaryInput
                        label='Phone number'
                        type='text'
                        value={selectedData.phoneNumber}
                        name='phoneNumber'
                        changed={(e) => handleChange(e.target.name, e.target.value)}
                        mandatory
                    />
                </div>

                <div className={[classes.EditRow, classes.FullRow].join(' ')}>
                    <PrimaryInput
                        label='Comments'
                        type='text'
                        value={selectedData.comments}
                        name='comments'
                        changed={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </div>
            </div>
        </Aux>
    );
};

export default AdminEditSim;
