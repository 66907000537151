import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { saveAs } from 'file-saver';
import IconButton from '@material-ui/core/IconButton';
import SaveAlt from '@material-ui/icons/SaveAlt';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { useCurrentPng } from 'recharts-to-png';

import classes from './MyPieChart.module.css';

const MyPieChart = (props) => {
    const [getPng, { ref: chartRef, isLoading }] = useCurrentPng();

    const [activePieIndex, setActivePieIndex] = useState(0);
    const [selectedYear, setSelectedYear] = useState(props.years[0]);

    useEffect(() => {
        if (props.selectedYear) setSelectedYear(props.selectedYear);
        else setSelectedYear(props.years[0]);
    }, [props.selectedYear]);

    const sumHandler = () => {
        props.changeTimeframe('all');
    };
    const yearHandler = () => {
        let thisYear = props.selectedYear;
        if (!thisYear || thisYear === '' || props.years.includes(props.selectedYear) === false) {
            thisYear = props.years[props.years.length - 1];
        }

        props.changeYear(thisYear);
        props.changeTimeframe('year');
    };
    const monthHandler = () => {
        let thisMonth = props.selectedMonth;
        if (!thisMonth || thisMonth === '') {
            thisMonth = 1;
        }

        props.changeMonth(thisMonth);
        props.changeTimeframe('month');
    };

    const previousYearHandler = () => {
        props.changeYear(props.selectedYear - 1);
    };
    const nextYearHandler = () => {
        props.changeYear(props.selectedYear + 1);
    };

    const previousMonthHandler = () => {
        props.changeMonth(props.selectedMonth - 1);
    };
    const nextMonthHandler = () => {
        props.changeMonth(props.selectedMonth + 1);
    };

    const checkYear = (type) => {
        if (props.years.length === 1) return false;

        if (type === 'first' && props.years[0] == props.selectedYear) return false;
        if (type === 'last' && props.years[props.years.length - 1] == props.selectedYear) return false;

        return true;
    };

    const renderActiveShape = (properties) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = properties;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        const pieVal = Math.round(payload.val * 100) / 100;

        return (
            <g>
                <foreignObject x={cx - 80} y={0} width='160' height='400' style={{ textAlign: 'center' }}>
                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ textAlign: 'center', fontSize: 12, color: '#3a5d9d' }}>{payload[props.showProperty]}</span>
                    </div>
                </foreignObject>
                {/* <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
                    {payload[props.showProperty]}
                </text> */}
                <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
                <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>{`${props.numberLabel} ${pieVal}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
                    {`(Percentage ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    const onPieEnter = (_, index) => {
        setActivePieIndex(index);
    };

    const exportChart = useCallback(async () => {
        const png = await getPng();
        if (png) {
            saveAs(png, `${props.name}.png`);
        }
    }, [getPng]);

    return (
        <React.Fragment>
            {props.data.length > 0 && (
                <React.Fragment>
                    <div className={classes.ExportToPngWrapper}>
                        {isLoading ? <span>Loading...</span> : null}
                        <MaterialTooltip title='Export to png'>
                            <IconButton onClick={exportChart}>
                                <SaveAlt />
                            </IconButton>
                        </MaterialTooltip>
                    </div>

                    <div className={classes.ChartWrapper}>
                        <ResponsiveContainer width='100%' height='100%'>
                            <PieChart ref={chartRef}>
                                <Pie
                                    activeIndex={activePieIndex}
                                    activeShape={renderActiveShape}
                                    dataKey='val'
                                    data={props.data}
                                    cx='50%'
                                    cy='50%'
                                    innerRadius={80}
                                    outerRadius={120}
                                    fill='#3a5d9d'
                                    onMouseEnter={onPieEnter}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    <div className={classes.PeriodSelection}>
                        {props.timeframe === 'year' && (
                            <div className={classes.YearSelection}>
                                {checkYear('first') ? (
                                    <div className={classes.SvgWrapper} onClick={previousYearHandler}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}

                                <span className={classes.Year}>{props.selectedYear}</span>

                                {checkYear('last') ? (
                                    <div className={classes.SvgWrapper} onClick={nextYearHandler}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}
                            </div>
                        )}

                        {props.timeframe === 'month' && (
                            <div className={classes.YearSelection}>
                                {props.selectedMonth > 1 ? (
                                    <div className={classes.SvgWrapper} onClick={previousMonthHandler}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}

                                <span className={classes.Year}>{`${moment(props.selectedMonth, 'M').format('MMM')}-${selectedYear}`}</span>

                                {props.selectedMonth < 12 ? (
                                    <div className={classes.SvgWrapper} onClick={nextMonthHandler}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}
                            </div>
                        )}

                        <div
                            onClick={monthHandler}
                            className={props.timeframe === 'month' ? [classes.Active, classes.MonthButton].join(' ') : classes.MonthButton}
                        >
                            Month
                        </div>
                        <div onClick={yearHandler} className={props.timeframe === 'year' ? [classes.Active, classes.YearButton].join(' ') : classes.YearButton}>
                            Year
                        </div>
                        <div onClick={sumHandler} className={props.timeframe === 'all' ? [classes.Active, classes.SumButton].join(' ') : classes.SumButton}>
                            All
                        </div>
                    </div>
                </React.Fragment>
            )}
            {props.data.length === 0 && (
                <React.Fragment>
                    <div className={classes.ChartWrapper}>
                        <span>No data</span>
                    </div>
                    <div className={classes.PeriodSelection}>
                        {props.timeframe === 'year' && (
                            <div className={classes.YearSelection}>
                                {checkYear('first') ? (
                                    <div className={classes.SvgWrapper} onClick={previousYearHandler}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}

                                <span className={classes.Year}>{props.selectedYear}</span>

                                {checkYear('last') ? (
                                    <div className={classes.SvgWrapper} onClick={nextYearHandler}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}
                            </div>
                        )}

                        {props.timeframe === 'month' && (
                            <div className={classes.YearSelection}>
                                {props.selectedMonth > 1 ? (
                                    <div className={classes.SvgWrapper} onClick={previousMonthHandler}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}

                                <span className={classes.Year}>{`${moment(props.selectedMonth, 'M').format('MMM')}-${selectedYear}`}</span>

                                {props.selectedMonth < 12 ? (
                                    <div className={classes.SvgWrapper} onClick={nextMonthHandler}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}
                            </div>
                        )}

                        <div
                            onClick={monthHandler}
                            className={props.timeframe === 'month' ? [classes.Active, classes.MonthButton].join(' ') : classes.MonthButton}
                        >
                            Month
                        </div>
                        <div onClick={yearHandler} className={props.timeframe === 'year' ? [classes.Active, classes.YearButton].join(' ') : classes.YearButton}>
                            Year
                        </div>
                        <div onClick={sumHandler} className={props.timeframe === 'all' ? [classes.Active, classes.SumButton].join(' ') : classes.SumButton}>
                            All
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MyPieChart;
