import * as actionTypes from './actionTypes';
import axios from '../../axios-api';
import axiosRoot from 'axios';
import Swal from 'sweetalert2';

import { loading } from './_system';

// Company statistics --------------------------------
export const getCompanyStatistics = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('StatisticsCompany')
            .then((response) => {
                dispatch(getCompanyStatisticsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const getCompanyStatisticsSuccess = (stats) => {
    return {
        type: actionTypes.GET_COMPANY_STATISTICS_SUCCESS,
        stats: stats,
    };
};

// Airport statistics --------------------------------
export const getStatisticsAirports = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('Airport/-1')
            .then((response) => {
                dispatch(getStatisticsAirportsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again';
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                Swal.fire({ type: 'error', text: message });
                dispatch(loading(false));
            });
    };
};

export const getStatisticsAirportsSuccess = (airports) => {
    return {
        type: actionTypes.GET_STATISTICS_AIRPORTS_SUCCESS,
        airports: airports,
    };
};

export const getAirportStatistics = (airportId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('StatisticsAirport?airportId=' + airportId)
            .then((response) => {
                dispatch(getAirportStatisticsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again';
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                Swal.fire({ type: 'error', text: message });
                dispatch(loading(false));
            });
    };
};

export const getAirportStatisticsSuccess = (stats) => {
    return {
        type: actionTypes.GET_AIRPORT_STATISTICS_SUCCESS,
        stats: stats,
    };
};

// Gse prefilter --------------------------------
export const getEquipmentPrefilterStatistics = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('GsePreFilter/-1')
            .then((response) => {
                dispatch(getGsePrefilterStatisticsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again';
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                Swal.fire({ type: 'error', text: message });
                dispatch(loading(false));
            });
    };
};

export const getGsePrefilterStatisticsSuccess = (gseData) => {
    return {
        type: actionTypes.GET_GSE_PREFILTER_STATISTICS_SUCCESS,
        gseData: gseData,
    };
};

export const getGsePrefilterStatistics = () => {
    return (dispatch) => {
        dispatch(loading(true));

        const requestOne = axios.get('GsePreFilter/-1');
        const requestTwo = axios.get('Airport/-1');

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });
                        dispatch(loading(false));
                        return;
                    }

                    dispatch(getGsePrefilterStatisticsSuccess(responseOne.data));
                    dispatch(getStatisticsAirportsSuccess(responseTwo.data));

                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });

        // axios
        //     .get('GsePreFilter/-1')
        //     .then((response) => {
        //         dispatch(getGsePrefilterStatisticsSuccess(response.data));
        //         dispatch(loading(false));
        //     })
        //     .catch((error) => {
        //         let message = 'Something went wrong. Please try again';
        //         if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
        //         Swal.fire({ type: 'error', text: message });
        //         dispatch(loading(false));
        //     });
    };
};

// Equipment statistics --------------------------------
export const getEquipmentStatistics = (equipmentType) => {
    return (dispatch) => {
        dispatch(loading(true));

        const urlEquipmentType = encodeURIComponent(equipmentType);

        axios
            .get('StatisticsEquipment?equipment=' + urlEquipmentType)
            .then((response) => {
                dispatch(getEquipmentStatisticsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again';
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                Swal.fire({ type: 'error', text: message });
                dispatch(loading(false));
            });
    };
};

export const getEquipmentStatisticsSuccess = (stats) => {
    return {
        type: actionTypes.GET_EQUIPMENT_STATISTICS_SUCCESS,
        stats: stats,
    };
};

// Equipment airport statistics --------------------------------
export const getGseEquipmentAndAirports = () => {
    return (dispatch) => {
        dispatch(loading(true));

        const requestOne = axios.get('GsePreFilter/-1');
        const requestTwo = axios.get('Airport/-1');

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });
                        dispatch(loading(false));
                        return;
                    }

                    dispatch(getGseEquipmentAndAirportsSuccess(responseOne.data, responseTwo.data));

                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getGseEquipmentAndAirportsSuccess = (gseData, airports) => {
    return {
        type: actionTypes.GET_GSE_EQUIPMENT_AND_AIRPORTS_SUCCESS,
        gseData: gseData,
        airports: airports,
    };
};

export const getEquipmentAirportStatistics = (airportId, equipmentType) => {
    return (dispatch) => {
        dispatch(loading(true));

        const urlEquipmentType = encodeURIComponent(equipmentType);

        axios
            .get(`StatisticsAirportEquipment?airportid=${airportId}&equipment=${urlEquipmentType}`)
            .then((response) => {
                dispatch(getEquipmentAirportStatisticsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again';
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                Swal.fire({ type: 'error', text: message });
                dispatch(loading(false));
            });
    };
};

export const getEquipmentAirportStatisticsSuccess = (stats) => {
    return {
        type: actionTypes.GET_EQUIPMENT_AIRPORT_STATISTICS_SUCCESS,
        stats: stats,
    };
};

// Gse statistics --------------------------------
export const getGseStatistics = (gseId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('StatisticsGse?gseId=' + gseId)
            .then((response) => {
                dispatch(getGseStatisticsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again';
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                Swal.fire({ type: 'error', text: message });
                dispatch(loading(false));
            });
    };
};

export const getGseStatisticsSuccess = (stats) => {
    return {
        type: actionTypes.GET_GSE_STATISTICS_SUCCESS,
        stats: stats,
    };
};

// Other --------------------------------
export const resetStatistics = () => {
    return {
        type: actionTypes.RESET_STATISTICS,
    };
};

// Tracking
export const getTracking = (day, equipment) => {
    return (dispatch) => {
        dispatch(loading(true));
        const uriEquipment = encodeURIComponent(equipment);

        const requestOne = axios.get(`StatisticsTrips/getequipmileage?equipment=${uriEquipment}&dayStamp=${day}`);
        const requestTwo = axios.get(`StatisticsTrips/getequipoperational?equipment=${uriEquipment}&dayStamp=${day}`);
        const requestThree = axios.get(`StatisticsTrips/getequipidling?equipment=${uriEquipment}&dayStamp=${day}`);
        const requestFour = axios.get(`StatisticsTrips/getequipflights?equipment=${uriEquipment}&dayStamp=${day}`);

        axiosRoot
            .all([requestOne, requestTwo, requestThree, requestFour])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];
                    const responseFour = responses[3];

                    if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200 || responseFour.status !== 200) {
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });
                        dispatch(loading(false));
                        return;
                    }

                    dispatch(getTrackingSuccess(responseOne.data, responseTwo.data, responseThree.data, responseFour.data));
                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getTrackingSuccess = (mileage, operational, idling, flights) => {
    return {
        type: actionTypes.GET_TRACKING_SUCCESS,
        mileage: mileage,
        operational: operational,
        idling: idling,
        flights: flights,
    };
};

export const resetTracking = () => {
    return {
        type: actionTypes.RESET_TRACKING,
    };
};

export const getTrackingGse = (day, gseId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get(`StatisticsTrips/getgse?gseId=${gseId}&dayStamp=${day}`)
            .then((response) => {
                dispatch(getTrackingGseSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                let message = 'Something went wrong. Please try again';
                if (error.response && error.response.data && error.response.data.message) message = error.response.data.message;
                Swal.fire({ type: 'error', text: message });
                dispatch(loading(false));
            });
    };
};
export const getTrackingGseSuccess = (trackingGse) => {
    return {
        type: actionTypes.GET_TRACKING_GSE_SUCCESS,
        trackingGse: trackingGse,
    };
};
