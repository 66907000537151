import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faShuttleVan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import classes from './FlightInfo.module.css';
import TabFlights from './TabFlights';
import TabFlightsEquipment from './TabFlightsEquipment';
import PrimaryDatePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatePickerInput';
import * as actions from '../../store/actions/index';

class FlightInfo extends Component {
    state = {
        selectedDate: new Date(moment().subtract(1, 'days')),
        maxDate: new Date(),
    };

    componentDidMount() {
        const year = parseInt(moment(this.state.selectedDate).format('YYYY'));
        const month = parseInt(moment(this.state.selectedDate).format('MM'));
        const day = parseInt(moment(this.state.selectedDate).format('DD'));

        this.props.onGetFlights(year, month, day);
        this.props.onGetFlightsEquipment(year, month, day);
    }

    updateFlights = (value) => {
        if (!value) return;
        if (value === this.state.selectedDate) return;

        this.setState({ selectedDate: value });

        const year = parseInt(moment(value).format('YYYY'));
        const month = parseInt(moment(value).format('MM'));
        const day = parseInt(moment(value).format('DD'));

        this.props.onGetFlights(year, month, day);
        this.props.onGetFlightsEquipment(year, month, day);
    };

    render() {
        let page = (
            <Aux>
                <div className={classes.FiltersSpace}>
                    <span className={classes.FilterName}>Select date</span>
                    <PrimaryDatePicker
                        // label="Select year, month and day"
                        value={this.state.selectedDate}
                        dateChanged={(value) => this.updateFlights(value)}
                        name='selectedDate'
                        narrow
                        maxDate={this.state.maxDate}
                    />
                </div>
                <div className={classes.PageTitle}>
                    <h1>Flight Info</h1>
                </div>
                <div className={classes.PageContent}>
                    <Tabs>
                        <TabList>
                            <Tab>
                                <FontAwesomeIcon icon={faPlane} />
                                Flights
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faShuttleVan} />
                                Flights equipment
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabFlights flights={this.props.flights} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabFlightsEquipment flightsEquipment={this.props.flightsEquipment} />
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </Aux>
        );
        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        selectedFlightsInfoDate: state._data.selectedFlightsInfoDate,
        flights: state._data.flights,
        flightsEquipment: state._data.flightsEquipment,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onUpdateDataStateProperty: (property, value) => dispatch(actions.updateDataStateProperty(property, value)),
        onGetFlights: (year, month, day) => dispatch(actions.getFlights(year, month, day)),
        onGetFlightsEquipment: (year, month, day) => dispatch(actions.getFlightsEquipment(year, month, day)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightInfo);
