import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// import { cancelApiMiddleware } from './middlewares/cancelApiMiddleware';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import _systemReducer from './store/reducers/_system';
import _dataReducer from './store/reducers/_data';
import loginReducer from './store/reducers/login';
import adminReducer from './store/reducers/admin';
import gseDataReducer from './store/reducers/gseData';
import statisticsReducer from './store/reducers/statistics';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  _system: _systemReducer,
  _data: _dataReducer,
  login: loginReducer,
  admin: adminReducer,
  gseData: gseDataReducer,
  statistics: statisticsReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunk
      // cancelApiMiddleware
    )
  )
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
