import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';

import classes from './PrimaryAutocomplete.module.css';

const Combobox = (props) => {
  // const [value, setValue] = React.useState(props.value);
  // const [inputValue, setInputValue] = React.useState(props.value || "");

  const defaultProps = {
    options: props.options,
    getOptionLabel: (option) => option.label,
  };

  let elementClasses = [classes.FormControl];

  if (props.fullWidth) elementClasses.push(classes.FullWidth);
  else if (props.narrow) elementClasses.push(classes.Narrow);
  else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

  return (
    <FormControl className={elementClasses.join(' ')} disabled={props.disabled}>
          <Autocomplete
            {...defaultProps}
            value={props.value}
            onChange={(e, newValue) => {
              // setValue(newValue);
              props.changed(newValue);
            }}
            // inputValue={inputValue}
            // onInputChange={(event, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            // options={props.options}
            renderInput={(params) => <TextField variant="outlined" {...params} label={props.label} />}
            clearOnEscape
            defaultValue={props.defaultValue}
          />
    </FormControl>
  )
}

export default Combobox;