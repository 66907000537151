import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import * as actions from '../../../store/actions/index';

class Topbar extends Component {
    componentDidMount() {
        this.props.onGetUserFromLocalStorage();
    }

    logout() {
        this.props.onLogoutUser();
        this.props.history.push('/login');
    }

    resetState = (page) => {
        if (page === 'gseData') {
            this.props.onResetGseData();
        } else if (page === 'admin') {
            this.props.onResetAdmin();
        } else if (page === 'statistics') {
            this.props.onResetStatistics();
        }
    };

    render() {
        let page = <div></div>;

        if (this.props.loggedInUser) {
            page = (
                <React.Fragment>
                    <MediaQuery minWidth={1200}>
                        <MenuDesktop loggedInUser={this.props.loggedInUser} logout={() => this.logout()} resetState={(page) => this.resetState(page)} />
                    </MediaQuery>

                    <MediaQuery maxWidth={1200}>
                        <MenuMobile loggedInUser={this.props.loggedInUser} logout={() => this.logout()} resetState={(page) => this.resetState(page)} />
                    </MediaQuery>
                </React.Fragment>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.login.loggedIn,
        loggedInUser: state.login.loggedInUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetUserFromLocalStorage: () => dispatch(actions.getUserFromLocalStorage()),
        onLogoutUser: () => dispatch(actions.logout()),
        onResetGseData: () => dispatch(actions.resetGseData()),
        onResetAdmin: () => dispatch(actions.resetAdmin()),
        onResetStatistics: () => dispatch(actions.resetStatistics()),
        onUpdateBreadcrumbs: (page) => dispatch(actions.updateBreadcrumbs(page)),
        // onCancelApiRequest: () => dispatch(actions.cancelApiRequest()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
