import React from 'react';

import project_logo from '../../assets/images/ecoserve_logo_color.png';
import classes from './Logo.module.css';

const logo = (props) => {
    let attachedClasses = [classes.Logo];

    if (props.closed === true) {
        attachedClasses = [classes.Logo, classes.Closed];
    }

    return (
        <div className={attachedClasses.join(' ')}>
            <img src={project_logo} alt="Project logo" />
        </div>
    )
};

export default logo;