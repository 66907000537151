import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faExclamation, faCalendar } from '@fortawesome/free-solid-svg-icons';

import DataTable from '../../components/DataTable/DataTable';
import { formatDateTime } from '../../store/utilities';
import PrimaryDateRangePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDateRangePicker';
import PrimaryDatePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatePicker';
import PrimaryYearPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearPicker';
import PrimaryYearMonthPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearMonthPicker';
import classes from './GseInfo.module.css';
import Block from '../../components/Block/Block';
import * as actions from '../../store/actions/index';

class TabNotifications extends Component {
    state = {
        selectedPeriod: 'month',
        customPeriod: [null, null],
        selectedYear: null,
        selectedMonth: new Date(),
        selectedDay: null,
    };

    componentDidMount() {
        const gseId = this.props.gseDetails.id;
        this.props.onGetNotifications('?gseId=' + gseId);
    }

    getFilteredNotifications = (type) => {
        let filteredNotifications = [];
        const gseId = this.props.gseDetails.id;

        if (this.props.notifications) {
            for (let notification of this.props.notifications) {
                const isOfType = notification.type === type ? true : false;
                if (!isOfType) continue;
                if (notification.gseId !== gseId) continue;

                let isInPeriod;
                if (this.state.selectedPeriod === 'day') {
                    isInPeriod = moment(notification.datetimeHappened).isSame(moment(this.state.selectedDay), 'day');
                } else if (this.state.selectedPeriod === 'month') {
                    isInPeriod = moment(notification.datetimeHappened).isSame(moment(this.state.selectedMonth), 'month');
                } else if (this.state.selectedPeriod === 'year') {
                    isInPeriod = moment(notification.datetimeHappened).isSame(moment(this.state.selectedYear), 'year');
                } else if (this.state.selectedPeriod === 'range' && this.state.customPeriod[0] && this.state.customPeriod[1]) {
                    const startDate = moment(this.state.customPeriod[0]);
                    startDate.set({ hour: 0, minute: 0, second: 0 });
                    const endDate = moment(this.state.customPeriod[1]);
                    endDate.set({ hour: 23, minute: 59, second: 59 });
                    const compareDate = moment(notification.datetimeHappened);

                    isInPeriod = compareDate.isBetween(startDate, endDate, null, []);
                }

                if (isInPeriod) filteredNotifications.push(notification);
            }
        }

        return filteredNotifications;
    };

    handleSelectedCustomPeriod = (value) => {
        if (!value[0]) return;
        this.setState({ selectedPeriod: 'range', selectedYear: null, selectedMonth: null, selectedDay: null, customPeriod: value });
    };
    handleSelectedYear = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'year', selectedYear: value, selectedMonth: null, selectedDay: null, customPeriod: [null, null] });
    };
    handleSelectedMonth = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'month', selectedYear: null, selectedMonth: value, selectedDay: null, customPeriod: [null, null] });
    };
    handleSelectedDay = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'day', selectedYear: null, selectedMonth: null, selectedDay: value, customPeriod: [null, null] });
    };

    getTableFields = () => {
        let tableFields = [
            { title: 'Parameter', field: 'title' },
            {
                title: 'Date',
                field: 'datetimeHappened',
                render: (rowData) => formatDateTime(rowData.datetimeHappened),
            },
            { title: 'GSE', field: 'gse' },
            { title: 'Airport', field: 'airport' },
            { title: 'Type', field: 'type' },
            { title: 'Description', field: 'description', width: 350 },
        ];

        return tableFields;
    };

    render() {
        const alertNotifications = this.getFilteredNotifications('error');
        const warningNotifications = this.getFilteredNotifications('warning');
        const updateNotifications = this.getFilteredNotifications('update');

        const tableFields = this.getTableFields();

        const page = (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={classes.ScrollInner} id='TabNotificationsPrint'>
                    <Block title='GSE Notifications'>
                        <div className={classes.Timeframe}>
                            <div
                                className={
                                    this.state.selectedPeriod === 'day' ? [classes.CustomRange, classes.TimeframeSelected].join(' ') : classes.CustomRange
                                }
                            >
                                <PrimaryDatePicker
                                    value={this.state.selectedDay}
                                    dateChanged={(value) => this.handleSelectedDay(value)}
                                    maxDate={new Date()}
                                    placeholder='Day'
                                />
                            </div>
                            <div
                                className={
                                    this.state.selectedPeriod === 'month' ? [classes.CustomRange, classes.TimeframeSelected].join(' ') : classes.CustomRange
                                }
                            >
                                <PrimaryYearMonthPicker
                                    value={this.state.selectedMonth}
                                    dateChanged={(value) => this.handleSelectedMonth(value)}
                                    maxDate={new Date()}
                                    placeholder='Month'
                                />
                            </div>
                            <div
                                className={
                                    this.state.selectedPeriod === 'year' ? [classes.CustomRange, classes.TimeframeSelected].join(' ') : classes.CustomRange
                                }
                            >
                                <PrimaryYearPicker
                                    value={this.state.selectedYear}
                                    dateChanged={(value) => this.handleSelectedYear(value)}
                                    maxDate={new Date()}
                                    placeholder='Year'
                                />
                            </div>
                            <div
                                className={
                                    this.state.selectedPeriod === 'range' ? [classes.CustomRange, classes.TimeframeSelected].join(' ') : classes.CustomRange
                                }
                            >
                                <PrimaryDateRangePicker
                                    value={this.state.customPeriod}
                                    dateChanged={(value) => this.handleSelectedCustomPeriod(value)}
                                    maxDate={new Date()}
                                    placeholder='Custom range'
                                />
                            </div>
                            {/* <div
                                className={this.state.selectedPeriod === 'today' ? classes.TimeframeSelected : null}
                                onClick={() => this.setState({ selectedPeriod: 'today' })}
                            >
                                Today
                            </div>
                            <div
                                className={this.state.selectedPeriod === 'week' ? classes.TimeframeSelected : null}
                                onClick={() => this.setState({ selectedPeriod: 'week' })}
                            >
                                This week
                            </div>
                            <div
                                className={this.state.selectedPeriod === 'month' ? classes.TimeframeSelected : null}
                                onClick={() => this.setState({ selectedPeriod: 'month' })}
                            >
                                This month
                            </div>
                            <div
                                onClick={() => this.setState({ selectedPeriod: 'custom' })}
                                className={
                                    this.state.selectedPeriod === 'custom' ? [classes.CustomRange, classes.TimeframeSelected].join(' ') : classes.CustomRange
                                }
                            >
                                <PrimaryDateRangePicker
                                    value={this.state.customPeriod}
                                    dateChanged={(value) => this.handleSelectedCustomPeriod(value)}
                                    maxDate={new Date()}
                                    placeholder='Custom'
                                />
                            </div> */}
                        </div>

                        <div className={classes.NotificationsWrapper}>
                            <div className={[classes.Notifications, classes.Errors].join(' ')}>
                                <div className={classes.NotificationsTitle}>
                                    <FontAwesomeIcon icon={faBell} />
                                    <h4>Errors</h4>
                                </div>
                                <div className={classes.DataTableWrapper}>
                                    <DataTable data={alertNotifications} columns={tableFields} withExport withSearch noActions />
                                </div>
                            </div>

                            <div className={[classes.Notifications, classes.Warnings].join(' ')}>
                                <div className={classes.NotificationsTitle}>
                                    <FontAwesomeIcon icon={faExclamation} />
                                    <h4>Warnings</h4>
                                </div>
                                <div className={classes.DataTableWrapper}>
                                    <DataTable data={warningNotifications} columns={tableFields} withExport withSearch noActions />
                                </div>
                            </div>

                            <div className={[classes.Notifications, classes.Updates].join(' ')}>
                                <div className={classes.NotificationsTitle}>
                                    <FontAwesomeIcon icon={faCalendar} />
                                    <h4>Updates</h4>
                                </div>
                                <div className={classes.DataTableWrapper}>
                                    <DataTable data={updateNotifications} columns={tableFields} withExport withSearch noActions />
                                </div>
                            </div>
                        </div>
                    </Block>
                </div>
            </Scrollbars>
        );

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._data.loading,
        notifications: state._data.notifications,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetNotifications: () => dispatch(actions.getNotifications('')),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabNotifications);
