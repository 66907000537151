import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import classes from './GseInfo.module.css';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import { formatDateTime } from '../../store/utilities';
import * as actions from '../../store/actions/index';

class TabService extends Component {
    state = {
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
    };

    getServiceFields = () => {
        let tableFields = [
            {
                title: 'Date performed',
                field: 'datePerformed',
                render: (rowData) => formatDateTime(rowData.datePerformed),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Airport',
                field: 'airportName',
                render: (rowData) => rowData.airportName,
                defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Job description',
                field: 'jobDescription',
                render: (rowData) => rowData.jobDescription,
                defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Current mileage (km)',
                render: (rowData) => (rowData.curMilKm ? rowData.curMilKm : ''),
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Current operational time (h)',
                render: (rowData) => (rowData.curOperTimeHours ? rowData.curOperTimeHours : ''),
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        const serviceFields = this.getServiceFields();

        let page = (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={classes.ScrollInner}>
                    <Loader show={this.props.loading} />
                </div>
            </Scrollbars>
        );

        if (this.props.loading === false) {
            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner} id='TabServicePrint'>
                        <DataTable
                            data={this.props.gseServices}
                            columns={serviceFields}
                            withExport
                            withSearch
                            noActions
                            tableOptions={this.state.tableOptions}
                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                        />
                    </div>
                </Scrollbars>
            );
        }
        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
        gseServices: state._data.gseServices,
    };
};

export default connect(mapStateToProps, null)(TabService);
