import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';
import { latLngBounds } from 'leaflet';

const initialState = {
    allUsers: [],
    allFields: {},
    allAirports: [],
    selectedAirport: null,
    selectedAirportLocations: [],
    rawAirportLocations: [],
    mapCenter: [40.519, 22.971],
    fgBounds: null,
    sims: [],
    devices: [],
    gseOptions: [],
    simOptions: [],
};

const getAllUsersSuccess = (state, action) => {
    const sortedUsers = action.users.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    return updateObject(state, { allUsers: sortedUsers });
};

const getAllListsSuccess = (state, action) => {
    //let sortedFields = action.fields.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
    let allFields = {};

    for (let field of action.fields) {
        if (!allFields[field.objectName]) {
            allFields[field.objectName] = [];
        }

        let fieldId = field.id;
        field.fieldChoices = action.fieldChoices.filter((x) => x.fieldId === fieldId);
        // let sortedChoices = filteredChoices.sort((a, b) => a.choice.localeCompare(b.choice));
        // field.fieldChoices = sortedChoices;

        allFields[field.objectName].push(field);
    }

    return updateObject(state, { allFields: allFields });
};

const getAllAirportsSuccess = (state, action) => {
    let sortedAirports = action.airports.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    return updateObject(state, { allAirports: sortedAirports });
};

const updateAdminState = (state, action) => {
    return updateObject(state, { [action.property]: action.value });
};

const getAirportLocationsSuccess = (state, action) => {
    let filteredLocations = [];

    for (let i = 0; i < action.airportLocations.length; i++) {
        const airportLocation = action.airportLocations[i];

        if (airportLocation.area && airportLocation.area.length) {
            filteredLocations.push(airportLocation);
        }
    }

    // const sortedAirportLocations = action.airportLocations.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    const sortedAirportLocations = filteredLocations.sort((a, b) => parseInt(b.id) - parseInt(a.id));

    let bounds;

    if (action.airportLocations.length) bounds = latLngBounds();

    action.airportLocations.forEach((location) => {
        if (location.area && location.area.length) {
            bounds.extend(location.area);
        } else {
            bounds.extend([location.lat, location.lon]);
        }
    });

    return updateObject(state, { selectedAirportLocations: sortedAirportLocations, fgBounds: bounds, rawAirportLocations: action.airportLocations });
};

const updateMapCenterSuccess = (state, action) => {
    // let bounds = latLngBounds();

    // bounds.extend([action.firstResult.geometry.coordinates[1], action.firstResult.geometry.coordinates[0]]);

    const mapCenter = [action.firstResult.geometry.coordinates[1], action.firstResult.geometry.coordinates[0]];

    return updateObject(state, { selectedAirportLocations: [], fgBounds: null, mapCenter: mapCenter });
};

const getTrackersSuccess = (state, action) => {
    for (let device of action.devices) {
        let simId = device.simId;
        let foundSim = action.sims.find((s) => s.id === simId);
        device.phoneNumber = '';

        if (foundSim) {
            device.phoneNumber = foundSim.phoneNumber;
        }
    }

    const sortedDevices = action.devices.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    const sortedSims = action.sims.sort((a, b) => parseInt(b.id) - parseInt(a.id));

    let gseOptions = [];
    for (let gse of action.gses) {
        let isAssigned = false;
        let foundInDevice = sortedDevices.find((d) => d.gseid === gse.gseId);

        if (foundInDevice) {
            isAssigned = true;
        }

        gseOptions.push({
            label: 'id: ' + gse.gseId + ' | info: ' + gse.info,
            id: gse.gseId,
            info: gse.info,
            isAssigned: isAssigned,
        });
    }

    let simOptions = [];
    for (let sim of action.sims) {
        let isAssigned = false;
        let foundInDevice = sortedDevices.find((d) => d.simId === sim.id);

        if (foundInDevice) isAssigned = true;

        simOptions.push({
            label: 'id: ' + sim.id + ' | number: ' + sim.phoneNumber,
            id: sim.id,
            phoneNumber: sim.phoneNumber,
            isAssigned: isAssigned,
        });
    }

    return updateObject(state, { devices: sortedDevices, sims: sortedSims, simOptions: simOptions, gseOptions: gseOptions });
};
const getSimsSuccess = (state, action) => {
    const sortedSims = action.sims.sort((a, b) => parseInt(b.id) - parseInt(a.id));
    return updateObject(state, { sims: sortedSims });
};

const resetAdmin = (state) => {
    const resetState = { ...initialState };
    return resetState;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_USERS_SUCCESS:
            return getAllUsersSuccess(state, action);
        case actionTypes.GET_ALL_LISTS_SUCCESS:
            return getAllListsSuccess(state, action);
        case actionTypes.GET_ALL_AIRPORTS_SUCCESS:
            return getAllAirportsSuccess(state, action);
        case actionTypes.UPDATE_ADMIN_STATE:
            return updateAdminState(state, action);
        case actionTypes.GET_AIRPORT_LOCATIONS_SUCCESS:
            return getAirportLocationsSuccess(state, action);
        case actionTypes.UPDATE_MAP_CENTER_SUCCESS:
            return updateMapCenterSuccess(state, action);
        case actionTypes.GET_TRACKERS_SUCCESS:
            return getTrackersSuccess(state, action);
        case actionTypes.GET_SIMS_SUCCESS:
            return getSimsSuccess(state, action);
        case actionTypes.RESET_ADMIN:
            return resetAdmin(state);
        default:
            return state;
    }
};

export default reducer;
