import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

import classes from './Admin.module.css';
import * as actions from '../../store/actions/index';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import AdminEditSim from './AdminEditSim';
import Modal from '../../components/UI/Modal/Modal';
import { formatDate, dateTimeToISO } from '../../store/utilities';

class AdminSims extends Component {
    state = {
        showModal: false,
        selectedData: null,
        action: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
    };

    componentDidMount() {
        this.props.onGetTrackers();
    }

    getSimFields = () => {
        let tableFields = [
            { title: 'id', field: 'id', defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null },
            {
                title: 'Serial number',
                field: 'serialNo',
                defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Purchased date',
                field: 'purchasedDate',
                render: (rowData) => formatDate(rowData.purchasedDate),
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Phone number',
                field: 'phoneNumber',
                defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Comments',
                field: 'comments',
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    addSim() {
        let now = new Date();
        now = dateTimeToISO(now);

        const selectedData = {
            id: 0,
            serialNo: '',
            purchasedDate: now,
            phoneNumber: '',
            comments: '',
        };

        this.setState({ selectedData: selectedData, showModal: true, action: 'new' });
    }

    editSim(data) {
        const selectedData = { ...data };
        this.setState({ selectedData: selectedData, showModal: true, action: 'edit' });
    }

    prepareDeleteSim(rowData) {
        // Check first if the sim is assigned to a device
        let isAssigned = this.props.devices.find((d) => d.simId === rowData.id);

        if (isAssigned) {
            Swal.fire({
                type: 'error',
                text: 'The sim is assigned to a device. Please remove it from the device before deleting it.',
            });
            return;
        }

        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The sim with id: ' + rowData.id + ' will be deleted.',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteSim(rowData.id);
            },
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The sim was deleted.',
                });
            }
        });
    }

    prepareSaveSim = (postData) => {
        const invalidNum = document.getElementsByClassName('Mui-error').length;
        if (invalidNum > 0) {
            Swal.fire({
                type: 'error',
                text: 'Please fill in all the mandatory fields',
            });
            return;
        }

        let sureMsg = 'A new sim will be created.';
        let confirmMsg = 'The new sim was created';

        if (this.state.action === 'edit') {
            sureMsg = 'The sim details will be updated.';
            confirmMsg = 'The sim details were updated.';
        }

        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: sureMsg,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onUpdateSim(postData);
                this.setState({ showModal: false, action: null });
            },
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: confirmMsg,
                });
            }
        });
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        const simFields = this.getSimFields();

        let page = (
            <Aux>
                <div className={classes.FiltersSpace}></div>
                <div className={classes.PageTitle}>
                    <h1>Sim cards</h1>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </Aux>
        );

        if (this.props.loading === false) {
            page = (
                <Aux>
                    <div className={classes.FiltersSpace}></div>
                    <div className={classes.PageTitle}>
                        <h1>Sim cards</h1>
                        <div className={classes.MarginLeftAuto}>
                            <div className={classes.ActionButtonsWrapper}>
                                <div className={classes.CancelButton} onClick={() => this.props.history.push('/admin')}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.PageContent}>
                        <div className={classes.PageInner}>
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <div className={classes.ScrollInner}>
                                    <div className={classes.AddUserButtonWrapper}>
                                        <PrimaryButton clicked={() => this.addSim()}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            Add
                                        </PrimaryButton>
                                    </div>

                                    <DataTable
                                        data={this.props.sims}
                                        columns={simFields}
                                        editClicked={(rowData) => this.editSim(rowData)}
                                        deleteClicked={(rowData) => this.prepareDeleteSim(rowData)}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    />
                                </div>
                            </Scrollbars>
                        </div>
                    </div>

                    <Modal show={this.state.showModal} modalClosed={() => this.setState({ showModal: false, action: null })}>
                        {this.state.action === 'edit' || this.state.action === 'new' ? (
                            <AdminEditSim
                                selectedData={this.state.selectedData}
                                saveSim={(data) => this.prepareSaveSim(data)}
                                cancel={() => this.setState({ showModal: false, action: null })}
                            />
                        ) : null}
                    </Modal>
                </Aux>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.loggedInUser,
        loading: state._system.loading,
        sims: state.admin.sims,
        devices: state.admin.devices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onGetSims: () => dispatch(actions.getSims()),
        onGetTrackers: () => dispatch(actions.getTrackers()),
        onUpdateSim: (postData) => dispatch(actions.updateSim(postData)),
        onDeleteSim: (simId) => dispatch(actions.deleteSim(simId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSims);
