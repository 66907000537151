import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import asyncComponent from "./components/AsyncComponent";
import Layout from './hoc/Layout/Layout';
import * as actions from './store/actions/index';

// const AsyncNewProject = asyncComponent(() => import("./containers/NewProject/NewProject"));

import Login from './containers/Login/Login';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import GseData from './containers/GseData/GseData';
import Service from './containers/Service/Service';
import FlightInfo from './containers/FlightInfo/FlightInfo';
import Notifications from './containers/Notifications/Notifications';
import Admin from './containers/Admin/Admin';
import AdminUsers from './containers/Admin/AdminUsers';
import AdminLists from './containers/Admin/AdminLists';
import AdminAirports from './containers/Admin/AdminAirports';
import AdminTrackers from './containers/Admin/AdminTrackers';
import AdminSims from './containers/Admin/AdminSims';
import AdminEditAirport from './containers/Admin/AdminEditAirport';
import AdminSymbology from './containers/Admin/AdminSymbology';
import LocationMap from './containers/LocationMap/LocationMap';
import TripHistory from './containers/TripHistory/TripHistory';
import Statistics from './containers/Statistics/Statistics';
import StatisticsCompany from './containers/Statistics/StatisticsCompany';
import StatisticsAirport from './containers/Statistics/StatisticsAirport';
import StatisticsEquipment from './containers/Statistics/StatisticsEquipment';
import StatisticsEquipmentAirport from './containers/Statistics/StatisticsEquipmentAirport';
import StatisticsGse from './containers/Statistics/StatisticsGse';
import Tracking from './containers/Tracking/Tracking';
// import Page404 from './components/Page404/Page404';

const CONFIG = require('./reactConfig.json');

class App extends Component {
    componentDidMount() {
        this.props.onGetLists();
        this.props.onGetAirports();
        this.props.onGetGsePreFilter();
    }

    render() {
        const user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));

        return (
            <div className='main-theme'>
                <Layout user={user}>
                    <Switch>
                        {/* <Route path="/queries" component={AsyncQueries} />
            <Route path="/" exact component={AsyncNewProject} /> */}

                        <PrivateRoute path={`${process.env.PUBLIC_URL}/gseData`} user={user} component={GseData} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/service`} user={user} component={Service} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/flightInfo`} user={user} component={FlightInfo} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/notifications`} user={user} component={Notifications} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/admin`} user={user} component={Admin} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/adminUsers`} user={user} component={AdminUsers} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/adminLists`} user={user} component={AdminLists} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/adminAirports`} user={user} component={AdminAirports} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/adminTrackers`} user={user} component={AdminTrackers} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/adminSims`} user={user} component={AdminSims} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/adminEditAirport`} user={user} component={AdminEditAirport} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/adminSymbology`} user={user} component={AdminSymbology} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/locationMap`} user={user} component={LocationMap} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/tripHistory`} user={user} component={TripHistory} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/statistics`} user={user} component={Statistics} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/statisticsCompany`} user={user} component={StatisticsCompany} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/statisticsAirport`} user={user} component={StatisticsAirport} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/statisticsEquipment`} user={user} component={StatisticsEquipment} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/statisticsEquipmentAirport`} user={user} component={StatisticsEquipmentAirport} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/statisticsGse`} user={user} component={StatisticsGse} />
                        <PrivateRoute path={`${process.env.PUBLIC_URL}/tracking`} user={user} component={Tracking} />
                        <Route path='/login' component={Login} />
                        <Route path='/' exact component={Login} />
                        <Redirect from='*' to={`${process.env.PUBLIC_URL}/gseData`} />
                    </Switch>
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ddLists: state._data.ddLists,
        airports: state._data.airports,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onAuthCheckState: () => dispatch(actions.authCheckState()),
        onGetLists: () => dispatch(actions.getLists()),
        onGetAirports: () => dispatch(actions.getAirports()),
        onGetGsePreFilter: () => dispatch(actions.getGsePreFilter()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
