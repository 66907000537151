import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faDownload } from '@fortawesome/free-solid-svg-icons';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Swal from 'sweetalert2';

import classes from './GseEdit.module.css';
import Block from '../../components/Block/Block';
import Loader from '../../components/UI/Loader/Loader';
import PrimaryInput2 from '../../components/UI/PrimaryInput/PrimaryInput2';
import PrimarySelect from '../../components/UI/PrimarySelect/PrimarySelect';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import Dropzone from '../../components/Dropzone/Dropzone';
import * as actions from '../../store/actions/index';
import PrimaryYearPickerInput from '../../components/UI/PrimaryDatetimePicker/PrimaryYearPickerInput';
import AuthenticatedLink from '../../components/AuthenticatedLink/AuthenticatedLink';
import Tooltip from '../../components/Tooltip/Tooltip';
import SideFilters from '../../components/Filters/SideFilters';

class GseEdit extends Component {
    state = {
        localGseDetails: {
            generalInfo: {},
            engineSpecs: {},
            technicalSpecs: {},
            auxEngine: {},
            tyre: {},
        },
    };

    componentDidMount() {
        let gseId = -1;

        if (this.props.editGseId) {
            gseId = this.props.editGseId;
        }

        // this.props.onUpdateBreadcrumbs(["GSE Data"])
        this.props.onGetGseEditDetails(gseId);
    }

    shouldComponentUpdate(nextProps) {
        return this.props.gseDetails !== nextProps.gseDetails || this.props.loading !== nextProps.loading;
    }

    prepareDeleteFile = (fileId, gseId, fileHash) => {
        // If the gse has not been saved just delete it
        if (gseId < 0) {
            this.props.onDeleteFile(fileId, -1, fileHash);
            return;
        }

        // If new file (the file has not been saved) just delete it
        if (!fileId) {
            this.props.onDeleteFile(null, gseId, fileHash);
            return;
        }

        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The action is irreversible',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteFile(fileId, gseId, fileHash);
            },
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The file was deleted',
                });
            }
        });
    };

    prepareSaveGse = () => {
        const invalidNum = document.getElementsByClassName('Mui-error').length;
        if (invalidNum > 0) {
            Swal.fire({
                type: 'error',
                text: 'Please fill in all the mandatory fields',
            });
            return;
        }

        let text = 'You are going to update the GSE';

        if (!this.props.gseDetails.id) {
            text = 'You are going to save the GSE';
        }

        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: text,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                // Get all the properties which are not included in the localGseDetails from the props.gseDetails
                let postData = { ...this.props.gseDetails };
                if (!postData.tyre)
                    postData.tyre = {
                        manufacturer: null,
                        rollingDiameter: 0,
                        dimensionsWidth: 0,
                        dimensionsAspect: 0,
                        dimensionsDiameter: 0,
                    };

                for (const category in postData) {
                    if (category !== 'uploads' && category !== 'images') {
                        for (const property in postData[category]) {
                            if (
                                this.state.localGseDetails[category][property] ||
                                this.state.localGseDetails[category][property] === '' ||
                                this.state.localGseDetails[category][property] === null ||
                                this.state.localGseDetails[category][property] === 0
                            )
                                // Check if the property exists in the localGseDetails
                                postData[category][property] = this.state.localGseDetails[category][property];
                        }
                    }
                }

                this.props.onSaveGse(postData, this.props.filesToUpload);

                this.setState({
                    localGseDetails: {
                        generalInfo: {},
                        engineSpecs: {},
                        technicalSpecs: {},
                        auxEngine: {},
                        tyre: {},
                    },
                });
            },
        });
    };

    updateLocalState = (category, property, value, isId) => {
        let localGseDetails = { ...this.state.localGseDetails };

        if (moment.isMoment(value)) {
            value = value.format('YYYY-MM-DDTHH:mm:ss');
        } else if (isId) {
            if (value.trim() === '') {
                value = null;
            } else {
                value = parseInt(value);
            }
        }

        localGseDetails[category][property] = value;

        this.setState({ localGseDetails: localGseDetails });
    };

    onCancel = () => {
        let hasChanges = false;

        const keys = Object.keys(this.state.localGseDetails);
        keys.forEach((key, index) => {
            const obj = this.state.localGseDetails[key];
            if (Object.keys(obj).length) hasChanges = true;
        });

        if (hasChanges) {
            Swal.fire({
                type: 'warning',
                title: 'Are you sure?',
                text: 'All the changes will be lost',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                allowOutsideClick: false,
                preConfirm: () => {
                    this.setState({
                        localGseDetails: {
                            generalInfo: {},
                            engineSpecs: {},
                            technicalSpecs: {},
                            auxEngine: {},
                            tyre: {},
                        },
                    });
                    this.props.onCancel();
                },
            });
        } else {
            this.props.onCancel();
        }
    };

    gotoView = () => {
        let hasChanges = false;

        const keys = Object.keys(this.state.localGseDetails);
        keys.forEach((key, index) => {
            const obj = this.state.localGseDetails[key];
            if (Object.keys(obj).length) hasChanges = true;
        });

        if (hasChanges) {
            Swal.fire({
                type: 'warning',
                title: 'Are you sure?',
                text: 'All the changes will be lost',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                allowOutsideClick: false,
                preConfirm: () => {
                    this.setState({
                        localGseDetails: {
                            generalInfo: {},
                            engineSpecs: {},
                            technicalSpecs: {},
                            auxEngine: {},
                            tyre: {},
                        },
                    });
                    this.props.gotoDetails();
                },
            });
        } else {
            this.props.gotoDetails();
        }
    };

    render() {
        let page = (
            <Aux>
                <div className={classes.FiltersSpace}>
                    <SideFilters />
                </div>
                <div className={classes.PageTitle}>
                    <h1>{this.props.gseDetails ? this.props.gseDetails.generalInfo.info : ''}</h1>
                    <div className={classes.ModalButtonsWrapper}>
                        <PrimaryButton clicked={() => this.onCancel()}>Cancel</PrimaryButton>
                    </div>
                </div>
                <div className={classes.PageContent}>
                    <div className={classes.PageInner}>
                        <Loader show={this.props.loading} />
                    </div>
                </div>
            </Aux>
        );

        if (this.props.gseDetails) {
            page = (
                <Aux>
                    <div className={classes.FiltersSpace}>
                        <SideFilters />
                    </div>
                    <div className={classes.PageTitle}>
                        <h1>{this.props.gseDetails ? this.props.gseDetails.generalInfo.info : ''}</h1>
                        <div className={classes.ActionButtonsWrapper}>
                            <PrimaryButton clicked={() => this.prepareSaveGse()}>
                                <FontAwesomeIcon icon={faSave} />
                                Save
                            </PrimaryButton>
                            {this.props.gseDetails && this.props.gseDetails.id ? (
                                <PrimaryButton clicked={() => this.gotoView()}>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#8D9CAB'>
                                        <path d='M20.036 17.31l-4.724-4.724a9.1 9.1 0 0 1-2.727 2.727l4.724 4.724a1.934 1.934 0 0 0 2.727-2.727z' />
                                        <path d='M15.45 7.725a7.725 7.725 0 1 0-7.715 7.725 7.725 7.725 0 0 0 7.715-7.725zm-7.715 5.8a5.791 5.791 0 1 1 5.791-5.791 5.791 5.791 0 0 1-5.791 5.791z' />
                                        <path d='M3.223 7.725h1.289a3.17 3.17 0 0 1 3.17-3.17v-1.29a4.513 4.513 0 0 0-4.46 4.46z' />
                                    </svg>
                                    View GSE
                                </PrimaryButton>
                            ) : null}

                            <div className={classes.CancelButton} onClick={() => this.onCancel()}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.PageContent}>
                        <div className={classes.PageInner}>
                            <Loader show={this.props.loading} />

                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <div className={classes.ScrollInner}>
                                    <div className={classes.Row}>
                                        <div className={classes.Column}>
                                            <div className={classes.BlockItem}>
                                                <Block title='Files (new files will be uploaded on save)'>
                                                    <div className={classes.GseFilesWrapper}>
                                                        <Dropzone
                                                            label='Files to upload'
                                                            fileType='document'
                                                            filesProperty='filesToUpload'
                                                            acceptedFiles={['image/*', 'application/pdf']}
                                                            maxFiles={20}
                                                        />

                                                        <div className={classes.FilesList}>
                                                            {this.props.gseDetails &&
                                                            this.props.gseDetails.uploads &&
                                                            this.props.gseDetails.uploads.find((f) => f.fileType === 'document') ? (
                                                                <Aux>
                                                                    <h5 className={classes.UploadFilesTitle}>Uploaded files</h5>
                                                                    {this.props.gseDetails.uploads.map((fileItem, index) => {
                                                                        if (fileItem.fileType === 'document') {
                                                                            return (
                                                                                <div className={classes.FileItem} key={index}>
                                                                                    {fileItem.originalName}
                                                                                    <div className={classes.FileActions}>
                                                                                        <AuthenticatedLink
                                                                                            filename={fileItem.filePath}
                                                                                            savename={fileItem.originalName}
                                                                                            subfolder={fileItem.gseId}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faDownload} />
                                                                                        </AuthenticatedLink>

                                                                                        <DeleteOutline
                                                                                            className={classes.DeleteIcon}
                                                                                            onClick={() =>
                                                                                                this.prepareDeleteFile(
                                                                                                    fileItem.id,
                                                                                                    fileItem.gseId,
                                                                                                    fileItem.filePath
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })}
                                                                </Aux>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Block>
                                            </div>

                                            <div className={classes.BlockItem}>
                                                <Block title='Image (new image will be uploaded on save)'>
                                                    {this.props.gseDetails && this.props.gseDetails.images && this.props.gseDetails.images.length ? (
                                                        <div className={[classes.GseImageWrapper, classes.WithImage].join(' ')}>
                                                            <div>
                                                                <img src={this.props.gseDetails.images[0].imageData} alt='GSE' />
                                                            </div>

                                                            <div className={classes.DeleteImageWrapper}>
                                                                <div
                                                                    className={classes.DeleteImage}
                                                                    onClick={() =>
                                                                        this.prepareDeleteFile(
                                                                            this.props.gseDetails.images[0].fileId,
                                                                            this.props.gseDetails.uploads[0].gseId,
                                                                            this.props.gseDetails.images[0].imageHash
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteOutline className={classes.DeleteIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={classes.GseImageWrapper}>
                                                            <Dropzone label='Image to upload' fileType='image' acceptedFiles={['image/*']} maxFiles={1} />
                                                        </div>
                                                    )}
                                                </Block>
                                            </div>

                                            <div className={classes.BlockItem}>
                                                <Block title='General information'>
                                                    <ul className={classes.InfoList}>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='GSE ID'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.info}
                                                                name='info'
                                                                fullWidth
                                                                disabled
                                                            />
                                                            {/* <Tooltip text="Example: GH 240104" /> */}
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Date of purchase'
                                                                type='text'
                                                                defaultValue={
                                                                    this.props.gseDetails.generalInfo.purchaseDate
                                                                        ? moment(this.props.gseDetails.generalInfo.purchaseDate).format('MMMM YYYY')
                                                                        : ''
                                                                }
                                                                name='purchaseDate'
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Airport'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.airportCode}
                                                                name='airportId'
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </li>

                                                        <li>
                                                            <PrimaryInput2
                                                                label='Vehicle type'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.vehicleType}
                                                                name='vehicleType'
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Equipment type'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.equipmentType}
                                                                name='equipmentType'
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Manufacturer'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.manufacturer}
                                                                name='manufacturer'
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Model'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.model}
                                                                name='model'
                                                                fullWidth
                                                                disabled
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Use'
                                                                options={this.props.ddLists.use}
                                                                defaultValue={
                                                                    this.props.gseDetails.generalInfo.use
                                                                        ? this.props.gseDetails.generalInfo.use.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('generalInfo', 'use', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Serial number'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.serialNumber}
                                                                blur={(e) => this.updateLocalState('generalInfo', e.target.name, e.target.value)}
                                                                name='serialNumber'
                                                                fullWidth
                                                            />
                                                            <Tooltip text='Example: G 00078' />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='License plate'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.generalInfo.licencePlate}
                                                                blur={(e) => this.updateLocalState('generalInfo', e.target.name, e.target.value)}
                                                                name='licencePlate'
                                                                fullWidth
                                                            />
                                                            <Tooltip text='Example: 95662' />
                                                        </li>
                                                    </ul>
                                                </Block>
                                            </div>

                                            <div className={classes.BlockItem}>
                                                <Block title='Main engine specifications'>
                                                    <ul className={classes.InfoList}>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='ID / Name'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.engineSpecs.name}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='name'
                                                                fullWidth
                                                            />
                                                            <Tooltip text='Example: OM 602' />
                                                        </li>
                                                        <li>
                                                            <PrimaryYearPickerInput
                                                                label='Year of production'
                                                                value={
                                                                    this.state.localGseDetails.engineSpecs.productionYear
                                                                        ? this.state.localGseDetails.engineSpecs.productionYear.toString()
                                                                        : this.props.gseDetails.engineSpecs && this.props.gseDetails.engineSpecs.productionYear
                                                                        ? this.props.gseDetails.engineSpecs.productionYear.toString()
                                                                        : null
                                                                }
                                                                dateChanged={(value) => this.updateLocalState('engineSpecs', 'productionYear', value)}
                                                                fullWidth
                                                                maxDate={new Date()}
                                                                isClearable
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Manufacturer'
                                                                options={this.props.ddLists.engineManufacturer}
                                                                defaultValue={
                                                                    this.props.gseDetails.engineSpecs.manufacturer
                                                                        ? this.props.gseDetails.engineSpecs.manufacturer.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('engineSpecs', 'manufacturer', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Model'
                                                                options={this.props.ddLists.engineModel}
                                                                defaultValue={
                                                                    this.props.gseDetails.engineSpecs.model
                                                                        ? this.props.gseDetails.engineSpecs.model.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('engineSpecs', 'model', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Displacement (cm3)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.displacement}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='displacement'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Emission standard'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.engineSpecs.euroStandard}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='euroStandard'
                                                                fullWidth
                                                            />
                                                            <Tooltip text='Example: Euro 3' />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Fuel consumption (manufacturer) (l/km)'
                                                                type='number'
                                                                step='0.1'
                                                                defaultValue={this.props.gseDetails.engineSpecs.fuelConsumption}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='fuelConsumption'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Fuel'
                                                                options={this.props.ddLists.engineFuel}
                                                                defaultValue={
                                                                    this.props.gseDetails.engineSpecs.fuel
                                                                        ? this.props.gseDetails.engineSpecs.fuel.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('engineSpecs', 'fuel', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Fuel tank capacity (lt)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.fuelTankCapacity}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='fuelTankCapacity'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Autonomy cycle (km)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.autonomyCycle}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='autonomyCycle'
                                                                fullWidth
                                                            />
                                                        </li>

                                                        <li>
                                                            <PrimaryInput2
                                                                label='CO2 emissions (manufacturer) (g/km)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.co2Emissions}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='co2Emissions'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='No of cylinders'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.noCylinders}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='noCylinders'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Cylinder configuration'
                                                                options={this.props.ddLists.cylinderConfiguration}
                                                                defaultValue={
                                                                    this.props.gseDetails.engineSpecs.cylinderConfiguration
                                                                        ? this.props.gseDetails.engineSpecs.cylinderConfiguration.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('engineSpecs', 'cylinderConfiguration', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Naturally aspirated or turbocharged'
                                                                options={this.props.ddLists.turboCharged}
                                                                defaultValue={
                                                                    this.props.gseDetails.engineSpecs.aspiratedTurbocharged
                                                                        ? this.props.gseDetails.engineSpecs.aspiratedTurbocharged.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('engineSpecs', 'aspiratedTurbocharged', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li className={classes.MultipleInputs}>
                                                            <PrimaryInput2
                                                                label='Speed range (min)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.speedRangeMin}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='speedRangeMin'
                                                                fullWidth
                                                            />
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <span>-</span>
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <PrimaryInput2
                                                                label='Speed range (max)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.speedRangeMax}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='speedRangeMax'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Maximum power (kW)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.maximumPower}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='maximumPower'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Idle speed (rpm)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.idleSpeed}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='idleSpeed'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Maximum torque (Nm)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.engineSpecs.maximumTorque}
                                                                blur={(e) => this.updateLocalState('engineSpecs', e.target.name, e.target.value)}
                                                                name='maximumTorque'
                                                                fullWidth
                                                            />
                                                        </li>
                                                    </ul>
                                                </Block>
                                            </div>
                                        </div>

                                        <div className={classes.Separator}></div>

                                        <div className={classes.Column}>
                                            <div className={classes.BlockItem}>
                                                <Block title='Technical specifications'>
                                                    <ul className={classes.InfoList}>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Operating weight (kg)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.operatingWeight}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='operatingWeight'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Curb weight (kg)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.curbWeight}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='curbWeight'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Load / Cargo type'
                                                                options={this.props.ddLists.LoadCargoType}
                                                                defaultValue={
                                                                    this.props.gseDetails.technicalSpecs.loadCargoType
                                                                        ? this.props.gseDetails.technicalSpecs.loadCargoType.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('technicalSpecs', 'loadCargoType', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Load / Cargo capacity (kg)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.loadCargoCapacity}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='loadCargoCapacity'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Height (mm)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.height}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='height'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Length (mm)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.length}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='length'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Width (mm)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.width}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='width'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Wheelbase (mm)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.wheelbase}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='wheelbase'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Frontal area (m2)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.frontalArea}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='frontalArea'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Drag coefficient (Cd)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.dragCoefficient}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='dragCoefficient'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Drivetrain'
                                                                options={this.props.ddLists.drivetrain}
                                                                defaultValue={
                                                                    this.props.gseDetails.technicalSpecs.drivetrain
                                                                        ? this.props.gseDetails.technicalSpecs.drivetrain.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('technicalSpecs', 'drivetrain', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li className={classes.MultipleInputs}>
                                                            <PrimaryInput2
                                                                label='Gear box type (speed)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.gearBoxTypeSpeed}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='gearBoxTypeSpeed'
                                                                fullWidth
                                                            />
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <span>/</span>
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <PrimaryInput2
                                                                label='Gear box type (reverse)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.gearBoxTypeReverse}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='gearBoxTypeReverse'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Single / multiple - Speed gear box'
                                                                options={this.props.ddLists.speedGearBox}
                                                                defaultValue={
                                                                    this.props.gseDetails.technicalSpecs.gearBox
                                                                        ? this.props.gseDetails.technicalSpecs.gearBox.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('technicalSpecs', 'gearBox', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Gear ratio (for each speed/reverse)'
                                                                type='text'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.gearRatio}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='gearRatio'
                                                                fullWidth
                                                            />
                                                            <Tooltip text='Example: 3.72, 2.13, 1.32, 0.89, 0.67 / 3.8' />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Final drive'
                                                                type='number'
                                                                step='0.01'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.finalDrive}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='finalDrive'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='No of gears'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.gears}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='gears'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='No of axles'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.axles}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='axles'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='No of wheels'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.wheels}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='wheels'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='No of seats'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.technicalSpecs.seats}
                                                                blur={(e) => this.updateLocalState('technicalSpecs', e.target.name, e.target.value)}
                                                                name='seats'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Battery voltage'
                                                                options={this.props.ddLists.batteryVoltage}
                                                                defaultValue={
                                                                    this.props.gseDetails.technicalSpecs.batteryVoltage
                                                                        ? this.props.gseDetails.technicalSpecs.batteryVoltage.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('technicalSpecs', 'batteryVoltage', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Air conditioning'
                                                                options={[
                                                                    { label: 'Yes', value: 'Yes' },
                                                                    { label: 'No', value: 'No' },
                                                                ]}
                                                                defaultValue={
                                                                    this.props.gseDetails.technicalSpecs.airConditioning
                                                                        ? this.props.gseDetails.technicalSpecs.airConditioning.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('technicalSpecs', 'airConditioning', value)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='OBD port'
                                                                options={[
                                                                    { label: 'Yes', value: 'Yes' },
                                                                    { label: 'No', value: 'No' },
                                                                ]}
                                                                defaultValue={
                                                                    this.props.gseDetails.technicalSpecs.obdPort
                                                                        ? this.props.gseDetails.technicalSpecs.obdPort.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('technicalSpecs', 'obdPort', value)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                    </ul>
                                                </Block>
                                            </div>

                                            <div className={classes.BlockItem}>
                                                <Block title='Tyres'>
                                                    <ul className={classes.InfoList}>
                                                        <li className={classes.MultipleInputs}>
                                                            <PrimaryInput2
                                                                label='Tyre width'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.tyre ? this.props.gseDetails.tyre.dimensionsWidth : ''}
                                                                blur={(e) => this.updateLocalState('tyre', e.target.name, e.target.value)}
                                                                name='dimensionsWidth'
                                                                fullWidth
                                                            />
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <span>/</span>
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <PrimaryInput2
                                                                label='Tyre aspect'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.tyre ? this.props.gseDetails.tyre.dimensionsAspect : ''}
                                                                blur={(e) => this.updateLocalState('tyre', e.target.name, e.target.value)}
                                                                name='dimensionsAspect'
                                                                fullWidth
                                                            />
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <span>R</span>
                                                            <div className={classes.HorizontalSpacer}></div>
                                                            <PrimaryInput2
                                                                label='Tyre diameter'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.tyre ? this.props.gseDetails.tyre.dimensionsDiameter : ''}
                                                                blur={(e) => this.updateLocalState('tyre', e.target.name, e.target.value)}
                                                                name='dimensionsDiameter'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimarySelect
                                                                label='Manufacturer'
                                                                options={this.props.ddLists.tyreManufacturer}
                                                                defaultValue={
                                                                    this.props.gseDetails.tyre && this.props.gseDetails.tyre.manufacturer
                                                                        ? this.props.gseDetails.tyre.manufacturer.toString()
                                                                        : ''
                                                                }
                                                                changed={(value) => this.updateLocalState('tyre', 'manufacturer', value, true)}
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2
                                                                label='Rolling diameter (mm)'
                                                                type='number'
                                                                defaultValue={this.props.gseDetails.tyre ? this.props.gseDetails.tyre.rollingDiameter : ''}
                                                                blur={(e) => this.updateLocalState('tyre', e.target.name, e.target.value)}
                                                                name='rollingDiameter'
                                                                fullWidth
                                                            />
                                                        </li>
                                                        {/* <li>
                                                            <PrimaryInput2
                                                                label="Front tyre pressure (manufacturer) (bar)"
                                                                type="number"
                                                                defaultValue={this.props.gseDetails.tyre ? this.props.gseDetails.tyre.frontPressure : ""}
                                                                blur={ (e) => this.updateLocalState("tyre", e.target.name, e.target.value) }
                                                                name="frontPressure"
                                                                fullWidth
                                                            />
                                                        </li>
                                                        <li>
                                                            <PrimaryInput2 
                                                                label="Rear tyre pressure (manufacturer) (bar)"
                                                                type="number"
                                                                defaultValue={this.props.gseDetails.tyre ? this.props.gseDetails.tyre.rearPressure : ""}
                                                                blur={ (e) => this.updateLocalState("tyre", e.target.name, e.target.value) }
                                                                name="rearPressure"
                                                                fullWidth
                                                            />
                                                        </li> */}
                                                    </ul>
                                                </Block>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </Aux>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        gseDetails: state._data.gseEditDetails,
        loading: state._system.loading,
        filesToUpload: state._data.filesToUpload,
        ddLists: state._data.ddLists,
        airportsOptions: state._data.airportsOptions,
        editGseId: state.gseData.editGseId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetGseEditDetails: (gseId) => dispatch(actions.getGseEditDetails(gseId)),
        onUpdateGseProperty: (category, property, value) => dispatch(actions.updateGseProperty(category, property, value)),
        onSaveGse: (gseDetails, filesToUpload) => dispatch(actions.saveGse(gseDetails, filesToUpload)),
        onDeleteFile: (fileId, gseId, fileHash) => dispatch(actions.deleteFile(fileId, gseId, fileHash)),
        // onUpdateBreadcrumbs: (breadcrumbs) => dispatch(actions.updateBreadcrumbs(breadcrumbs)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GseEdit);
