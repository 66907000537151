import React from 'react';
import Button from '@material-ui/core/Button';

import classes from './PrimaryButton.module.css';

const PrimaryButton = (props) => {
    let color = "primary";
    if (props.color === "secondary") color = "secondary";
    
    let classNames = [classes.PrimaryButton];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    if (props.iconButton) classNames.push(classes.IconButton)
    if (props.danger) classNames.push(classes.DangerButton)

    return (
        <Button 
            className={classNames.join(' ')}
            variant="contained" 
            color={color} 
            onClick={props.clicked}
            disabled={props.disabled}
            size={props.size}
        >
            {props.children}
        </Button>
    )
}

export default PrimaryButton;