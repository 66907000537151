import * as actionTypes from './actionTypes';
import axios from '../../axios-api';
import axiosDb from '../../axios-db';
import axiosRoot from 'axios';
import Swal from 'sweetalert2';
import countryList from 'react-select-country-list';

import { loading } from './_system';
import { getResets } from './_data';

// All data --------------------------------
export const getAdminData = () => {
    return (dispatch) => {
        dispatch(loading(true));

        const requestOne = axiosDb.get('admin_getAllUsers.php');
        const requestTwo = axios.get('Field/-1');
        const requestThree = axios.get('FieldChoice/-1');

        axiosRoot
            .all([requestOne, requestTwo, requestThree])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];

                    if (responseOne.status === 200) dispatch(getAllUsersSuccess(responseOne.data));
                    if (responseTwo.status === 200 && responseThree.status === 200) dispatch(getAllListsSuccess(responseTwo.data, responseThree.data));

                    if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200)
                        Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });

                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });
                dispatch(loading(false));
            });
    };
};

// Users --------------------------------
export const getAllUsers = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axiosDb
            .get('admin_getAllUsers.php')
            .then((response) => {
                dispatch(getAllUsersSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const getAllUsersSuccess = (users) => {
    return {
        type: actionTypes.GET_ALL_USERS_SUCCESS,
        users: users,
    };
};

export const updateUser = (postData) => {
    return (dispatch) => {
        dispatch(loading(true));

        axiosDb
            .post('admin_updateUser.php', postData)
            .then((response) => {
                dispatch(getAllUsers());
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const addUser = (postData) => {
    return (dispatch) => {
        dispatch(loading(true));

        axiosDb
            .post('admin_addUser.php', postData)
            .then((response) => {
                dispatch(getAllUsers());
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const deleteUser = (userTodeleteId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axiosDb
            .post('admin_deleteUser.php', userTodeleteId)
            .then((response) => {
                dispatch(getAllUsers());
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

// Lists --------------------------------
export const getAllLists = () => {
    return (dispatch) => {
        dispatch(loading(true));

        const requestOne = axios.get('Field/-1');
        const requestTwo = axios.get('FieldChoice/-1');

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status === 200 && responseTwo.status === 200) dispatch(getAllListsSuccess(responseOne.data, responseTwo.data));

                    if (responseOne.status !== 200 || responseTwo.status !== 200)
                        Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });

                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });
                dispatch(loading(false));
            });
    };
};

export const getAllListsSuccess = (fields, fieldChoices) => {
    return {
        type: actionTypes.GET_ALL_LISTS_SUCCESS,
        fields: fields,
        fieldChoices: fieldChoices,
    };
};

export const saveChoices = (listItem) => {
    return (dispatch) => {
        dispatch(loading(true));

        let promises = [];

        for (let i = 0; i < listItem.fieldChoices.length; i++) {
            let fieldChoice = listItem.fieldChoices[i];

            // Hack. Delete negative ids
            if (fieldChoice.id < 0) delete fieldChoice.id;

            // Delete empty strings
            if (fieldChoice.choice.trim() === '') continue;

            promises.push(
                axios.post('FieldChoice', fieldChoice).catch((error) => {
                    Swal.fire({ type: 'error', text: error.response.data.message });
                    dispatch(loading(false));
                })
            );
        }

        Promise.all(promises).then(() => {
            dispatch(getAllLists());
            dispatch(loading(false));
        });
    };
};

export const deleteField = (fieldId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .delete('FieldChoice/' + fieldId)
            .then((response) => {
                dispatch(getAllLists());
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

// Airports --------------------------------
export const getAllAirports = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('Airport/-1')
            .then((response) => {
                dispatch(getAllAirportsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const getAllAirportsSuccess = (airports) => {
    return {
        type: actionTypes.GET_ALL_AIRPORTS_SUCCESS,
        airports: airports,
    };
};

export const getAirportLocations = (selectedAirport) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('AirportLocation/' + selectedAirport.id)
            .then((response) => {
                dispatch(getAirportLocationsSuccess(response.data));

                if (response.data.length === 0) {
                    dispatch(updateMapCenter(selectedAirport.country, selectedAirport.city));
                } else {
                    dispatch(loading(false));
                }
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const getAirportLocationsSuccess = (airportLocations) => {
    return {
        type: actionTypes.GET_AIRPORT_LOCATIONS_SUCCESS,
        airportLocations: airportLocations,
    };
};

export const updateMapCenter = (country, city) => {
    return (dispatch) => {
        const countryLabel = countryList().getLabel(country);
        let cityWithCountry = city + ' ' + countryLabel;
        const API_KEY = 'pk.eyJ1IjoiZ2lrYXBhIiwiYSI6ImNpenpjdXNsNjAwMnkzMnJtcGx2aDRpZTAifQ.R3IwSWcHkImDLa8vlHbmYA';

        axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${cityWithCountry}.json?access_token=${API_KEY}`).then((res) => {
            // const cityCenter = [res.data.features[0].geometry.coordinates[1], res.data.features[0].geometry.coordinates[0]];
            if (res.data.features.length) {
                dispatch(updateMapCenterSuccess(res.data.features[0]));
            }

            dispatch(loading(false));
        });
    };
};

export const updateMapCenterSuccess = (firstResult) => {
    return {
        type: actionTypes.UPDATE_MAP_CENTER_SUCCESS,
        // mapCenter: mapCenter,
        firstResult: firstResult,
    };
};

export const saveAirport = (postData) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .post('Airport', postData)
            .then((response) => {
                let savedAirport = postData;
                savedAirport.id = response.data;
                dispatch(updateAdminState('selectedAirport', savedAirport));
                dispatch(getAllAirports(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const deleteAirport = (airportId) => {
    return (dispatch) => {
        dispatch(loading(true));

        // First check if there are any GSEs assigned to this airport
        axios
            .get('GsePreFilter/-1')
            .then((response) => {
                if (response.status !== 200) {
                    Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });
                    dispatch(loading(false));
                    return;
                }

                for (let gse of response.data) {
                    const thisAirportId = gse.airportId;
                    if (thisAirportId == airportId) {
                        Swal.fire({ type: 'error', text: 'There are GSEs assigned to this airport. Please remove them before deleting the airport.' });
                        dispatch(loading(false));
                        return;
                    }
                }

                axios
                    .delete('Airport/' + airportId)
                    .then((response) => {
                        dispatch(getAllAirports(response.data));
                        dispatch(loading(false));
                        Swal.fire({ type: 'success', text: 'The airport was deleted.' });
                    })
                    .catch((error) => {
                        Swal.fire({ type: 'error', text: error.response.data.message });
                        dispatch(loading(false));
                    });
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });
                dispatch(loading(false));
            });
    };
};

export const saveLocation = (postData) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .post('AirportLocation', postData)
            .then((response) => {
                dispatch(getAirportLocations(postData.airport));
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const deleteLocation = (airport, locationId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .delete('AirportLocation/' + locationId)
            .then((response) => {
                dispatch(getAirportLocations(airport));
                dispatch(loading(false));
                Swal.fire({ type: 'success', text: 'The location was deleted.' });
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

// Devices / sims
export const getTrackers = () => {
    return (dispatch) => {
        dispatch(loading(true));

        const requestOne = axios.get('Sim/-1');
        const requestTwo = axios.get('Device/-1');
        const requestThree = axios.get('GsePreFilter/-1');

        axiosRoot
            .all([requestOne, requestTwo, requestThree])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];

                    if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200) {
                        Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });
                        dispatch(loading(false));
                        return;
                    }
                    dispatch(getTrackersSuccess(responseOne.data, responseTwo.data, responseThree.data));
                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({ type: 'error', text: 'Error: Something went wrong. Please try again.' });
                dispatch(loading(false));
            });
    };
};

export const getTrackersSuccess = (sims, devices, gses) => {
    return {
        type: actionTypes.GET_TRACKERS_SUCCESS,
        sims: sims,
        devices: devices,
        gses: gses,
    };
};

export const getSims = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('Sim/-1')
            .then((response) => {
                dispatch(getSimsSuccess(response.data));
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const getSimsSuccess = (sims) => {
    return {
        type: actionTypes.GET_SIMS_SUCCESS,
        sims: sims,
    };
};

export const updateSim = (postData) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .post('Sim', postData)
            .then((response) => {
                dispatch(getSims());
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const deleteSim = (simId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .delete('Sim/' + simId)
            .then((response) => {
                dispatch(getSims());
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const updateDevice = (postData) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .post('Device', postData)
            .then((response) => {
                dispatch(getTrackers());
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

export const deleteDevice = (deviceId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .delete('Device/' + deviceId)
            .then((response) => {
                dispatch(getTrackers());
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

// Reset refueling meters --------------------------------
export const resetMeter = (payload) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .post('RefuelingReset', payload)
            .then((response) => {
                dispatch(getResets(payload.gseId));
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({ type: 'error', text: error.response.data.message });
                dispatch(loading(false));
            });
    };
};

// Other --------------------------------
export const updateAdminState = (property, value) => {
    return {
        type: actionTypes.UPDATE_ADMIN_STATE,
        property: property,
        value: value,
    };
};

export const resetAdmin = () => {
    return {
        type: actionTypes.RESET_ADMIN,
    };
};
