import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import classes from './Service.module.css';
import DataTable from '../../components/DataTable/DataTable';
import * as actions from '../../store/actions/index';
import Loader from '../../components/UI/Loader/Loader';
import { formatDateTime } from '../../store/utilities';

class TabMaintenance extends Component {
    state = {
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
    };

    componentDidMount() {
        this.props.onGetService(-1);
    }

    getServiceFields = () => {
        let tableFields = [
            {
                title: 'Date performed',
                field: 'datePerformed',
                render: (rowData) => formatDateTime(rowData.datePerformed),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Gse',
                field: 'gseName',
                render: (rowData) => rowData.gseName,
                defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Airport',
                field: 'airportName',
                render: (rowData) => rowData.airportName,
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Current mileage (km)',
                render: (rowData) => rowData.curMilKm,
                defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Current operational time (h)',
                render: (rowData) => rowData.curOperTimeHours,
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
            },
            // {title: "Vehicle type", field: "vehicleType", render: rowData => rowData.vehicleType, defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null},
            // {title: "Equipment type", field: "equipmentType", render: rowData => rowData.equipmentType, defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null},
            {
                title: 'Manufacturer',
                field: 'manufacturer',
                render: (rowData) => rowData.manufacturer,
                defaultSort: this.state.tableOptions.defaultSort.column === 5 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Model',
                field: 'model',
                render: (rowData) => rowData.model,
                defaultSort: this.state.tableOptions.defaultSort.column === 6 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    gotoDetails = (rowData) => {
        const selectedGseId = rowData.gseId;

        this.props.onUpdateGseDataProperty('selectedGseId', selectedGseId);
        this.props.onClearGseEditDetails();
        this.props.gotoGse();
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        const serviceFields = this.getServiceFields();

        let page = (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={classes.ScrollInner}>
                    <Loader show={this.props.loading} />
                </div>
            </Scrollbars>
        );

        if (this.props.loading === false) {
            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner}>
                        <DataTable
                            data={this.props.filtered_serviceRecords}
                            columns={serviceFields}
                            viewClicked={(rowData) => {
                                this.gotoDetails(rowData);
                            }}
                            withExport
                            withSearch
                            tableOptions={this.state.tableOptions}
                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                        />
                    </div>
                </Scrollbars>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.loggedInUser,
        filtered_serviceRecords: state._data.filtered_serviceRecords,
        loading: state._system.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetService: (id) => dispatch(actions.getService(id)),
        onUpdateGseDataProperty: (property, value) => dispatch(actions.updateGseDataProperty(property, value)),
        onClearGseEditDetails: () => dispatch(actions.clearGseEditDetails()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabMaintenance);
