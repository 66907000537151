import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import { Map, TileLayer, ScaleControl, Polyline, Popup, CircleMarker, Marker, FeatureGroup, ZoomControl, Pane } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/dist/styles.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import FullscreenControl from 'react-leaflet-fullscreen';
import { CoordinatesControl } from 'react-leaflet-coordinates';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

import classes from './TripHistory.module.css';
import Block from '../../components/Block/Block';
import PrimaryAutocompleteControlled from '../../components/UI/PrimaryAutocomplete/PrimaryAutocompleteControlled';
import PrimaryDatetimePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatetimePicker';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimarySlider from '../../components/UI/PrimarySlider/PrimarySlider';
import Loader from '../../components/UI/Loader/Loader';
import { formatDateTime } from '../../store/utilities';
import TripFilters from '../../components/Filters/TripFilters';
import DataTable from '../../components/DataTable/DataTable';
import * as actions from '../../store/actions/index';

class TripHistory extends Component {
    state = {
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
        flightFrom: null,
        flightTo: null,
        minDateTime: null,
        maxDateTime: null,

        dateTimeInitLimits: {
            minDateFrom: null,
            maxDateFrom: null,
            minDateTo: null,
            maxDateTo: null,
            // minTime: null,
            // maxTime: null,
        },
        minDateFrom: null,
        maxDateFrom: null,
        minDateTo: null,
        maxDateTo: null,
        // minTime: null,
        // maxTime: null,

        selectedGseOption: null,
        selectedEquipmentTypeOption: null,
        selectedFlight: null,
        filteredGseOptions: null,
        filteredEquipmentTypeOptions: null,
        selectedTripId: null,
        selectedTripIndex: null,
        selectedFlightId: null,
        playingTrips: false,
        changesWhereMade: false,
        mapFilters: [
            { trips: 'bound', property: 'trips' },
            { trips: 'notBound', property: 'trips' },
            { starting_points: 'true', property: 'starting_points' },
            { ending_points: 'true', property: 'ending_points' },
            { flights: 'arrival', property: 'flights' },
            { flights: 'departure', property: 'flights' },
        ],
        timeLimit: 72,
    };

    componentDidMount() {
        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        // Initial date and time limits
        const today = new Date();
        const maxDateMoment = moment(today).subtract(this.state.timeLimit, 'hours');
        const maxDate = new Date(maxDateMoment);

        // const maxTime = new Date(maxDateMoment);
        // let minTime = new Date(maxDateMoment);
        // minTime = new Date(minTime.setHours(0, 0, 0));

        const dateTimeInitLimits = {
            maxDateFrom: maxDate,
            maxDateTo: maxDate,
            // minTime: minTime,
            // maxTime: maxTime,
        };
        this.setState({ maxDateFrom: maxDate, maxDateTo: maxDate, dateTimeInitLimits: dateTimeInitLimits });
        // ---------------------------

        this.playingTripsInterval = null;
        this.lineRefs = {};
        // this.tripsTableRef;
    }
    componentWillUnmount() {
        clearInterval(this.playingTripsInterval);
    }

    updateDate = (property, dt) => {
        let newDate = null;
        let minDateFrom = this.state.minDateFrom;
        let maxDateFrom = this.state.maxDateFrom;
        let minDateTo = this.state.minDateTo;
        let maxDateTo = this.state.maxDateTo;
        let changesWhereMade = true;

        if (dt === null) {
            newDate = null;
            changesWhereMade = false;

            if (property === 'flightFrom') {
                minDateTo = null;
                maxDateTo = this.state.dateTimeInitLimits.maxDateTo;
            } else if (property === 'flightTo') {
                minDateFrom = null;
                maxDateFrom = this.state.dateTimeInitLimits.maxDateTo;
            }
        } else {
            newDate = new Date(dt);

            if (property === 'flightFrom') {
                minDateTo = new Date(dt);
                maxDateTo = new Date(moment(new Date(dt)).add(24, 'hours'));
            } else if (property === 'flightTo') {
                minDateFrom = new Date(moment(new Date(dt)).subtract(24, 'hours'));
                maxDateFrom = new Date(dt);
            }
        }

        this.setState({
            [property]: newDate,
            minDateFrom: minDateFrom,
            maxDateFrom: maxDateFrom,
            minDateTo: minDateTo,
            maxDateTo: maxDateTo,
            changesWhereMade: changesWhereMade,
        });

        // Get Flights info
        if ((property === 'flightFrom' && dt && this.state.flightTo) || (property === 'flightTo' && dt && this.state.flightFrom)) {
            let dateFrom;
            let dateTo;
            if (property === 'flightFrom') {
                dateFrom = moment(new Date(dt)).format('yyyy-MM-DDTHH:mm:ss');
                dateTo = moment(this.state.flightTo, 'DD/MM/yyyy HH:mm').format('yyyy-MM-DDTHH:mm:ss');
            } else {
                dateFrom = moment(this.state.flightFrom, 'DD/MM/yyyy HH:mm').format('yyyy-MM-DDTHH:mm:ss');
                dateTo = moment(new Date(dt)).format('yyyy-MM-DDTHH:mm:ss');
            }

            this.props.onGetMapGsesFlightsInfo(dateFrom, dateTo);
        }
    };

    updateDate2 = (property, dt) => {
        let newDateStr = null;
        let minDateTime = this.state.minDateTime;
        let maxDateTime = this.state.maxDateTime;
        let changesWhereMade = true;

        if (dt === null || moment.isMoment(dt) === false) {
            newDateStr = null;
            changesWhereMade = false;
            if ((property === 'flightFrom' && !this.state.flightTo) || (property === 'flightTo' && !this.state.flightFrom)) {
                minDateTime = null;
                maxDateTime = null;
            }
        } else {
            newDateStr = moment(new Date(dt)).format('DD/MM/yyyy HH:mm');

            if (property === 'flightFrom') {
                minDateTime = moment(new Date(dt));

                // Check if maxDateTime is after the maxDateForTimeLimit
                const timeLimit = this.state.timeLimit;
                const maxDateForInput = moment(new Date(dt)).add(24, 'hours');
                const maxDateForTimeLimit = moment(new Date()).subtract(timeLimit, 'hours');

                if (maxDateForTimeLimit.isBefore(maxDateForInput)) {
                    maxDateTime = maxDateForTimeLimit;
                } else {
                    maxDateTime = maxDateForInput;
                }

                // maxDateTime = moment(new Date(dt)).add(24, 'hours');
            } else if (property === 'flightTo') {
                minDateTime = moment(new Date(dt)).subtract(24, 'hours');
                maxDateTime = moment(new Date(dt));
            }
        }

        this.setState({
            [property]: newDateStr,
            minDateTime: minDateTime,
            maxDateTime: maxDateTime,
            changesWhereMade: changesWhereMade,
        });
    };

    // onMapResize = () => {
    //     this.mapRef.leafletElement.invalidateSize()
    // }

    updateStateProperty = (property, value) => {
        this.setState({ [property]: value, changesWhereMade: true });
    };

    getTrips = () => {
        let gseIdsCsv = null;

        if (this.state.selectedGseOption) {
            gseIdsCsv = this.state.selectedGseOption.value;
        } else if (this.state.filteredGseOptions) {
            this.state.filteredGseOptions.forEach((gseOption) => {
                gseIdsCsv = gseIdsCsv + gseOption.value + ',';
            });
            gseIdsCsv = gseIdsCsv.slice(0, -1);
        }

        const from = moment(this.state.flightFrom, 'DD/MM/yyyy HH:mm').format('yyyy-MM-DDTHH:mm:ss');
        const to = moment(this.state.flightTo, 'DD/MM/yyyy HH:mm').format('yyyy-MM-DDTHH:mm:ss');

        this.props.onGetMapTripsFlightsData(gseIdsCsv, from, to);

        this.setState({ changesWhereMade: false });
    };

    onFeatureGroupAdd = (e) => {
        const bounds = e.target.getBounds();

        if (this.mapRef && this.mapRef.leafletElement && Object.entries(bounds).length > 0) {
            this.mapRef.leafletElement.fitBounds(bounds);
        }
    };

    getCustomMarker = (isBound) => {
        let customClass = 'DefaultColor';
        if (isBound) customClass = 'RedColor';

        const svg =
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>';
        const customIcon = L.divIcon({
            iconAnchor: [8, 24],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<div class=${classes[customClass]}>${svg}</div>`,
            className: 'dummy', // Removes white square
        });

        // const customIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 256C160 202.1 202.1 160 256 160C309 160 352 202.1 352 256C352 309 309 352 256 352C202.1 352 160 309 160 256zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>'
        // const customIcon = L.divIcon({
        //     iconAnchor: [8, 12],
        //     labelAnchor: [-6, 0],
        //     popupAnchor: [0, -36],
        //     html: `<div class=${classes[customClass]}>${svg}</div>`,
        //     className: 'dummy' // Removes white square
        // });

        return customIcon;
    };
    getFlightMarker = (flightSector) => {
        let customClass = 'BlueColor';
        if (flightSector === 'Departure') customClass = 'GreenColor';

        const svg =
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"/></svg>';
        const customIcon = L.divIcon({
            iconAnchor: [10, 12],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<div class=${classes[customClass]}>${svg}</div>`,
            className: 'dummy', // Removes white square
        });
        return customIcon;
    };

    onClickTripLocation = (e, tripId) => {
        const searchText = tripId.toString();
        this.tripsTableRef.searchText(searchText);

        this.setState({ selectedTripId: tripId });
        this.mapRef.leafletElement.openPopup(this.lineRefs[tripId].leafletElement.getPopup(), e.latlng);
    };
    onSelectTrip = (tripId) => {
        this.setState({ selectedTripId: tripId });

        // const bounds = this.lineRefs[tripId].leafletElement.getBounds()
        // this.mapRef.leafletElement.fitBounds(bounds);
    };

    onClickFlightMarker = (e, flightId) => {
        const searchText = flightId.toString();
        this.flightsTableRef.searchText(searchText);

        this.setState({ selectedFlightId: flightId });
    };
    onSelectFlight = (flightId) => {
        this.setState({ selectedFlightId: flightId });

        // const bounds = this.lineRefs[tripId].leafletElement.getBounds()
        // this.mapRef.leafletElement.fitBounds(bounds);
    };

    gotoDetails = (tripInfo) => {
        const selectedGseId = tripInfo.cgeId;

        this.props.onUpdateGseDataProperty('selectedGseId', selectedGseId);
        this.props.onClearGseEditDetails();
        this.props.history.push('/gseData');
    };
    gotoBluetooth = (bluetoothId) => {
        this.props.onUpdateGseDataProperty('selectedGseId', bluetoothId);
        this.props.onClearGseEditDetails();
        this.props.history.push('/gseData');
    };

    getHistoryRoutes = () => {
        let historyRoutes = [];
        let startingPoints = [];
        let endingPoints = [];

        // const myRenderer = L.canvas({ padding: 0.5, tolerance: 10 }); //Makes it easier to click on lines. Not working good. Gets stuck

        if (this.props.tripsData && Array.isArray(this.props.tripsData.tripLocations)) {
            this.props.tripsData.tripLocations.forEach((tripLocation, i) => {
                let points = [];
                let tripInfo = null;

                tripInfo = this.props.tripsData.tripInfos.find((t) => t.tripId === tripLocation.tripId);
                if (tripInfo && tripInfo.flightId) tripLocation.isBound = true;

                if (this.state.selectedTripId === tripInfo.tripId) tripLocation.selected = true;
                else tripLocation.selected = false;

                tripLocation.mapLocations.forEach((mapLocation, index) => {
                    points.push([mapLocation.pointY, mapLocation.pointX]);
                });

                let isVisible = null;
                let show = true;
                if (tripLocation.isBound) {
                    isVisible = this.state.mapFilters.find((f) => f.trips === 'bound');
                    if (!isVisible) show = false;
                } else {
                    isVisible = this.state.mapFilters.find((f) => f.trips === 'notBound');
                    if (!isVisible) show = false;
                }

                if (show) {
                    historyRoutes.push(
                        <Pane key={`route_${tripLocation.tripId}`} style={tripLocation.selected ? { zIndex: 503 } : { zIndex: 499 }}>
                            <Polyline
                                ref={(ref) => (this.lineRefs[tripLocation.tripId] = ref)}
                                positions={points}
                                color={tripLocation.isBound ? 'red' : '#404B58'}
                                opacity={tripLocation.selected ? '1' : '0.3'}
                                onclick={(e) => this.onClickTripLocation(e, tripLocation.tripId)}
                                // renderer={myRenderer}
                            >
                                <Popup>
                                    <div className={classes.PopupWrapper}>
                                        <div className={classes.PopupDetails}>
                                            {/* <div className={classes.PopupRow}>
                                                <span>Trip ID:</span>
                                                <span>{tripInfo && tripInfo.tripId ? tripInfo.tripId : ""}</span>
                                            </div> */}
                                            <div className={classes.PopupRow}>
                                                <span>GSE ID:</span>
                                                <span>{tripInfo && tripInfo.plate ? tripInfo.plate : ''}</span>
                                            </div>
                                            <div className={classes.PopupRow}>
                                                <span>Device ID:</span>
                                                <span>{tripInfo && tripInfo.deviceId ? tripInfo.deviceId : ''}</span>
                                            </div>
                                            <div className={classes.PopupRow}>
                                                <span>Start time:</span>
                                                <span>{tripInfo && tripInfo.dateTimeFrom ? formatDateTime(tripInfo.dateTimeFrom) : ''}</span>
                                            </div>
                                            <div className={classes.PopupRow}>
                                                <span>End time:</span>
                                                <span>{tripInfo && tripInfo.dateTimeTo ? formatDateTime(tripInfo.dateTimeTo) : ''}</span>
                                            </div>

                                            <div className={classes.PopupRow}>
                                                <span>Mileage driven:</span>
                                                <span>{tripInfo && tripInfo.mileage ? `${Math.round(tripInfo.mileage * 100) / 100} (m)` : ''}</span>
                                            </div>
                                            <div className={classes.PopupRow}>
                                                <span>Duration:</span>
                                                <span>{tripInfo && tripInfo.duration ? `${tripInfo.duration} (s)` : ''}</span>
                                            </div>
                                            <div className={classes.PopupRow}>
                                                <span>Idling duration:</span>
                                                <span>{tripInfo && tripInfo.durationIdle ? `${tripInfo.durationIdle} (s)` : ''}</span>
                                            </div>
                                        </div>
                                        <div className={classes.PopupButtonWrapper}>
                                            <PrimaryButton
                                                disabled={tripInfo && tripInfo.cgeId ? false : true}
                                                fullWidth
                                                clicked={() => this.gotoDetails(tripInfo)}
                                            >
                                                <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#8D9CAB'>
                                                    <path d='M20.036 17.31l-4.724-4.724a9.1 9.1 0 0 1-2.727 2.727l4.724 4.724a1.934 1.934 0 0 0 2.727-2.727z' />
                                                    <path d='M15.45 7.725a7.725 7.725 0 1 0-7.715 7.725 7.725 7.725 0 0 0 7.715-7.725zm-7.715 5.8a5.791 5.791 0 1 1 5.791-5.791 5.791 5.791 0 0 1-5.791 5.791z' />
                                                    <path d='M3.223 7.725h1.289a3.17 3.17 0 0 1 3.17-3.17v-1.29a4.513 4.513 0 0 0-4.46 4.46z' />
                                                </svg>
                                                Goto GSE
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </Popup>
                            </Polyline>
                        </Pane>
                    );

                    const startingFilter = this.state.mapFilters.find((f) => f.property === 'starting_points');
                    const endingFilter = this.state.mapFilters.find((f) => f.property === 'ending_points');
                    let showStarting = true;
                    let showEnding = true;

                    if (startingFilter.starting_points === 'false') showStarting = false;
                    if (endingFilter.ending_points === 'false') showEnding = false;

                    if (showStarting) {
                        startingPoints.push(
                            <CircleMarker
                                key={`startingPoint${i}`}
                                center={{
                                    lat: tripLocation.mapLocations[0].pointY,
                                    lng: tripLocation.mapLocations[0].pointX,
                                }}
                                fillColor='#ffffff'
                                fillOpacity={tripLocation.selected ? '1' : '0.3'}
                                color={tripLocation.isBound ? 'red' : '#404B58'}
                                opacity={tripLocation.selected ? '1' : '0.3'}
                                radius={5}
                                onclick={(e) => this.onClickTripLocation(e, tripLocation.tripId)}
                            />
                        );
                    }

                    if (showEnding) {
                        endingPoints.push(
                            <Marker
                                key={`endingPoint${i}`}
                                position={[
                                    tripLocation.mapLocations[tripLocation.mapLocations.length - 1].pointY,
                                    tripLocation.mapLocations[tripLocation.mapLocations.length - 1].pointX,
                                ]}
                                icon={this.getCustomMarker(tripLocation.isBound)}
                                opacity={tripLocation.selected ? '1' : '0.3'}
                                onclick={(e) => this.onClickTripLocation(e, tripLocation.tripId)}
                            />
                        );
                    }
                }
            });
        }

        const allRoutes = (
            <FeatureGroup key={`allRoutes${Math.floor(Math.random() * 100)}`} onAdd={this.onFeatureGroupAdd}>
                {historyRoutes}

                <FeatureGroup key={'startingPoints'}>{startingPoints}</FeatureGroup>
                <FeatureGroup key={'endingPoints'}>{endingPoints}</FeatureGroup>
            </FeatureGroup>
        );

        return allRoutes;
    };

    getFlightsMarkers = () => {
        let flightMarkers = [];

        if (this.props.tripsData && Array.isArray(this.props.tripsData.flightInfos)) {
            this.props.tripsData.flightInfos.forEach((flight, i) => {
                if (this.state.selectedFlightId === flight.flightId) flight.selected = true;
                else flight.selected = false;

                let isVisible = null;
                let show = true;
                if (flight.sectorType === 'Arrival') {
                    isVisible = this.state.mapFilters.find((f) => f.flights === 'arrival');
                    if (!isVisible) show = false;
                } else if (flight.sectorType === 'Departure') {
                    isVisible = this.state.mapFilters.find((f) => f.flights === 'departure');
                    if (!isVisible) show = false;
                }

                if (show && flight.parkingPosY && flight.parkingPosX) {
                    flightMarkers.push(
                        <Marker
                            key={`flight${i}`}
                            position={[flight.parkingPosY, flight.parkingPosX]}
                            icon={this.getFlightMarker(flight.sectorType)}
                            opacity={flight.selected ? '1' : '0.5'}
                            onclick={(e) => this.onClickFlightMarker(e, flight.flightId)}
                        >
                            <Popup>
                                <div className={classes.PopupWrapper}>
                                    <div className={classes.PopupDetails}>
                                        <div className={classes.PopupRow}>
                                            <span>Flight number:</span>
                                            <span>{flight.flightNumber}</span>
                                        </div>
                                        <div className={classes.PopupRow}>
                                            <span>Sector type:</span>
                                            <span>{flight.sectorType}</span>
                                        </div>
                                        <div className={classes.PopupRow}>
                                            <span>Aircraft parking position:</span>
                                            <span>{flight.parkingPos}</span>
                                        </div>
                                        <div className={classes.PopupRow}>
                                            <span>Flight date:</span>
                                            <span>{flight.flightDate ? formatDateTime(flight.flightDate) : ''}</span>
                                        </div>
                                        <div className={classes.PopupRow}>
                                            <span>Pax number:</span>
                                            <span>{flight.paxNumber}</span>
                                        </div>
                                        <div className={classes.PopupRow}>
                                            <span>Bags pieces:</span>
                                            <span>{flight.bagsPcs}</span>
                                        </div>
                                        <div className={classes.PopupRow}>
                                            <span>Flight cargo:</span>
                                            <span>{flight.flightCargo}</span>
                                        </div>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                }
            });
        }

        const allFlights = (
            <FeatureGroup key={'flights'}>
                <Pane style={{ zIndex: 510 }}>{flightMarkers}</Pane>
            </FeatureGroup>
        );
        return allFlights;
    };

    updatedEquipmentType = (value) => {
        let filteredGseOptions = [...this.props.tripGseOptions];
        let selectedGseOption = this.state.selectedGseOption;

        if (value) {
            // Filter Gses
            filteredGseOptions = this.props.tripGseOptions.filter((g) => g.equipmentType === value.value);

            // If selected gse, check if it is in the filtered options
            if (selectedGseOption && selectedGseOption.equipmentType !== value.value) {
                selectedGseOption = null;
            }
        }

        this.setState({
            selectedEquipmentTypeOption: value,
            filteredGseOptions: filteredGseOptions,
            selectedGseOption: selectedGseOption,
            changesWhereMade: true,
        });
    };

    updateTrip = (value) => {
        const selectedTripId = this.props.tripsData.tripLocations[value - 1].tripId;
        const searchText = selectedTripId.toString();
        this.tripsTableRef.searchText(searchText);

        this.setState({ selectedTripIndex: value, selectedTripId: selectedTripId });
    };

    playTrips = () => {
        this.playingTripsInterval = setInterval(() => {
            let selectedTripIndex = this.state.selectedTripIndex;

            if (selectedTripIndex === this.props.tripsData.tripLocations.length) {
                this.stopTrips();
            } else {
                selectedTripIndex = selectedTripIndex + 1;
                const selectedTripId = this.props.tripsData.tripLocations[selectedTripIndex - 1].tripId;
                const searchText = selectedTripId.toString();
                this.tripsTableRef.searchText(searchText);
                this.setState({
                    playingTrips: true,
                    selectedTripIndex: selectedTripIndex,
                    selectedTripId: selectedTripId,
                });
            }
        }, 1000);
    };
    stopTrips = () => {
        clearInterval(this.playingTripsInterval);

        this.setState({ playingTrips: false });
    };

    clearSelections = () => {
        this.tripsTableRef.searchText('');
        this.flightsTableRef.searchText('');
        this.setState({ selectedTripId: null, selectedFlightId: null });
    };

    getTripsFields = () => {
        let tableFields = [
            { title: 'Trip ID', field: 'tripId', hidden: true, searchable: true },
            { title: 'GSE ID', field: 'plate' },
            { title: 'Device ID', field: 'deviceId' },
            {
                title: 'Start time',
                field: 'dateTimeFrom',
                render: (rowData) => formatDateTime(rowData.dateTimeFrom),
            },
            {
                title: 'End time',
                field: 'dateTimeTo',
                render: (rowData) => formatDateTime(rowData.dateTimeTo),
            },
            {
                title: 'Mileage driven (m)',
                field: 'mileage',
                render: (rowData) => rowData.mileage.toFixed(2),
            },
            { title: 'Duration (s)', field: 'duration' },
            { title: 'Idling duration (s)', field: 'durationIdle' },
            {
                title: 'Average speed (km/h)',
                field: 'averageSpeed',
                render: (rowData) => rowData.averageSpeed.toFixed(0),
            },
            {
                title: 'Maximum speed (km/h)',
                field: 'maxSpeed',
                render: (rowData) => rowData.maxSpeed.toFixed(0),
            },
            {
                title: 'Towing',
                field: 'towing',
                render: (rowData) => {
                    if (!rowData.towingIds) return null;

                    let bluetoothButtons = [];
                    let towingPlates;
                    let towingIds;

                    if (rowData.towingPlates.includes(',')) {
                        towingPlates = rowData.towingPlates.split(',');
                        towingIds = rowData.towingIds.split(',');
                    } else {
                        towingPlates = [rowData.towingPlates];
                        towingIds = [rowData.towingIds];
                    }

                    for (let i = 0; i < towingPlates.length; i++) {
                        bluetoothButtons.push(
                            <React.Fragment>
                                <PrimaryButton fullWidth clicked={() => this.gotoBluetooth(towingIds[i])}>
                                    {towingPlates[i]}
                                </PrimaryButton>
                                <div className={classes.VerticalSpacer}></div>
                            </React.Fragment>
                        );
                    }

                    // const bluetoothButtons = towingPlates.map((plate) => (
                    //   <PrimaryButton fullWidth clicked={() => this.gotoBluetooth(rowData)}>
                    //     {plate}
                    //   </PrimaryButton>
                    // ));

                    return rowData.towingPlates !== '' ? bluetoothButtons : '';
                },
            },
            {
                title: 'Bound with flight',
                field: 'bindToFlight',
                render: (rowData) => (rowData.bindToFlight === 0 ? 'No' : 'Yes'),
            },
            {
                title: 'Type of binding',
                field: 'bindTypeName',
                // render: (rowData) => {
                //     let bindType = 'Not calculated';
                //     if (rowData.bindType === 1) bindType = 'Before start time';
                //     else if (rowData.bindType === 2) bindType = 'Before start time';
                // },
            },

            { title: 'Flight Number', field: 'flightNumber' },
        ];

        return tableFields;
    };

    getFlightsFields = () => {
        let tableFields = [
            { title: 'Flight ID', field: 'flightId', hidden: true, searchable: true },
            { title: 'Flight number', field: 'flightNumber' },
            { title: 'Aircraft parking position', field: 'parkingPos' },
            {
                title: 'Flight date',
                field: 'flightDate',
                render: (rowData) => formatDateTime(rowData.flightDate),
                defaultSort: 'asc',
            },
            { title: 'Sector type', field: 'sectorType' },
            { title: 'Pax number', field: 'paxNumber' },
            { title: 'Bags pieces', field: 'bagsPcs' },
            { title: 'Flight cargo', field: 'flightCargo' },
        ];

        return tableFields;
    };

    getFlightEquipmentFields = () => {
        let tableFields = [
            { title: 'Flight equipment ID', field: 'flightEquipmentId', hidden: true, searchable: false },
            { title: 'Quantity of service', field: 'quantityOfService' },
            { title: 'Service code', field: 'serviceCode' },
            { title: 'Service description', field: 'serviceDescription' },
        ];

        return tableFields;
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        const historyRoutes = this.getHistoryRoutes();
        const flights = this.getFlightsMarkers();
        const tripsFields = this.getTripsFields();
        const flightsFields = this.getFlightsFields();
        const flightEquipmentFields = this.getFlightEquipmentFields();
        console.log(this.state.minDateTo);
        console.log(this.state.maxDateTo);
        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}></div>
                <div className={classes.PageTitle}>
                    <h1>Trip history</h1>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </React.Fragment>
        );

        if (this.props.loading === false) {
            page = (
                <React.Fragment>
                    <div className={classes.FiltersSpace}>
                        <TripFilters mapFilters={this.state.mapFilters} onUpdateMapFilters={(newFilters) => this.setState({ mapFilters: newFilters })} />
                    </div>
                    <div className={classes.PageTitle}>
                        <h1>Trip history</h1>
                    </div>
                    <div className={classes.PageContent}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className={classes.PageInner}>
                                <div className={classes.Row}>
                                    <div className={classes.Column}>
                                        <div className={classes.BlockItem}>
                                            <Block title='Routes'>
                                                <div className={classes.Flexed}>
                                                    <div className={classes.DatetimeWrapper}>
                                                        <PrimaryDatetimePicker
                                                            label='From'
                                                            value={this.state.flightFrom}
                                                            dateChanged={(value) => this.updateDate('flightFrom', value)}
                                                            minDate={this.state.minDateFrom}
                                                            maxDate={this.state.maxDateFrom}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className={classes.DatetimeWrapper}>
                                                        <PrimaryDatetimePicker
                                                            label='To'
                                                            value={this.state.flightTo}
                                                            dateChanged={(value) => this.updateDate('flightTo', value)}
                                                            minDate={this.state.minDateTo}
                                                            maxDate={this.state.maxDateTo}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </Block>
                                        </div>
                                    </div>

                                    <div className={classes.Separator}></div>

                                    <div className={classes.Column}>
                                        <div className={classes.BlockItem}>
                                            <Block title='GSE'>
                                                <div className={classes.Flexed}>
                                                    <div className={classes.DatetimeWrapper}>
                                                        <PrimaryAutocompleteControlled
                                                            label='Equipment type'
                                                            options={this.props.tripEquipmentTypeOptions}
                                                            value={this.state.selectedEquipmentTypeOption}
                                                            changed={(value) => this.updatedEquipmentType(value)}
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <div className={classes.DatetimeWrapper}>
                                                        <PrimaryAutocompleteControlled
                                                            label='GSE'
                                                            options={this.state.filteredGseOptions ? this.state.filteredGseOptions : this.props.tripGseOptions}
                                                            value={this.state.selectedGseOption}
                                                            changed={(value) => this.updateStateProperty('selectedGseOption', value)}
                                                            fullWidth
                                                        />
                                                    </div>
                                                </div>
                                            </Block>
                                        </div>
                                    </div>

                                    {/* <div className={classes.Separator}></div>

                                        <div className={classes.Column}>
                                            <Block title="Flight">
                                                <PrimaryAutocompleteControlled 
                                                    label="Flight"
                                                    options={this.props.tripFlightsOptions}
                                                    value={this.state.selectedFlight}
                                                    changed={(value) => this.updateStateProperty("selectedFlight", value)}
                                                    fullWidth
                                                />
                                            </Block>
                                        </div> */}
                                </div>
                                <div className={[classes.Row, classes.ButtonWrapper].join(' ')}>
                                    <PrimaryButton
                                        clicked={this.getTrips}
                                        disabled={this.state.flightFrom === null || this.state.flightTo === null || this.state.changesWhereMade === false}
                                    >
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                            <path d='M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z'></path>
                                        </svg>
                                        Update trips
                                    </PrimaryButton>
                                </div>

                                {this.state.selectedGseOption &&
                                this.props.tripsData &&
                                this.props.tripsData.tripLocations.length &&
                                this.state.changesWhereMade === false ? (
                                    <div className={classes.SliderWrapper}>
                                        <h4>Select trip</h4>

                                        <div className={classes.SliderBar}>
                                            {!this.state.playingTrips ? <FontAwesomeIcon icon={faPlay} onClick={this.playTrips} /> : null}

                                            {this.state.playingTrips ? <FontAwesomeIcon icon={faPause} onClick={this.stopTrips} /> : null}

                                            <PrimarySlider
                                                min={1}
                                                max={this.props.tripsData.tripLocations.length}
                                                changed={(e, value) => this.updateTrip(value)}
                                                value={this.state.selectedTripIndex}
                                                marks
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                ) : null}

                                {this.props.tripsData ? (
                                    <div className={classes.ResultsWrapper}>
                                        <div className={classes.MapWrapper}>
                                            <Map
                                                className={classes.Map}
                                                ref={(ref) => (this.mapRef = ref)}
                                                maxZoom={23}
                                                center={[40, 22]}
                                                // bounds={this.state.mapBounds}
                                                // whenReady={this.removeBounds}
                                                boundsOptions={{ padding: [50, 50] }}
                                                // maxBoundsViscosity={1.0}
                                                // maxBounds={this.props.selectedAirport.bounds}
                                                zoom={13}
                                                zoomControl={false}
                                                onClick={this.clearSelections}
                                                gestureHandling={true}
                                            >
                                                <FullscreenControl position='topleft' />

                                                <TileLayer attribution={''} url={'http://{s}.tile.osm.org/{z}/{x}/{y}.png'} />

                                                {flights}
                                                {historyRoutes}

                                                <CoordinatesControl coordinates='decimal' position='bottomleft' />
                                                <ScaleControl position='bottomleft' />
                                                <ZoomControl position='topright' />
                                            </Map>
                                        </div>

                                        <div className={classes.TripsTable}>
                                            <Block title='Trips'>
                                                <DataTable
                                                    ref={(ref) => (this.tripsTableRef = ref)}
                                                    data={this.props.tripsData.tripInfos}
                                                    // extraData={this.props.tripsData.flightInfos}
                                                    // extraDataJoinWith="flightId"
                                                    columns={tripsFields}
                                                    withExport
                                                    withSearch
                                                    // tableOptions={this.state.tableOptions}
                                                    viewClicked={(rowData) => this.onSelectTrip(rowData.tripId)}
                                                    selectedRow={this.state.selectedTripId}
                                                    selectProperty={'tripId'}

                                                    // detailPanels={[{title:"Flight info", columns:tripsDetailFields}]}
                                                />
                                            </Block>
                                        </div>

                                        <div className={classes.TripsTable}>
                                            <Block title='Flights'>
                                                <DataTable
                                                    ref={(ref) => (this.flightsTableRef = ref)}
                                                    data={this.props.tripsData.flightInfos}
                                                    // extraData={this.props.tripsData.flightInfos}
                                                    // extraDataJoinWith='flightId'
                                                    columns={flightsFields}
                                                    withExport
                                                    withSearch
                                                    //tableOptions={this.state.tableOptions}
                                                    viewClicked={(rowData) => this.onSelectFlight(rowData.flightId)}
                                                    selectedRow={this.state.selectedFlightId}
                                                    selectProperty={'flightId'}
                                                    detailPanels={[
                                                        { property: 'flightEquipments', title: 'Flight equipments', columns: flightEquipmentFields },
                                                    ]}
                                                />
                                            </Block>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </Scrollbars>
                    </div>
                </React.Fragment>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        // selectedAirport: state._data.selectedAirport,
        // history: state._data.history,
        // routes: state._data.routes,

        // flights:state._data.flights,
        // flightsEquipment:state._data.flightsEquipment,
        loading: state._system.loading,
        tripGseOptions: state._data.tripGseOptions,
        tripEquipmentTypeOptions: state._data.tripEquipmentTypeOptions,
        // tripFlightsOptions: state._data.tripFlightsOptions,
        tripsData: state._data.tripsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetMapGsesFlightsInfo: (dateFrom, dateTo) => dispatch(actions.getMapGsesFlightsInfo(dateFrom, dateTo)),
        onGetMapTripsFlightsData: (gseIdsCsv, dateFrom, dateTo) => dispatch(actions.getMapTripsFlightsData(gseIdsCsv, dateFrom, dateTo)),
        onUpdateGseDataProperty: (property, value) => dispatch(actions.updateGseDataProperty(property, value)),
        onClearGseEditDetails: () => dispatch(actions.clearGseEditDetails()),
        // onGetFlightsEquipment: (year,month,day) => dispatch(actions.getFlightsEquipment(year,month,day))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TripHistory);
