import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '../UI/PrimaryCheckbox/PrimaryCheckbox';
import classes from './SideFilter.module.css';

const SideFilter = (props) => {
  const [isHidden, setIsHidden] = React.useState(props.isHidden);
  const [showMore, setShowMore] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const [isAllChecked, setAllChecked] = React.useState(
    props.toggleAll || false
  );

  const handleIsHiddenToggle = () => {
    setIsHidden((prevState) => !prevState);
  };

  const handleShowMore = () => {
    setShowMore((prevState) => !prevState);
    updateFilteredOptions(filter);
  };

  const handleFilterOptions = (value) => {
    setFilter(value);
    updateFilteredOptions(value);
  };

  const updateFilteredOptions = (newFilter) => {
    const storedFilters = props.filters;
    let changedOptions = [];

    if (!newFilter) {
      const copyOptions = _.cloneDeep(props.options);
      changedOptions = copyOptions.slice(5, copyOptions.length); // Get the rest options
    } else {
      for (let i = 5; i < props.options.length; i++) {
        const filterOption = props.options[i];
        const filterOptionLabel =
          filterOption[props.titleProperty].toLowerCase();
        const searchString = newFilter.toLowerCase();

        if (filterOptionLabel.includes(searchString)) {
          changedOptions.push(filterOption);
        }
      }
    }

    if (changedOptions.length) {
      changedOptions.sort((a, b) =>
        a[props.titleProperty] > b[props.titleProperty]
          ? 1
          : b[props.titleProperty] > a[props.titleProperty]
          ? -1
          : 0
      );
    }

    for (let i = 0; i < changedOptions.length; i++) {
      const option = changedOptions[i];
      let isChecked = false;

      if (storedFilters.length) {
        const foundIndex = storedFilters.findIndex(
          (f) => f[props.valueProperty] == option[props.value]
        );
        if (foundIndex > -1) isChecked = true;
      }

      changedOptions[i].checked = isChecked;
    }

    setFilteredOptions(changedOptions);
  };

  const toggleField = (option, isChecked) => {
    let storedFilters = props.filters;
    let updatedFilters = _.cloneDeep(storedFilters);

    if (isChecked) {
      const newFilter = {
        [props.valueProperty]: option[props.value],
        property: props.valueProperty,
        label: props.label,
      };
      updatedFilters.push(newFilter);
    } else {
      const foundIndex = updatedFilters.findIndex(
        (f) => f[props.valueProperty] == option[props.value]
      );
      updatedFilters.splice(foundIndex, 1);
    }

    // If toggle all, check isAllChecked
    if (props.toggleAll) {
      const found = updatedFilters.filter(
        (f) => f.property === props.valueProperty
      );
      const foundLength = found.length;

      if (foundLength === 0) setAllChecked(false);
      else if (foundLength === props.options.length) setAllChecked(true);
    }

    props.updateFilters(updatedFilters);
  };

  const changeRadio = (e) => {
    let storedFilters = props.filters;
    let updatedFilters = storedFilters.filter(
      (f) => f.property !== props.valueProperty
    );
    const newValue = e.target.value;

    const newFilter = {
      [props.valueProperty]: newValue,
      property: props.valueProperty,
    };

    updatedFilters.push(newFilter);
    props.updateFilters(updatedFilters);
  };

  const getChecked = (option) => {
    const storedFilters = props.filters;

    let isChecked = false;

    if (storedFilters && storedFilters.length) {
      const foundIndex = storedFilters.findIndex(
        (f) => f[props.valueProperty] == option[props.value]
      );
      if (foundIndex > -1) isChecked = true;
    }

    return isChecked;
  };

  const getRadioDefault = () => {
    const storedFilters = props.filters;

    let defaultValue = null;
    if (storedFilters && storedFilters.length) {
      const found = storedFilters.find(
        (f) => f.property === props.valueProperty
      );
      if (found) defaultValue = found[props.valueProperty];
    }

    return defaultValue;
  };

  const getMiniFilters = () => {
    let miniFilters = null;

    if (props.filters && props.options && props.options.length) {
      const theseFilters = props.filters.filter(
        (f) => f.property === props.valueProperty
      );
      const theseFiltersLength = theseFilters.length;
      let elClasses = [classes.MiniContent];
      if (isHidden) elClasses.push(classes.Show);
      if (theseFiltersLength === 0) elClasses.push(classes.Empty);

      miniFilters = (
        <div className={elClasses.join(' ')}>
          {theseFilters.map((thisFilter, index) => {
            const thisFilterId = thisFilter[thisFilter.property];
            const foundOption = props.options.find(
              (x) => x[props.value] === thisFilterId
            );

            return (
              <span key={`mini_${index}`}>
                {foundOption[props.titleProperty]}
                {index < theseFiltersLength - 1 ? ',' : ''}
              </span>
            );
          })}
        </div>
      );
    }

    return miniFilters;
  };

  const getIcon = (option, iconSet) => {
    let value = option.value;
    let icon = '';

    if (isNaN(value) === false) {
      // this is a valid number
      value = parseInt(option.value);
    }

    if (iconSet[value]) {
      icon = iconSet[value];
    }

    return icon;
  };

  const getToggleAll = () => {
    const toggle = (
      <div key={`toggleAll_${props.label}`} className={classes.FilterOptionAll}>
        <span className={classes.FilterOptionLabel}>Toggle All</span>
        <Checkbox checked={isAllChecked} changed={(e) => toggleAll()} />
      </div>
    );

    return toggle;
  };

  const toggleAll = () => {
    let storedFilters = props.filters;
    let updatedFilters = storedFilters.filter(
      (f) => f.property !== props.valueProperty
    ); // remove all toggle filters

    // If toggle to true, add all options
    if (isAllChecked === false) {
      for (let option of props.options) {
        const newFilter = {
          [props.valueProperty]: option[props.value],
          label: props.label,
          property: props.valueProperty,
        };
        updatedFilters.push(newFilter);
      }
    }

    setAllChecked(!isAllChecked);

    props.updateFilters(updatedFilters);
  };

  return (
    <div className={classes.SideFilter}>
      <div className={classes.Header} onClick={handleIsHiddenToggle}>
        <h4>{props.label}</h4>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>

      {props.toggleAll && !isHidden ? getToggleAll() : null}

      {props.filters && props.options && props.options.length
        ? getMiniFilters()
        : null}

      <div
        className={
          isHidden ? [classes.Content, classes.Hide].join(' ') : classes.Content
        }
      >
        <div className={classes.ContentInner}>
          <div
            className={[classes.FilterOptions, classes[props.type]].join(' ')}
          >
            {props.options && props.type === 'Multilist' ? (
              props.options.map((option, index) => {
                if (index < 5) {
                  return (
                    <div
                      key={`filterOption_${props.label}_${index}`}
                      className={classes.FilterOption}
                    >
                      {props.icons ? getIcon(option, props.icons) : null}
                      <span className={classes.FilterOptionLabel}>
                        {option[props.titleProperty]}
                      </span>
                      <Checkbox
                        checked={getChecked(option)}
                        changed={(e, isChecked) =>
                          toggleField(option, isChecked)
                        }
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })
            ) : props.options && props.type === 'Radio' ? (
              <RadioGroup
                value={getRadioDefault()}
                onChange={(e) => changeRadio(e)}
              >
                {props.options.map((option, index) => {
                  return (
                    <FormControlLabel
                      key={`${props.valueProperty}_${index}`}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  );
                })}
              </RadioGroup>
            ) : null}
          </div>

          {props.type === 'Multilist' &&
          props.options &&
          props.options.length > 5 ? (
            <div className={classes.OptionsMoreWrapper}>
              <div
                className={classes.OptionsMoreHeader}
                onClick={handleShowMore}
              >
                <span>See more</span>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
              <div
                className={
                  showMore
                    ? [classes.OptionsMoreContent, classes.Show].join(' ')
                    : classes.OptionsMoreContent
                }
              >
                <div className={classes.InputWrapper}>
                  <input
                    type='text'
                    placeholder='Search'
                    value={filter}
                    onChange={(e) => handleFilterOptions(e.target.value)}
                  />
                  {filter ? (
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => handleFilterOptions('')}
                    />
                  ) : null}
                </div>

                <div
                  className={[classes.FilterOptions, classes[props.type]].join(
                    ' '
                  )}
                >
                  {filteredOptions.map((option, index) => (
                    <div
                      key={`filterOption_${props.label}_${index}`}
                      className={classes.FilterOption}
                    >
                      {props.icons ? getIcon(option, props.icons) : null}

                      <span className={classes.FilterOptionLabel}>
                        {option[props.titleProperty]}
                      </span>
                      <Checkbox
                        checked={getChecked(option)}
                        changed={(e, isChecked) =>
                          toggleField(option, isChecked)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SideFilter;
