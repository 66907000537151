import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import classes from './PrimarySelect.module.css';

const PrimarySelect_controlled = (props) => {
    let elementClasses = [classes.FormControl];

    if (props.fullWidth) elementClasses.push(classes.FullWidth);
    else if (props.narrow) elementClasses.push(classes.Narrow);
    else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

    return (
        <FormControl className={elementClasses.join(' ')} disabled={props.disabled} variant="outlined">
            <InputLabel>
                {props.label}
            </InputLabel>
            <Select 
                labelId={props.labelId}
                value={props.value}
                onChange={(event)=>props.changed(event.target.value)}
                displayEmpty 
                className={classes.SelectEmpty}
                label={props.label}>

                {props.noEmpty
                    ? null
                    :<MenuItem value="">
                        {props.placeholder}
                    </MenuItem>
                }

                {props.options.map((option,index) => (
                    <MenuItem key={"menuItem_" + index} value={option.value}>{option.label}</MenuItem>
                ))}

            </Select>
        </FormControl>
    );
}

export default PrimarySelect_controlled;