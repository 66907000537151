import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faListUl } from '@fortawesome/free-solid-svg-icons';

import Button from '../PrimaryButton/PrimaryButton';
import Checkbox from '../PrimaryCheckbox/PrimaryCheckbox';
import classes from './MultiSelect.module.css';


class MultiSelect extends Component {

    state={
        showFields:false
    }

    toggleStateVariable = (variable) => {
        const current = this.state[variable];
        this.setState({[variable]:!current});
    }

    areAllChecked = () => {
        let checkedAllFields = true;

        if (this.props.fields){
            for (let i = 0; i < this.props.fields.length; i++) {
                if (this.props.fields[i].checked === false){
                    checkedAllFields = false;
                    break;
                }
            }
        }

        return checkedAllFields;
    }

    handleClickOutside = evt => {
        this.setState({showFields:false});
    };

    render() {
        const checkedAllFields = this.areAllChecked();
        

        return (
            <div className={this.state.showFields ? [classes.SelectFieldsWrapper, classes.ShowFields].join(' ') : classes.SelectFieldsWrapper}>
                <Button clicked={() => this.toggleStateVariable("showFields")}>
                    <FontAwesomeIcon icon={faListUl} />
                    <span>Fields</span>
                    <FontAwesomeIcon icon={faCaretDown} />
                </Button>
    
                <div className={classes.FieldsWrapper}>
                    <div className={classes.SelectAllWrapper}>
                        <Checkbox 
                            label="Select all"
                            labelPlacement="start"
                            checked={checkedAllFields}
                            changed={() => this.props.toggleAll(checkedAllFields)}
                        />
                    </div>
                    <ul className={classes.FieldsWrapperUl}>
                        {this.props.fields.map((field,index) => (
                            <li key={`${field}_${index}`}>
                                <div>
                                    <Checkbox 
                                        label={field.title}
                                        labelPlacement="end"
                                        checked={field.checked}
                                        changed={() => this.props.toggleField(index)}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
    
}

export default onClickOutside(MultiSelect);