import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../components/UI/Theme/Theme';

import classes from './Layout.module.css';
import Topbar from '../../components/Navigation/Topbar/Topbar';
import Footer from '../../components/Navigation/Footer/Footer';
// import SideFilters from '../../components/Filters/SideFilters';
// import PrimaryBreadcrumbs from '../../components/UI/PrimaryBreadcrumbs/PrimaryBreadcrumbs';

// const CONFIG = require('../../reactConfig.json');

class Layout extends Component {
    state = {
        fullSideBar: true
    }

    sideBarMinHandler = () => {
        this.setState( { fullSideBar: false } );
    }

    sideBarToggleHandler = () => {
        this.setState( ( prevState ) => {
            return { fullSideBar: !prevState.fullSideBar };
        } );
    }
    
    render () {
        let attachedClasses = [classes.Wrapper];
        // const user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
        let menuOpen = true;
    
        if (this.state.fullSideBar === false) {
            attachedClasses = [classes.Wrapper, classes.SidebarClosed];
            menuOpen = false;
        }

        return (
            <ThemeProvider theme={theme}>
                <div className={attachedClasses.join(' ')}>
                    <div className={classes.Strip}></div>
                    <div className={classes.NoStrip}>
                        <div className={classes.SecondColumn}>
                            <Topbar menuOpen={menuOpen} drawerToggleClicked={this.sideBarToggleHandler} />
                            {/* {
                                user 
                                ? <SideFilters /> 
                                : null
                            } */}
                            <div className={classes.ContentWrapper}>
                                <main className={classes.Content}>
                                    {/* <PrimaryBreadcrumbs breadcrumbs={this.props.breadcrumbs}/> */}
                                    <div className={classes.Content_inner}>
                                        {this.props.children}
                                    </div>
                                    <Footer /> 
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        )
    }
}

export default Layout;