import React, { useEffect, useState, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import moment from 'moment';
import { saveAs } from 'file-saver';
import IconButton from '@material-ui/core/IconButton';
import SaveAlt from '@material-ui/icons/SaveAlt';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { useCurrentPng } from 'recharts-to-png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import classes from './MyLineChart.module.css';

const MyBarChart = (props) => {
    const rollingAverage = 6;

    const [getPng, { ref: chartRef, isLoading }] = useCurrentPng();

    const [averageValue, setAverageValue] = useState(null);
    const [stDeviationValue, setStDeviationValue] = useState(null);
    const [coefValue, setCoefValue] = useState(null);

    useEffect(() => {
        if (props.withAverage && props.data && props.data.length) {
            const allValues = props.data.map((a) => a.val);
            const filteredValues = allValues.filter((v) => v !== null);
            let average;
            let stDev = '';
            let coef = '';

            // Get average value
            if (filteredValues.length) {
                const sum = filteredValues.reduce((acc, cur) => acc + cur);

                if (props.refuelingData && props.operationalData) {
                    average = getActualFuelConsumpionAverage();
                } else {
                    average = sum / filteredValues.length;
                }

                stDev = standardDeviation(filteredValues);

                if (filteredValues.length > 1) {
                    // Get the coefficient of variation
                    coef = stDev / average;
                }

                setAverageValue(average);
                setStDeviationValue(stDev);
                setCoefValue(coef);
            } else {
                setAverageValue(null);
                setStDeviationValue(null);
                setCoefValue(null);
            }
        } else {
            setAverageValue(null);
            setStDeviationValue(null);
            setCoefValue(null);
        }
    }, [props.data]);

    const getActualFuelConsumpionAverage = () => {
        const values1 = props.refuelingData.map((a) => a.val);
        const filteredValues1 = values1.filter((v) => v !== null);
        const sumValues1 = filteredValues1.reduce((acc, cur) => acc + cur);

        const values2 = props.operationalData.map((a) => a.val);
        const filteredValues2 = values2.filter((v) => v !== null);
        const sumValues2 = filteredValues2.reduce((acc, cur) => acc + cur);

        const average = sumValues1 / sumValues2;

        return average;
    };

    const standardDeviation = (arr, usePopulation = false) => {
        const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
        return Math.sqrt(
            arr.reduce((acc, val) => acc.concat((val - mean) ** 2), []).reduce((acc, val) => acc + val, 0) / (arr.length - (usePopulation ? 0 : 1))
        );
    };

    const sumHandler = () => {
        props.changeTimeframe('all');
    };
    const yearHandler = () => {
        let thisYear = props.selectedYear;
        if (!thisYear || thisYear === '' || props.years.includes(props.selectedYear) === false) {
            thisYear = props.years[props.years.length - 1];
        }

        props.changeYear(thisYear);
        props.changeTimeframe('year');
    };
    const previousYearHandler = () => {
        props.changeYear(props.selectedYear - 1);
    };
    const nextYearHandler = () => {
        props.changeYear(props.selectedYear + 1);
    };

    const checkYear = (type) => {
        if (props.years.length === 1) return false;

        if (type === 'first' && props.years[0] == props.selectedYear) return false;
        if (type === 'last' && props.years[props.years.length - 1] == props.selectedYear) return false;

        return true;
    };

    const formatDate = (value) => {
        const formattedDate = moment(new Date(value)).format('DD/MM/yyyy');
        return formattedDate;
    };

    const exportChart = useCallback(async () => {
        const png = await getPng();
        if (png) {
            saveAs(png, `${props.name}.png`);
        }
    }, [getPng]);

    return (
        <React.Fragment>
            {props.data.length > 0 && (
                <React.Fragment>
                    <div className={classes.StatsProperties}>
                        {averageValue && (
                            <div className={classes.StatsProperty}>
                                <span>Average:</span>
                                <span>{averageValue.toFixed(2)}</span>
                            </div>
                        )}
                        {stDeviationValue && (
                            <div className={classes.StatsProperty}>
                                <span>Standard deviation:</span>
                                <span>{stDeviationValue.toFixed(2)}</span>
                            </div>
                        )}

                        {coefValue && (
                            <div className={classes.StatsProperty}>
                                <span>Coefficient of variation:</span>
                                <span>{(coefValue * 100).toFixed(0)}%</span>
                            </div>
                        )}
                    </div>

                    <div className={classes.ExportToPngWrapper}>
                        {isLoading ? <span>Loading...</span> : null}
                        <MaterialTooltip title='Export to png'>
                            <IconButton onClick={exportChart}>
                                <SaveAlt />
                            </IconButton>
                        </MaterialTooltip>
                    </div>

                    <div className={classes.ChartWrapper}>
                        <ResponsiveContainer width='100%' height='100%'>
                            <LineChart
                                ref={chartRef}
                                width={500}
                                height={300}
                                data={props.data}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    left: 0,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid strokeDasharray='1' vertical={false} />
                                <XAxis
                                    dataKey={props.xDataKey}
                                    tickLine={false}
                                    axisLine={{ stroke: '#d5d5d5' }}
                                    tick={{ fill: '#8D9CAB', fontSize: '1.3rem' }}
                                    tickFormatter={formatDate}
                                />
                                <YAxis
                                    tickLine={false}
                                    axisLine={false}
                                    tick={{ fill: '#8D9CAB', fontSize: '1.3rem' }}
                                    label={{
                                        value: props.yLabel ? props.yLabel : '',
                                        angle: -90,
                                        position: 'insideLeft',
                                        fill: '#404b58',
                                        fontSize: '1.3rem',
                                        fontWeight: '700',
                                    }}
                                />
                                <Tooltip />
                                <Legend
                                    layout='horizontal'
                                    align='center'
                                    verticalAlign='top'
                                    wrapperStyle={{
                                        paddingBottom: '30px',
                                    }}
                                />
                                <Line type='monotone' name={props.name} dataKey={props.valKey} stroke='#3a5d9d' activeDot={{ r: 8 }} />
                                {props.withAverage ? (
                                    <ReferenceLine
                                        y={averageValue}
                                        stroke='#e39124'
                                        strokeDasharray='15 5'
                                        strokeWidth={2}
                                        isFront={true}
                                        ifOverflow='extendDomain'
                                    />
                                ) : null}
                                {props.withAverage ? (
                                    <Line name='Average' dataKey='null123' stroke='#e39124' dot={false} strokeDashArray='15 5' strokeWidth={2} />
                                ) : null}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className={classes.PeriodSelection}>
                        {props.timeframe === 'year' && (
                            <div className={classes.YearSelection}>
                                {checkYear('first') ? (
                                    <div className={classes.SvgWrapper} onClick={previousYearHandler}>
                                        <FontAwesomeIcon icon={faAngleLeft} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}

                                <span className={classes.Year}>{props.selectedYear}</span>

                                {checkYear('last') ? (
                                    <div className={classes.SvgWrapper} onClick={nextYearHandler}>
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </div>
                                ) : (
                                    <div className={classes.Placeholder}></div>
                                )}
                            </div>
                        )}

                        <div onClick={yearHandler} className={props.timeframe === 'year' ? [classes.Active, classes.YearButton].join(' ') : classes.YearButton}>
                            Year
                        </div>
                        <div onClick={sumHandler} className={props.timeframe === 'all' ? [classes.Active, classes.SumButton].join(' ') : classes.SumButton}>
                            All
                        </div>
                    </div>
                </React.Fragment>
            )}
            {props.data.length === 0 && <span>No data</span>}
        </React.Fragment>
    );
};

export default MyBarChart;
