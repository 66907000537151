import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';

import classes from './PrimarySelect.module.css';

const PrimarySelect = (props) => {
  const [value, setValue] = React.useState(props.defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.changed(event.target.value);
  };

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth + 20);
  }, []);

  let elementClasses = [classes.FormControl];

  if (props.fullWidth) elementClasses.push(classes.FullWidth);
  else if (props.narrow) elementClasses.push(classes.Narrow);
  else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

  return (
    <FormControl
      className={elementClasses.join(' ')}
      disabled={props.disabled}
      variant='outlined'
    >
      <InputLabel shrink ref={inputLabel} htmlFor='outlined-always-notched'>
        {props.label}
      </InputLabel>
      <Select
        labelId={props.labelId}
        value={
          props.options &&
          props.options.filter((x) => x.value === value).length > 0
            ? value
            : ''
        }
        onChange={handleChange}
        displayEmpty
        // error={props.invalid ? true : false}
        error={
          props.mandatory &&
          (value === null || (typeof value === 'string' && value.trim() === ''))
            ? true
            : false
        }
        className={classes.SelectEmpty}
        label={props.label}
        input={
          <OutlinedInput
            notched
            labelWidth={labelWidth}
            id='outlined-always-notched'
          />
        }
      >
        {props.noEmpty ? null : (
          <MenuItem value=''>{props.placeholder}</MenuItem>
        )}

        {props.options
          ? props.options.map((option, index) => (
              <MenuItem key={'menuItem_' + index} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
};

export default PrimarySelect;
