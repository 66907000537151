import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

import classes from './Admin.module.css';
import * as actions from '../../store/actions/index';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import DataTable from '../../components/DataTable/DataTable';
import Modal from "../../components/UI/Modal/Modal";
import AdminEditUser from './AdminEditUser';
import { dateTimeToISO } from '../../store/utilities';
import Loader from '../../components/UI/Loader/Loader';

const { Entropy } = require('entropy-string')

class AdminUsers extends Component {
    
    state={
        showModal:false,
        selectedData: null,
        password:"",
        confirmPassword:"",
        action:null,
        tableOptions:{
            initialPage:0,
            pageSize:5,
            searchText:"",
            defaultSort:{column: null, order:""}
        }
    }

    componentDidMount () {
        this.props.onGetAllUsers();
    }

    generateRandomPassword = () => {
        // Creates a random string
        let entropy;
        let charset;

        charset = '234689bdfghmnpqrBDFGHJLMNR!@#_)(';
        // const specialCharsArr = ["!","@","#","_",")","("];
        
        const bits = 8 * 5; // The first number is the minimum characters necessary

        entropy = new Entropy({ charset:charset, bits: bits });
        let string = entropy.string();
       
        this.setState({ password:string, confirmPassword:string })
    }

    addUser(){
        let now = new Date();
        now = dateTimeToISO(now);

        const selectedData = {
            id:0,
            email: "",
            name: "",
            active: "1",
            registration_date: now,
            role_id: "20",
            role_name: "User",
            username: ""
        }

        this.setState({selectedData:selectedData, showModal:true, action:"new"})
    }

    editUser(data){
        const selectedData = {...data};
        this.setState({selectedData:selectedData, showModal:true, action:"edit"});
    }

    prepareDeleteUser(rowData){
        if (this.props.loggedInUser.id == rowData.id){
            Swal.fire({
                type: 'error',
                text: 'You cannot delete the user you are logged in as'
            })
            return;
        }


        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The user with id: ' + rowData.id + ' will be deleted.',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteUser(rowData.id);
            }
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The user was deleted.'
                })
            }
        });
    }

    prepareSaveUser = () => {
        const invalidNum = document.getElementsByClassName("Mui-error").length;
        if (invalidNum > 0){
            Swal.fire({
                type: 'error',
                text: 'Please fill in all the mandatory fields',
            })
            return
        }

        if (this.state.password.trim() !== this.state.confirmPassword.trim()){
            Swal.fire({
                type: 'error',
                text: 'The password and confirm password do not match',
            })
            return
        }

        let sureMsg = "A new user will be created. An email will be sent to the user.";
        let confirmMsg = "The new user was created. An email was sent to the user";

        if (this.state.action === "edit"){
            sureMsg = "The user details will be updated.";
            confirmMsg = "The user details were updated.";
        } 

        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: sureMsg,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                var postData = {...this.state.selectedData};
                postData.password = this.state.password;

                if (this.state.action === "edit"){
                    this.props.onUpdateUser(postData);
                
                } else if (this.state.action === "new"){
                    this.props.onAddUser(postData);
                } 

                this.setState({showModal:false, action:null, password:"", confirmPassword:""})
            }
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: confirmMsg,
                })
            }
        })
    }

    getUsersFields = () => {
        let tableFields = [
            {title: "id", field: "id", defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null},
            {title: "Name", field: "name", defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null},
            {title: "Email", field: "email", defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null},
            {title: "Role", field: "role_name", defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null},
            {title: "Status", field: "active", render: rowData => rowData.active === "1" ? "Active" : "Not active", defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null}
        ]

        return tableFields;
    }

    onEditSelectedData = (property, value) => {
        let selectedData = {...this.state.selectedData};

        selectedData[property] = value;
        
        this.setState({
            selectedData:selectedData
        })
    }

    updateTableOptions = (property, value) => {
        let tableOptions = {...this.state.tableOptions};
        tableOptions[property] = value;

        this.setState({tableOptions: tableOptions})
    }
    
    render () {
        const usersFields = this.getUsersFields();

        let page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Users</h1>
                        </div>
                        <div className={classes.PageContent}>
                            <Loader show={this.props.loading} />
                        </div>
                    </Aux>

        if (this.props.loading === false){
            page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Users</h1>
                            <div className={classes.MarginLeftAuto}>
                                <div className={classes.ActionButtonsWrapper}>
                                    <div className={classes.CancelButton} onClick={() => this.props.history.push('/admin')}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.PageContent}>
                            <div className={classes.PageInner}>
                                <Scrollbars style={{width:"100%", height:"100%"}}>
                                    <div className={classes.ScrollInner}>
                                        <div className={classes.AddUserButtonWrapper}>
                                            <PrimaryButton clicked={() => this.addUser()}>
                                                <FontAwesomeIcon icon={faPlus} />
                                                Add
                                            </PrimaryButton>
                                        </div>
                                        
                                        <DataTable 
                                            data={this.props.allUsers} 
                                            columns={usersFields}
                                            editClicked={(rowData)=>this.editUser(rowData)}
                                            deleteClicked={(rowData)=>this.prepareDeleteUser(rowData)}
                                            withExport
                                            withSearch
                                            tableOptions={this.state.tableOptions}
                                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        />
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>

                        <Modal 
                            show={this.state.showModal}
                            modalClosed={() => this.setState({showModal:false, action:null, password:"", confirmPassword:""})}
                        >
                            {
                                this.state.action === "edit" || this.state.action === "new"
                                ?<AdminEditUser 
                                    selectedData={this.state.selectedData}
                                    loggedInUser={this.props.loggedInUser}
                                    password={this.state.password}
                                    confirmPassword={this.state.confirmPassword}
                                    action={this.state.action}
                                    updateState={ (property, value) => this.setState({[property]: value}) }
                                    editSelectedData={(name, value)=>this.onEditSelectedData(name, value)}
                                    generateRandomPassword={()=>this.generateRandomPassword()}
                                    saveUser={()=>this.prepareSaveUser()}
                                    cancel={() => this.setState({showModal:false, action:null, password:"", confirmPassword:""})}
                                />
                                : null
                            }
                        </Modal>
                    </Aux> 
        }
        return(
            page
            
        )
    }
}

const mapStateToProps = state => {
    return {
        allUsers: state.admin.allUsers,
        loggedInUser:state.login.loggedInUser,
        loading:state._system.loading
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetAllUsers: () => dispatch(actions.getAllUsers()),
        onDeleteUser: (userId) => dispatch(actions.deleteUser(userId)),
        onUpdateUser: (userData) => dispatch(actions.updateUser(userData)),
        onAddUser: (userData) => dispatch(actions.addUser(userData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
