import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Can from '../../containers/Login/Can';

const PrivateRoute = ({ component: Component, ...rest }) => {
    let route = <Route {...rest} render={(props) => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />} />;

    if (rest.user) {
        route = (
            <Can
                role={rest.user.role}
                perform={`${rest.path.substr(1)}:view`}
                yes={() => <Route {...rest} render={(props) => <Component {...props} />} />}
                no={() => <Route {...rest} render={(props) => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />} />}
            />
        );
    }

    return route;
};

export default PrivateRoute;
