import * as actionTypes from './actionTypes';

export const loading = (value) => {
    return {
        type: actionTypes.LOADING,
        value: value
    };
};

export const partLoading = (value) => {
    return {
        type: actionTypes.PART_LOADING,
        value: value
    };
};

export const updateBreadcrumbs = (breadcrumbs) => {
    return {
        type: actionTypes.UPDATE_BREADCRUMBS,
        breadcrumbs: breadcrumbs
    };
};

// export const cancelApiRequest = () => {
//     return {
//         type: actionTypes.CANCEL_API_REQUEST
//     };
// };