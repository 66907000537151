import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const initialState = {
    loading:false,
    partLoading: false,
    breadcrumbs:""
}

const loading = (state,action) => {
    return updateObject(state, {loading:action.value});
}

const partLoading = (state,action) => {
    return updateObject(state, {partLoading:action.value});
}

const updateBreadcrumbs = (state,action) => {
    return updateObject(state, {breadcrumbs:action.breadcrumbs});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOADING: return loading(state,action);
        case actionTypes.PART_LOADING: return partLoading(state,action);
        case actionTypes.UPDATE_BREADCRUMBS: return updateBreadcrumbs(state,action);
        default: return state;
    }
};

export default reducer;