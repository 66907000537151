import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const PrimaryCheckbox = (props) => {
    let color = "primary";
    if (props.color === "secondary") color = "secondary";

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.checked}
                    onChange={props.changed}
                    color={color} 
                    name={props.name}
                    disabled={props.disabled}
                />
            }
            label={props.label}
            labelPlacement={props.labelPlacement}
        />
    )
}

export default PrimaryCheckbox;