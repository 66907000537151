import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Print from '@material-ui/icons/Print';
import MaterialTooltip from '@material-ui/core/Tooltip';

import { useReactToPrint } from 'react-to-print';

import classes from './PrintId.module.css';

const PrintId = (props) => {
    const [el, setEl] = React.useState(null);

    React.useEffect(() => {
        const el = document.getElementById(props.contentId);
        setEl(el);
    }, [props.contentId, props.loading]);

    const handlePrint = useReactToPrint({
        content: () => el,
    });

    return (
        <div className={classes.PrintId}>
            <MaterialTooltip title='Print page'>
                <IconButton onClick={handlePrint}>
                    <Print />
                </IconButton>
            </MaterialTooltip>
        </div>
    );
};

export default PrintId;
