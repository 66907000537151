import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import Backdrop from '../UI/Backdrop/Backdrop';
import Button from '../UI/PrimaryButton/PrimaryButton';
import SideFilter from './SideFilter';
import classes from './SideFilters.module.css';
import * as actions from '../../store/actions/index';

class MapFilters extends Component {
    state = {
        show: false,
        storedFilters: null,
        storedMapFilters: null,
    };

    componentDidMount() {
        const storedFilters = JSON.parse(localStorage.getItem('filters')) || [];
        this.setState({ storedFilters: storedFilters });
    }

    toggleStateVariable = (variable) => {
        const current = this.state[variable];
        this.setState({ [variable]: !current });
    };

    updateFilters = (newFilters) => {
        localStorage.setItem('filters', JSON.stringify(newFilters));
        this.setState({ storedFilters: newFilters });
        this.props.onUpdateFilteredData(this.props.gseGeneralInfo, 'gseData');
        this.props.onUpdateFilteredData(this.props.lastLocations, 'lastLocations');
        this.props.onUpdateFilteredData(this.props.serviceRecords, 'serviceRecords');
        this.props.onUpdateFilteredData(this.props.tiresRecords, 'tiresRecords');
        this.props.onUpdateFilteredData(this.props.batteriesRecords, 'batteriesRecords');
    };

    removeFilter = (index) => {
        let storedFilters = JSON.parse(localStorage.getItem('filters')) || [];
        storedFilters.splice(index, 1);
        localStorage.setItem('filters', JSON.stringify(storedFilters));

        this.setState({ storedFilters: storedFilters });
        this.props.onUpdateFilteredData(this.props.gseGeneralInfo, 'gseData');
        this.props.onUpdateFilteredData(this.props.lastLocations, 'lastLocations');
        this.props.onUpdateFilteredData(this.props.serviceRecords, 'serviceRecords');
        this.props.onUpdateFilteredData(this.props.tiresRecords, 'tiresRecords');
        this.props.onUpdateFilteredData(this.props.batteriesRecords, 'batteriesRecords');
    };

    getStoredFilters = () => {
        let storedFilters = this.state.storedFilters;
        let filtersBoxes = null;

        if (!storedFilters || storedFilters.length === 0 || _.isEmpty(this.props.ddLists)) {
            return null;
        }

        filtersBoxes = storedFilters.map((filter, index) => {
            let value = '';
            let found;

            if (filter.property === 'airportId') {
                found = this.props.airports.find((x) => x.id === filter[filter.property]);
                if (found) value = found.shortName;
            } else if (this.props.filtersDdlists[filter.property]) {
                found = this.props.filtersDdlists[filter.property].find((x) => x.value === filter[filter.property]);
                if (found) value = found.label;
            } else if (this.props.ddLists[filter.property]) {
                found = this.props.ddLists[filter.property].find((x) => x.value === filter[filter.property]);
                if (found) value = found.label;
            }

            return (
                <div key={`filterBox_${index}`} className={classes.FilterBox} onClick={() => this.removeFilter(index)}>
                    <span className={classes.FilterBoxLabel}>{`${filter.label}: ${value}`}</span>
                    <div className={classes.FilterBoxIcon}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
            );
        });

        return filtersBoxes;
    };

    getLocationIcons = () => {
        const iconSet = {
            241: <div className={classes.LocationTypeIcon}></div>, //other
            242: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'>
                        <path d='M205.22 22.09c-7.94-28.78-49.44-30.12-58.44 0C100.01 179.85 0 222.72 0 333.91 0 432.35 78.72 512 176 512s176-79.65 176-178.09c0-111.75-99.79-153.34-146.78-311.82zM176 448c-61.75 0-112-50.25-112-112 0-8.84 7.16-16 16-16s16 7.16 16 16c0 44.11 35.89 80 80 80 8.84 0 16 7.16 16 16s-7.16 16-16 16z' />
                    </svg>
                </div>
            ), //Water supply point
            243: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                        <path d='M256.47 216.77l86.73 109.18s-16.6 102.36-76.57 150.12C206.66 523.85 0 510.19 0 510.19s3.8-23.14 11-55.43l94.62-112.17c3.97-4.7-.87-11.62-6.65-9.5l-60.4 22.09c14.44-41.66 32.72-80.04 54.6-97.47 59.97-47.76 163.3-40.94 163.3-40.94zM636.53 31.03l-19.86-25c-5.49-6.9-15.52-8.05-22.41-2.56l-232.48 177.8-34.14-42.97c-5.09-6.41-15.14-5.21-18.59 2.21l-25.33 54.55 86.73 109.18 58.8-12.45c8-1.69 11.42-11.2 6.34-17.6l-34.09-42.92 232.48-177.8c6.89-5.48 8.04-15.53 2.55-22.44z' />
                    </svg>
                </div>
            ), //G.S.E. Cleaning Area
            244: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                        <path d='M128 0C74.98 0 32 85.96 32 192v172.07c0 41.12-9.8 62.77-31.17 126.87C-2.62 501.3 5.09 512 16.01 512h280.92c13.77 0 26-8.81 30.36-21.88 12.83-38.48 24.71-72.4 24.71-126.05V192c0-83.6 23.67-153.52 60.44-192H128zM96 224c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm64 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm64 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm64 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zM480 0c-53.02 0-96 85.96-96 192s42.98 192 96 192 96-85.96 96-192S533.02 0 480 0zm0 256c-17.67 0-32-28.65-32-64s14.33-64 32-64 32 28.65 32 64-14.33 64-32 64z' />
                    </svg>
                </div>
            ), //Toilet unit emptying point
            245: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                        <path d='M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z' />
                    </svg>
                </div>
            ), //Aircraft Taxi Area
            246: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                        <path d='M509.5 184.6L458.9 32.8C452.4 13.2 434.1 0 413.4 0H272v192h238.7c-.4-2.5-.4-5-1.2-7.4zM240 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240V0zM0 224v240c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V224H0z' />
                    </svg>
                </div>
            ), //Cargo
            247: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                        <path d='M502.63 214.63l-45.25-45.25c-6-6-14.14-9.37-22.63-9.37H384V80c0-26.51-21.49-48-48-48H176c-26.51 0-48 21.49-48 48v80H77.25c-8.49 0-16.62 3.37-22.63 9.37L9.37 214.63c-6 6-9.37 14.14-9.37 22.63V320h128v-16c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v16h128v-16c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v16h128v-82.75c0-8.48-3.37-16.62-9.37-22.62zM320 160H192V96h128v64zm64 208c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-16H192v16c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-16H0v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96H384v16z' />
                    </svg>
                </div>
            ), //G.S.E. Maintenance
            248: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                        <path d='M544 160v64h32v64h-32v64H64V160h480m16-64H48c-26.51 0-48 21.49-48 48v224c0 26.51 21.49 48 48 48h512c26.51 0 48-21.49 48-48v-16h8c13.255 0 24-10.745 24-24V184c0-13.255-10.745-24-24-24h-8v-16c0-26.51-21.49-48-48-48zm-240 96H96v128h224V192z' />
                    </svg>
                </div>
            ), //G.S.E. Charging points
            249: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                        <path d='M628.88 210.65L494.39 49.27A48.01 48.01 0 0 0 457.52 32H32C14.33 32 0 46.33 0 64v288c0 17.67 14.33 32 32 32h32c0 53.02 42.98 96 96 96s96-42.98 96-96h128c0 53.02 42.98 96 96 96s96-42.98 96-96h32c17.67 0 32-14.33 32-32V241.38c0-11.23-3.94-22.1-11.12-30.73zM64 192V96h96v96H64zm96 240c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm160-240h-96V96h96v96zm160 240c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm-96-240V96h66.02l80 96H384z' />
                    </svg>
                </div>
            ), //G.S.E. Staging Area
            250: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                        <path d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM240 320h-48v48c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h96c52.9 0 96 43.1 96 96s-43.1 96-96 96zm0-128h-48v64h48c17.6 0 32-14.4 32-32s-14.4-32-32-32z' />
                    </svg>
                </div>
            ), //Aircraft Parking Stands
            251: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                        <path d='M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z' />
                    </svg>
                </div>
            ), //Baggage Sorting Area
            252: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                        <path d='M224 320h32V96h-32c-17.67 0-32 14.33-32 32v160c0 17.67 14.33 32 32 32zm352-32V128c0-17.67-14.33-32-32-32h-32v224h32c17.67 0 32-14.33 32-32zm48 96H128V16c0-8.84-7.16-16-16-16H16C7.16 0 0 7.16 0 16v32c0 8.84 7.16 16 16 16h48v368c0 8.84 7.16 16 16 16h82.94c-1.79 5.03-2.94 10.36-2.94 16 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.64-1.15-10.97-2.94-16h197.88c-1.79 5.03-2.94 10.36-2.94 16 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.64-1.15-10.97-2.94-16H624c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM480 96V48c0-26.51-21.49-48-48-48h-96c-26.51 0-48 21.49-48 48v272h192V96zm-48 0h-96V48h96v48z' />
                    </svg>
                </div>
            ), //Baggage Delivery Arrivals
            253: <div className={classes.LocationTypeIcon}></div>, //Out of Gauge departures/arrivals
            254: (
                <div className={classes.LocationTypeIcon}>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                        <path d='M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z' />
                    </svg>
                </div>
            ), //G.S.E. Refueling Area
        };

        return iconSet;
    };

    render() {
        const filters = this.getStoredFilters();
        const locationIcons = this.getLocationIcons();

        return (
            <div className={this.state.show ? [classes.SideFilters, classes.Show].join(' ') : classes.SideFilters}>
                <div className={classes.FiltersRow}>
                    <Button clicked={() => this.toggleStateVariable('show')}>
                        <FontAwesomeIcon icon={faFilter} />
                        <span>Filters</span>
                    </Button>

                    <div className={classes.FiltersBoxes}>{filters}</div>
                </div>

                <Backdrop show={this.state.show} clicked={() => this.setState({ show: false })} />

                <div className={classes.SideFiltersInner}>
                    <div className={classes.Title}>
                        <h2>Map Filters</h2>
                        <div className={classes.CancelButton} onClick={() => this.setState({ show: false })}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>

                    <div className={classes.FiltersWrapper}>
                        <SideFilter
                            label='Bluetooth / GPS'
                            type='Multilist'
                            options={[
                                { value: 'bluetooth', label: 'Bluetooth' },
                                { value: 'gps', label: 'GPS' },
                            ]}
                            titleProperty='label'
                            valueProperty='bluetooth_gps'
                            value='value'
                            isHidden={false}
                            updateFilters={(updatedFilters) => this.props.onUpdateMapFilters(updatedFilters)}
                            filters={this.props.mapFilters}
                        />
                        <SideFilter
                            label='Active / Not active'
                            type='Multilist'
                            options={[
                                { value: 'active', label: 'Active' },
                                { value: 'notActive', label: 'Not active' },
                            ]}
                            titleProperty='label'
                            valueProperty='active_notActive'
                            value='value'
                            isHidden={false}
                            updateFilters={(updatedFilters) => this.props.onUpdateMapFilters(updatedFilters)}
                            filters={this.props.mapFilters}
                        />
                        {/* <SideFilter
              label='Airport location types'
              type='Multilist'
              options={this.props.ddLists.locationTypes}
              titleProperty='label'
              valueProperty='locationType'
              value='value'
              isHidden={false}
              updateFilters={(updatedFilters) =>
                this.props.onUpdateMapFilters(updatedFilters)
              }
              filters={this.props.mapFilters}
              toggleAll={true}
              icons={locationIcons}
            /> */}
                    </div>

                    <div className={[classes.Title, classes.MarginTop20].join(' ')}>
                        <h2>GSE Filters</h2>
                    </div>

                    <div className={classes.FiltersWrapper}>
                        <SideFilter
                            label='Airport'
                            type='Multilist'
                            options={this.props.airports}
                            titleProperty='shortName'
                            valueProperty='airportId'
                            value='id'
                            isHidden={true}
                            updateFilters={(updatedFilters) => this.updateFilters(updatedFilters)}
                            filters={this.state.storedFilters}
                        />
                        <SideFilter
                            label='Vehicle type'
                            type='Multilist'
                            options={this.props.filtersDdlists.vehicleType}
                            titleProperty='label'
                            valueProperty='vehicleType'
                            value='value'
                            isHidden={true}
                            updateFilters={(updatedFilters) => this.updateFilters(updatedFilters)}
                            filters={this.state.storedFilters}
                        />
                        <SideFilter
                            label='Equipment type'
                            type='Multilist'
                            options={this.props.filtersDdlists.equipmentType}
                            titleProperty='label'
                            valueProperty='equipmentType'
                            value='value'
                            isHidden={true}
                            updateFilters={(updatedFilters) => this.updateFilters(updatedFilters)}
                            filters={this.state.storedFilters}
                        />
                        <SideFilter
                            label='Manufacturer'
                            type='Multilist'
                            options={this.props.filtersDdlists.manufacturer}
                            titleProperty='label'
                            valueProperty='manufacturer'
                            value='value'
                            isHidden={true}
                            updateFilters={(updatedFilters) => this.updateFilters(updatedFilters)}
                            filters={this.state.storedFilters}
                        />
                        <SideFilter
                            label='Model'
                            type='Multilist'
                            options={this.props.filtersDdlists.model}
                            titleProperty='label'
                            valueProperty='model'
                            value='value'
                            isHidden={true}
                            updateFilters={(updatedFilters) => this.updateFilters(updatedFilters)}
                            filters={this.state.storedFilters}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.loggedInUser,
        loading: state._system.loading,
        airports: state._data.airports,
        ddLists: state._data.ddLists,
        filtersDdlists: state._data.filtersDdlists,
        gseGeneralInfo: state._data.gseGeneralInfo,
        lastLocations: state._data.lastLocations,
        serviceRecords: state._data.serviceRecords,
        tiresRecords: state._data.tiresRecords,
        batteriesRecords: state._data.batteriesRecords,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateFilteredData: (data, dataType) => dispatch(actions.updateFilteredData(data, dataType)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapFilters);
