import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import classes from './PrimaryInput.module.css';

const PrimaryInput2 = (props) => {
    const [value, setValue] = React.useState(props.defaultValue);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    let classNames = [classes.PrimaryInput];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    return (
        <FormControl className={classNames.join(' ')}>
            <TextField
                variant='outlined'
                label={props.invalid && props.value && props.invalidLabel ? props.invalidLabel : props.label}
                // error={props.invalid ? true : false}
                error={props.mandatory && (!value || value.trim() === '') ? true : false}
                type={props.type}
                disabled={props.disabled}
                name={props.name}
                // defaultValue={props.defaultValue}
                value={!value ? '' : value}
                onBlur={props.blur}
                onChange={handleChange}
                inputProps={{
                    minLength: props.minLength,
                    maxLength: props.maxLength,
                    min: props.min,
                    max: props.max,
                    step: props.step,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </FormControl>
    );
};

export default PrimaryInput2;
