import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';

import classes from './PrimaryAutocomplete.module.css';

const Combobox = (props) => {
  const defaultProps = {
    options: props.options,
    getOptionLabel: (option) => option.label,
  };

  let elementClasses = [classes.FormControl];

  if (props.fullWidth) elementClasses.push(classes.FullWidth);
  else if (props.narrow) elementClasses.push(classes.Narrow);
  else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

  if (props.short) elementClasses.push(classes.Short);

  return (
    <FormControl className={elementClasses.join(' ')} disabled={props.disabled}>
      <Autocomplete
        {...defaultProps}
        // options={props.options}
        renderInput={(params) => (
          <TextField variant='outlined' {...params} label={props.label} />
        )}
        onChange={props.changed}
        clearOnEscape
        defaultValue={props.defaultValue}
      />
    </FormControl>
  );
};

export default Combobox;
