import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import Backdrop from '../UI/Backdrop/Backdrop';
import Button from '../UI/PrimaryButton/PrimaryButton';
import SideFilter from './SideFilter';
import classes from './SideFilters.module.css';
import * as actions from '../../store/actions/index';

class SideFilters extends Component {
  state = {
    show: false,
    storedFilters: null,
  };

  componentDidMount() {
    let storedFilters = JSON.parse(localStorage.getItem('filters')) || [];
    this.setState({ storedFilters: storedFilters });
  }

  toggleStateVariable = (variable) => {
    const current = this.state[variable];
    this.setState({ [variable]: !current });
  };

  updateFilters = (newFilters) => {
    localStorage.setItem('filters', JSON.stringify(newFilters));
    this.setState({ storedFilters: newFilters });
    this.props.onUpdateFilteredData(this.props.gseGeneralInfo, 'gseData');
    this.props.onUpdateFilteredData(this.props.lastLocations, 'lastLocations');
    this.props.onUpdateFilteredData(
      this.props.serviceRecords,
      'serviceRecords'
    );
    this.props.onUpdateFilteredData(this.props.tiresRecords, 'tiresRecords');
    this.props.onUpdateFilteredData(
      this.props.batteriesRecords,
      'batteriesRecords'
    );
  };

  removeFilter = (index) => {
    let storedFilters = JSON.parse(localStorage.getItem('filters')) || [];
    storedFilters.splice(index, 1);
    localStorage.setItem('filters', JSON.stringify(storedFilters));

    this.setState({ storedFilters: storedFilters });
    this.props.onUpdateFilteredData(this.props.gseGeneralInfo, 'gseData');
    this.props.onUpdateFilteredData(this.props.lastLocations, 'lastLocations');
    this.props.onUpdateFilteredData(
      this.props.serviceRecords,
      'serviceRecords'
    );
    this.props.onUpdateFilteredData(this.props.tiresRecords, 'tiresRecords');
    this.props.onUpdateFilteredData(
      this.props.batteriesRecords,
      'batteriesRecords'
    );
  };

  getStoredFilters = () => {
    let storedFilters = this.state.storedFilters;
    let filtersBoxes = null;

    if (
      !storedFilters ||
      storedFilters.length === 0 ||
      _.isEmpty(this.props.ddLists)
    ) {
      return null;
    }

    filtersBoxes = storedFilters.map((filter, index) => {
      let value = '';
      let found;

      if (filter.property === 'airportId') {
        found = this.props.airports.find(
          (x) => x.id === filter[filter.property]
        );
        if (found) value = found.shortName;
      } else if (this.props.filtersDdlists[filter.property]) {
        found = this.props.filtersDdlists[filter.property].find(
          (x) => x.value === filter[filter.property]
        );
        if (found) value = found.label;
      } else if (this.props.ddLists[filter.property]) {
        found = this.props.ddLists[filter.property].find(
          (x) => x.value === filter[filter.property]
        );
        if (found) value = found.label;
      }

      return (
        <div
          key={`filterBox_${index}`}
          className={classes.FilterBox}
          onClick={() => this.removeFilter(index)}
        >
          <span
            className={classes.FilterBoxLabel}
          >{`${filter.label}: ${value}`}</span>
          <div className={classes.FilterBoxIcon}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      );
    });

    return filtersBoxes;
  };

  render() {
    const filters = this.getStoredFilters();

    return (
      <div
        className={
          this.state.show
            ? [classes.SideFilters, classes.Show].join(' ')
            : classes.SideFilters
        }
      >
        <div className={classes.FiltersRow}>
          <Button clicked={() => this.toggleStateVariable('show')}>
            <FontAwesomeIcon icon={faFilter} />
            <span>GSE Filters</span>
          </Button>

          <div className={classes.FiltersBoxes}>{filters}</div>
        </div>

        <Backdrop
          show={this.state.show}
          clicked={() => this.setState({ show: false })}
        />

        <div className={classes.SideFiltersInner}>
          <div className={classes.Title}>
            <h2>GSE Filters</h2>
            <div
              className={classes.CancelButton}
              onClick={() => this.setState({ show: false })}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>

          <div className={classes.FiltersWrapper}>
            <SideFilter
              label='Airport'
              type='Multilist'
              options={this.props.airports}
              titleProperty='shortName'
              valueProperty='airportId'
              value='id'
              isHidden={false}
              updateFilters={(updatedFilters) =>
                this.updateFilters(updatedFilters)
              }
              filters={this.state.storedFilters}
            />
            <SideFilter
              label='Vehicle type'
              type='Multilist'
              options={this.props.filtersDdlists.vehicleType}
              titleProperty='label'
              valueProperty='vehicleType'
              value='value'
              isHidden={true}
              updateFilters={(updatedFilters) =>
                this.updateFilters(updatedFilters)
              }
              filters={this.state.storedFilters}
            />
            <SideFilter
              label='Equipment type'
              type='Multilist'
              options={this.props.filtersDdlists.equipmentType}
              titleProperty='label'
              valueProperty='equipmentType'
              value='value'
              isHidden={true}
              updateFilters={(updatedFilters) =>
                this.updateFilters(updatedFilters)
              }
              filters={this.state.storedFilters}
            />
            <SideFilter
              label='Manufacturer'
              type='Multilist'
              options={this.props.filtersDdlists.manufacturer}
              titleProperty='label'
              valueProperty='manufacturer'
              value='value'
              isHidden={true}
              updateFilters={(updatedFilters) =>
                this.updateFilters(updatedFilters)
              }
              filters={this.state.storedFilters}
            />
            <SideFilter
              label='Model'
              type='Multilist'
              options={this.props.filtersDdlists.model}
              titleProperty='label'
              valueProperty='model'
              value='value'
              isHidden={true}
              updateFilters={(updatedFilters) =>
                this.updateFilters(updatedFilters)
              }
              filters={this.state.storedFilters}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.login.loggedInUser,
    loading: state._system.loading,
    airports: state._data.airports,
    ddLists: state._data.ddLists,
    filtersDdlists: state._data.filtersDdlists,
    gseGeneralInfo: state._data.gseGeneralInfo,
    lastLocations: state._data.lastLocations,
    serviceRecords: state._data.serviceRecords,
    tiresRecords: state._data.tiresRecords,
    batteriesRecords: state._data.batteriesRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateFilteredData: (data, dataType) =>
      dispatch(actions.updateFilteredData(data, dataType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideFilters);
