import { createTheme  } from '@material-ui/core/styles';


export const theme = createTheme ({
    palette: {
        primary: {
            main: "#008f40",
            contrastText: "#fff"
        },
        secondary: {
            main: "#EE3224",
            contrastText: "#fff"
        }
    },
    typography:{
        fontFamily: 'Open Sans, sans-serif',
    },
    overrides:{
        MuiToolbar:{
            root:{
                "& .MuiInput-root": {
                    fontSize: '1.4rem',
                    fontFamily: 'inherit',
                    lineHeight: 'inherit',
                    letterSpacing: 'inherit',
                    color: '#404B58',
                    background: '#fff',
                    padding: '2px',
                    paddingLeft: '10px',
                    marginRight: '5px',
                    borderRadius: 3
                  },
            }
        },
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: "#008F40",
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            // backgroundColor: lightBlue.A200,
            // color: "white",
          },
        },
        MuiTabs:{
          indicator:{
            backgroundColor:"#00642c"
          }
        },
        MuiAutocomplete:{
          endAdornment:{
            top:"unset"
          }
        },
        MuiTable:{
            root: {
                "& .MuiTable-root": {
                  fontSize: '1.4rem'
                },
                "& .MuiSvgIcon-root":{
                  fontSize: 'initial'
                },
                "& .MuiTableCell-root":{
                  fontSize: 'inherit',
                  fontFamily: 'inherit',
                  lineHeight: 'inherit',
                  letterSpacing: 'inherit',
                  color: 'inherit'
                },
                "& .MuiTableRow-head th > span":{
                  fontWeight: 600,
                  color: '#404B58!important'
                },
                "& .MuiTableRow-head th > span > div":{
                  fontWeight: 600,
                  fontSize: 'inherit',
                  color: '#404B58!important'
                },
                "& .MuiTableRow-head th > div > span":{
                  fontWeight: 600,
                  fontSize: 'inherit',
                  color: '#404B58!important'
                },
                "& .MuiTableBody-root td":{
                  borderBottom: 'none',
                  paddingBottom: '5px!important',
                  paddingTop: '5px!important'
                },
          
                "& .MuiTableFooter-root":{
                  borderTop: '1px solid rgba(224, 224, 224, 1)'
                },
                "& tfoot .MuiTableCell-root":{
                  borderBottom:0
                }
            }
        },
        MuiSvgIcon:{
            root:{
                width: "20px!important",
                height: "20px!important"
            }
        },
        MuiTooltip:{
            popper:{
                "& div":{
                    fontSize: "12px!important",
                    fontFamily: 'Open Sans, sans-serif'
                }
            }
        }
    }
})