const rules = {
    User: {
        static: [
            'gseData:view',
            'gseInfo:view',
            'locationMap:view',
            'flightInfo:view',
            'service:view',
            'notifications:view',
            'tripHistory:view',
            'statistics:view',
            'statisticsCompany:view',
            'statisticsAirport:view',
            'statisticsEquipment:view',
            'statisticsEquipmentAirport:view',
            'statisticsGse:view',
            'tracking:view',
        ],
    },
    Admin: {
        static: [
            'gseData:view',
            'gseInfo:view',
            'gseEdit:view',
            'locationMap:view',
            'flightInfo:view',
            'service:view',
            'notifications:view',
            'tripHistory:view',
            'admin:view',
            'adminUsers:view',
            'adminLists:view',
            'adminAirports:view',
            'adminTrackers:view',
            'adminSims:view',
            'adminEditAirport:view',
            'adminSymbology:view',
            'statistics:view',
            'statisticsCompany:view',
            'statisticsAirport:view',
            'statisticsEquipment:view',
            'statisticsEquipmentAirport:view',
            'statisticsGse:view',
            'tracking:view',
        ],
    },
    // user: {
    //     static: [
    //         "posts:list",
    //         "home-page:visit"
    //     ]
    // },
    // admin: {
    //     static: [
    //       "posts:list",
    //       "posts:create",
    //       "posts:edit",
    //       "posts:delete",
    //       "users:get",
    //       "users:getSelf",
    //       "home-page:visit",
    //       "dashboard-page:visit"
    //     ]
    // },
    // writer: {
    //   static: [
    //     "posts:list",
    //     "posts:create",
    //     "users:getSelf",
    //     "home-page:visit",
    //     "dashboard-page:visit"
    //   ],
    //   dynamic: {
    //     "posts:edit": ({userId, postOwnerId}) => {
    //       if (!userId || !postOwnerId) return false;
    //       return userId === postOwnerId;
    //     }
    //   }
    // }
};

export default rules;
