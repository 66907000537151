import axiosDb from '../../axios-db';
import Swal from 'sweetalert2';

import classes from './AuthenticatedLink.module.css';

const AuthenticatedLink = (props) => {

  let authenticatedLinkClasses = [classes.AuthenticatedLink];

  if (props.type === "button"){
    authenticatedLinkClasses.push(classes.AuthenticatedLinkButton)
  }

  const handleAction = async () => {

    axiosDb.get('getFile.php', {
      responseType: 'blob',
      params: {
        filename: props.filename,
        savename: props.savename,
        subfolder: props.subfolder
      }
    })
      .then(response => {
          let url = window.URL.createObjectURL(response.data);
          let a = document.createElement('a');
          a.href = url;
          a.download = props.savename;
          a.click();  
          a = null;
      })
      .catch( error => {
        Swal.fire({ type: 'error', text: "Error: Something went wrong. Please try again." });
      });
  }

  return (
      <a className={authenticatedLinkClasses.join(' ')} role='button' onClick={handleAction}>{props.children}</a>
  )
}

export default AuthenticatedLink;