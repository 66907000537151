import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faListUl,
  faMapMarkerAlt,
  faSimCard,
  // faPalette,
} from '@fortawesome/free-solid-svg-icons';

import classes from './Admin.module.css';
import Loader from '../../components/UI/Loader/Loader';

class Admin extends Component {
  render() {
    return (
      <Aux>
        <div className={classes.FiltersSpace}></div>
        <div className={classes.PageTitle}>
          <h1>Admin</h1>
        </div>
        <div className={classes.PageContent}>
          <div className={classes.PageInner}>
            <Loader show={this.props.loading} />
            <Scrollbars style={{ width: '100%', height: '100%' }}>
              <div className={classes.ScrollInner}>
                <div className={classes.AdminUsers}>
                  <div
                    className={classes.AdminBoxWrapper}
                    onClick={() => this.props.history.push('/adminUsers')}
                  >
                    <div className={classes.AdminBox}>
                      <FontAwesomeIcon icon={faUsers} />
                      <span>Users</span>
                    </div>
                  </div>
                  <div
                    className={classes.AdminBoxWrapper}
                    onClick={() => this.props.history.push('/adminLists')}
                  >
                    <div className={classes.AdminBox}>
                      <FontAwesomeIcon icon={faListUl} />
                      <span>Lists</span>
                    </div>
                  </div>
                  <div
                    className={classes.AdminBoxWrapper}
                    onClick={() => this.props.history.push('/adminAirports')}
                  >
                    <div className={classes.AdminBox}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='89.447'
                        height='135.919'
                        viewBox='0 0 89.447 135.919'
                      >
                        <g>
                          <path
                            d='M147.81,319.345c2.853-20.416,5.689-40.719,8.531-61.046.52-.029.9-.072,1.281-.072q9.323,0,18.644-.017c.911,0,1.356.168,1.5,1.208q4.105,29.763,8.288,59.508a2.711,2.711,0,0,1-.017.419Z'
                            transform='translate(-105.079 -183.562)'
                          />
                          <path
                            d='M180.533,174.68c-.818,2.48-1.593,4.848-2.376,7.213-1.466,4.429-2.955,8.849-4.391,13.3a1.442,1.442,0,0,1-1.616,1.2c-10.208-.043-20.416-.032-30.624-.09a1.741,1.741,0,0,1-1.362-.815c-2.333-6.774-4.591-13.588-6.863-20.373a1.878,1.878,0,0,1,0-.425Z'
                            transform='translate(-94.755 -124.18)'
                          />
                          <path
                            d='M145,81.71h4.834V96.847h15.981V81.736h4.842V96.847h4.091v8.994H120v-8.9h4.065v-15.2h4.848V96.827H145Z'
                            transform='translate(-85.308 -58.088)'
                          />
                          <path
                            d='M47.513,274.94c-.483,3.426-.942,6.707-1.414,10.046H0V274.94Z'
                            transform='translate(0 -195.456)'
                          />
                          <path
                            d='M119.88,45.172v-5.14h10.483V33.51h2.3v6.375h7.728V35.5h2.284V39.94h32v5.233Z'
                            transform='translate(-85.223 -23.822)'
                          />
                          <path
                            d='M154.369,4.105a4.03,4.03,0,0,1-4.1,3.949,3.978,3.978,0,0,1-3.955-4.07A4.047,4.047,0,0,1,150.382,0a4.105,4.105,0,0,1,3.987,4.105Z'
                            transform='translate(-104.017 0)'
                          />
                          <path
                            d='M190.827,18.6a2.764,2.764,0,0,1-2.729,2.755,2.74,2.74,0,1,1,2.735-2.755Z'
                            transform='translate(-131.758 -11.285)'
                          />
                          <path
                            d='M53.865,329.106c.194-1.347.373-2.57.578-4H44.759V318.82H39.622v6.2H31.614v-6.152H26.485v6.158H18.4v-6.2H13.49V351.2H25.514v11.364c7.765,0,15.366,0,22.966-.038.289,0,.775-.523.821-.853.971-6.617,1.891-13.244,2.848-20.063H44.892V329.106Zm-27.444,12.4H18.587V329.251h7.835Zm13.128.038H31.712V329.23h7.837Z'
                            transform='translate(-9.59 -226.65)'
                          />
                        </g>
                      </svg>
                      <span>Airports</span>
                    </div>
                  </div>
                  <div
                    className={classes.AdminBoxWrapper}
                    onClick={() => this.props.history.push('/adminSims')}
                  >
                    <div className={classes.AdminBox}>
                      <FontAwesomeIcon icon={faSimCard} />
                      <span>Sim cards</span>
                    </div>
                  </div>
                  <div
                    className={classes.AdminBoxWrapper}
                    onClick={() => this.props.history.push('/adminTrackers')}
                  >
                    <div className={classes.AdminBox}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      <span>Trackers</span>
                    </div>
                  </div>
                  {/* <div className={classes.AdminBoxWrapper} onClick={() => this.props.history.push('/adminSymbology')}>
                                        <div className={classes.AdminBox}>
                                            <FontAwesomeIcon icon={faPalette} />
                                            <span>Symbology</span>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      </Aux>
    );
  }
}

export default withRouter(Admin);
