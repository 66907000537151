import React from 'react';
import Aux from 'react-aux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';

import classes from './Tooltip.module.css';

const Tooltip = (props) => {
    return (
        <Aux>        
            <a className={classes.Tooltip} data-tip={props.text} data-event="touchstart focus mouseover" data-event-off="mouseout" data-background-color="#404b58">
                <FontAwesomeIcon icon={faInfo} />
            </a>
            <ReactTooltip globalEventOff='touchstart' effect='solid' />
        </Aux>
    )
}

export default Tooltip;