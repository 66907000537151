import React, { Component } from 'react';
import Aux from 'react-aux';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons';

import classes from './Login.module.css';
import PrimaryCheckbox from '../../components/UI/PrimaryCheckbox/PrimaryCheckbox';
import * as actions from '../../store/actions/index';

const CONFIG = require('../../reactConfig.json');

class Login extends Component {
    state = {
        username: '',
        password: '',
        name_reg: '',
        email_reg: '',
        username_reg: '',
        password_reg: '',
        password_reg_confirm: '',
        checkTerms: false,
        password_reset: '',
        password_reset_confirm: '',
        email_reset: '',
        rememberMe: false,
    };

    componentDidMount() {
        const activationKey = window.location.href.split('?')[1];
        if (activationKey) {
            if (activationKey.startsWith('reset')) {
                this.props.onUpdatePassword(activationKey);
            } else {
                this.props.onAccountActivation(activationKey);
            }
        }
    }

    login = (e) => {
        e.preventDefault();
        if (this.state.username && this.state.password) {
            this.props.onCheckLogin(this.state);
        }
    };

    onChange(name, value) {
        this.setState({ [name]: value });
    }

    checkResetPassword = () => {
        if (!this.state.password_reset || !this.state.password_reset_confirm || !this.state.email_reset) {
            // this.props.onAlertError("Please complete all fields");
        } else if (this.state.password_reset !== this.state.password_reset_confirm) {
            // this.props.onAlertError("The two passwords do not match");
        } else {
            this.props.onSendResetPasswordEmail(this.state.email_reset, this.state.password_reset);
        }
    };

    showModal(type) {
        this.props.onShowLoginRegister(type);

        this.setState({
            username: '',
            password: '',
            name_reg: '',
            email_reg: '',
            username_reg: '',
            password_reg: '',
            password_reg_confirm: '',
            checkTerms: false,
            password_reset: '',
            password_reset_confirm: '',
            email_reset: '',
            rememberMe: false,
        });
    }

    render() {
        let page = (
            <Aux>
                <div className={classes.PageTitle}>
                    <FontAwesomeIcon icon={faUnlockAlt} />
                    <h1>Login</h1>
                </div>

                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={[classes.LoginRegisterWrapper, classes.ShowModal].join(' ')}>
                        <div className={this.props.show === 'login' ? [classes.LoginModalWrapper, classes.Show].join(' ') : classes.LoginModalWrapper}>
                            <form className={classes.LoginForm} onSubmit={this.login}>
                                <div className={classes.InputWrapper}>
                                    <div>
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                                            <path d='M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'></path>
                                        </svg>
                                    </div>
                                    <input
                                        id='login_username'
                                        type='text'
                                        name='username'
                                        placeholder='Username'
                                        value={this.state.username.trim()}
                                        onChange={(e) => this.onChange(e.target.name, e.target.value)}
                                    />
                                </div>

                                <div className={classes.InputWrapper}>
                                    <div>
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                                            <path d='M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z'></path>
                                        </svg>
                                    </div>
                                    <input
                                        id='login_password'
                                        type='password'
                                        name='password'
                                        placeholder='Password'
                                        value={this.state.password.trim()}
                                        onChange={(e) => this.onChange(e.target.name, e.target.value)}
                                    />
                                </div>

                                <div className={classes.Flexed}>
                                    <div className={classes.InputCheckWrapper}>
                                        <PrimaryCheckbox
                                            checked={this.state.rememberMe}
                                            changed={(e) => this.onChange(e.target.name, e.target.checked)}
                                            name='rememberMe'
                                        />
                                        <span>Remember me</span>
                                    </div>
                                    {/* <div className={classes.Link} onClick={() => this.showModal("changePassword")}>Forgot password?</div> */}
                                </div>
                                <input id='submit_login' type='submit' className={classes.SubmitLogin} value='Login' />
                            </form>
                        </div>

                        {/* <div className={this.props.show === "changePassword" ? [classes.ChangePasswordModalWrapper, classes.Show].join(' ') : classes.ChangePasswordModalWrapper}>                            
                        <div className={classes.ChangePasswordForm}>
                            <div className={classes.Flexed}>
                                <div className={classes.InputWrapper}>
                                    <input type="text" name="email_reset" placeholder="Email" value={this.state.email_reset.trim()} onChange={(e) => this.onChange(e.target.name,e.target.value)}/>
                                </div>
                            </div>

                            <div className={classes.Flexed}>
                                <div className={classes.InputWrapper}>
                                    <input type="password" name="password_reset" placeholder="New Password" value={this.state.password_reset.trim()} onChange={(e) => this.onChange(e.target.name,e.target.value)} />
                                </div>
                                <div className={[classes.InputWrapper, classes.FlexRight].join(' ')}>
                                    <input type="password" name="password_reset_confirm" placeholder="Confirm new password" value={this.state.password_reset_confirm.trim()} onChange={(e) => this.onChange(e.target.name,e.target.value)} />
                                </div>
                            </div>

                            <div className={classes.ChangePasswordButtons}>
                                <input onClick={this.checkResetPassword} type="submit" className={classes.ChangePasswordButton} value="Submit new password" />
                                <input onClick={() => this.showModal("login")} type="submit" className={[classes.ChangePasswordButton, classes.FlexRight].join(' ')} value="Cancel" />
                            </div>
                        </div>                                
                    </div> */}
                    </div>
                </Scrollbars>
            </Aux>
        );

        const user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
        if (user) {
            page = <Redirect to='/gseData' />;
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.login.showModal,
        show: state.login.show,
        confirmationMessage: state.login.confirmationMessage,
        showSpinner: state.login.showSpinner,
        loggedIn: state.login.loggedIn,
        loggedInUser: state.login.loggedInUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onShowLoginRegister: (type) => dispatch(actions.showLoginRegister(type)),
        onHideLoginRegister: () => dispatch(actions.hideLoginRegister()),
        onCheckLogin: (loginInfo) => dispatch(actions.checkLogin(loginInfo)),
        // onRegisterUser: (registerInfo) => dispatch(actions.registerUser(registerInfo)),
        // onAlertClear: () => dispatch(actions.alertClear()),
        // onAlertError: (message) => dispatch(actions.alertError(message)),
        onAccountActivation: (activationKey) => dispatch(actions.accountActivation(activationKey)),
        onSendResetPasswordEmail: (email, password) => dispatch(actions.sendResetPasswordEmail(email, password)),
        onUpdatePassword: (activationKey) => dispatch(actions.updatePassword(activationKey)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
