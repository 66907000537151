import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import classes from './PrimaryInput.module.css';

const PrimaryInput = (props) => {
    let classNames = [classes.PrimaryInput];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    return (
        <FormControl className={classNames.join(' ')}>
            <TextField
                variant='outlined'
                label={props.invalid && props.value && props.invalidLabel ? props.invalidLabel : props.label}
                // error={props.invalid ? true : false}
                error={props.invalid || (props.mandatory && (!props.value || props.value.trim() === '')) ? true : false}
                type={props.type}
                onChange={props.changed}
                onClick={props.onClick ? props.onClick : null}
                name={props.name}
                value={!props.value ? '' : props.value}
                disabled={props.disabled}
                multiline={props.multiline ? true : false}
                inputProps={{
                    minLength: props.minLength,
                    maxLength: props.maxLength,
                    min: props.min,
                    max: props.max,
                    step: props.step,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </FormControl>
    );
};

export default PrimaryInput;
