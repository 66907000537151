import React from 'react';
import Aux from 'react-aux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
// import { Scrollbars } from 'react-custom-scrollbars';

import classes from './Admin.module.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimaryInput2 from '../../components/UI/PrimaryInput/PrimaryInput2';

const AdminEditList = (props) => {
    return(
        <Aux>
            <div className={classes.ModalButtonsWrapper}>
                <PrimaryButton clicked={() => props.saveList()}>
                    <FontAwesomeIcon icon={faSave} />
                    Save
                </PrimaryButton>
                <div className={classes.CancelButton} onClick={()=>props.cancel()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>

            {/* <Scrollbars style={{width:"100%", height:"100%"}}>
                <div className={classes.ScrollInner}> */}
                <div>
                    <div>
                        <PrimaryButton clicked={() => props.addField()}>
                            <FontAwesomeIcon icon={faPlus} />
                            Add
                        </PrimaryButton>
                    </div>
                    <div className={classes.ListItemValues}>
                        {props.selectedListItem.fieldChoices.map((field, index) => {
                            return (
                                <div 
                                    className={classes.ListItemValue} 
                                    key={`${index}${props.selectedListItem.fieldChoices.length}` } // Hack to update DOM when adding new field choice
                                >
                                    {/* <PrimaryInput 
                                        type="text"
                                        value={field.choice}
                                        changed={ (e) => props.editSelectedListItem(field.id, e.target.value) }
                                    />  */}
                                    <PrimaryInput2
                                        type="text"
                                        defaultValue={field.choice}
                                        blur={ (e) => props.editSelectedListItem(field.id, e.target.value) }
                                    /> 
                                    {
                                        props.selectedListItem.fieldName !== "Vehicle type"
                                        ? <DeleteOutline className={classes.DeleteIcon} onClick={()=> props.deleteField(field)}/>
                                        : null
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* </div>
            </Scrollbars> */}
        </Aux>   
    )
}

export default AdminEditList;