import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../components/UI/Loader/Loader';
import DataTable from '../../components/DataTable/DataTable';
import classes from './StatisticsEquipment.module.css';
import Block from '../../components/Block/Block';
import MyBarChart from '../../components/MyCharts/MyBarChart';
import PrimaryAutocomplete from '../../components/UI/PrimaryAutocomplete/PrimaryAutocomplete';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import * as actions from '../../store/actions/index';

class StatisticsEquipment extends Component {
    state = {
        timeframe: 'all',
        selectedYear: null,
        selectedEquipment: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: 2, order: 'desc' },
        },
    };
    componentDidMount() {
        this.props.onGetEquipmentPrefilterStatistics();
    }
    componentWillUnmount() {
        this.props.onResetStatistics();
    }

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    changeTimeframe = (timeframe) => {
        this.setState({ timeframe: timeframe });
    };

    changeYear = (year) => {
        this.setState({ selectedYear: year });
    };

    getData = (type) => {
        if (!this.props.equipmentStatistics) return;

        if (this.state.timeframe === 'all') {
            return this.props.equipmentStatistics[type];
        } else if (this.state.timeframe === 'year') {
            let itemsPerMonth = {
                1: { dtMonth: `JAN-${this.state.selectedYear}`, val: null },
                2: { dtMonth: `FEB-${this.state.selectedYear}`, val: null },
                3: { dtMonth: `MAR-${this.state.selectedYear}`, val: null },
                4: { dtMonth: `APR-${this.state.selectedYear}`, val: null },
                5: { dtMonth: `MAY-${this.state.selectedYear}`, val: null },
                6: { dtMonth: `JUN-${this.state.selectedYear}`, val: null },
                7: { dtMonth: `JUL-${this.state.selectedYear}`, val: null },
                8: { dtMonth: `AUG-${this.state.selectedYear}`, val: null },
                9: { dtMonth: `SEP-${this.state.selectedYear}`, val: null },
                10: { dtMonth: `OCT-${this.state.selectedYear}`, val: null },
                11: { dtMonth: `NOV-${this.state.selectedYear}`, val: null },
                12: { dtMonth: `DEC-${this.state.selectedYear}`, val: null },
            };

            for (let i = 0; i < this.props.equipmentStatistics[type].length; i++) {
                const item = this.props.equipmentStatistics[type][i];
                const dtMonth = item.dtMonth;

                const year = moment(dtMonth, 'MMM-YYYY').format('yyyy');
                if (year == this.state.selectedYear) {
                    const monthIndex = moment(dtMonth, 'MMM-YYYY').format('M');
                    itemsPerMonth[monthIndex] = item;
                }
            }

            const itemsArr = Object.values(itemsPerMonth);

            return itemsArr;
        }
    };

    changeEquipment = (equipment) => {
        this.setState({ selectedEquipment: equipment });
    };

    getStats = () => {
        if (!this.state.selectedEquipment) return;
        this.props.onGetEquipmentStatistics(this.state.selectedEquipment.value);
    };

    getBarFields = () => {
        let tableFields = [
            {
                title: 'Month',
                field: 'dtMonth',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Value',
                field: 'val',
                render: (rowData) => (rowData.val ? Math.round(rowData.val * 100) / 100 : rowData.val),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    render() {
        const barFields = this.getBarFields();
        const refuelingEqData = this.getData('refuelingEq');
        const opertTimeEqData = this.getData('opertTimeEq');
        // const opertTimeEqFlightsData = this.getData('opertTimeEqFlights');
        // const refuelingEqFlightsData = this.getData('refuelingEqFlights');

        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}>
                    <span className={classes.FilterName}>Select equipment</span>
                    <PrimaryAutocomplete options={this.props.equipmentOptions} changed={(e, value) => this.changeEquipment(value)} short narrow />
                    <PrimaryButton disabled={this.state.selectedEquipment === null} clicked={() => this.getStats()} iconButton>
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </PrimaryButton>
                </div>
                <div className={classes.PageTitle}>
                    <h1>Equipment statistics</h1>
                    <div className={classes.MarginLeftAuto}>
                        <div className={classes.ActionButtonsWrapper}>
                            <div className={classes.CancelButton} onClick={() => this.props.history.push('/statistics')}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </React.Fragment>
        );

        if (this.props.loading === false && this.props.equipmentStatistics) {
            page = (
                <React.Fragment>
                    <div className={classes.FiltersSpace}>
                        <span className={classes.FilterName}>Select equipment</span>
                        <PrimaryAutocomplete options={this.props.equipmentOptions} changed={(e, value) => this.changeEquipment(value)} short narrow />
                        <PrimaryButton disabled={this.state.selectedEquipment === null} clicked={() => this.getStats()} iconButton>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </PrimaryButton>
                    </div>
                    <div className={classes.PageTitle}>
                        <h1>Equipment statistics</h1>
                        <div className={classes.MarginLeftAuto}>
                            <div className={classes.ActionButtonsWrapper}>
                                <div className={classes.CancelButton} onClick={() => this.props.history.push('/statistics')}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.PageContent}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className={classes.ScrollInner}>
                                <Block title='Refueling consumption'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={refuelingEqData}
                                            years={this.props.equipmentStatisticsYears.refuelingEq}
                                            name='Refueling consumption'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Liters'
                                        />
                                    </div>

                                    <DataTable
                                        data={refuelingEqData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Operational time'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={opertTimeEqData}
                                            years={this.props.equipmentStatisticsYears.opertTimeEq}
                                            name='Operational time'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Hours'
                                        />
                                    </div>

                                    <DataTable
                                        data={opertTimeEqData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>
                            </div>
                        </Scrollbars>
                    </div>
                </React.Fragment>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
        equipmentOptions: state.statistics.equipmentOptions,
        equipmentStatistics: state.statistics.equipmentStatistics,
        equipmentStatisticsYears: state.statistics.equipmentStatisticsYears,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetEquipmentPrefilterStatistics: () => dispatch(actions.getEquipmentPrefilterStatistics()),
        onGetEquipmentStatistics: (equipmentType) => dispatch(actions.getEquipmentStatistics(equipmentType)),
        onResetStatistics: () => dispatch(actions.resetStatistics()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsEquipment);
