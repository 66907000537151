import React, { forwardRef, useImperativeHandle } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowDropDown';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import _ from 'lodash';
import XLSX from 'xlsx';
import customClasses from './DataTable.module.css';

const DataTable = forwardRef((props, parentRef) => {
    const tableRef = React.useRef();

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    let filtering = false;
    if (props.filtering) filtering = true;

    const downloadExcel = () => {
        const filteredData = _.cloneDeep(tableRef.current.state.data);

        const newData = filteredData.map((row) => {
            delete row.tableData;
            return row;
        });
        const worksheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, worksheet, 'Sheet1');
        // Buffer
        // let buf=XLSX.write(workBook,{bookType:"xlsx",type:"buffer"})
        // Binary string
        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
        // Download
        XLSX.writeFile(workBook, 'tableData.xlsx');
    };

    const handleChangePage = (initialPage) => {
        props.updateTableOptions('initialPage', initialPage);
    };
    const handleChangeRowsPerPage = (pageSize) => {
        props.updateTableOptions('pageSize', pageSize);
    };
    const handleSearchChange = (searchText) => {
        props.updateTableOptions('searchText', searchText);
    };
    useImperativeHandle(parentRef, () => ({
        searchText: (searchText) => {
            tableRef.current.dataManager.changeSearchText(searchText);
            tableRef.current.setState({ searchText: searchText });
            tableRef.current.setState(tableRef.current.dataManager.getRenderState());
        },
    }));

    const handleOrderChange = (column, order) => {
        // props.updateTableOptions("defaultSortColumn", column);
        // props.updateTableOptions("defaultSortOrder", order);
        props.updateTableOptions('defaultSort', { column: column, order: order });
    };
    const handleColumnOrderChange = (prevCol, newCol) => {};

    const getExtraData = (id) => {
        const found = props.extraData.find((d) => d[props.extraDataJoinWith] == id);
        return found;
    };

    return (
        <div
            className={
                props.withSearchAbsolutePos && props.data.length
                    ? [customClasses.Datatable, customClasses.searchAbs].join(' ')
                    : [customClasses.Datatable, customClasses.searchRel].join(' ')
            }
        >
            <MaterialTable
                tableRef={tableRef}
                title=''
                icons={tableIcons}
                // isLoading={!props.data.length}
                columns={props.columns}
                data={props.data}
                onPageChange={props.tableOptions && props.updateTableOptions ? handleChangePage : null}
                onRowsPerPageChange={props.tableOptions && props.updateTableOptions ? handleChangeRowsPerPage : null}
                onOrderChange={props.tableOptions && props.updateTableOptions ? handleOrderChange : null}
                onSearchChange={props.tableOptions && props.updateTableOptions ? handleSearchChange : null}
                onColumnDragged={props.tableOptions && props.updateTableOptions ? handleColumnOrderChange : null}
                options={{
                    search: (props.withSearch || props.withSearchAbsolutePos) && props.data && props.data.length > 0 ? true : false,
                    draggable: false,
                    //search: true,
                    //exportButton: true,
                    toolbar: props.data && props.data.length > 0 ? true : false,
                    paging: props.data && props.data.length < 6 ? false : true,
                    sorting: true,
                    filtering: filtering,
                    actionsColumnIndex: -1,
                    actionsCellStyle: { padding: '16px' },
                    initialPage: props.tableOptions ? props.tableOptions.initialPage : 0,
                    pageSize: props.tableOptions ? props.tableOptions.pageSize : 5,
                    searchText: props.tableOptions ? props.tableOptions.searchText : '',
                    rowStyle: (data, index) => {
                        // console.log(props.strikeThroughProperty);
                        // console.log(data[props.strikeThroughProperty]);
                        // if (props.strikeThroughProperty && data[props.strikeThroughProperty]) {
                        //     return { backgroundColor: '#e8eef3' };
                        // }

                        if (props.selectedRow && data[props.selectProperty] && data[props.selectProperty] === props.selectedRow) {
                            return { backgroundColor: '#e8eef3' };
                        } else {
                            return { backgroundColor: '#fff' };
                        }
                    },
                }}
                actions={
                    props.noActions
                        ? [
                              {
                                  icon: () => <tableIcons.Export />,
                                  tooltip: 'Export to excel',
                                  isFreeAction: true,
                                  onClick: (event, rowData) => downloadExcel(rowData),
                                  hidden: !props.withExport,
                              },
                          ]
                        : [
                              {
                                  icon: () => (
                                      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#8D9CAB'>
                                          <path d='M20.036 17.31l-4.724-4.724a9.1 9.1 0 0 1-2.727 2.727l4.724 4.724a1.934 1.934 0 0 0 2.727-2.727z' />
                                          <path d='M15.45 7.725a7.725 7.725 0 1 0-7.715 7.725 7.725 7.725 0 0 0 7.715-7.725zm-7.715 5.8a5.791 5.791 0 1 1 5.791-5.791 5.791 5.791 0 0 1-5.791 5.791z' />
                                          <path d='M3.223 7.725h1.289a3.17 3.17 0 0 1 3.17-3.17v-1.29a4.513 4.513 0 0 0-4.46 4.46z' />
                                      </svg>
                                  ),
                                  //iconProps: { style: { fill: "primary" } },
                                  tooltip: 'View',
                                  onClick: (event, rowData) => props.viewClicked(rowData),
                                  disabled: props.viewDisabled,
                                  hidden: !props.viewClicked,
                              },
                              {
                                  icon: () => (
                                      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#8D9CAB'>
                                          <path d='M20.036 17.31l-4.724-4.724a9.1 9.1 0 0 1-2.727 2.727l4.724 4.724a1.934 1.934 0 0 0 2.727-2.727z' />
                                          <path d='M15.45 7.725a7.725 7.725 0 1 0-7.715 7.725 7.725 7.725 0 0 0 7.715-7.725zm-7.715 5.8a5.791 5.791 0 1 1 5.791-5.791 5.791 5.791 0 0 1-5.791 5.791z' />
                                          <path d='M3.223 7.725h1.289a3.17 3.17 0 0 1 3.17-3.17v-1.29a4.513 4.513 0 0 0-4.46 4.46z' />
                                      </svg>
                                  ),
                                  tooltip: 'Zoom',
                                  onClick: (event, rowData) => props.zoomClicked(rowData),
                                  disabled: props.zoomDisabled,
                                  hidden: !props.zoomClicked,
                              },
                              {
                                  icon: () => <tableIcons.Save />,
                                  tooltip: 'Save',
                                  onClick: (event, rowData) => props.saveClicked(rowData),
                                  disabled: props.saveDisabled,
                                  hidden: !props.saveClicked,
                              },
                              {
                                  icon: () => <tableIcons.Edit />,
                                  tooltip: 'Edit',
                                  onClick: (event, rowData) => props.editClicked(rowData),
                                  disabled: props.editDisabled,
                                  hidden: !props.editClicked,
                              },
                              {
                                  icon: () => <tableIcons.Delete color={'secondary'} />,
                                  tooltip: 'Delete',
                                  onClick: (event, rowData) => props.deleteClicked(rowData),
                                  disabled: props.deleteDisabled,
                                  hidden: !props.deleteClicked,
                              },
                              {
                                  icon: () => <tableIcons.Export />,
                                  tooltip: 'Export to excel',
                                  isFreeAction: true,
                                  onClick: (event, rowData) => downloadExcel(rowData),
                                  hidden: !props.withExport,
                              },
                          ]
                }
                detailPanel={
                    props.detailPanels
                        ? [
                              {
                                  render: (rowData) => {
                                      //   console.log(rowData.rowData.flightEquipments);
                                      return (
                                          <React.Fragment>
                                              {props.detailPanels.map((detailPanel, index) => {
                                                  if (rowData.rowData[detailPanel.property].length && rowData.rowData[detailPanel.property][0]) {
                                                      return (
                                                          <div key={'detailPanel_' + index} className={customClasses.DetailPanel}>
                                                              <span className={customClasses.DetailPanelTitle}>{detailPanel.title}</span>
                                                              <MaterialTable
                                                                  title=''
                                                                  icons={tableIcons}
                                                                  columns={detailPanel.columns}
                                                                  data={rowData.rowData[detailPanel.property]}
                                                                  options={{
                                                                      paging: rowData.rowData[detailPanel.property].length < 6 ? false : true,
                                                                      search: false,
                                                                      toolbar: false,
                                                                  }}
                                                              />
                                                          </div>
                                                      );
                                                  } else
                                                      return (
                                                          <div key={'detailPanel_' + index} className={customClasses.DetailPanel}>
                                                              <span className={customClasses.DetailPanelTitle}>{detailPanel.title}</span>
                                                              <span>No data</span>
                                                          </div>
                                                      );
                                              })}
                                          </React.Fragment>
                                      );
                                      //   return (
                                      //       <div
                                      //       style={{
                                      //         fontSize: 100,
                                      //         textAlign: 'center',
                                      //         color: 'white',
                                      //         backgroundColor: '#43A047',
                                      //       }}
                                      //       >
                                      //           asdsad
                                      //       </div>
                                      //   );
                                  },
                              },
                          ]
                        : null
                }
            />
        </div>
    );
});

export default DataTable;
