import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './MenuDesktop.module.css';

const MenuDesktop = (props) => {
    return (     
        <div className={classes.Topbar}>
            <div className={classes.LogoContainer}>
                <Logo />
            </div>
           
            <nav className={classes.Sidenav}>
                <NavigationItems 
                    user={props.loggedInUser} 
                    clicked={(page)=>{ 
                        props.resetState(page); 
                    }} 
                />
            </nav>

            <div className={classes.Rightpart}>
                <FontAwesomeIcon icon={faUnlockAlt} />
                <span className={classes.ConnectedAs}>Logged in as:</span>
                <span className={classes.LoggedInName}>{props.loggedInUser.name}</span>
                <div 
                    className={classes.LogoutWrapper} 
                    onClick={() => { 
                        props.logout(); 
                    }}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
            </div>
        </div>
    )
}

export default MenuDesktop;