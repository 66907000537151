import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import classes from './FlightInfo.module.css';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import { formatDateTime } from '../../store/utilities';

class TabFlightsEquipment extends Component {
    state = {
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
    };

    getFlightsEquipmentFields = () => {
        let tableFields = [
            {
                title: 'Date & time',
                field: 'departureDate',
                render: (rowData) => formatDateTime(rowData.departureDate),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Flight number',
                field: 'flightNumber',
                render: (rowData) => rowData.flightNumber,
                defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Sector type',
                field: 'typeOfFlight',
                render: (rowData) => rowData.typeOfFlight,
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Airport name',
                field: 'departureAirportName',
                render: (rowData) => rowData.departureAirportName,
                defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Quantity of service',
                field: 'quantityOfService',
                render: (rowData) => rowData.quantityOfService,
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Service code',
                field: 'serviceCode',
                render: (rowData) => rowData.serviceCode,
                defaultSort: this.state.tableOptions.defaultSort.column === 5 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Service description',
                field: 'serviceDescription',
                render: (rowData) => rowData.serviceDescription,
                defaultSort: this.state.tableOptions.defaultSort.column === 6 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Service category code',
                field: 'serviceCategoryCode',
                render: (rowData) => rowData.serviceCategoryCode,
                defaultSort: this.state.tableOptions.defaultSort.column === 7 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Service category description',
                field: 'serviceCategoryDescription',
                render: (rowData) => rowData.serviceCategoryDescription,
                defaultSort: this.state.tableOptions.defaultSort.column === 8 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'GSE used 1',
                field: 'equipment1',
                render: (rowData) => rowData.equipment1,
                defaultSort: this.state.tableOptions.defaultSort.column === 9 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'GSE used 2',
                field: 'equipment2',
                render: (rowData) => rowData.equipment2,
                defaultSort: this.state.tableOptions.defaultSort.column === 10 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        const flightsEquipmentFields = this.getFlightsEquipmentFields();

        let page = (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={classes.ScrollInner}>
                    <Loader show={this.props.loading} />
                </div>
            </Scrollbars>
        );

        if (this.props.loading === false) {
            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner}>
                        <DataTable
                            data={this.props.flightsEquipment}
                            columns={flightsEquipmentFields}
                            withExport
                            withSearch
                            noActions
                            tableOptions={this.state.tableOptions}
                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                        />
                    </div>
                </Scrollbars>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
    };
};

export default connect(mapStateToProps, null)(TabFlightsEquipment);
