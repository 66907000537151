import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToolbox, faCarBattery } from '@fortawesome/free-solid-svg-icons';

import classes from './Service.module.css';
import TabBatteries from './TabBatteries';
import TabMaintenance from './TabMaintenance';
import TabTires from './TabTires';
import SideFilters from '../../components/Filters/SideFilters';

class Service extends Component {
    componentDidMount() {}

    render() {
        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}>
                    <SideFilters />
                </div>
                <div className={classes.PageTitle}>
                    <h1>GSE Service</h1>
                </div>
                <div className={classes.PageContent}>
                    <Tabs>
                        <TabList>
                            <Tab>
                                <FontAwesomeIcon icon={faToolbox} />
                                Service records
                            </Tab>
                            <Tab>
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'>
                                    <path d='M456.19 11.8c-92.72 8.24-182.18 43.87-258.8 102.87-20.88 16.09-66.66 61.88-82.76 82.76C39.35 294.93 2.76 413.5 11.19 531.31c7.09 99.24 38.31 183.71 97.89 263.79 32.18 43.49 87.35 93.67 135.44 123.17 85.25 52.3 188.88 77.78 286.58 70.69 69.54-4.98 122.98-19.16 184.47-49.23 97.12-47.32 176.62-126.82 224.13-224.13 29.88-61.3 44.25-114.94 49.23-184.28 4.59-64.36-5.75-138.5-28.16-199.03-8.81-23.95-32.18-72.03-44.25-90.8-28.16-44.25-76.43-98.08-113.79-126.82C701.58 36.7 580.32.88 456.19 11.8zm76.63 149.22c102.87 10.54 193.67 65.13 250.95 151.14 66.86 100.57 75.09 228.73 21.84 338.3-33.14 68.39-90.23 124.71-159 157.27-108.04 50.96-235.43 41.95-334.47-23.95-38.5-25.67-70.11-57.09-95.78-95.59-75.48-113.02-75.67-263.21-.19-376.04 70.68-105.54 192.32-163.58 316.65-151.13z' />
                                    <path d='M461.75 188.03c-108.04 13.79-200.57 79.5-247.31 175.09-48.27 99.04-41.19 214.74 18.77 307.46 77.97 120.11 229.49 172.6 367.8 127.2 23.37-7.47 55.93-23.37 76.05-36.97 143.68-97.12 183.33-286.77 89.85-431.21-50.96-78.73-133.9-129.69-230.45-141.57-18.59-2.49-56.52-2.3-74.71 0zm124.32 68.01c39.08 13.03 72.41 33.91 101.34 63.6 8.81 9 16.66 17.62 17.62 19.54 1.34 2.68-9.77 9-76.05 43.29-42.91 22.03-78.35 40.23-79.12 40.61-.57.19-2.49-.57-4.4-1.92-2.68-2.11-3.64-12.83-7.09-83.91-2.11-44.83-3.83-84.1-3.83-87.35v-5.94l15.9 2.68c8.62 1.54 24.71 5.76 35.63 9.4zm-120.49-5.56c0 3.06-1.72 40.61-3.83 83.9-2.11 43.1-3.83 80.46-3.83 82.56 0 2.49-1.53 4.6-3.83 5.17-2.49.58-29.69-12.45-81.99-39.27-42.91-22.03-78.35-40.42-78.73-40.8-1.53-1.72 36.78-38.89 51.53-50 31.6-23.74 86-45.96 114.55-46.54 5.55-.19 6.13.38 6.13 4.98zm-77.59 231.8c16.47 10.54 21.07 14.37 21.07 17.81 0 3.45-13.22 12.83-74.71 52.49-47.12 30.46-75.28 47.51-76.24 46.36-.77-1.15-4.21-11.11-7.85-22.22-18.01-55.17-16.28-111.11 4.79-167.81l3.83-10.15 54.02 35.05c29.69 19.15 63.6 40.99 75.09 48.47zm360.14-65.52c12.84 33.72 17.24 79.12 11.68 117.05-2.68 18.96-13.98 59.58-17.81 65.13-.96 1.15-28.93-15.71-76.24-46.36-61.11-39.46-74.71-49.04-74.71-52.49 0-3.45 13.41-12.64 74.71-51.72 41.19-26.25 75.29-46.93 76.05-46.36.77.77 3.64 7.47 6.32 14.75zm-227.96 37.16c19.16 8.62 29.69 25.09 29.69 45.98-.19 29.31-20.69 50-49.81 50s-49.81-20.69-49.81-49.81c0-20.5 9.39-36.01 27.2-44.83 12.47-6.13 30.86-6.7 42.73-1.34zm-65.51 125.09c2.68 2.11 3.64 13.03 7.09 84.67 2.11 45.21 3.83 84.67 3.83 87.54v5.17l-14.75-2.68c-56.51-10.15-109.77-39.84-145.4-80.84l-12.64-14.37 8.24-4.41c98.85-51.53 148.46-77.01 149.42-77.2.57-.18 2.48.98 4.21 2.12zm174.32 38.7 77.77 40.04-4.21 5.94c-14.37 20.69-53.64 52.11-84.29 67.43-24.14 12.26-78.73 27.58-82.95 23.37-.57-.77.77-40.23 3.06-87.93 4.02-86.59 4.41-90.8 10.73-89.27 1.16.19 37.17 18.39 79.89 40.42z' />
                                </svg>
                                Tyre replacement
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faCarBattery} />
                                Battery replacement
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabMaintenance gotoGse={() => this.props.history.push('/gseData')} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabTires gotoGse={() => this.props.history.push('/gseData')} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabBatteries gotoGse={() => this.props.history.push('/gseData')} />
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </React.Fragment>
        );
        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        // loading:state._system.loading,
        loggedInUser: state.login.loggedInUser,
    };
};

export default connect(mapStateToProps, null)(Service);
