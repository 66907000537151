import * as actionTypes from './actionTypes';
import axios from '../../axios-api';
import axiosDb from '../../axios-db';
import axiosRoot from 'axios';
import Swal from 'sweetalert2';

import { loading } from './_system';
import { dateTimeToISO, dataURLtoFile } from '../../store/utilities';
import { getGseStatisticsSuccess, getTrackingGseSuccess } from './statistics';

export const updateDataStateProperty = (property, value) => {
    return {
        type: actionTypes.UPDATE_DATA_STATE_PROPERTY,
        property: property,
        value: value,
    };
};

// Import
// ---------------------------------------------------------------------------
export const getGsePreFilter = () => {
    return (dispatch, getState) => {
        dispatch(loading(true));

        const gseGeneralInfoColumns = getState().gseData.gseGeneralInfoColumns;
        const requestOne = axios.get('Field/-1');
        const requestTwo = axios.get('FieldChoice/-1');
        const requestThree = axios.get('Airport/-1');
        //const requestFour = axios.get('GsePreFilter');

        axiosRoot
            .all([requestOne, requestTwo, requestThree])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];
                    //const requestFour = responses[3];

                    if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200) {
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });
                        dispatch(loading(false));
                        return;
                    }

                    dispatch(getListsSuccess(responseOne.data, responseTwo.data));
                    dispatch(getAirportsSuccess(responseThree.data));

                    axios
                        .get('GsePreFilter/-1')
                        .then((response) => {
                            if (response.status === 200) dispatch(getGseGeneralInfoSuccess(response.data, gseGeneralInfoColumns));
                            else
                                Swal.fire({
                                    type: 'error',
                                    text: 'Error: Something went wrong. Please try again.',
                                });
                            dispatch(loading(false));
                        })
                        .catch((error) => {
                            Swal.fire({
                                type: 'error',
                                text: 'Error: Something went wrong. Please try again.',
                            });
                            dispatch(loading(false));
                        });
                })
            )
            .catch((errors) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getGseGeneralInfoSuccess = (gseGeneralInfo, gseGeneralInfoColumns) => {
    return {
        type: actionTypes.GET_GSE_GENERAL_INFO_SUCCESS,
        gseGeneralInfo: gseGeneralInfo,
        gseGeneralInfoColumns: gseGeneralInfoColumns,
    };
};

// ---------------------------------------------------------------------------
export const getGseDetails = (id) => {
    return (dispatch) => {
        dispatch(loading(true));

        const requestOne = axios.get('Gse/' + id);
        const requestTwo = axios.get('Refueling/' + id);
        const requestThree = axios.get('Uploads/' + id);

        const requestFour = axios.get('Service/' + id);
        const requestFive = axios.get('Tyre/' + id);
        const requestSix = axios.get('Battery/' + id);

        axiosRoot
            .all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];
                    const responseFour = responses[3];
                    const responseFive = responses[4];
                    const responseSix = responses[5];

                    if (responseOne.status === 200) dispatch(getGseSuccess(responseOne.data, responseThree.data));
                    if (responseTwo.status === 200) dispatch(getRefuelingSuccess(responseTwo.data));
                    if (responseFour.status === 200 && responseFive.status === 200 && responseSix.status === 200) {
                        dispatch(getGseServiceSuccess(responseFour.data, responseFive.data, responseSix.data));
                    }

                    if (
                        responseOne.status !== 200 ||
                        responseTwo.status !== 200 ||
                        responseFour.status !== 200 ||
                        responseFive.status !== 200 ||
                        responseSix.status !== 200
                    )
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });

                    // Get image data in base64
                    const imagesArr = responseThree.data.filter((x) => x.fileType === 'Image' || x.fileType === 'image');
                    if (imagesArr.length) {
                        for (let im of imagesArr) {
                            let imageHash = im.filePath;
                            dispatch(getGseImage(id, imageHash, im.id));
                        }
                    }

                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getGseSuccess = (gseDetails, gseUploads) => {
    return {
        type: actionTypes.GET_GSE_SUCCESS,
        gseDetails: gseDetails,
        gseUploads: gseUploads,
    };
};

export const getRefuelingSuccess = (refueling) => {
    return {
        type: actionTypes.GET_REFUELING_SUCCESS,
        refueling: refueling,
    };
};

// export const getGseService = (id) => {
//     return (dispatch) => {
//         dispatch(loading(true));

//         const requestOne = axios.get('Service/' + id);
//         const requestTwo = axios.get('Tyre/' + id);
//         const requestThree = axios.get('Battery/' + id);

//         axiosRoot
//             .all([requestOne, requestTwo, requestThree])
//             .then(
//                 axiosRoot.spread((...responses) => {
//                     const responseOne = responses[0];
//                     const responseTwo = responses[1];
//                     const responseThree = responses[2];

//                     if (responseOne.status === 200 && responseTwo.status === 200 && responseThree.status === 200) {
//                         dispatch(getGseServiceSuccess(responseOne.data, responseTwo.data, responseThree.data));
//                     }

//                     if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200)
//                         Swal.fire({
//                             type: 'error',
//                             text: 'Error: Something went wrong. Please try again.',
//                         });

//                     dispatch(loading(false));
//                 })
//             )
//             .catch((errors) => {
//                 Swal.fire({
//                     type: 'error',
//                     text: 'Error: Something went wrong. Please try again.',
//                 });
//                 dispatch(loading(false));
//             });
//     };
// };

export const getGseServiceSuccess = (service, tires, batteries) => {
    return {
        type: actionTypes.GET_GSE_SERVICE_SUCCESS,
        service: service,
        tires: tires,
        batteries: batteries,
    };
};

// Flights
// ---------------------------------------------------------------------------
export const getFlights = (year, month, day) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('Flight/' + year + '/' + month + '/' + day)
            .then((response) => {
                if (response.status === 200) dispatch(getFlightsSuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getFlightsSuccess = (flights) => {
    return {
        type: actionTypes.GET_FLIGHTS_SUCCESS,
        flights: flights,
    };
};

export const getFlightsEquipment = (year, month, day) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('FlightEquipment/' + year + '/' + month + '/' + day)
            .then((response) => {
                if (response.status === 200) dispatch(getFlightsEquipmentSuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getFlightsEquipmentSuccess = (flightsEquipment) => {
    return {
        type: actionTypes.GET_FLIGHTS_EQUIPMENT_SUCCESS,
        flightsEquipment: flightsEquipment,
    };
};

export const getFlightSuccess = (flight) => {
    return {
        type: actionTypes.GET_FLIGHT_SUCCESS,
        flight: flight,
    };
};

export const getMapGsesFlightsInfo = (dateFrom, dateTo) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get(`MapGsesFlightsInfo?dateFrom=${dateFrom}&dateTo=${dateTo}`)
            .then((response) => {
                if (response.status === 200) dispatch(getMapGsesFlightsInfoSuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getMapGsesFlightsInfoSuccess = (flightsGses) => {
    return {
        type: actionTypes.GET_MAP_GSES_FLIGHTS_INFO_SUCCESS,
        flightsGses: flightsGses,
    };
};

export const getMapTripsFlightsData = (gseId, dateFrom, dateTo) => {
    return (dispatch) => {
        dispatch(loading(true));

        let gseIdsCsv = '';

        if (gseId) {
            gseIdsCsv = `&gseIdsCsv=${gseId}`;
        }

        axios
            .get(`MapTripsFlightsData?dateFrom=${dateFrom}&dateTo=${dateTo}${gseIdsCsv}`)
            .then((response) => {
                if (response.status === 200) dispatch(getMapTripsFlightsDataSuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getMapTripsFlightsDataSuccess = (tripsData) => {
    return {
        type: actionTypes.GET_MAP_TRIPS_FLIGHTS_DATA_SUCCESS,
        tripsData: tripsData,
    };
};

// ---------------------------------------------------------------------------
export const getHistory = (id, from, to) => {
    return (dispatch) => {
        dispatch(loading(true));

        const filters = {
            vehId: id,
            From: from,
            To: to,
        };
        const filtersString = JSON.stringify(filters);

        axios
            .post('history', filtersString)
            .then((response) => {
                if (response.status === 200) dispatch(getHistorySuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getHistorySuccess = (history) => {
    return {
        type: actionTypes.GET_HISTORY_SUCCESS,
        history: history,
    };
};

// Airports ---------------------------------------------------------------------------
export const getAirports = () => {
    return (dispatch) => {
        axios
            .get('Airport/-1')
            .then((response) => {
                if (response.status === 200) dispatch(getAirportsSuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                // dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                // dispatch(loading(false));
            });
    };
};

export const getAirportsSuccess = (airports) => {
    return {
        type: actionTypes.GET_AIRPORTS_SUCCESS,
        airports: airports,
    };
};

export const getAllAirportLocations = () => {
    return (dispatch) => {
        axios
            .get('AirportLocation/-1')
            .then((response) => {
                dispatch(getAllAirportLocationsSuccess(response.data));
            })
            .catch((error) => {
                // Swal.fire({ type: 'error', text: error.response.data.message });
                // dispatch(loading(false));
            });
    };
};

export const getAllAirportLocationsSuccess = (allAirportLocations) => {
    return {
        type: actionTypes.GET_ALL_AIRPORT_LOCATIONS_SUCCESS,
        allAirportLocations: allAirportLocations,
    };
};

// Refuelling ---------------------------------------------------------------------------
export const deleteRefueling = (refuelingId, gseId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .delete('Refueling/' + refuelingId)
            .then((response) => {
                if (response.status !== 200) {
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                    dispatch(loading(false));
                    return;
                }

                dispatch(getRefueling(gseId));
                Swal.fire({ type: 'success', text: 'The refueling item was deleted' });
                dispatch(loading(false));
            })
            .catch((error) => {
                let txt = 'Error: Something went wrong. Please try again.';
                if (error.response.data.title) txt = error.response.data.title;
                Swal.fire({ type: 'error', text: txt });
                dispatch(loading(false));
            });
    };
};

export const getRefueling = (id) => {
    return (dispatch) => {
        axios
            .get('Refueling/' + id)
            .then((response) => {
                if (response.status === 200) dispatch(getRefuelingSuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                let txt = 'Error: Something went wrong. Please try again.';
                if (error.response.data.title) txt = error.response.data.title;
                Swal.fire({ type: 'error', text: txt });
                dispatch(loading(false));
            });
    };
};

export const updateRefueling = (refuelingData, gseId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .post('Refueling', refuelingData)
            .then((response) => {
                if (response.status !== 200) {
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                    dispatch(loading(false));
                    return;
                }

                dispatch(getRefueling(gseId));
                Swal.fire({ type: 'success', text: 'The refueling was updated' });
                dispatch(loading(false));
            })
            .catch((error) => {
                let txt = 'Error: Something went wrong. Please try again.';
                if (error.response.data.title) txt = error.response.data.title;
                Swal.fire({ type: 'error', text: txt });
                dispatch(loading(false));
            });
    };
};

// GSE Edit ---------------------------------------------------------------------------
export const getGseEditDetails = (id) => {
    return (dispatch) => {
        dispatch(loading(true));

        if (id && id > -1) {
            const requestOne = axios.get('Gse/' + id);
            const requestTwo = axios.get('Uploads/' + id);

            axiosRoot
                .all([requestOne, requestTwo])
                .then(
                    axiosRoot.spread((...responses) => {
                        const responseOne = responses[0];
                        const responseTwo = responses[1];

                        if (responseOne.status !== 200) {
                            Swal.fire({
                                type: 'error',
                                text: 'Error: Something went wrong. Please try again.',
                            });
                            return;
                        }

                        dispatch(getGseEditSuccess(responseOne.data, responseTwo.data));

                        // Get image data in base64
                        const imagesArr = responseTwo.data.filter((x) => x.fileType === 'Image' || x.fileType === 'image');
                        if (imagesArr.length) {
                            for (let im of imagesArr) {
                                let imageHash = im.filePath;
                                dispatch(getGseImage(id, imageHash, im.id));
                            }
                        }

                        dispatch(loading(false));
                    })
                )
                .catch((errors) => {
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                    dispatch(loading(false));
                });
        } else {
            let now = new Date();
            now = dateTimeToISO(now);

            const gseEditDetails = [
                {
                    generalInfo: {
                        airportId: null,
                        vehicleType: null,
                        equipmentType: null,
                        use: null,
                        info: '',
                        manufacturer: null,
                        model: null,
                        serialNumber: '',
                        licencePlate: '',
                        purchaseDate: now,
                        parkingPosition: '',
                    },
                    technicalSpecs: {
                        operatingWeight: 0,
                        curbWeight: 0,
                        loadCargoType: null,
                        loadCargoCapacity: 0,
                        height: 0,
                        length: 0,
                        width: 0,
                        wheelbase: 0,
                        frontalArea: 0,
                        dragCoefficient: 0,
                        drivetrain: null,
                        gearBoxTypeSpeed: 0,
                        gearBoxTypeReverse: 0,
                        gearBox: null,
                        gearRatio: '',
                        finalDrive: 0,
                        gears: 0,
                        axles: 0,
                        wheels: 0,
                        seats: 0,
                        batteryVoltage: null,
                        airConditioning: null,
                        obdPort: null,
                    },
                    engineSpecs: {
                        name: '',
                        productionYear: 0,
                        manufacturer: null,
                        model: null,
                        displacement: 0,
                        euroStandard: '',
                        fuelConsumption: 0,
                        fuel: null,
                        fuelTankCapacity: 0,
                        autonomyCycle: 0,
                        co2Emissions: 0,
                        noCylinders: 0,
                        cylinderConfiguration: null,
                        aspiratedTurbocharged: null,
                        speedRangeMin: 0,
                        speedRangeMax: 0,
                        maximumPower: 0,
                        idleSpeed: 0,
                        maximumTorque: 0,
                    },
                    // "auxEngine": {
                    //     "engineType": "",
                    //     "manufacturer": "",
                    //     "model": "",
                    //     "use": "",
                    //     "power": 0,
                    //     "fuel": "",
                    //     "fuelTankCapacity": 0
                    // },
                    tyre: {
                        manufacturer: null,
                        // "productionDate": now,
                        // "purchaseDate": now,
                        rollingDiameter: 0,
                        dimensionsWidth: 0,
                        dimensionsAspect: 0,
                        dimensionsDiameter: 0,
                        // "frontPressure": 0,
                        // "rearPressure": 0
                    },
                },
            ];

            dispatch(getGseEditSuccess(gseEditDetails, null));
            dispatch(loading(false));
        }
    };
};

export const getGseEditSuccess = (gseEditDetails, gseUploads) => {
    return {
        type: actionTypes.GET_GSE_EDIT_SUCCESS,
        gseEditDetails: gseEditDetails,
        gseUploads: gseUploads,
    };
};

export const updateGseProperty = (category, property, value) => {
    return {
        type: actionTypes.UPDATE_GSE_PROPERTY,
        category: category,
        property: property,
        value: value,
    };
};

export const clearGseEditDetails = () => {
    return {
        type: actionTypes.CLEAR_GSE_EDIT_DETAILS,
    };
};

// Gse uploads ---------------------------------------------------------------------------
export const getGseUploads = (id) => {
    return (dispatch) => {
        axios
            .get('Uploads/' + id)
            .then((response) => {
                if (response.status === 200) dispatch(getGseUploadsSuccess(response.data));
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getGseUploadsSuccess = (gseUploads) => {
    return {
        type: actionTypes.GET_GSE_UPLOADS_SUCCESS,
        gseUploads: gseUploads,
    };
};

// Gse image ---------------------------------------------------------------------------
export const getGseImage = (gseId, imageHash, fileId) => {
    return (dispatch) => {
        dispatch(loading(true));

        if (imageHash === null) {
            dispatch(getGseImageSuccess(null));
            return;
        }

        axiosDb
            .get('getFile.php', {
                responseType: 'blob',
                params: {
                    filename: imageHash,
                    savename: imageHash,
                    subfolder: gseId,
                },
            })
            .then((response) => {
                const fileReaderInstance = new FileReader();
                fileReaderInstance.readAsDataURL(response.data);
                fileReaderInstance.onload = () => {
                    const base64data = fileReaderInstance.result;
                    dispatch(getGseImageSuccess(base64data, imageHash, gseId, fileId));
                };
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'The image file was not found in server',
                });
                dispatch(loading(false));
            });
    };
};

export const getGseImageSuccess = (imageData, imageHash, gseId, fileId) => {
    return {
        type: actionTypes.GET_GSE_IMAGE_SUCCESS,
        imageData: imageData,
        imageHash: imageHash,
        gseId: gseId,
        fileId: fileId,
    };
};

export const addToUploads = (fileInfo) => {
    return {
        type: actionTypes.ADD_TO_UPLOADS,
        fileInfo: fileInfo,
    };
};

// ---------------------------------------------------------------------------
export const saveGse = (gseEditDetails, filesToUpload) => {
    return (dispatch) => {
        let postData = { ...gseEditDetails };
        delete postData.uploads;
        delete postData.images;

        axios
            .post('Gse', postData)
            .then((response) => {
                if (response.status !== 200) {
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                    return;
                }

                if (filesToUpload.length) {
                    dispatch(uploadFiles(filesToUpload, response.data));
                } else {
                    dispatch(getGsePreFilter());
                    Swal.fire({
                        type: 'success',
                        text: 'The GSE was saved',
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
            });
    };
};

export const updateUploadId = (newFile, fileId) => {
    return {
        type: actionTypes.UPDATE_UPLOAD_ID,
        newFile: newFile,
        fileId: fileId,
    };
};

export const deleteGse = (gseId) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .delete('Gse/' + gseId)
            .then((response) => {
                if (response.status === 200) dispatch(getGsePreFilter());
                else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

// Files ---------------------------------------------------------------------------
export const uploadFiles = (filesToUpload, gseId) => {
    return (dispatch) => {
        let promises = [];
        //let filesInfo = [];
        const newFiles = [...filesToUpload];

        for (let i = 0; i < newFiles.length; i++) {
            let newFile = newFiles[i];
            let fileType = newFile.fileType;

            const file = dataURLtoFile(newFile.data, newFile.file.name);

            // Upload to server (needed first to check if there is an issue with the file)
            let formData = new FormData();
            formData.append('file', file);

            const fileTypeAppend = new Blob([''], { type: 'text/plain' });
            formData.append('fileType', fileTypeAppend, fileType);

            const gseIdAppend = new Blob([''], { type: 'text/plain' });
            formData.append('gseId', gseIdAppend, gseId);

            const fileNameAppend = new Blob([''], { type: 'text/plain' });
            formData.append('fileName', fileNameAppend, newFile.file.name);

            const config = {
                onUploadProgress: function (progressEvent) {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    dispatch(uploadProgress(i, percentCompleted));
                },
            };

            // Upload the file to server
            promises.push(
                axiosDb
                    .post('upload.php', formData, config)
                    .then((response) => {
                        const uploadedFile = {
                            gseId: gseId,
                            fileType: fileType,
                            filePath: response.data,
                            originalName: newFile.file.name,
                        };

                        axios
                            .post('Uploads', uploadedFile)
                            .then((response2) => {
                                if (response2.status !== 200) {
                                    Swal.fire({
                                        type: 'error',
                                        text: 'Error: Something went wrong. Please try again.',
                                    });
                                    return;
                                }
                                let addFile = { ...uploadedFile };
                                addFile.id = response2.data;
                                dispatch(addToUploads(addFile));

                                if (fileType === 'image') {
                                    dispatch(getGseImage(gseId, response.data, response2.data));
                                }
                            })
                            .catch((error) => {
                                Swal.fire({
                                    type: 'error',
                                    text: 'Error: Something went wrong. Please try again.',
                                });
                            });
                    })
                    .catch((error) => {
                        Swal.fire({ type: 'error', text: error.response.data.message });
                        dispatch(loading(false));
                    })
            );
        }
        Promise.all(promises).then(() => {
            dispatch(getGsePreFilter());
            Swal.fire({
                type: 'success',
                text: 'The GSE was saved',
            });
        });
    };
};

export const uploadProgress = (fileIndex, percentCompleted) => {
    return {
        type: actionTypes.UPLOAD_PROGRESS,
        fileIndex: fileIndex,
        percentCompleted: percentCompleted,
    };
};

export const deleteFile = (fileId, gseId, fileHash) => {
    return (dispatch) => {
        // If new file (the images has not been saved) just delete it
        if (gseId < 0) {
            axiosDb.post('deleteFileFromServer.php', {
                fileHash: fileHash,
                gseId: gseId,
            });
            dispatch(deleteFileSuccess(fileHash));
            return;
        }
        if (!fileId) {
            axiosDb.post('deleteFileFromServer.php', {
                fileHash: fileHash,
                gseId: gseId,
            });
            dispatch(deleteFileSuccess(fileHash));
            return;
        }

        axios
            .delete('Uploads/' + fileId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(deleteFileSuccess(fileHash));

                    // Delete from server
                    axiosDb.post('deleteFileFromServer.php', {
                        fileHash: fileHash,
                        gseId: gseId,
                    });
                } else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });

                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const deleteFileSuccess = (fileHash) => {
    return {
        type: actionTypes.DELETE_FILE_SUCCESS,
        fileHash: fileHash,
    };
};

export const updateFilesToUpload = (files, fileType) => {
    return {
        type: actionTypes.UPDATE_FILES_TO_UPLOAD,
        files: files,
        fileType: fileType,
    };
};

// Lists --------------------------------
export const getLists = () => {
    return (dispatch) => {
        // dispatch(loading(true));

        const requestOne = axios.get('Field/-1');
        const requestTwo = axios.get('FieldChoice/-1');

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status === 200 && responseTwo.status === 200) dispatch(getListsSuccess(responseOne.data, responseTwo.data));

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });
                    }
                    // dispatch(loading(false));
                })
            )
            .catch((errors) => {
                console.log(errors);
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                // dispatch(loading(false));
            });
    };
};

export const getListsSuccess = (fields, fieldChoices) => {
    return {
        type: actionTypes.GET_LISTS_SUCCESS,
        fields: fields,
        fieldChoices: fieldChoices,
    };
};

// Locations --------------------------------
export const getLocationFilters = () => {
    return (dispatch) => {
        //dispatch(loading(true));

        const requestOne = axios.get('Airport/-1');
        const requestTwo = axios.get('Field/-1');
        const requestThree = axios.get('FieldChoice/-1');

        axiosRoot
            .all([requestOne, requestTwo, requestThree])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    const responseThree = responses[2];

                    if (responseOne.status !== 200 || responseTwo.status !== 200 || responseThree.status !== 200) {
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });
                        return;
                    }

                    dispatch(getAirportsSuccess(responseOne.data));
                    dispatch(getListsSuccess(responseTwo.data, responseThree.data));

                    //dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getLastLocations = (showLoader) => {
    return (dispatch) => {
        // const currentLocation = getState()._system.breadcrumbs;

        // if (currentLocation !== "locationMap" && currentLocation !== ""){
        //     return;
        // }

        if (showLoader) dispatch(loading(true));

        const requestOne = axios.get('DeviceLocation/-1');
        const requestTwo = axios.get('GsePreFilter/-1');

        axiosRoot
            .all([requestOne, requestTwo])
            .then(
                axiosRoot.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];

                    if (responseOne.status !== 200 || responseTwo.status !== 200) {
                        Swal.fire({
                            type: 'error',
                            text: 'Error: Something went wrong. Please try again.',
                        });
                        dispatch(loading(false));
                        return;
                    }

                    dispatch(getLastLocationSuccess(responseOne.data, responseTwo.data));

                    dispatch(loading(false));
                })
            )
            .catch((errors) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getLastLocationSuccess = (devices, gses) => {
    return {
        type: actionTypes.GET_LAST_LOCATION_SUCCESS,
        devices: devices,
        gses: gses,
    };
};

// Update filters
export const updateFilteredData = (data, dataType) => {
    return {
        type: actionTypes.UPDATE_FILTERED_DATA,
        data: data,
        dataType: dataType,
    };
};

// Resets
export const getResets = (gseId) => {
    return (dispatch) => {
        axios
            .get('RefuelingReset/' + gseId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getResetsSuccess(response.data));
                } else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });

                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getResetsSuccess = (gseResets) => {
    return {
        type: actionTypes.GET_RESETS_SUCCESS,
        gseResets: gseResets,
    };
};

export const deleteReset = (resetId, gseId) => {
    return (dispatch) => {
        axios
            .delete('RefuelingReset/' + resetId)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getResets(gseId));
                } else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
            });
    };
};

// Service
export const getService = (id) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('Service/' + id)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getServiceSuccess(response.data));
                } else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });

                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getServiceSuccess = (serviceRecords) => {
    return {
        type: actionTypes.GET_SERVICE_SUCCESS,
        serviceRecords: serviceRecords,
    };
};

// Tires
export const getTires = (id) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('Tyre/' + id)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getTiresSuccess(response.data));
                } else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });

                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getTiresSuccess = (tiresRecords) => {
    return {
        type: actionTypes.GET_TIRES_SUCCESS,
        tiresRecords: tiresRecords,
    };
};

// Batteries
export const getBatteries = (id) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('Battery/' + id)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getBatteriesSuccess(response.data));
                } else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });

                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getBatteriesSuccess = (batteriesRecords) => {
    return {
        type: actionTypes.GET_BATTERIES_SUCCESS,
        batteriesRecords: batteriesRecords,
    };
};

// Notifications
export const getNotifications = (filter) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios
            .get('NotificationsAirportGse' + filter)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(getNotificationsSuccess(response.data));
                } else
                    Swal.fire({
                        type: 'error',
                        text: 'Error: Something went wrong. Please try again.',
                    });

                dispatch(loading(false));
            })
            .catch((error) => {
                Swal.fire({
                    type: 'error',
                    text: 'Error: Something went wrong. Please try again.',
                });
                dispatch(loading(false));
            });
    };
};

export const getNotificationsSuccess = (notifications) => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        notifications: notifications,
    };
};

// Reset
export const resetGseInfo = () => {
    return (dispatch) => {
        dispatch(getRefuelingSuccess([]));
        dispatch(clearGseEditDetails());
        dispatch(getGseServiceSuccess([], [], []));
        dispatch(getGseStatisticsSuccess(null));
        dispatch(getTrackingGseSuccess(null));
    };
};
