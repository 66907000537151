import React from 'react';

import classes from './Loader.module.css';

const Loader = (props) => {

    let loaderClasses = [classes.LoaderWrapper]
    if (props.show){
        loaderClasses.push(classes.Show)
    }

    return (
        <div className={loaderClasses.join(' ')}>
            <div className={classes.Loader}>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader;