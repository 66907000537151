import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuttleVan } from '@fortawesome/free-solid-svg-icons';

import classes from './Statistics.module.css';
import Loader from '../../components/UI/Loader/Loader';

class Statistics extends Component {
    state = {
        selectedType: null,
    };

    componentDidMount() {
        // this.props.onGetAirports();
    }

    changeFilter(filter) {
        this.setState({ selectedType: filter });
    }

    render() {
        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}></div>
                <div className={classes.PageTitle}>
                    <h1>Statistics</h1>
                </div>

                <div className={classes.PageContent}>
                    <div className={classes.PageInner}>
                        <Loader show={this.props.loading} />
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className={classes.ScrollInner}>
                                <div className={classes.Boxes}>
                                    <div className={classes.BoxWrapper} onClick={() => this.props.history.push('/statisticsCompany')}>
                                        <div className={classes.Box}>
                                            <svg style={{ width: '90px', marginTop: '-7px' }} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 214.8 160.298'>
                                                <path d='M184.613 87.742c1.844-13.193 3.676-26.313 5.513-39.447.336-.019.581-.047.828-.047q6.025 0 12.048-.011c.588 0 .876.108.97.781q2.653 19.232 5.356 38.453a1.752 1.752 0 01-.011.271z' />
                                                <path d='M212.429 32.632c-.529 1.6-1.029 3.133-1.536 4.661-.947 2.862-1.909 5.718-2.838 8.593a.932.932 0 01-1.044.777c-6.6-.028-13.193-.021-19.789-.058a1.125 1.125 0 01-.88-.527c-1.508-4.377-2.967-8.78-4.435-13.165a1.212 1.212 0 010-.275z' />
                                                <path d='M195.571 15.264h3.124v9.782h10.322v-9.765h3.129v9.765h2.643v5.808h-35.372v-5.751h2.627v-9.824h3.133v9.753h10.392z' />
                                                <path d='M187.7 51.362c-.312 2.214-.609 4.334-.914 6.492H157v-6.492z' />
                                                <path d='M179.395 13.796v-3.322h6.774V6.26h1.483v4.119h4.993V7.55h1.476v2.866h20.678v3.381z' />
                                                <path d='M189.537 2.653A2.6 2.6 0 01186.89 5.2a2.571 2.571 0 01-2.556-2.63A2.615 2.615 0 01186.961 0a2.653 2.653 0 012.576 2.653z' />
                                                <path d='M195.169 4.726a1.786 1.786 0 01-1.764 1.78 1.771 1.771 0 111.767-1.78z' />
                                                <path d='M185.61 66.207l.374-2.586h-6.254V59.56h-3.32v4.009h-5.18v-3.975h-3.313v3.979h-5.221v-4.009h-3.176v20.923h7.77v7.344c5.018 0 9.929 0 14.84-.024.187 0 .5-.338.531-.551.628-4.276 1.222-8.558 1.84-12.965h-4.689v-8.083zm-17.734 8.012h-5.062V66.3h5.063zm8.483.024h-5.064v-7.956h5.064z' />
                                                <path d='M64.758 57.711v-6.829L37.442 33.81V15.089a5.063 5.063 0 10-10.126 0V33.81L0 50.882v6.829l27.316-8.477v18.721l-6.829 5.063v5.063l11.892-3.3 11.892 3.414v-5.06l-6.829-5.063V49.116z' />
                                                <path d='M121.477 153.45h-20.4a4.639 4.639 0 01-1.5-.08 1.227 1.227 0 01-.725-.869 1.265 1.265 0 01.721-.894 4.64 4.64 0 011.5-.067h41.169a3.628 3.628 0 011.307.046c.388.164.658.595.979.907-.321.312-.59.755-.974.894a4.64 4.64 0 01-1.5.063z' />
                                                <path d='M108.115 156.975a3.117 3.117 0 01-3.02 3.041 2.919 2.919 0 01-2.927-2.851 2.771 2.771 0 012.826-3.054 2.952 2.952 0 013.121 2.864z' />
                                                <path d='M141.166 156.938a2.952 2.952 0 01-2.9 3.079 3.117 3.117 0 01-3.058-3 2.952 2.952 0 012.885-2.9 2.788 2.788 0 013.073 2.821z' />
                                                <path d='M97.116 151.644v1.687H84.678c-.042-.548-.08-1.067-.131-1.687z' />
                                                <path d='M80.7 136.279c-9.456 0-18.908-.034-28.36.03-1.5 0-1.965-.422-1.923-1.919.1-3.273.03-6.55.03-9.827v-4.4l-1.873-.274-1.456-3.1c-.531.974-1.063 1.944-1.738 3.189h-1.644c-6.238 0-12.48.034-18.718-.03a2.261 2.261 0 00-2.418 1.527c-1.96 4.222-4.048 8.389-6.043 12.581a4.374 4.374 0 00-.557 2.133c.59 6.061 1.265 12.113 1.9 18.225h9.941c-.228-5.728 2.817-9.789 7.592-10.123 5.6-.422 8.7 2.868 9.481 10.123h18.034c-.321-5.546 2.813-9.7 7.524-10.123a8.381 8.381 0 016.183 1.798c2.687 2.151 3.518 5.061 3.243 8.313h3.045v-18.123zm-53.662 11.21a18.744 18.744 0 01-3 .051v.038a13.923 13.923 0 01-2.817-.055c-.447-.1-.772-.789-1.151-1.21.4-.321.776-.894 1.189-.919a52.723 52.723 0 015.656 0 1.418 1.418 0 011.067.966 1.476 1.476 0 01-.941 1.13zm18.832-12.6a8.71 8.71 0 01-8.781 8.71H30.1c-2.585 0-5.167.072-7.744-.051-.633-.03-1.232-.7-1.843-1.071.612-.422 1.215-1.13 1.835-1.143 4.783-.1 9.57-.042 14.34-.059 4.247-.025 6.833-2.556 6.883-6.774.034-3.083 0-6.171 0-9.254 0-.962 0-2.138 1.265-2.012.4.038.949 1.307.974 2.029.136 3.204.09 6.418.06 9.625zm-19.688-13.583a2.434 2.434 0 00-2.7 1.641 54.353 54.353 0 01-3.88 7.95c-2.248 3.513-2.2 7.17-1.641 11.025.527 3.669.814 7.373 1.185 11.063a2.775 2.775 0 01-.152.785l-.51.03c-.152-1.236-.325-2.467-.456-3.691-.477-4.5-.966-9-1.379-13.5a4.391 4.391 0 01.384-2.164c2-4.294 4.028-8.545 6.166-12.746A2.493 2.493 0 0125 120.543c7.8-.076 15.6-.054 23.422-.042a8.612 8.612 0 011.32.2v.493c-.62.051-1.24.135-1.86.139-7.217.011-14.459.045-21.7-.027zm56.189 32.4h-.6v-15.959h-31.08v6.719a6.618 6.618 0 01-.118 2.054 1.569 1.569 0 01-1.139.932 1.476 1.476 0 01-.979-1.067q-.12-4.53 0-9.064c0-1.063.645-1.367 1.687-1a7.191 7.191 0 002.32.422c9.279.03 18.512.021 27.77.021H82.4z' />
                                                <path d='M144.434 139.696c-.059-2.366-1.286-3.539-3.741-3.543q-18.98-.042-37.993 0c-2.611 0-3.8 1.265-3.821 3.851-.025 2.771-.072 5.546.042 8.313.025.654.637 1.286.983 1.928.346-.637.97-1.265 1-1.911.114-2.767 0-5.546.1-8.313.025-.616.679-1.687 1.1-1.729 2.484-.164 4.981-.08 7.495-.08v4.306c-1.687 0-3.269-.089-4.821.046-.519.046-.987.654-1.48 1 .506.337.991.924 1.523.97 1.548.131 3.113.046 4.918.046 0 1.641-.084 3.079.042 4.5.046.553.574 1.059.882 1.586.325-.51.882-1 .928-1.531.127-1.472.042-2.952.042-4.429h20.081c0 1.615-.076 3.117.046 4.6.038.477.595.911.911 1.362.312-.464.844-.907.886-1.392.118-1.485.042-2.986.042-4.69a39.178 39.178 0 004.3-.059c.734-.08 2-.586 2.02-.949.067-1.476-1.24-1.025-2.109-1.063-1.362-.059-2.725 0-4.159 0v-4.325c2.341 0 4.534.063 6.719-.021 1.464-.055 2.05.46 2 1.957q-.122 4.159.025 8.313c.021.612.6 1.2.919 1.8.367-.553 1.038-1.1 1.059-1.687.133-2.934.129-5.899.061-8.856zm-12.805 2.666h-19.887v-4.033h19.887z' />
                                                <path d='M36.304 145.111a7.592 7.592 0 107.58 7.529 7.592 7.592 0 00-7.58-7.529z' />
                                                <path d='M71.311 145.115a7.592 7.592 0 107.74 7.626 7.723 7.723 0 00-7.74-7.626z' />
                                                <path d='M90.691 66.075c0 .8-.009 1.564 0 2.329a1.183 1.183 0 002.217.56 1.546 1.546 0 00.153-.646c.017-.742 0-1.482 0-2.247h4.883v2.268a1.178 1.178 0 001.69 1.121 1.119 1.119 0 00.675-1.039c.015-.626 0-1.251 0-1.877v-.472h4.853v1.049c0 .424-.008.85.016 1.274a1.184 1.184 0 102.365-.068c.005-.715 0-1.439.008-2.16a.391.391 0 01.032-.091h4.835c0 .791-.013 1.566 0 2.341a1.178 1.178 0 001.728 1.022 1.162 1.162 0 00.642-1.1v-1.877c0-.127.011-.253.017-.4h4.855v2.263a1.213 1.213 0 001.171 1.258 1.234 1.234 0 001.209-1.258v-2.249h4.84v.619c.009.626-.017 1.254.044 1.874a1.15 1.15 0 001.213 1.01 1.2 1.2 0 001.134-1.185c.009-.766 0-1.53 0-2.32.124-.008.227-.02.328-.02h4.993a1.237 1.237 0 011.407 1.414v44.725a1.23 1.23 0 01-1.387 1.4H81.321c-.934 0-1.375-.45-1.375-1.4V92.465q0-5.523-.011-11.048 0-7.041.012-14.088a1.173 1.173 0 011.215-1.273h9.348a.8.8 0 01.181.019zm42.873 14.159H82.381v30.909h51.183z' />
                                                <path d='M107.149 66.135v2.121c0 .572-.307.918-.8.915a.8.8 0 01-.783-.9q-.011-2.105 0-4.212a1.135 1.135 0 00-.667-1.082 1.169 1.169 0 00-1.3.121 1.146 1.146 0 00-.474.872c-.044.547-.376.888-.828.86-.506-.032-.786-.416-.734-1a2.794 2.794 0 015.584.187c.01.7.002 1.41.002 2.118z' />
                                                <path d='M114.389 66.148v2.124a.8.8 0 11-1.58.016c-.009-1.4 0-2.808 0-4.212a1.155 1.155 0 00-.777-1.153 1.1 1.1 0 00-1.3.28 1.6 1.6 0 00-.355.778c-.089.544-.388.88-.85.842a.813.813 0 01-.732-.945 2.8 2.8 0 015.592.108c.01.723.002 1.443.002 2.162z' />
                                                <path d='M128.862 66.11v2.2a.768.768 0 01-.766.868.8.8 0 01-.8-.882v-4.14a1.22 1.22 0 10-2.436-.128.806.806 0 01-.846.808.826.826 0 01-.74-.947 2.824 2.824 0 012.713-2.628 2.741 2.741 0 012.82 2.241 15.318 15.318 0 01.06 2.189c-.004.136-.005.277-.005.419z' />
                                                <path d='M92.653 66.113v2.229a.766.766 0 01-.766.832.788.788 0 01-.787-.854v-4.175a1.23 1.23 0 00-2.456-.155c-.065.558-.39.882-.842.84a.843.843 0 01-.724-1.026 2.809 2.809 0 012.85-2.55 2.776 2.776 0 012.736 2.8v2.059z' />
                                                <path d='M99.897 66.099v2.159c0 .575-.3.925-.791.917s-.772-.355-.774-.9c0-1.415 0-2.831-.011-4.248a1.121 1.121 0 00-.764-1.093 1.1 1.1 0 00-1.3.267 1.745 1.745 0 00-.364.811c-.1.534-.418.86-.881.812a.811.811 0 01-.7-1 2.8 2.8 0 015.526-.374 2.543 2.543 0 01.055.56c.008.697.004 1.393.004 2.089z' />
                                                <path d='M121.625 66.12v2.128c0 .586-.292.934-.783.934s-.787-.354-.788-.934v-4.074a1.228 1.228 0 10-2.452-.113.783.783 0 01-.8.77.808.808 0 01-.767-.852 2.8 2.8 0 015.588-.053c.011.732.002 1.458.002 2.194z' />
                                                <path d='M101.581 96.227v2.971c0 .483-.084.57-.58.57h-5.554c-.392 0-.49-.088-.491-.472v-6.085c0-.331.153-.466.476-.464q2.83 0 5.661.009c.366 0 .472.117.474.5v2.972z' />
                                                <path d='M104.657 96.269v-2.972c0-.448.1-.542.534-.542h5.522c.466 0 .57.1.57.551v5.946c0 .42-.1.515-.524.515h-5.592c-.416 0-.5-.08-.5-.494v-3.007z' />
                                                <path d='M114.365 96.264v-2.97c0-.439.1-.534.546-.534h5.518c.462 0 .56.1.56.559v5.87c0 .516-.076.584-.6.584h-5.482c-.444 0-.542-.091-.543-.534v-2.971z' />
                                                <path d='M104.657 105.553v-2.974c0-.455.088-.534.534-.534h5.522c.471 0 .568.089.568.547v5.944c0 .414-.108.519-.526.519h-5.592c-.418 0-.507-.1-.507-.534v-2.972z' />
                                                <path d='M130.699 86.964v2.972c0 .468-.085.554-.56.554h-5.556c-.412 0-.516-.1-.518-.534V84.01c0-.45.095-.534.534-.534h5.556c.422 0 .524.1.526.514v2.974z' />
                                                <path d='M91.87 96.285v2.9c0 .5-.085.583-.567.583h-5.519c-.452 0-.552-.1-.552-.566v-5.869c0-.459.112-.571.555-.571h5.554c.412 0 .522.111.523.524v3.006z' />
                                                <path d='M91.861 105.567c0 1-.007 2 0 3.006 0 .35-.145.487-.488.487h-5.658c-.358 0-.479-.12-.483-.5-.009-.755 0-1.509 0-2.268 0-1.238 0-2.475.007-3.713 0-.442.1-.534.544-.534h5.552c.428 0 .534.107.534.554v2.97z' />
                                                <path d='M120.988 105.573v2.935c0 .452-.089.552-.534.552h-5.588c-.4 0-.506-.095-.507-.5v-6.047c0-.325.149-.463.478-.463h5.661c.368 0 .484.117.486.486v3.043z' />
                                                <path d='M130.691 105.561v2.975c0 .426-.093.52-.523.522h-5.6c-.4 0-.5-.1-.5-.51v-6.019c0-.383.107-.486.487-.486h5.63c.4 0 .5.1.5.5v3.01z' />
                                                <path d='M120.987 87.01v2.935c0 .446-.1.546-.534.546h-5.555c-.443 0-.534-.093-.534-.542v-5.942c0-.443.093-.534.546-.534h5.52c.463 0 .556.093.558.558v2.972z' />
                                                <path d='M94.959 105.547v-2.971c0-.456.08-.534.534-.534h5.552c.42 0 .518.1.518.52v5.907c0 .514-.076.582-.6.583h-5.376c-.588 0-.636-.047-.638-.652v-2.863z' />
                                                <path d='M130.693 96.298v2.931c0 .447-.087.534-.54.534h-5.587c-.392 0-.5-.107-.5-.507v-5.977c0-.426.105-.534.52-.534h5.588c.407 0 .512.115.514.534v3.006z' />
                                            </svg>
                                            <span>Company</span>
                                        </div>
                                    </div>

                                    <div className={classes.BoxWrapper} onClick={() => this.props.history.push('/statisticsAirport')}>
                                        <div className={classes.Box}>
                                            <svg style={{ width: '50px', marginTop: '-7px' }} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 89.447 135.919'>
                                                <g>
                                                    <path
                                                        d='M147.81,319.345c2.853-20.416,5.689-40.719,8.531-61.046.52-.029.9-.072,1.281-.072q9.323,0,18.644-.017c.911,0,1.356.168,1.5,1.208q4.105,29.763,8.288,59.508a2.711,2.711,0,0,1-.017.419Z'
                                                        transform='translate(-105.079 -183.562)'
                                                    />
                                                    <path
                                                        d='M180.533,174.68c-.818,2.48-1.593,4.848-2.376,7.213-1.466,4.429-2.955,8.849-4.391,13.3a1.442,1.442,0,0,1-1.616,1.2c-10.208-.043-20.416-.032-30.624-.09a1.741,1.741,0,0,1-1.362-.815c-2.333-6.774-4.591-13.588-6.863-20.373a1.878,1.878,0,0,1,0-.425Z'
                                                        transform='translate(-94.755 -124.18)'
                                                    />
                                                    <path
                                                        d='M145,81.71h4.834V96.847h15.981V81.736h4.842V96.847h4.091v8.994H120v-8.9h4.065v-15.2h4.848V96.827H145Z'
                                                        transform='translate(-85.308 -58.088)'
                                                    />
                                                    <path d='M47.513,274.94c-.483,3.426-.942,6.707-1.414,10.046H0V274.94Z' transform='translate(0 -195.456)' />
                                                    <path
                                                        d='M119.88,45.172v-5.14h10.483V33.51h2.3v6.375h7.728V35.5h2.284V39.94h32v5.233Z'
                                                        transform='translate(-85.223 -23.822)'
                                                    />
                                                    <path
                                                        d='M154.369,4.105a4.03,4.03,0,0,1-4.1,3.949,3.978,3.978,0,0,1-3.955-4.07A4.047,4.047,0,0,1,150.382,0a4.105,4.105,0,0,1,3.987,4.105Z'
                                                        transform='translate(-104.017 0)'
                                                    />
                                                    <path
                                                        d='M190.827,18.6a2.764,2.764,0,0,1-2.729,2.755,2.74,2.74,0,1,1,2.735-2.755Z'
                                                        transform='translate(-131.758 -11.285)'
                                                    />
                                                    <path
                                                        d='M53.865,329.106c.194-1.347.373-2.57.578-4H44.759V318.82H39.622v6.2H31.614v-6.152H26.485v6.158H18.4v-6.2H13.49V351.2H25.514v11.364c7.765,0,15.366,0,22.966-.038.289,0,.775-.523.821-.853.971-6.617,1.891-13.244,2.848-20.063H44.892V329.106Zm-27.444,12.4H18.587V329.251h7.835Zm13.128.038H31.712V329.23h7.837Z'
                                                        transform='translate(-9.59 -226.65)'
                                                    />
                                                </g>
                                            </svg>
                                            <span>Airport</span>
                                        </div>
                                    </div>

                                    <div className={classes.BoxWrapper} onClick={() => this.props.history.push('/statisticsEquipment')}>
                                        <div className={[classes.Box, classes.TwoIcons].join(' ')}>
                                            <FontAwesomeIcon icon={faShuttleVan} />
                                            <svg style={{ width: '60px', marginTop: '-7px' }} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 202.178 68.439'>
                                                <path d='M165.915 57.667H133.83a7.3 7.3 0 01-2.355-.126 1.931 1.931 0 01-1.141-1.367 1.99 1.99 0 011.135-1.407 7.3 7.3 0 012.355-.106h64.76a5.706 5.706 0 012.057.073c.61.259 1.035.935 1.539 1.426-.5.491-.929 1.188-1.533 1.407a7.3 7.3 0 01-2.355.1z' />
                                                <path d='M144.896 63.213a4.9 4.9 0 01-4.75 4.783 4.591 4.591 0 01-4.6-4.485 4.359 4.359 0 014.445-4.8 4.644 4.644 0 014.91 4.5z' />
                                                <path d='M196.879 63.154a4.644 4.644 0 01-4.564 4.843 4.9 4.9 0 01-4.81-4.724 4.644 4.644 0 014.538-4.558 4.385 4.385 0 014.836 4.439z' />
                                                <path d='M127.594 54.827v2.654h-19.565c-.066-.862-.126-1.679-.206-2.654z' />
                                                <path d='M101.772 30.658c-14.874 0-29.742-.053-44.61.046-2.362 0-3.092-.663-3.025-3.019.159-5.148.046-10.3.046-15.458V5.308l-2.946-.431L48.949 0c-.836 1.533-1.672 3.058-2.733 5.016h-2.588c-9.812 0-19.631.053-29.444-.046a3.556 3.556 0 00-3.808 2.4C7.3 14.012 4.014 20.567.876 27.161A6.88 6.88 0 000 30.518c.929 9.534 1.99 19.054 2.992 28.667H18.63c-.358-9.01 4.432-15.4 11.942-15.923 8.811-.663 13.68 4.511 14.914 15.923h28.362c-.5-8.724 4.425-15.259 11.836-15.923a13.183 13.183 0 019.726 2.82c4.226 3.384 5.533 7.961 5.1 13.076h4.79v-28.5zm-84.41 17.634a29.483 29.483 0 01-4.724.08v.06a21.9 21.9 0 01-4.432-.086c-.7-.153-1.214-1.241-1.811-1.9.624-.5 1.221-1.407 1.871-1.446a82.933 82.933 0 018.9 0 2.231 2.231 0 011.679 1.519 2.322 2.322 0 01-1.479 1.778zm29.623-19.817a13.7 13.7 0 01-13.813 13.7H22.179c-4.067 0-8.127.113-12.181-.08C9 42.049 8.061 41 7.1 40.41c.962-.663 1.911-1.778 2.886-1.8 7.523-.153 15.054-.066 22.557-.093 6.681-.04 10.748-4.02 10.827-10.655.053-4.85 0-9.706 0-14.556 0-1.513 0-3.364 1.99-3.165.63.06 1.493 2.057 1.533 3.191.207 5.039.139 10.094.092 15.143zM16.016 7.105c-2.163 0-3.357.5-4.246 2.581a85.5 85.5 0 01-6.1 12.506c-3.54 5.527-3.461 11.279-2.585 17.343.829 5.772 1.28 11.6 1.864 17.4a4.365 4.365 0 01-.239 1.234l-.8.046c-.239-1.944-.511-3.881-.717-5.805-.75-7.079-1.519-14.158-2.169-21.23a6.906 6.906 0 01.6-3.4c3.145-6.754 6.336-13.441 9.7-20.049A3.921 3.921 0 0114.151 5.9C26.432 5.785 38.7 5.818 51 5.838a13.547 13.547 0 012.077.318v.776c-.975.08-1.951.212-2.926.219-11.351.014-22.744.067-34.135-.046zM104.4 58.078h-.949V32.967H54.568v10.568a10.411 10.411 0 01-.186 3.231 2.468 2.468 0 01-1.791 1.466 2.322 2.322 0 01-1.539-1.679q-.189-7.125 0-14.257c0-1.672 1.015-2.15 2.654-1.579a11.312 11.312 0 003.649.663c14.6.046 29.119.033 43.681.033h3.4z' />
                                                <path d='M202.026 36.032c-.093-3.722-2.023-5.566-5.885-5.573q-29.855-.066-59.763 0c-4.107 0-5.971 1.99-6.011 6.057-.04 4.359-.113 8.724.066 13.076.04 1.028 1 2.024 1.546 3.032.544-1 1.526-1.99 1.566-3.005.179-4.352 0-8.724.159-13.076.04-.969 1.068-2.654 1.725-2.72 3.908-.259 7.835-.126 11.789-.126v6.769c-2.654 0-5.142-.139-7.583.073-.816.073-1.552 1.028-2.329 1.579.8.531 1.559 1.453 2.4 1.526 2.435.206 4.9.073 7.736.073 0 2.581-.133 4.843.066 7.072.073.869.9 1.665 1.387 2.495.511-.8 1.387-1.572 1.46-2.408.2-2.315.066-4.644.066-6.966h31.587c0 2.541-.119 4.9.073 7.238.06.75.935 1.433 1.433 2.143.491-.73 1.327-1.426 1.393-2.189.186-2.335.066-4.7.066-7.378a61.629 61.629 0 006.767-.093c1.154-.126 3.145-.922 3.178-1.493.106-2.322-1.951-1.612-3.317-1.672-2.143-.093-4.286 0-6.542 0v-6.8c3.682 0 7.132.1 10.569-.033 2.3-.086 3.224.723 3.151 3.078q-.192 6.542.04 13.076c.033.962.942 1.891 1.446 2.833.577-.869 1.632-1.725 1.665-2.654.209-4.619.202-9.283.096-13.934zm-20.142 4.193h-31.282v-6.342h31.281z' />
                                                <path d='M31.938 44.55a11.942 11.942 0 1011.925 11.842A11.942 11.942 0 0031.938 44.55z' />
                                                <path d='M87.004 44.557a11.942 11.942 0 1012.174 12 12.148 12.148 0 00-12.174-12z' />
                                            </svg>
                                            <span>Equipment</span>
                                        </div>
                                    </div>

                                    <div className={classes.BoxWrapper} onClick={() => this.props.history.push('/statisticsEquipmentAirport')}>
                                        <div className={[classes.Box, classes.ThreeIcons].join(' ')}>
                                            <div className={classes.PairedIcons}>
                                                <FontAwesomeIcon icon={faShuttleVan} />
                                                <svg
                                                    style={{ width: '30px', marginTop: '-7px' }}
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 89.447 135.919'
                                                >
                                                    <g>
                                                        <path
                                                            d='M147.81,319.345c2.853-20.416,5.689-40.719,8.531-61.046.52-.029.9-.072,1.281-.072q9.323,0,18.644-.017c.911,0,1.356.168,1.5,1.208q4.105,29.763,8.288,59.508a2.711,2.711,0,0,1-.017.419Z'
                                                            transform='translate(-105.079 -183.562)'
                                                        />
                                                        <path
                                                            d='M180.533,174.68c-.818,2.48-1.593,4.848-2.376,7.213-1.466,4.429-2.955,8.849-4.391,13.3a1.442,1.442,0,0,1-1.616,1.2c-10.208-.043-20.416-.032-30.624-.09a1.741,1.741,0,0,1-1.362-.815c-2.333-6.774-4.591-13.588-6.863-20.373a1.878,1.878,0,0,1,0-.425Z'
                                                            transform='translate(-94.755 -124.18)'
                                                        />
                                                        <path
                                                            d='M145,81.71h4.834V96.847h15.981V81.736h4.842V96.847h4.091v8.994H120v-8.9h4.065v-15.2h4.848V96.827H145Z'
                                                            transform='translate(-85.308 -58.088)'
                                                        />
                                                        <path
                                                            d='M47.513,274.94c-.483,3.426-.942,6.707-1.414,10.046H0V274.94Z'
                                                            transform='translate(0 -195.456)'
                                                        />
                                                        <path
                                                            d='M119.88,45.172v-5.14h10.483V33.51h2.3v6.375h7.728V35.5h2.284V39.94h32v5.233Z'
                                                            transform='translate(-85.223 -23.822)'
                                                        />
                                                        <path
                                                            d='M154.369,4.105a4.03,4.03,0,0,1-4.1,3.949,3.978,3.978,0,0,1-3.955-4.07A4.047,4.047,0,0,1,150.382,0a4.105,4.105,0,0,1,3.987,4.105Z'
                                                            transform='translate(-104.017 0)'
                                                        />
                                                        <path
                                                            d='M190.827,18.6a2.764,2.764,0,0,1-2.729,2.755,2.74,2.74,0,1,1,2.735-2.755Z'
                                                            transform='translate(-131.758 -11.285)'
                                                        />
                                                        <path
                                                            d='M53.865,329.106c.194-1.347.373-2.57.578-4H44.759V318.82H39.622v6.2H31.614v-6.152H26.485v6.158H18.4v-6.2H13.49V351.2H25.514v11.364c7.765,0,15.366,0,22.966-.038.289,0,.775-.523.821-.853.971-6.617,1.891-13.244,2.848-20.063H44.892V329.106Zm-27.444,12.4H18.587V329.251h7.835Zm13.128.038H31.712V329.23h7.837Z'
                                                            transform='translate(-9.59 -226.65)'
                                                        />
                                                    </g>
                                                </svg>
                                            </div>

                                            <svg style={{ width: '60px', marginTop: '-7px' }} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 202.178 68.439'>
                                                <path d='M165.915 57.667H133.83a7.3 7.3 0 01-2.355-.126 1.931 1.931 0 01-1.141-1.367 1.99 1.99 0 011.135-1.407 7.3 7.3 0 012.355-.106h64.76a5.706 5.706 0 012.057.073c.61.259 1.035.935 1.539 1.426-.5.491-.929 1.188-1.533 1.407a7.3 7.3 0 01-2.355.1z' />
                                                <path d='M144.896 63.213a4.9 4.9 0 01-4.75 4.783 4.591 4.591 0 01-4.6-4.485 4.359 4.359 0 014.445-4.8 4.644 4.644 0 014.91 4.5z' />
                                                <path d='M196.879 63.154a4.644 4.644 0 01-4.564 4.843 4.9 4.9 0 01-4.81-4.724 4.644 4.644 0 014.538-4.558 4.385 4.385 0 014.836 4.439z' />
                                                <path d='M127.594 54.827v2.654h-19.565c-.066-.862-.126-1.679-.206-2.654z' />
                                                <path d='M101.772 30.658c-14.874 0-29.742-.053-44.61.046-2.362 0-3.092-.663-3.025-3.019.159-5.148.046-10.3.046-15.458V5.308l-2.946-.431L48.949 0c-.836 1.533-1.672 3.058-2.733 5.016h-2.588c-9.812 0-19.631.053-29.444-.046a3.556 3.556 0 00-3.808 2.4C7.3 14.012 4.014 20.567.876 27.161A6.88 6.88 0 000 30.518c.929 9.534 1.99 19.054 2.992 28.667H18.63c-.358-9.01 4.432-15.4 11.942-15.923 8.811-.663 13.68 4.511 14.914 15.923h28.362c-.5-8.724 4.425-15.259 11.836-15.923a13.183 13.183 0 019.726 2.82c4.226 3.384 5.533 7.961 5.1 13.076h4.79v-28.5zm-84.41 17.634a29.483 29.483 0 01-4.724.08v.06a21.9 21.9 0 01-4.432-.086c-.7-.153-1.214-1.241-1.811-1.9.624-.5 1.221-1.407 1.871-1.446a82.933 82.933 0 018.9 0 2.231 2.231 0 011.679 1.519 2.322 2.322 0 01-1.479 1.778zm29.623-19.817a13.7 13.7 0 01-13.813 13.7H22.179c-4.067 0-8.127.113-12.181-.08C9 42.049 8.061 41 7.1 40.41c.962-.663 1.911-1.778 2.886-1.8 7.523-.153 15.054-.066 22.557-.093 6.681-.04 10.748-4.02 10.827-10.655.053-4.85 0-9.706 0-14.556 0-1.513 0-3.364 1.99-3.165.63.06 1.493 2.057 1.533 3.191.207 5.039.139 10.094.092 15.143zM16.016 7.105c-2.163 0-3.357.5-4.246 2.581a85.5 85.5 0 01-6.1 12.506c-3.54 5.527-3.461 11.279-2.585 17.343.829 5.772 1.28 11.6 1.864 17.4a4.365 4.365 0 01-.239 1.234l-.8.046c-.239-1.944-.511-3.881-.717-5.805-.75-7.079-1.519-14.158-2.169-21.23a6.906 6.906 0 01.6-3.4c3.145-6.754 6.336-13.441 9.7-20.049A3.921 3.921 0 0114.151 5.9C26.432 5.785 38.7 5.818 51 5.838a13.547 13.547 0 012.077.318v.776c-.975.08-1.951.212-2.926.219-11.351.014-22.744.067-34.135-.046zM104.4 58.078h-.949V32.967H54.568v10.568a10.411 10.411 0 01-.186 3.231 2.468 2.468 0 01-1.791 1.466 2.322 2.322 0 01-1.539-1.679q-.189-7.125 0-14.257c0-1.672 1.015-2.15 2.654-1.579a11.312 11.312 0 003.649.663c14.6.046 29.119.033 43.681.033h3.4z' />
                                                <path d='M202.026 36.032c-.093-3.722-2.023-5.566-5.885-5.573q-29.855-.066-59.763 0c-4.107 0-5.971 1.99-6.011 6.057-.04 4.359-.113 8.724.066 13.076.04 1.028 1 2.024 1.546 3.032.544-1 1.526-1.99 1.566-3.005.179-4.352 0-8.724.159-13.076.04-.969 1.068-2.654 1.725-2.72 3.908-.259 7.835-.126 11.789-.126v6.769c-2.654 0-5.142-.139-7.583.073-.816.073-1.552 1.028-2.329 1.579.8.531 1.559 1.453 2.4 1.526 2.435.206 4.9.073 7.736.073 0 2.581-.133 4.843.066 7.072.073.869.9 1.665 1.387 2.495.511-.8 1.387-1.572 1.46-2.408.2-2.315.066-4.644.066-6.966h31.587c0 2.541-.119 4.9.073 7.238.06.75.935 1.433 1.433 2.143.491-.73 1.327-1.426 1.393-2.189.186-2.335.066-4.7.066-7.378a61.629 61.629 0 006.767-.093c1.154-.126 3.145-.922 3.178-1.493.106-2.322-1.951-1.612-3.317-1.672-2.143-.093-4.286 0-6.542 0v-6.8c3.682 0 7.132.1 10.569-.033 2.3-.086 3.224.723 3.151 3.078q-.192 6.542.04 13.076c.033.962.942 1.891 1.446 2.833.577-.869 1.632-1.725 1.665-2.654.209-4.619.202-9.283.096-13.934zm-20.142 4.193h-31.282v-6.342h31.281z' />
                                                <path d='M31.938 44.55a11.942 11.942 0 1011.925 11.842A11.942 11.942 0 0031.938 44.55z' />
                                                <path d='M87.004 44.557a11.942 11.942 0 1012.174 12 12.148 12.148 0 00-12.174-12z' />
                                            </svg>
                                            <span>Equipment/airport</span>
                                        </div>
                                    </div>

                                    <div className={classes.BoxWrapper} onClick={() => this.props.history.push('/statisticsGse')}>
                                        <div className={classes.Box}>
                                            <svg style={{ width: '90px', marginTop: '-7px' }} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 202.178 68.439'>
                                                <path d='M165.915 57.667H133.83a7.3 7.3 0 01-2.355-.126 1.931 1.931 0 01-1.141-1.367 1.99 1.99 0 011.135-1.407 7.3 7.3 0 012.355-.106h64.76a5.706 5.706 0 012.057.073c.61.259 1.035.935 1.539 1.426-.5.491-.929 1.188-1.533 1.407a7.3 7.3 0 01-2.355.1z' />
                                                <path d='M144.896 63.213a4.9 4.9 0 01-4.75 4.783 4.591 4.591 0 01-4.6-4.485 4.359 4.359 0 014.445-4.8 4.644 4.644 0 014.91 4.5z' />
                                                <path d='M196.879 63.154a4.644 4.644 0 01-4.564 4.843 4.9 4.9 0 01-4.81-4.724 4.644 4.644 0 014.538-4.558 4.385 4.385 0 014.836 4.439z' />
                                                <path d='M127.594 54.827v2.654h-19.565c-.066-.862-.126-1.679-.206-2.654z' />
                                                <path d='M101.772 30.658c-14.874 0-29.742-.053-44.61.046-2.362 0-3.092-.663-3.025-3.019.159-5.148.046-10.3.046-15.458V5.308l-2.946-.431L48.949 0c-.836 1.533-1.672 3.058-2.733 5.016h-2.588c-9.812 0-19.631.053-29.444-.046a3.556 3.556 0 00-3.808 2.4C7.3 14.012 4.014 20.567.876 27.161A6.88 6.88 0 000 30.518c.929 9.534 1.99 19.054 2.992 28.667H18.63c-.358-9.01 4.432-15.4 11.942-15.923 8.811-.663 13.68 4.511 14.914 15.923h28.362c-.5-8.724 4.425-15.259 11.836-15.923a13.183 13.183 0 019.726 2.82c4.226 3.384 5.533 7.961 5.1 13.076h4.79v-28.5zm-84.41 17.634a29.483 29.483 0 01-4.724.08v.06a21.9 21.9 0 01-4.432-.086c-.7-.153-1.214-1.241-1.811-1.9.624-.5 1.221-1.407 1.871-1.446a82.933 82.933 0 018.9 0 2.231 2.231 0 011.679 1.519 2.322 2.322 0 01-1.479 1.778zm29.623-19.817a13.7 13.7 0 01-13.813 13.7H22.179c-4.067 0-8.127.113-12.181-.08C9 42.049 8.061 41 7.1 40.41c.962-.663 1.911-1.778 2.886-1.8 7.523-.153 15.054-.066 22.557-.093 6.681-.04 10.748-4.02 10.827-10.655.053-4.85 0-9.706 0-14.556 0-1.513 0-3.364 1.99-3.165.63.06 1.493 2.057 1.533 3.191.207 5.039.139 10.094.092 15.143zM16.016 7.105c-2.163 0-3.357.5-4.246 2.581a85.5 85.5 0 01-6.1 12.506c-3.54 5.527-3.461 11.279-2.585 17.343.829 5.772 1.28 11.6 1.864 17.4a4.365 4.365 0 01-.239 1.234l-.8.046c-.239-1.944-.511-3.881-.717-5.805-.75-7.079-1.519-14.158-2.169-21.23a6.906 6.906 0 01.6-3.4c3.145-6.754 6.336-13.441 9.7-20.049A3.921 3.921 0 0114.151 5.9C26.432 5.785 38.7 5.818 51 5.838a13.547 13.547 0 012.077.318v.776c-.975.08-1.951.212-2.926.219-11.351.014-22.744.067-34.135-.046zM104.4 58.078h-.949V32.967H54.568v10.568a10.411 10.411 0 01-.186 3.231 2.468 2.468 0 01-1.791 1.466 2.322 2.322 0 01-1.539-1.679q-.189-7.125 0-14.257c0-1.672 1.015-2.15 2.654-1.579a11.312 11.312 0 003.649.663c14.6.046 29.119.033 43.681.033h3.4z' />
                                                <path d='M202.026 36.032c-.093-3.722-2.023-5.566-5.885-5.573q-29.855-.066-59.763 0c-4.107 0-5.971 1.99-6.011 6.057-.04 4.359-.113 8.724.066 13.076.04 1.028 1 2.024 1.546 3.032.544-1 1.526-1.99 1.566-3.005.179-4.352 0-8.724.159-13.076.04-.969 1.068-2.654 1.725-2.72 3.908-.259 7.835-.126 11.789-.126v6.769c-2.654 0-5.142-.139-7.583.073-.816.073-1.552 1.028-2.329 1.579.8.531 1.559 1.453 2.4 1.526 2.435.206 4.9.073 7.736.073 0 2.581-.133 4.843.066 7.072.073.869.9 1.665 1.387 2.495.511-.8 1.387-1.572 1.46-2.408.2-2.315.066-4.644.066-6.966h31.587c0 2.541-.119 4.9.073 7.238.06.75.935 1.433 1.433 2.143.491-.73 1.327-1.426 1.393-2.189.186-2.335.066-4.7.066-7.378a61.629 61.629 0 006.767-.093c1.154-.126 3.145-.922 3.178-1.493.106-2.322-1.951-1.612-3.317-1.672-2.143-.093-4.286 0-6.542 0v-6.8c3.682 0 7.132.1 10.569-.033 2.3-.086 3.224.723 3.151 3.078q-.192 6.542.04 13.076c.033.962.942 1.891 1.446 2.833.577-.869 1.632-1.725 1.665-2.654.209-4.619.202-9.283.096-13.934zm-20.142 4.193h-31.282v-6.342h31.281z' />
                                                <path d='M31.938 44.55a11.942 11.942 0 1011.925 11.842A11.942 11.942 0 0031.938 44.55z' />
                                                <path d='M87.004 44.557a11.942 11.942 0 1012.174 12 12.148 12.148 0 00-12.174-12z' />
                                            </svg>
                                            <span>GSE</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </React.Fragment>
        );

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._data.loading,
        // gseGeneralInfo: state._data.gseGeneralInfo,
        // gseGeneralInfoColumns: state.gseData.gseGeneralInfoColumns
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onGetAirports: () => dispatch(actions.getAirports()),
        // onToggleFieldCheckAll: (checkedAllFields) => dispatch(actions.toggleFieldCheckAll(checkedAllFields)),
        // onToggleFieldCheck: (index) => dispatch(actions.toggleFieldCheck(index))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
