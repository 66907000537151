import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import axios from 'axios';
import L from 'leaflet';
import { Map, TileLayer, ScaleControl, FeatureGroup, Popup, ZoomControl, Polygon, CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// import { GestureHandling } from 'leaflet-gesture-handling';
// import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import FullscreenControl from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/dist/styles.css';
import { CoordinatesControl } from 'react-leaflet-coordinates';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import classes from './Admin.module.css';
import * as actions from '../../store/actions/index';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimaryInput2 from '../../components/UI/PrimaryInput/PrimaryInput2';
import PrimarySelect from '../../components/UI/PrimarySelect/PrimarySelect';
import DataTable from '../../components/DataTable/DataTable';
import Modal from '../../components/UI/Modal/Modal';
import Loader from '../../components/UI/Loader/Loader';
import Block from '../../components/Block/Block';
import AdminEditAirportLocation from './AdminEditAirportLocation';
import countryList from 'react-select-country-list';

const API_KEY = 'pk.eyJ1IjoiZ2lrYXBhIiwiYSI6ImNpenpjdXNsNjAwMnkzMnJtcGx2aDRpZTAifQ.R3IwSWcHkImDLa8vlHbmYA';

// Fix marker icon not showing
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

class AdminEditAirport extends Component {
    state = {
        showModal: false,
        name: '',
        shortName: '',
        city: '',
        country: '',
        action: null,
        selectedData: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
    };

    componentDidMount() {
        // L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        if (this.props.selectedAirport && this.props.selectedAirport.id) {
            this.props.onGetAirportLocations(this.props.selectedAirport);
        } else {
            this.props.onGetAirportLocationsSuccess([]); // This is for when adding new airport
        }
    }

    updateLocalState = (property, value) => {
        this.setState({ [property]: value });
    };

    prepareSaveAirport = () => {
        const invalidNum = document.getElementsByClassName('Mui-error').length;
        if (invalidNum > 0) {
            Swal.fire({
                type: 'error',
                text: 'Please fill in all the mandatory fields',
            });
            return;
        }

        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The airport will be updated',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                let postData = { ...this.props.selectedAirport };

                for (const property in postData) {
                    if (this.state[property] && this.state[property] !== '') postData[property] = this.state[property];
                }
                delete postData.tableData;
                if (!postData.id) postData.id = 0;

                this.props.onSaveAirport(postData);
            },
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The Airport was updated',
                });
            }
        });
    };

    getLocationsFields = () => {
        let tableFields = [
            { title: 'id', field: 'id', defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null },
            { title: 'Name', field: 'name', defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null },
            { title: 'Lat', field: 'lat', defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null },
            { title: 'Lon', field: 'lon', defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null },
            {
                title: 'Type',
                field: 'locationType',
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
                render: (rowData) => {
                    let locationType = '';
                    const found = this.props.ddLists.locationTypes.find((x) => x.value == rowData.locationType);
                    if (found) {
                        locationType = found.label;
                    }
                    return locationType;
                },
            },
            // {title: "Status", field: "active", render: rowData => rowData.active === "1" ? "Active" : "Not active"},
        ];

        return tableFields;
    };

    addLocation = () => {
        // const center = this.mapRef.leafletElement.getCenter();
        // const selectedData = {
        //     id: 0,
        //     airportId: this.props.selectedAirport.id,
        //     name: '',
        //     lat: center.lat,
        //     lon: center.lng,
        // };

        const selectedData = {
            id: 0,
            airportId: this.props.selectedAirport.id,
            name: '',
            lat: 0,
            lon: 0,
            locationType: 0,
            area: [],
        };

        this.setState({ selectedData: selectedData, showModal: true, action: 'new' });
    };

    editLocation = (rowData) => {
        const selectedData = { ...rowData };
        this.setState({ selectedData: selectedData, showModal: true, action: 'edit' });
    };

    prepareSaveLocation = () => {
        const invalidNum = document.getElementsByClassName('Mui-error').length;
        if (invalidNum > 0) {
            Swal.fire({
                type: 'error',
                text: 'Please fill in all the mandatory fields',
            });
            return;
        }
        if (!this.state.selectedData.area || this.state.selectedData.area.length === 0) {
            Swal.fire({
                type: 'error',
                text: 'Please draw a polygon with the location area',
            });
            return;
        }

        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The location will be saved',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                const postData = {
                    id: this.state.selectedData.id,
                    airportId: this.props.selectedAirport.id,
                    airport: this.props.selectedAirport,
                    name: this.state.selectedData.name,
                    lat: 0,
                    lon: 0,
                    area: this.state.selectedData.area,
                    locationType: this.state.selectedData.locationType,
                };

                this.props.onSaveLocation(postData);
            },
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The location was saved',
                });
                this.setState({ showModal: false, action: null, selectedData: null });
            }
        });
    };

    prepareDeleteLocation = (rowData) => {
        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The location with id: ' + rowData.id + ' will be deleted.',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteLocation(this.props.selectedAirport, rowData.id);
            },
        });
    };

    updateCoords = (coords) => {
        let selectedData = { ...this.state.selectedData };
        // selectedData.lat = latlng.lat;
        // selectedData.lon = latlng.lng;
        selectedData.lat = 0;
        selectedData.lon = 0;
        selectedData.area = coords;

        this.setState({ selectedData: selectedData });
    };
    updateLocationProperty = (property, value) => {
        let selectedData = { ...this.state.selectedData };
        selectedData[property] = value;

        this.setState({ selectedData: selectedData });
    };

    zoomToLocation = (location) => {
        if (location.area) {
            this.mapRef.leafletElement.fitBounds(location.area);
        } else {
            this.mapRef.leafletElement.setView([location.lat, location.lon], 20);
        }
    };

    getPopup = (location) => {
        let locationType = '';

        const found = this.props.ddLists.locationTypes.find((x) => x.value == location.locationType);
        if (found) {
            locationType = found.label;
        }

        const popup = (
            <div className={classes.PopupWrapper}>
                <div className={classes.PopupDetails}>
                    <div className={classes.PopupRow}>
                        <span>ID:</span>
                        <span>{location.id}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Name:</span>
                        <span>{location.name}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Latitude:</span>
                        <span>{location.lat}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Longitude:</span>
                        <span>{location.lon}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Type:</span>
                        <span>{locationType}</span>
                    </div>
                </div>
                <div className={classes.PopupEditButton}>
                    <div className={classes.PopupButtonWrapper}>
                        <PrimaryButton clicked={() => this.editLocation(location)}>
                            <EditIcon />
                            Edit
                        </PrimaryButton>
                    </div>
                    <div className={classes.PopupButtonWrapper}>
                        <PrimaryButton danger clicked={() => this.prepareDeleteLocation(location)}>
                            <DeleteIcon />
                            Delete
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        );

        return popup;
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}></div>
                <div className={classes.PageTitle}>
                    <h1>
                        {this.props.selectedAirport && this.props.selectedAirport.id
                            ? `Edit ${this.props.selectedAirport.shortName} Airport`
                            : 'Add new airport'}
                    </h1>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </React.Fragment>
        );

        const countryOptions = countryList().getData();

        if (this.props.loading === false) {
            page = (
                <React.Fragment>
                    <div className={classes.FiltersSpace}></div>
                    <div className={classes.PageTitle}>
                        <h1>
                            {this.props.selectedAirport && this.props.selectedAirport.id
                                ? `Edit ${this.props.selectedAirport.shortName} Airport`
                                : 'Add new airport'}
                        </h1>
                        <div className={classes.MarginLeftAuto}>
                            <div className={classes.ActionButtonsWrapper}>
                                <PrimaryButton clicked={() => this.prepareSaveAirport()}>
                                    <FontAwesomeIcon icon={faSave} />
                                    Save
                                </PrimaryButton>
                                <div className={classes.CancelButton} onClick={() => this.props.onUpdateAdminState('selectedAirport', null)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.PageContent}>
                        <div className={classes.PageInner}>
                            <Scrollbars style={{ width: '100%', height: '100%' }}>
                                <div className={classes.ScrollInner}>
                                    <Block title='Information'>
                                        <div className={classes.AirportDetails}>
                                            <div className={classes.InputWrapper}>
                                                <PrimaryInput2
                                                    label='Name *'
                                                    type='text'
                                                    defaultValue={this.props.selectedAirport.name}
                                                    blur={(e) => this.updateLocalState(e.target.name, e.target.value)}
                                                    name='name'
                                                    mandatory
                                                />
                                            </div>
                                            <div className={classes.InputWrapper}>
                                                <PrimaryInput2
                                                    label='Short name *'
                                                    type='text'
                                                    defaultValue={this.props.selectedAirport.shortName}
                                                    blur={(e) => this.updateLocalState(e.target.name, e.target.value)}
                                                    name='shortName'
                                                    mandatory
                                                />
                                            </div>
                                            <div className={classes.InputWrapper}>
                                                <PrimarySelect
                                                    label='Country *'
                                                    options={countryOptions}
                                                    defaultValue={this.props.selectedAirport.country}
                                                    changed={(value) => this.updateLocalState('country', value)}
                                                    mandatory
                                                />
                                            </div>
                                            <div className={classes.InputWrapper}>
                                                <PrimaryInput2
                                                    label='City'
                                                    type='text'
                                                    defaultValue={this.props.selectedAirport.city ? this.props.selectedAirport.city : ''}
                                                    blur={(e) => {
                                                        this.updateLocalState(e.target.name, e.target.value);
                                                        this.props.onUpdateMapCenter(this.props.selectedAirport.country, e.target.value);
                                                    }}
                                                    name='city'
                                                />
                                            </div>
                                        </div>
                                    </Block>

                                    {this.props.selectedAirport && this.props.selectedAirport.id ? (
                                        <Block title='Locations'>
                                            <div className={classes.MapWrapper}>
                                                <Map
                                                    className={classes.Map}
                                                    ref={(ref) => (this.mapRef = ref)}
                                                    maxZoom={18}
                                                    bounds={this.props.fgBounds ? this.props.fgBounds : null}
                                                    // whenReady={this.removeBounds}
                                                    center={this.props.mapCenter}
                                                    boundsOptions={{ padding: [50, 50] }}
                                                    zoom={13}
                                                    zoomControl={false}
                                                    // gestureHandling={true}
                                                >
                                                    <TileLayer attribution={''} url={'http://{s}.tile.osm.org/{z}/{x}/{y}.png'} />

                                                    <FeatureGroup ref={(ref) => (this['fg_locations'] = ref)}>
                                                        {this.props.rawAirportLocations.map((rawLocation) => {
                                                            if (rawLocation.area && rawLocation.area.length) {
                                                                return (
                                                                    <Polygon key={`location_${rawLocation.id}`} positions={rawLocation.area} color={'#008f40'}>
                                                                        <Popup>{this.getPopup(rawLocation)}</Popup>
                                                                    </Polygon>
                                                                );
                                                            } else if (rawLocation.lon > 0 || rawLocation.lat > 0) {
                                                                return (
                                                                    <CircleMarker
                                                                        key={`locationPoints_${rawLocation.id}`}
                                                                        center={{
                                                                            lat: rawLocation.lat,
                                                                            lng: rawLocation.lon,
                                                                        }}
                                                                        radius={5}
                                                                    >
                                                                        <Popup>{this.getPopup(rawLocation)}</Popup>
                                                                    </CircleMarker>
                                                                );
                                                            } else return null;
                                                        })}
                                                    </FeatureGroup>

                                                    <CoordinatesControl coordinates='decimal' position='bottomleft' />
                                                    <ScaleControl position='bottomleft' />
                                                    <FullscreenControl position='topright' />
                                                    <ZoomControl position='topright' />
                                                </Map>
                                            </div>

                                            <PrimaryButton clicked={() => this.addLocation()}>
                                                <FontAwesomeIcon icon={faPlus} />
                                                Add
                                            </PrimaryButton>

                                            <div className={classes.PaddingBottom20}>
                                                <DataTable
                                                    data={this.props.rawAirportLocations}
                                                    columns={this.getLocationsFields()}
                                                    editClicked={(rowData) => this.editLocation(rowData)}
                                                    deleteClicked={(rowData) => this.prepareDeleteLocation(rowData)}
                                                    zoomClicked={(rowData) => this.zoomToLocation(rowData)}
                                                    tableOptions={this.state.tableOptions}
                                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                                />
                                            </div>
                                        </Block>
                                    ) : null}
                                </div>
                            </Scrollbars>
                        </div>
                    </div>

                    <Modal show={this.state.showModal} modalClosed={() => this.setState({ showModal: false, action: null, selectedData: null })} full>
                        {this.state.action === 'edit' || this.state.action === 'new' ? (
                            <AdminEditAirportLocation
                                selectedData={this.state.selectedData}
                                selectedAirportLocations={this.props.selectedAirportLocations}
                                rawAirportLocations={this.props.rawAirportLocations}
                                ddLists={this.props.ddLists}
                                mapCenter={this.props.mapCenter}
                                updateCoords={(coords) => this.updateCoords(coords)}
                                updateLocationProperty={(property, value) => this.updateLocationProperty(property, value)}
                                saveLocation={() => this.prepareSaveLocation()}
                                cancel={() => this.setState({ showModal: false, action: null, selectedData: null })}
                            />
                        ) : null}
                    </Modal>
                </React.Fragment>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.loggedInUser,
        loading: state._system.loading,
        selectedAirport: state.admin.selectedAirport,
        selectedAirportLocations: state.admin.selectedAirportLocations,
        rawAirportLocations: state.admin.rawAirportLocations,
        ddLists: state._data.ddLists,
        mapCenter: state.admin.mapCenter,
        fgBounds: state.admin.fgBounds,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetAirportLocations: (selectedAirport) => dispatch(actions.getAirportLocations(selectedAirport)),
        onGetAirportLocationsSuccess: (locations) => dispatch(actions.getAirportLocationsSuccess(locations)),
        onUpdateAdminState: (property, value) => dispatch(actions.updateAdminState(property, value)),
        onSaveAirport: (airportItem) => dispatch(actions.saveAirport(airportItem)),
        onSaveLocation: (locationItem) => dispatch(actions.saveLocation(locationItem)),
        onDeleteLocation: (airport, locationId) => dispatch(actions.deleteLocation(airport, locationId)),
        // onGetLists: () => dispatch(actions.getLists()),
        onUpdateMapCenter: (country, city) => dispatch(actions.updateMapCenter(country, city)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditAirport);
