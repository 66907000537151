import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import Backdrop from '../UI/Backdrop/Backdrop';
import Button from '../UI/PrimaryButton/PrimaryButton';
import SideFilter from './SideFilter';
import classes from './SideFilters.module.css';
import * as actions from '../../store/actions/index';

class TripFilters extends Component {
    state={
        show:false
    }

    componentDidMount () {
        
    }

    toggleStateVariable = (variable) => {
        const current = this.state[variable];
        this.setState({[variable]:!current});
    }

    getTripsIcons = () => {
        const iconSet = {
            bound: <div className={classes.TripTypeIcon} style={{borderColor:"red"}}></div>, 
            notBound: <div className={classes.TripTypeIcon} style={{borderColor:"#404B58"}}></div>
        }

        return iconSet
    }
    getFlightsIcons = () => {
        const iconSet = {
            arrival: <div className={classes.SvgTypeIcon}><svg style={{fill:"#4f93cf"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"/></svg></div>, 
            departure: <div className={classes.SvgTypeIcon}><svg style={{fill:"#2aae65"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"/></svg></div>, 
        }

        return iconSet
    }
    // getStartingPointsIcons = () => {
    //     const iconSet = {
    //         true: <div className={classes.StartingTypeIcon} style={{background:"red"}}></div>, 
    //         false: <div className={classes.StartingTypeIcon} style={{background:"#404B58"}}></div>
    //     }

    //     return iconSet
    // }
    // getEndingPointsIcons = () => {
    //     const iconSet = {
    //         true: <svg className={classes.EndingTypeIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" fill="red" /></svg>, 
    //         false: <svg className={classes.EndingTypeIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" fill="#404B58"/></svg>
    //     }

    //     return iconSet
    // }

    render() {
        const tripsIcons = this.getTripsIcons();
        const flightsIcons = this.getFlightsIcons();
        // const startingPointsIcons = this.getStartingPointsIcons();
        // const endingPointsIcons = this.getEndingPointsIcons();

        return (
            <div className={this.state.show ? [classes.SideFilters, classes.Show].join(' ') : classes.SideFilters}>
                <div className={classes.FiltersRow}>
                    <Button clicked={() => this.toggleStateVariable("show")}>
                        <FontAwesomeIcon icon={faFilter} />
                        <span>Filters</span>
                    </Button>
                </div>
                
                <Backdrop show={this.state.show} clicked={() => this.setState({show:false})}/>

                <div className={classes.SideFiltersInner}>
                    <div className={classes.Title}>
                        <h2>Map Filters</h2>
                        <div className={classes.CancelButton} onClick={() => this.setState({show:false})}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>

                    <div className={classes.FiltersWrapper}>
                        <SideFilter 
                            label="Trips" 
                            type="Multilist" 
                            options={[{value:"bound", label:"Bound"}, {value:"notBound", label:"Not bound"}]} 
                            titleProperty="label"
                            valueProperty="trips"
                            value="value"
                            isHidden={false}
                            updateFilters={(updatedFilters) => this.props.onUpdateMapFilters(updatedFilters)}
                            filters={this.props.mapFilters}
                            icons={tripsIcons}
                        />
                        <SideFilter 
                            label="Show starting points" 
                            type="Radio" 
                            options={[{value:"true", label:"True"}, {value:"false", label:"False"}]} 
                            titleProperty="label"
                            valueProperty="starting_points"
                            value="value"
                            isHidden={false}
                            updateFilters={(updatedFilters) => this.props.onUpdateMapFilters(updatedFilters)}
                            filters={this.props.mapFilters}
                            // icons={startingPointsIcons}
                        />
                        <SideFilter 
                            label="Show ending points" 
                            type="Radio" 
                            options={[{value:"true", label:"True"}, {value:"false", label:"False"}]} 
                            titleProperty="label"
                            valueProperty="ending_points"
                            value="value"
                            isHidden={false}
                            updateFilters={(updatedFilters) => this.props.onUpdateMapFilters(updatedFilters)}
                            filters={this.props.mapFilters}
                            // icons={endingPointsIcons}
                        />
                        <SideFilter 
                            label="Flights" 
                            type="Multilist" 
                            options={[{value:"arrival", label:"Arrival"}, {value:"departure", label:"Departure"}]} 
                            titleProperty="label"
                            valueProperty="flights"
                            value="value"
                            isHidden={false}
                            updateFilters={(updatedFilters) => this.props.onUpdateMapFilters(updatedFilters)}
                            filters={this.props.mapFilters}
                            icons={flightsIcons}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loggedInUser: state.login.loggedInUser,
        loading:state._system.loading,
        airports:state._data.airports,
        ddLists:state._data.ddLists,
        filtersDdlists:state._data.filtersDdlists,
        gseGeneralInfo: state._data.gseGeneralInfo,
        lastLocations: state._data.lastLocations,
        serviceRecords: state._data.serviceRecords,
        tiresRecords: state._data.tiresRecords,
        batteriesRecords: state._data.batteriesRecords,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateFilteredData: (data, dataType) => dispatch(actions.updateFilteredData(data, dataType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripFilters);