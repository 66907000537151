import React from 'react';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';

import classes from './PrimarySlider.module.css';

const PrimarySlider= (props) => {
    let elementClasses = [classes.FormControl];

    if (props.fullWidth) elementClasses.push(classes.FullWidth);
    else if (props.narrow) elementClasses.push(classes.Narrow);
    else if (props.veryNarrow) elementClasses.push(classes.VeryNarrow);

    return (
        <FormControl className={elementClasses.join(' ')} disabled={props.disabled}>
            <Slider
                className={classes.Slider}
                min={props.min}
                max={props.max}
                step={props.step || 1}
                value={props.value || 1}
                onChange={props.changed}
                valueLabelDisplay="auto"
                marks={props.marks}
            />
        </FormControl>
    )
}

export default PrimarySlider;