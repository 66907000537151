import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faExclamationTriangle, faTimes, faGasPump, faToolbox, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import Edit from '@material-ui/icons/Edit';

import PrintId from '../../components/PrintId/PrintId';
import classes from './GseInfo.module.css';
import TabInfo from './TabInfo';
import TabRefueling from './TabRefueling';
import TabStatistics from './TabStatistics';
import TabService from './TabService';
import TabNotifications from './TabNotifications';
import TabTracking from './TabTracking';
import Loader from '../../components/UI/Loader/Loader';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import * as actions from '../../store/actions/index';
import SideFilters from '../../components/Filters/SideFilters';

class GseInfo extends Component {
    state = {
        tab: 0,
    };

    componentDidMount() {
        this.props.onGetGseDetails(this.props.selectedGseId);
    }

    componentWillUnmount() {
        this.props.onResetGseInfo();
    }

    render() {
        let contentId;
        if (this.state.tab === 0) contentId = 'TabInfoPrint';
        else if (this.state.tab === 1) contentId = 'TabRefuelingPrint';
        else if (this.state.tab === 2) contentId = 'TabServicePrint';
        else if (this.state.tab === 3) contentId = 'TabStatisticsPrint';
        else if (this.state.tab === 4) contentId = 'TabTrackingPrint';
        else if (this.state.tab === 5) contentId = 'TabNotificationsPrint';

        let page = (
            <Aux>
                <div className={classes.FiltersSpace}>
                    <SideFilters />

                    <div className={classes.PrintPage}>
                        <PrintId contentId={contentId} loading={this.props.loading} />
                    </div>
                </div>
                <div className={classes.PageTitle}>
                    <h1>{this.props.gseDetails ? this.props.gseDetails.generalInfo.info : ''}</h1>
                    <div className={classes.ActionButtonsWrapper}>
                        {this.props.loggedInUser && this.props.loggedInUser.role_id < 20 ? (
                            <PrimaryButton clicked={() => this.props.gotoEdit()}>
                                <Edit />
                                Edit GSE
                            </PrimaryButton>
                        ) : null}

                        <div className={classes.CancelButton} onClick={() => this.props.onCancel()}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                </div>
                <div className={classes.PageContent}>
                    <Tabs defaultIndex={0} onSelect={(index) => this.setState({ tab: index })}>
                        <TabList>
                            <Tab>
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 58.01 46.536'>
                                    <g transform='translate(-0.025 -0.012)'>
                                        <path
                                            d='M364.158,538.565a4.084,4.084,0,0,1,2.017.555l22.555,10.871c.59.285,1.291.472,1.352,1.391s-.645,1.2-1.207,1.544q-13.962,8.483-27.917,16.981a4.406,4.406,0,0,1-4.759.157c-7.586-4.071-15.2-8.078-22.8-12.115-.574-.3-1.291-.536-1.291-1.477s.7-1.151,1.291-1.469l28.447-15.718A4.479,4.479,0,0,1,364.158,538.565Z'
                                            transform='translate(-332.083 -538.55)'
                                        />
                                        <path
                                            d='M358.474,609.6a4.246,4.246,0,0,1-2.31-.69q-11.323-6.028-22.653-12.037a7.571,7.571,0,0,1-.726-.4,1.212,1.212,0,0,1-.513-1.694c.371-.78.845-.495,1.368-.214q5.912,3.168,11.833,6.3c3.411,1.807,6.844,3.561,10.229,5.432a5.341,5.341,0,0,0,5.841-.2q13.3-8.142,26.638-16.186c.52-.315,1.2-1.289,1.759-.1.529,1.125-.287,1.537-.968,1.945l-28.355,17.21A3.583,3.583,0,0,1,358.474,609.6Z'
                                            transform='translate(-332.083 -570.224)'
                                        />
                                        <path
                                            d='M358.4,619.218a5.983,5.983,0,0,1-2.307-.75l-22.511-11.954a5.882,5.882,0,0,1-.865-.5,1.228,1.228,0,0,1-.5-1.574c.3-.712.81-.637,1.355-.345,2.336,1.252,4.679,2.482,7.015,3.726,4.97,2.624,9.958,5.248,14.9,7.951a5.581,5.581,0,0,0,6.131-.157q13.211-8.138,26.5-16.119c.549-.33,1.291-1.406,1.855-.068.471,1.125-.381,1.5-1.049,1.912L360.852,618.4A5.236,5.236,0,0,1,358.4,619.218Z'
                                            transform='translate(-332.08 -576.192)'
                                        />
                                        <path
                                            d='M358.357,628.764a3.98,3.98,0,0,1-2.139-.634L333.419,616a3.49,3.49,0,0,1-.968-.63,1.113,1.113,0,0,1-.329-.721,1.169,1.169,0,0,1,.213-.778c.323-.487.645-.5,1.113-.221.613.375,1.265.675,1.9,1.008,6.673,3.55,13.366,7.059,20.007,10.676a5.844,5.844,0,0,0,6.425-.18c8.755-5.405,17.561-10.7,26.361-16.01.565-.341,1.291-1.409,1.852-.056.468,1.125-.432,1.526-1.075,1.916q-14.108,8.569-28.223,17.127A3.9,3.9,0,0,1,358.357,628.764Z'
                                            transform='translate(-332.088 -582.222)'
                                        />
                                        <path
                                            d='M358.635,600.027a5.043,5.043,0,0,1-2.472-.678q-11.323-6.039-22.656-12.067a7.115,7.115,0,0,1-.723-.375,1.22,1.22,0,0,1-.513-1.7c.371-.783.875-.491,1.371-.225,2.433,1.3,4.866,2.624,7.3,3.9,4.84,2.56,9.681,5.027,14.466,7.7a5.749,5.749,0,0,0,6.418-.225c8.81-5.424,17.661-10.758,26.5-16.119.5-.3,1.081-1.125,1.613-.049.481,1-.142,1.5-.791,1.874l-28.652,17.4A4.333,4.333,0,0,1,358.635,600.027Z'
                                            transform='translate(-332.083 -564.24)'
                                        />
                                    </g>
                                </svg>
                                Info
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faGasPump} />
                                Refueling
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faToolbox} />
                                Service
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faChartBar} />
                                Statistics
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faBinoculars} />
                                Tracking
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                Notifications
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <Loader show={this.props.loading} />
                                <TabInfo
                                    gseDetails={this.props.gseDetails}
                                    ddLists={this.props.ddLists}
                                    airportsOptions={this.props.airportsOptions}
                                    maintenance={this.props.maintenance}
                                    refueling={this.props.refueling}
                                />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <Loader show={this.props.loading} />
                                <TabRefueling gseDetails={this.props.gseDetails} refueling={this.props.refueling} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <Loader show={this.props.loading} />
                                <TabService gseDetails={this.props.gseDetails} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <Loader show={this.props.loading} />
                                <TabStatistics gseDetails={this.props.gseDetails} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <Loader show={this.props.loading} />
                                <TabTracking gseDetails={this.props.gseDetails} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <Loader show={this.props.loading} />
                                <TabNotifications gseDetails={this.props.gseDetails} />
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </Aux>
        );
        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        gseDetails: state._data.gseDetails,
        refueling: state._data.refueling,
        maintenance: state._data.maintenance,
        loading: state._system.loading,
        loggedInUser: state.login.loggedInUser,
        ddLists: state._data.ddLists,
        airportsOptions: state._data.airportsOptions,
        selectedGseId: state.gseData.selectedGseId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetGseDetails: (gseId) => dispatch(actions.getGseDetails(gseId)),
        onResetGseInfo: () => dispatch(actions.resetGseInfo()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GseInfo);
