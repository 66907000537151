import React, { Component } from 'react';
import { connect } from 'react-redux';
import {DropzoneAreaBase} from 'material-ui-dropzone'
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import classes from './Dropzone.module.css';
import * as actions from '../../store/actions/index';

class Dropzone extends Component{
    handleAdd = newFiles => {
        for (let file of newFiles){
            file.fileType = this.props.fileType;
        }

        const files = [...this.props.filesToUpload];  
        let updatedFiles = [...files, ...newFiles];

        if (this.props.maxFiles && this.props.maxFiles === 1){
            updatedFiles = [...newFiles]; 
        }

        this.props.onUpdateFilesToUpload(updatedFiles, this.props.fileType);
    };

    handleDelete = index => {
        let relevantFiles = [...this.props.filesToUpload.filter(f => f.fileType === this.props.fileType)];
        relevantFiles.splice(index, 1)

        const restFiles = [...this.props.filesToUpload.filter(f => f.fileType !== this.props.fileType)];
        const updatedFiles = [...relevantFiles, ...restFiles];

        this.props.onUpdateFilesToUpload(updatedFiles, this.props.fileType);
    };

    render () {
        return (
            <div>
                <div className={classes.root}>
                    <DropzoneAreaBase
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        previewText={this.props.label}
                        acceptedFiles={this.props.acceptedFiles}
                        maxFileSize={10000000}
                        filesLimit={this.props.maxFiles}
                        showAlerts={['error']}
                        // fileObjects={files}
                        //onAdd={files => this.props.onUpdateFilesToUpload(files, "add", this.props.maxFiles, this.props.fileType)}
                        onAdd={this.handleAdd}
                        // onDelete={this.handleDelete}
                        // onDrop={e => {
                        //     console.log(e)
                        //     e.forEach(item => props.addFiles(item));
                        // }}
                    />
                </div>

                <div className={classes.FilesList}>
                    {this.props.filesToUpload.filter(f => f.fileType === this.props.fileType).length
                        ?<h5 className={classes.UploadFilesTitle}>Files to upload</h5>
                        :null
                    }
                    {
                        this.props.filesToUpload.filter(f => f.fileType === this.props.fileType).map((fileItem, index) => {
                            return (
                                <div className={classes.UploadItemsWrapper} key={ index }>
                                    <div className={classes.FileItem}>
                                        {fileItem.file.name}

                                        {fileItem.uploadProgress >= 0
                                            ? null
                                            : <div className={classes.FileActions}>
                                                <DeleteOutline className={classes.DeleteIcon} onClick={() => this.handleDelete(index)} />
                                            </div>
                                        }
                                        
                                    </div>
                                    {fileItem.uploadProgress >= 0
                                        ?<div className={classes.FileProgressBarBackground}>
                                            <div className={classes.FileProgressBar} style={{width:fileItem.uploadProgress + "%"}}></div>
                                        </div>
                                        :null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
            
        )
    }
};

const mapStateToProps = state => {
    return {
        filesToUpload: state._data.filesToUpload
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateFilesToUpload: (files, fileType) => dispatch(actions.updateFilesToUpload(files, fileType)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropzone);