import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faCalendar, faBell } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import PrimaryDateRangePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDateRangePicker';
import PrimaryDatePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatePicker';
import PrimaryYearPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearPicker';
import PrimaryYearMonthPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearMonthPicker';
import TabAlerts from './TabAlerts';
import TabWarnings from './TabWarnings';
import TabUpdates from './TabUpdates';
import Loader from '../../components/UI/Loader/Loader';
import classes from './Notifications.module.css';
import * as actions from '../../store/actions/index';

class Notifications extends Component {
    state = {
        selectedPeriod: 'month',
        customPeriod: [null, null],
        selectedYear: null,
        selectedMonth: new Date(),
        selectedDay: null,
    };

    componentDidMount() {
        this.props.onGetNotifications();
    }

    getFilteredNotifications = (type) => {
        let filteredNotifications = [];

        if (this.props.notifications) {
            for (let notification of this.props.notifications) {
                const isOfType = notification.type === type ? true : false;
                if (!isOfType) continue;

                let checkDate = notification.datetimeHappened;
                if (!notification.datetimeHappened) checkDate = notification.datetimeNot;

                let isInPeriod;
                if (this.state.selectedPeriod === 'day') {
                    isInPeriod = moment(checkDate).isSame(moment(this.state.selectedDay), 'day');
                } else if (this.state.selectedPeriod === 'month') {
                    isInPeriod = moment(checkDate).isSame(moment(this.state.selectedMonth), 'month');
                    // isInPeriod = moment().diff(checkDate, 'days') <= 30 ? true : false;
                } else if (this.state.selectedPeriod === 'year') {
                    isInPeriod = moment(checkDate).isSame(moment(this.state.selectedYear), 'year');
                    // isInPeriod = moment().diff(checkDate, 'days') <= 365 ? true : false;
                } else if (this.state.selectedPeriod === 'range' && this.state.customPeriod[0] && this.state.customPeriod[1]) {
                    const startDate = moment(this.state.customPeriod[0]);
                    startDate.set({ hour: 0, minute: 0, second: 0 });
                    const endDate = moment(this.state.customPeriod[1]);
                    endDate.set({ hour: 23, minute: 59, second: 59 });
                    const compareDate = moment(checkDate);

                    isInPeriod = compareDate.isBetween(startDate, endDate, null, []);
                }

                if (isInPeriod) filteredNotifications.push(notification);
            }
        }

        return filteredNotifications;
    };

    handleSelectedCustomPeriod = (value) => {
        if (!value[0]) return;
        this.setState({ selectedPeriod: 'range', selectedYear: null, selectedMonth: null, selectedDay: null, customPeriod: value });
    };
    handleSelectedYear = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'year', selectedYear: value, selectedMonth: null, selectedDay: null, customPeriod: [null, null] });
    };
    handleSelectedMonth = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'month', selectedYear: null, selectedMonth: value, selectedDay: null, customPeriod: [null, null] });
    };
    handleSelectedDay = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'day', selectedYear: null, selectedMonth: null, selectedDay: value, customPeriod: [null, null] });
    };

    render() {
        const alertNotifications = this.getFilteredNotifications('error');
        const warningNotifications = this.getFilteredNotifications('warning');
        const updateNotifications = this.getFilteredNotifications('update');

        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}>
                    <span>Select timeframe</span>
                    <div className={this.state.selectedPeriod === 'day' ? [classes.CustomRange, classes.Selected].join(' ') : classes.CustomRange}>
                        <PrimaryDatePicker
                            value={this.state.selectedDay}
                            dateChanged={(value) => this.handleSelectedDay(value)}
                            maxDate={new Date()}
                            placeholder='Day'
                            // isClearable
                        />
                    </div>
                    <div className={this.state.selectedPeriod === 'month' ? [classes.CustomRange, classes.Selected].join(' ') : classes.CustomRange}>
                        <PrimaryYearMonthPicker
                            value={this.state.selectedMonth}
                            dateChanged={(value) => this.handleSelectedMonth(value)}
                            maxDate={new Date()}
                            placeholder='Month'
                            // isClearable
                        />
                    </div>
                    <div className={this.state.selectedPeriod === 'year' ? [classes.CustomRange, classes.Selected].join(' ') : classes.CustomRange}>
                        <PrimaryYearPicker
                            value={this.state.selectedYear}
                            dateChanged={(value) => this.handleSelectedYear(value)}
                            maxDate={new Date()}
                            placeholder='Year'
                            // isClearable
                        />
                    </div>
                    <div className={this.state.selectedPeriod === 'range' ? [classes.CustomRange, classes.Selected].join(' ') : classes.CustomRange}>
                        <PrimaryDateRangePicker
                            value={this.state.customPeriod}
                            dateChanged={(value) => this.handleSelectedCustomPeriod(value)}
                            maxDate={new Date()}
                            placeholder='Custom range'
                        />
                    </div>
                </div>
                <div className={classes.PageTitle}>
                    <h1>Notifications</h1>
                </div>

                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                    <Tabs>
                        <TabList>
                            <Tab>
                                <FontAwesomeIcon icon={faBell} />
                                Errors
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faExclamation} />
                                Warnings
                            </Tab>
                            <Tab>
                                <FontAwesomeIcon icon={faCalendar} />
                                Updates
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabAlerts notifications={alertNotifications} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabWarnings notifications={warningNotifications} />
                            </div>
                        </TabPanel>

                        <TabPanel>
                            <div className={classes.PageInner}>
                                <TabUpdates notifications={updateNotifications} />
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </React.Fragment>
        );

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._data.loading,
        notifications: state._data.notifications,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetNotifications: () => dispatch(actions.getNotifications('')),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
