import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import classes from './FlightInfo.module.css';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import { formatDateTime } from '../../store/utilities';

class TabFlights extends Component {
    state = {
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
    };

    getFlightsFields = () => {
        let tableFields = [
            {
                title: 'Date & time (From)',
                field: 'dateFrom',
                render: (rowData) => formatDateTime(rowData.dateFrom),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Date & time (To)',
                field: 'dateTo',
                render: (rowData) => formatDateTime(rowData.dateTo),
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Flight number',
                field: 'flightNumber',
                render: (rowData) => rowData.flightNumber,
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Sector type',
                field: 'sectorType',
                render: (rowData) => rowData.sectorType,
                defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Origin airport name',
                field: 'origAirportName',
                render: (rowData) => rowData.origAirportName,
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Destination airport name',
                field: 'destAirportName',
                render: (rowData) => rowData.destAirportName,
                defaultSort: this.state.tableOptions.defaultSort.column === 5 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Boarding method',
                field: 'boardingMethod',
                render: (rowData) => rowData.boardingMethod,
                defaultSort: this.state.tableOptions.defaultSort.column === 6 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Gate no',
                field: 'gateNo',
                render: (rowData) => rowData.gateNo,
                defaultSort: this.state.tableOptions.defaultSort.column === 7 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Bridge no',
                field: 'bridgeNo',
                render: (rowData) => rowData.bridgeNo,
                defaultSort: this.state.tableOptions.defaultSort.column === 8 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Aircraft parking position',
                field: 'parkingPos',
                render: (rowData) => rowData.parkingPos,
                defaultSort: this.state.tableOptions.defaultSort.column === 9 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Baggage system ID',
                field: 'baggageAystemId',
                render: (rowData) => rowData.baggageAystemId,
                defaultSort: this.state.tableOptions.defaultSort.column === 10 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Pax departed',
                field: 'paxDeparted',
                render: (rowData) => rowData.paxDeparted,
                defaultSort: this.state.tableOptions.defaultSort.column === 11 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Pax arrived',
                field: 'paxArrived',
                render: (rowData) => rowData.paxArrived,
                defaultSort: this.state.tableOptions.defaultSort.column === 12 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Type of flight',
                field: 'typeFlight',
                render: (rowData) => rowData.typeFlight,
                defaultSort: this.state.tableOptions.defaultSort.column === 13 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Bags pieces',
                field: 'bagsPcs',
                render: (rowData) => rowData.bagsPcs,
                defaultSort: this.state.tableOptions.defaultSort.column === 14 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Flight cargo',
                field: 'flightCargo',
                render: (rowData) => rowData.flightCargo,
                defaultSort: this.state.tableOptions.defaultSort.column === 15 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        const flightsFields = this.getFlightsFields();

        let page = (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={classes.ScrollInner}>
                    <Loader show={this.props.loading} />
                </div>
            </Scrollbars>
        );

        if (this.props.loading === false) {
            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner}>
                        <DataTable
                            data={this.props.flights}
                            columns={flightsFields}
                            withExport
                            withSearch
                            noActions
                            tableOptions={this.state.tableOptions}
                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                        />
                    </div>
                </Scrollbars>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
    };
};

export default connect(mapStateToProps, null)(TabFlights);
