import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import Swal from 'sweetalert2';

import classes from './GseInfo.module.css';
import DataTable from '../../components/DataTable/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faPlus, faClock, faRuler, faRedo } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimaryInput from '../../components/UI/PrimaryInput/PrimaryInput';
import Modal from '../../components/UI/Modal/Modal';
import * as actions from '../../store/actions/index';
import { formatDateTime } from '../../store/utilities';
import { dateTimeToISO } from '../../store/utilities';

const CONFIG = require('../../reactConfig.json');

class TabRefueling extends Component {
    state = {
        showModal: false,
        selectedData: null,
        previousRowData: null,
        showResetModal: false,
        resetMilesValue: 0,
        resetTimeValue: 0,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
    };

    componentDidMount() {
        this.props.onGetResets(this.props.gseDetails.id);
    }

    prepareDeleteRefueling = (rowData) => {
        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'A refueling item will be deleted',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteRefueling(rowData.id, rowData.gseId);
            },
        });
    };

    getRefuelingFields = () => {
        let tableFields = [
            {
                title: 'Date',
                field: 'dateTime',
                render: (rowData) => formatDateTime(rowData.dateTime),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Amount (l)',
                render: (rowData) => (rowData.refuelingAmount === 0 ? '' : rowData.refuelingAmount),
                defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Current mileage (km)',
                render: (rowData) => (rowData.mileage === 0 ? '' : rowData.mileage),
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Total mileage (km)',
                render: (rowData) => (rowData.cumMileage === 0 ? '' : rowData.cumMileage),
                defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Current operational time (h)',
                render: (rowData) => (rowData.operationalTime === 0 ? '' : rowData.operationalTime),
                defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Total operational time (h)',
                render: (rowData) => (rowData.cumOperationalTime === 0 ? '' : rowData.cumOperationalTime),
                defaultSort: this.state.tableOptions.defaultSort.column === 5 ? this.state.tableOptions.defaultSort.order : null,
            },
            // {title: "Employee", field: "employee"},
            // {title: "Fuel price (€)", render: rowData => rowData.fuelPrice === 0 ? "" : rowData.fuelPrice},
        ];

        return tableFields;
    };

    getResetsFields = () => {
        let tableFields = [
            // {title: "ID", field: "id"},
            { title: 'Reset type', field: 'resetType', render: (rowData) => (rowData.resetType === 1 ? 'Mileage meter' : 'Time meter') },
            { title: 'Date', field: 'dateTime', render: (rowData) => formatDateTime(rowData.dateTime) },
            { title: 'New meter initial value', field: 'totalMileage' },
        ];

        return tableFields;
    };

    addRefueling = () => {
        let now = new Date();
        now = dateTimeToISO(now);

        const user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
        const previousRefullingObj = this.props.refueling[0]; // The refuelling is sorted Desc, so the first object is always the last entered

        const selectedData = {
            dateTime: now,
            employee: user.id,
            fuelPrice: 0,
            gseId: this.props.gseDetails.id,
            refuelingAmount: 0,
            mileage: 0,
            operationalTime: 0,
            airportId: this.props.gseDetails.generalInfo.airportId,
        };

        this.setState({ selectedData: selectedData, showModal: true, previousRowData: previousRefullingObj });
    };

    editRefueling = (data) => {
        const selectedData = { ...data };

        const rowIndex = this.props.refueling.findIndex((r) => r.id === data.id);
        let previousRowData = null;

        if (rowIndex < this.props.refueling.length) {
            previousRowData = this.props.refueling[rowIndex + 1];
        }

        this.setState({ selectedData: selectedData, showModal: true, previousRowData: previousRowData });
    };

    onEditSelectedData = (property, value) => {
        let selectedData = { ...this.state.selectedData };

        selectedData[property] = value !== '' ? parseFloat(value) : 0;

        this.setState({
            selectedData: selectedData,
        });
    };

    prepareSaveRefueling = () => {
        const invalidNum = document.getElementsByClassName('Mui-error').length;
        if (invalidNum > 0) {
            Swal.fire({
                type: 'error',
                text: 'Please fill in all the mandatory fields',
            });
            return;
        }

        const fuelTankCapacity = this.props.gseDetails.engineSpecs.fuelTankCapacity;
        const refuelingAmount = this.state.selectedData.refuelingAmount;
        if ((fuelTankCapacity > 0 && refuelingAmount > fuelTankCapacity) || refuelingAmount > 999.99) {
            Swal.fire({
                type: 'error',
                text: 'The refuelling amount cannot be more than the tank capacity.',
            });
            return;
        }

        let txt = 'The refuelling will be updated';
        let swalType = 'warning';

        const lastMileage = this.state.previousRowData.mileage;
        const currentMileage = this.state.selectedData.mileage;
        const lastOperationalTime = this.state.previousRowData.operationalTime;
        const currentOperationalTime = this.state.selectedData.operationalTime;

        if (
            lastMileage &&
            lastOperationalTime &&
            currentMileage &&
            currentOperationalTime &&
            lastMileage > currentMileage &&
            lastOperationalTime > currentOperationalTime
        ) {
            txt = 'The mileage input and the operational time input are less than the previous records.';
            swalType = 'question';
        } else if (lastMileage && currentMileage && lastMileage > currentMileage) {
            txt = 'The mileage input is less than the previous mileage.';
            swalType = 'question';
        } else if (lastOperationalTime && currentOperationalTime && lastOperationalTime > currentOperationalTime) {
            txt = 'The operational time input is less than the previous operational time.';
            swalType = 'question';
        }

        Swal.fire({
            type: swalType,
            title: 'Are you sure?',
            text: txt,
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onUpdateRefueling(this.state.selectedData, this.props.gseDetails.id);
                this.setState({ showModal: false });

                if (swalType === 'question') {
                    // TODO: Add alert
                }
            },
        });
    };

    updateStateProperty = (property, value) => {
        if (value === '') {
            value = 0;
        }

        this.setState({ [property]: value });
    };

    prepareResetMeter = (resetType, resetTypeDescription) => {
        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The ' + resetTypeDescription + ' meter will be reset',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                let now = new Date();
                now = dateTimeToISO(now);

                let totalMileage = Number(this.state.resetMilesValue);

                if (resetType === 2) {
                    totalMileage = Number(this.state.resetTimeValue);
                }

                const payload = {
                    id: 0,
                    resetType: resetType,
                    gseId: this.props.gseDetails.id,
                    dateTime: now,
                    totalMileage: totalMileage,
                };

                this.props.onResetMeter(payload);
                this.setState({ showResetModal: false, resetMilesValue: 0, resetTimeValue: 0 });
            },
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The meter was reset',
                });
            }
        });
    };

    prepareDeleteReset = (rowData) => {
        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'A reset item will be deleted',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteReset(rowData.id, rowData.gseId);
            },
        });
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        let page = null;
        const refuelingFields = this.getRefuelingFields();
        const resetsFields = this.getResetsFields();

        if (this.props.refueling) {
            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner} id='TabRefuelingPrint'>
                        <div className={classes.MultipleButtonsWrapper}>
                            <PrimaryButton clicked={() => this.addRefueling()}>
                                <FontAwesomeIcon icon={faPlus} />
                                Add
                            </PrimaryButton>

                            {this.props.loggedInUser && this.props.loggedInUser.role_id < 20 ? (
                                <div className={classes.ResetButtons}>
                                    <PrimaryButton color='secondary' clicked={() => this.setState({ showResetModal: true })}>
                                        <FontAwesomeIcon icon={faRedo} />
                                        Reset meters
                                    </PrimaryButton>
                                </div>
                            ) : null}
                        </div>

                        <DataTable
                            data={this.props.refueling}
                            columns={refuelingFields}
                            editClicked={(rowData) => this.editRefueling(rowData)}
                            deleteClicked={(rowData) => this.prepareDeleteRefueling(rowData)}
                            withExport
                            withSearch
                            tableOptions={this.state.tableOptions}
                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                        />

                        <Modal show={this.state.showModal} modalClosed={() => this.setState({ showModal: false })}>
                            <div className={classes.ModalButtonsWrapper}>
                                <PrimaryButton clicked={() => this.prepareSaveRefueling()}>
                                    <FontAwesomeIcon icon={faSave} />
                                    Save
                                </PrimaryButton>
                                <div className={classes.CancelButton} onClick={() => this.setState({ showModal: false })}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>

                            {this.state.selectedData ? (
                                <div className={classes.EditRowsWrapper}>
                                    <div className={classes.DisabledFieldsWrapper}>
                                        <div className={classes.EditRow}>
                                            <PrimaryInput
                                                label='Date/time'
                                                type='text'
                                                value={formatDateTime(this.state.selectedData.dateTime)}
                                                name='dateTime'
                                                disabled
                                            />
                                        </div>

                                        <div className={classes.EditRow}>
                                            <PrimaryInput label='User' type='text' value={this.state.selectedData.employee} name='dateTime' disabled />
                                        </div>

                                        <div className={classes.EditRow}>
                                            <PrimaryInput
                                                label='Previous refueling'
                                                type='text'
                                                value={this.state.previousRowData ? formatDateTime(this.state.previousRowData.dateTime) : ''}
                                                name='previousRefuelling'
                                                disabled
                                            />
                                        </div>

                                        <div className={classes.EditRow}>
                                            <PrimaryInput label='Fuel type' type='text' value={this.state.selectedData.fuelType} name='fuelType' disabled />
                                        </div>

                                        <div className={classes.EditRow}>
                                            <PrimaryInput
                                                label='Last operational time (h)'
                                                type='text'
                                                value={this.state.previousRowData ? this.state.previousRowData.operationalTime : ''}
                                                name='lastOperationalTime'
                                                disabled
                                            />
                                        </div>

                                        <div className={classes.EditRow}>
                                            <PrimaryInput
                                                label='Last mileage (km)'
                                                type='text'
                                                value={this.state.previousRowData ? this.state.previousRowData.mileage : ''}
                                                name='lastMileage'
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className={classes.EditRow}>
                                        <PrimaryInput
                                            label='Amount (l)'
                                            type='number'
                                            value={this.state.selectedData.refuelingAmount === 0 ? '' : this.state.selectedData.refuelingAmount}
                                            changed={(e) => this.onEditSelectedData(e.target.name, e.target.value)}
                                            name='refuelingAmount'
                                            min='1'
                                            invalid={this.state.selectedData.refuelingAmount == 0 || this.state.selectedData.refuelingAmount === ''}
                                        />
                                    </div>

                                    <div className={classes.EditRow}>
                                        <PrimaryInput
                                            label='Fuel price (€)'
                                            type='number'
                                            value={this.state.selectedData.fuelPrice === 0 ? '' : this.state.selectedData.fuelPrice}
                                            changed={(e) => this.onEditSelectedData(e.target.name, e.target.value)}
                                            name='fuelPrice'
                                            step='.01'
                                        />
                                    </div>

                                    <div className={classes.EditRow}>
                                        <PrimaryInput
                                            label='Current mileage (km)'
                                            type='number'
                                            value={this.state.selectedData.mileage === 0 ? '' : this.state.selectedData.mileage}
                                            changed={(e) => this.onEditSelectedData(e.target.name, e.target.value)}
                                            name='mileage'
                                            min='0'
                                            invalid={
                                                (this.state.selectedData.mileage == 0 ||
                                                    this.state.selectedData.mileage === '' ||
                                                    this.state.selectedData.mileage === null) &&
                                                (this.state.selectedData.operationalTime == 0 ||
                                                    this.state.selectedData.operationalTime === '' ||
                                                    this.state.selectedData.operationalTime === null)
                                            }
                                        />
                                    </div>

                                    <div className={classes.EditRow}>
                                        <PrimaryInput
                                            label='Operational time (h)'
                                            type='number'
                                            value={this.state.selectedData.operationalTime === 0 ? '' : this.state.selectedData.operationalTime}
                                            changed={(e) => this.onEditSelectedData(e.target.name, e.target.value)}
                                            name='operationalTime'
                                            min='0'
                                            invalid={
                                                (this.state.selectedData.mileage == 0 ||
                                                    this.state.selectedData.mileage === '' ||
                                                    this.state.selectedData.mileage === null) &&
                                                (this.state.selectedData.operationalTime == 0 ||
                                                    this.state.selectedData.operationalTime === '' ||
                                                    this.state.selectedData.operationalTime === null)
                                            }
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </Modal>

                        <Modal show={this.state.showResetModal} modalClosed={() => this.setState({ showResetModal: false })}>
                            <div className={classes.ModalButtonsWrapper}>
                                <div className={classes.CancelButton} onClick={() => this.setState({ showResetModal: false })}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>

                            <div className={classes.ResetsWrapper}>
                                <div className={classes.ResetRow}>
                                    <PrimaryButton clicked={() => this.prepareResetMeter(1, 'Mileage')}>
                                        <FontAwesomeIcon icon={faRuler} />
                                        Reset mileage meter
                                    </PrimaryButton>
                                    <span>with initial value</span>
                                    <div className={classes.ResetInputWrapper}>
                                        <PrimaryInput
                                            type='number'
                                            min={0}
                                            value={this.state.resetMilesValue.toString()}
                                            changed={(e) => this.updateStateProperty('resetMilesValue', e.target.value)}
                                            name='resetMilesValue'
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className={classes.ResetRow}>
                                    <PrimaryButton clicked={() => this.prepareResetMeter(2, 'Time')}>
                                        <FontAwesomeIcon icon={faClock} />
                                        Reset time meter
                                    </PrimaryButton>
                                    <span>with initial value</span>
                                    <div className={classes.ResetInputWrapper}>
                                        <PrimaryInput
                                            type='number'
                                            min={0}
                                            value={this.state.resetTimeValue.toString()}
                                            changed={(e) => this.updateStateProperty('resetTimeValue', e.target.value)}
                                            name='resetTimeValue'
                                            fullWidth
                                        />
                                    </div>
                                </div>
                            </div>
                            <DataTable data={this.props.gseResets} columns={resetsFields} deleteClicked={(rowData) => this.prepareDeleteReset(rowData)} />
                        </Modal>
                    </div>
                </Scrollbars>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._data.loading,
        loggedInUser: state.login.loggedInUser,
        gseResets: state._data.gseResets,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDeleteRefueling: (refuelingId, gseId) => dispatch(actions.deleteRefueling(refuelingId, gseId)),
        onUpdateRefueling: (refuelingData, gseId) => dispatch(actions.updateRefueling(refuelingData, gseId)),
        onGetResets: (gseId) => dispatch(actions.getResets(gseId)),
        onResetMeter: (payload) => dispatch(actions.resetMeter(payload)),
        onDeleteReset: (resetId, gseId) => dispatch(actions.deleteReset(resetId, gseId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabRefueling);
