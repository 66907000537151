import { withLeaflet, MapControl } from "react-leaflet";
import L from "leaflet";

import classes from './Legend.module.css';


class MapInfo extends MapControl {
  componentDidMount() {
    const { map } = this.props.leaflet;
    this.leafletElement.addTo(map);
  }

  getColor = d => {
    return d === "GPS"
      ? "rgba(38, 133, 111, 1)"
      : d === "GPS (inactive)"
      ? "rgba(255, 82, 82, 1)"
      : d === "Bluetooth"
      ? "rgba(38, 133, 111, 1)"
      : d === "Bluetooth (inactive)"
      ? "rgba(255, 82, 82, 1)"
      : "rgba(38, 133, 111, 1)"
  };
  getIcon = d => {
    return d === "GPS"
      ? '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>'
      : d === "GPS (inactive)"
      ? '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>'
      : d === "Bluetooth"
      ? '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M196.48 260.023l92.626-103.333L143.125 0v206.33l-86.111-86.111-31.406 31.405 108.061 108.399L25.608 368.422l31.406 31.405 86.111-86.111L145.84 512l148.552-148.644-97.912-103.333zm40.86-102.996l-49.977 49.978-.338-100.295 50.315 50.317zM187.363 313.04l49.977 49.978-50.315 50.316.338-100.294z"/></svg>'
      : d === "Bluetooth (inactive)"
      ? '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M196.48 260.023l92.626-103.333L143.125 0v206.33l-86.111-86.111-31.406 31.405 108.061 108.399L25.608 368.422l31.406 31.405 86.111-86.111L145.84 512l148.552-148.644-97.912-103.333zm40.86-102.996l-49.977 49.978-.338-100.295 50.315 50.317zM187.363 313.04l49.977 49.978-50.315 50.316.338-100.294z"/></svg>'
      : '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>'
  };

  createLeafletElement() {
    const MapInfo = L.Control.extend({
      onAdd: map => {
        this.panelDiv = L.DomUtil.create("div", classes.Legend);
        let labels = [];
        const deviceTypes = ["GPS", "GPS (inactive)", "Bluetooth", "Bluetooth (inactive)"];
        let deviceType;

        for (let i = 0; i < deviceTypes.length; i++) {
          deviceType = deviceTypes[i]; 

          labels.push(
            '<div class=' + classes.LegendItem + '>' + 
              '<div class=' + classes.LegendIcon + ' style="background:' + this.getColor(deviceType) + '">' + 
                this.getIcon(deviceType) +
              '</div> ' + 
                deviceType + 
            '</div>'
          );
        }
          
        let legendTitle = '<h4>Legend</h4>';
        this.panelDiv.innerHTML = legendTitle + '<div class=' + classes.LegendItems + '>' + labels.join(' ') + '</div>';

        L.DomEvent.disableClickPropagation(this.panelDiv);
        L.DomEvent.on(this.panelDiv, 'click', L.DomEvent.stopPropagation);

        return this.panelDiv;
      }
    });
    return new MapInfo({ position: "bottomright" });
  }  
}

export default withLeaflet(MapInfo);