import proj4 from 'proj4';

export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    };
};

export const checkInputValidity = (value, rules) => {
    let isValid = true;

    if (!value || value.trim() === "") {
        return false;
    }

    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^-?\d+\.?\d*$/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isEGSAx) {
        isValid = value >= 95000 && value <= 1050000 && isValid
    }

    if (rules.isEGSAy) {
        isValid = value >= 3700000 && value <= 4700000 && isValid
    }

    if (rules.isWGSx) {
        isValid = value >= -180 && value <= 180 && isValid
    }

    if (rules.isWGSy) {
        isValid = value >= -90 && value <= 90 && isValid
    }

    if (rules.isOver0) {
        isValid = value > 0
    }

    return isValid;
}

export const changeCoordinateSystem = (sourceCoordSystemEpsg, sourceCoordSystemProj4, destCoordSystemEpsg, destCoordSystemProj4, coords) => {
    // Define the coordinate system (proj4 has wgs84 available but not many more)
    proj4.defs([
        [
            sourceCoordSystemEpsg,
            sourceCoordSystemProj4
        ],
        [
            destCoordSystemEpsg,
            destCoordSystemProj4
        ]
      ]);

    const obSource = new proj4.Proj(sourceCoordSystemEpsg);
    const obDest = new proj4.Proj(destCoordSystemEpsg);
    const obResult = new proj4.toPoint(coords);

    return proj4.transform(obSource, obDest, obResult);
}

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return hexToRgb(color);
}

// This function generates vibrant, "evenly spaced" colours (i.e. no clustering). This is ideal for creating easily distinguishable vibrant markers in Google Maps and other apps.
// Adam Cole, 2011-Sept-14
// HSV to RBG adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
export const getRainbowColor = (numOfSteps, step) => {
    let r, g, b;
    const h = step / numOfSteps;
    const i = ~~(h * 6);
    const f = h * 6 - i;
    const q = 1 - f;
    switch(i % 6){
        case 0: r = 1; g = f; b = 0; break;
        case 1: r = q; g = 1; b = 0; break;
        case 2: r = 0; g = 1; b = f; break;
        case 3: r = 0; g = q; b = 1; break;
        case 4: r = f; g = 0; b = 1; break;
        case 5: r = 1; g = 0; b = q; break;
        default: r = 1; g = f; b = 0;
    }
    const color = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
    return color;
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

// function rgbToHex(r, g, b) {
//     return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
// }

export function arrayMove(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
        oldIndex += arr.length;
    }
    while (newIndex < 0) {
        newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

    //return arr;
};

export const formatDate = (dateItem) => {
    let dateStr;
    
    if (typeof(dateItem) === "string"){
        dateStr = new Date(dateItem).toLocaleString('el-GR').split(', ')[0];

    } else if (dateItem instanceof Date){
        dateStr = dateItem.toLocaleString('el-GR').split(', ')[0];
    }

    return dateStr;
};

export const formatDateTime = (dateTimeItem) => {
    let dateStr;

    if (typeof(dateTimeItem) === "string"){
        dateStr = new Date(dateTimeItem).toLocaleString('el-GR', { hour12: false });

    } else if (dateTimeItem instanceof Date){
        dateStr = dateTimeItem.toLocaleString('el-GR', { hour12: false });
    }

    return dateStr;
};

export const dateTimeToISO = (dateTimeItem) => {
    let dateStr;

    if (typeof(dateTimeItem) === "string"){
        dateTimeItem = new Date(dateTimeItem)
    }

    const D = ("0" + dateTimeItem.getDate()).slice(-2);
    const M = ("0" + (dateTimeItem.getMonth() + 1)).slice(-2);
    const Y = dateTimeItem.getFullYear();

    const h = ("0" + dateTimeItem.getHours()).slice(-2);
    const m = ("0" + dateTimeItem.getMinutes()).slice(-2);
    const s = ("0" + dateTimeItem.getSeconds()).slice(-2);

    dateStr = Y + "-" + M + "-" + D + "T" + h + ":" + m + ":" + s;

    return dateStr;
};

export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
  }