import React from 'react';

import classes from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

const modal = (props) => {
    let elClasses = [classes.Modal]

    if (props.show){
        elClasses.push(classes.Show)
    }
    if (props.full){
        elClasses.push(classes.Full)
    } 

    return (
        <div className={elClasses.join(' ')}>
            <Backdrop show={props.show} clicked={props.modalClosed} />
            <div className={classes.ModalInner}>
                {props.children}
            </div>
        </div>
        
    )
}

export default modal;