import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import DataTable from '../../components/DataTable/DataTable';

import { formatDateTime } from '../../store/utilities';
import classes from './Notifications.module.css';

class TabUpdates extends Component {
    state = {};

    getTableFields = () => {
        let tableFields = [
            { title: 'Parameter', field: 'title' },
            {
                title: 'Date',
                field: 'datetimeHappened',
                render: (rowData) => (rowData.datetimeHappened ? formatDateTime(rowData.datetimeHappened) : formatDateTime(rowData.datetimeNot)),
            },
            { title: 'GSE', field: 'gse' },
            { title: 'Airport', field: 'airport' },
            { title: 'Type', field: 'type' },
            { title: 'Description', field: 'description', width: 350 },
        ];

        return tableFields;
    };

    render() {
        const tableFields = this.getTableFields();

        let page = (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={classes.ScrollInner}>
                    <DataTable data={this.props.notifications} columns={tableFields} withExport withSearch noActions />
                </div>
            </Scrollbars>
        );

        return page;
    }
}

export default TabUpdates;
