import * as actionTypes from './actionTypes';
import axios_db from '../../axios-db';
import Swal from 'sweetalert2';

import { getGsePreFilter } from './_data';

const CONFIG = require('../../reactConfig.json');

export const showLoginRegister = (showType) => {
    return {
        type: actionTypes.SHOW_LOGIN_REGISTER,
        showType: showType,
    };
};

export const hideLoginRegister = () => {
    return {
        type: actionTypes.HIDE_LOGIN_REGISTER,
    };
};

export const checkLogin = (loginInfo) => {
    return (dispatch) => {
        axios_db
            .post('checkLogin.php', loginInfo)
            .then((response) => {
                const data = response.data;
                if (data.token !== undefined && data.refreshToken !== undefined) {
                    localStorage.setItem(CONFIG.userTokenName, JSON.stringify(data.token));
                    localStorage.setItem(CONFIG.userTokenRefreshName, JSON.stringify(data.refreshToken));

                    const currentTime = new Date().getTime() / 1000; // time in seconds

                    dispatch(getGsePreFilter());
                    dispatch(authSuccess(data.token));
                    dispatch(checkAuthTimeout(data.token.expireAt - currentTime));
                }
            })
            .catch((error) => {
                let txt = 'Error: Something went wrong. Please try again.';
                if (error.response && error.response.data && error.response.data.message) {
                    txt = error.response.data.message;
                }
                Swal.fire({ type: 'error', text: txt });
            });
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(refreshAuthToken());
        }, (expirationTime - 60) * 1000); // Run refresh before the expiration time of the user token
    };
};

export const refreshAuthToken = () => {
    return (dispatch) => {
        axios_db
            .post('refreshToken.php', { refreshToken: JSON.parse(localStorage.getItem(CONFIG.userTokenRefreshName)) })
            .then((response) => {
                if (response.data !== undefined && response.data !== null) {
                    const currentTime = new Date().getTime() / 1000; // time in seconds

                    localStorage.setItem(CONFIG.userTokenName, JSON.stringify(response.data));
                    dispatch(checkAuthTimeout(response.data.expireAt - currentTime));
                } else {
                    dispatch(logout());
                }
            })
            .catch((error) => {
                dispatch(logout());
            });
    };
};

export const authCheckState = () => {
    return (dispatch) => {
        const user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));

        if (!user) {
            dispatch(logout());
        } else {
            const currentTime = new Date().getTime() / 1000; // time in seconds

            if (user.expireAt > currentTime) {
                dispatch(authSuccess(user));
                dispatch(checkAuthTimeout(user.expireAt - currentTime));
            } else {
                dispatch(logout());
            }
        }
    };
};

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: user,
    };
};

export const logout = () => {
    localStorage.removeItem(CONFIG.userTokenName);
    localStorage.removeItem(CONFIG.userTokenRefreshName);

    return {
        type: actionTypes.LOGOUT_SUCCESS,
    };
};

// export const logout = () => {
//     return dispatch => {
//         dispatch(logoutSuccess(CONFIG.userTokenName, CONFIG.userTokenRefreshName));

//         // Update database with the logout action and remove user from tbl_logged_users
//         axios_db.get( 'logOutUser.php')
//             .then( response => {})
//             .catch( error => {
//                 // Swal.fire({
//                 //     type: 'error',
//                 //     text: error.response.data.message,
//                 // })
//             });
//     }
// };

export const logoutSuccess = (userTokenName, userTokenRefreshName) => {
    localStorage.removeItem(userTokenName);
    localStorage.removeItem(userTokenRefreshName);

    return {
        type: actionTypes.LOGOUT_SUCCESS,
    };
};

export const registerUser = (userData) => {
    return (dispatch) => {
        dispatch(showLoadingSpinner());
        axios_db
            .post('register.php', userData)
            .then((response) => {
                dispatch(hideLoadingSpinner());
                // Swal.fire({
                //     type: 'success',
                //     text: response.data,
                // })
            })
            .catch((error) => {
                dispatch(hideLoadingSpinner());
                // Swal.fire({
                //     type: 'error',
                //     title: 'Υπήρξε κάποιο πρόβλημα.',
                //     text: error.response.data.message,
                // })
            });
    };
};

export const showLoadingSpinner = () => {
    return {
        type: actionTypes.SHOW_LOADING_SPINNER,
    };
};

export const hideLoadingSpinner = () => {
    return {
        type: actionTypes.HIDE_LOADING_SPINNER,
    };
};

export const accountActivation = (activationKey) => {
    return (dispatch) => {
        axios_db
            .post('accountActivation.php', { activationKey: activationKey })
            .then((response) => {
                dispatch(showConfirmation(response.data));
            })
            .catch((error) => {
                dispatch(showConfirmation(error.response.data.message));
            });
    };
};

export const showConfirmation = (confirmationMessage) => {
    return {
        type: actionTypes.SHOW_CONFIRMATION,
        confirmationMessage: confirmationMessage,
    };
};

export const sendResetPasswordEmail = (email, password) => {
    return (dispatch) => {
        axios_db
            .post('sendResetPasswordEmail.php', { email: email, password: password })
            .then((response) => {
                // Swal.fire({
                //     type: 'success',
                //     text: response.data,
                // })
            })
            .catch((error) => {
                // Swal.fire({
                //     type: 'error',
                //     title: 'Υπήρξε κάποιο πρόβλημα.',
                //     text: error.response.data.message,
                // })
            });
    };
};

export const updatePassword = (activationKey) => {
    return (dispatch) => {
        axios_db
            .post('updatePassword.php', { activationKey: activationKey })
            .then((response) => {
                dispatch(showConfirmation(response.data));
            })
            .catch((error) => {
                dispatch(showConfirmation(error.response.data.message));
            });
    };
};

export const getUserInfo = () => {
    return (dispatch) => {
        axios_db
            .post('getUserInfo.php')
            .then((response) => {
                dispatch(getUserInfoSuccess(response.data));
            })
            .catch((error) => {
                // Swal.fire({
                //     type: 'error',
                //     title: 'Υπήρξε κάποιο πρόβλημα.',
                //     text: error.response.data.message,
                // })
            });
    };
};

export const getUserInfoSuccess = (userInfo) => {
    return {
        type: actionTypes.GET_USER_INFO_SUCCESS,
        userInfo: userInfo,
    };
};

export const updateUserInfo = (newUserInfo) => {
    return (dispatch) => {
        axios_db
            .post('updateUserInfo.php', { newUserInfo: newUserInfo })
            .then((response) => {})
            .catch((error) => {
                // Swal.fire({
                //     type: 'error',
                //     title: 'Υπήρξε κάποιο πρόβλημα.',
                //     text: error.response.data.message,
                // })
            });
    };
};

export const updateUserPassword = (currentPassword, newPassword) => {
    return (dispatch) => {
        axios_db
            .post('updateUserPassword.php', { currentPassword: currentPassword, newPassword: newPassword })
            .then((response) => {
                // Swal.fire({
                //     type: 'success',
                //     text: 'Έγινε αλλαγή του password',
                // })
                dispatch(logout());
            })
            .catch((error) => {
                // Swal.fire({
                //     type: 'error',
                //     title: 'Υπήρξε κάποιο πρόβλημα.',
                //     text: error.response.data.message,
                // })
            });
    };
};

export const updateVariable = (variableName, variableValue) => {
    return {
        type: actionTypes.UPDATE_VARIABLE,
        variableName: variableName,
        variableValue: variableValue,
    };
};

export const getUserFromLocalStorage = () => {
    return {
        type: actionTypes.GET_USER_FROM_LOCAL_STORAGE,
    };
};
