import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

const navigationItems = (props) => (
    <React.Fragment>
        <div className={classes.MenuUnderline}></div>
        <ul className={classes.NavigationItems}>
            {props.user && props.user.role === 'Admin' ? (
                <NavigationItem
                    clicked={() => props.clicked('admin')}
                    title='Admin'
                    link='/admin'
                    location={['/admin', '/adminUsers', '/adminLists', '/adminAirports', '/adminTrackers', '/adminSims', '/adminSymbology']}
                ></NavigationItem>
            ) : null}

            <NavigationItem clicked={() => props.clicked('gseData')} title='GSE Data' link='/gseData' location={['/gseData']}></NavigationItem>

            <NavigationItem clicked={() => props.clicked('service')} title='GSE Service' link='/service' location={['/service']}></NavigationItem>

            <NavigationItem clicked={() => props.clicked('flightInfo')} title='Flight Info' link='/flightInfo' location={['/flightInfo']}></NavigationItem>

            <NavigationItem clicked={() => props.clicked('locationMap')} title='Map' link='/locationMap' location={['/locationMap']}></NavigationItem>

            <NavigationItem clicked={() => props.clicked('tripHistory')} title='Trip history' link='/tripHistory' location={['/tripHistory']}></NavigationItem>

            <NavigationItem
                clicked={() => props.clicked('statistics')}
                title='Statistics'
                link='/statistics'
                location={['/statistics', '/statisticsCompany', '/statisticsAirport', '/statisticsEquipment', '/statisticsEquipmentAirport', '/statisticsGse']}
            ></NavigationItem>

            <NavigationItem clicked={() => props.clicked('tracking')} title='Tracking' link='/tracking' location={['/tracking']}></NavigationItem>

            <NavigationItem
                clicked={() => props.clicked('notifications')}
                title='Notifications'
                link='/notifications'
                location={['/notifications']}
            ></NavigationItem>
        </ul>
    </React.Fragment>
);

export default navigationItems;
