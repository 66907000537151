import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';

import DataTable from '../../components/DataTable/DataTable';
import MyBarChart from '../../components/MyCharts/MyBarChart';
import MyLineChart from '../../components/MyCharts/MyLineChart';
import classes from './GseInfo.module.css';
import Block from '../../components/Block/Block';
import { formatDate } from '../../store/utilities';
import * as actions from '../../store/actions/index';

class TabStatistics extends Component {
    state = {
        timeframe: 'all',
        selectedYear: null,
        selectedGse: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: 2, order: 'desc' },
        },
    };

    componentDidMount() {
        this.props.onGetGseStatistics(this.props.gseDetails.id);
    }

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    getData = (type) => {
        if (!this.props.gseStatistics) return;

        if (this.state.timeframe === 'all') {
            return this.props.gseStatistics[type];
        } else if (this.state.timeframe === 'year') {
            let itemsPerMonth = {
                1: { dtMonth: `JAN-${this.state.selectedYear}`, val: null },
                2: { dtMonth: `FEB-${this.state.selectedYear}`, val: null },
                3: { dtMonth: `MAR-${this.state.selectedYear}`, val: null },
                4: { dtMonth: `APR-${this.state.selectedYear}`, val: null },
                5: { dtMonth: `MAY-${this.state.selectedYear}`, val: null },
                6: { dtMonth: `JUN-${this.state.selectedYear}`, val: null },
                7: { dtMonth: `JUL-${this.state.selectedYear}`, val: null },
                8: { dtMonth: `AUG-${this.state.selectedYear}`, val: null },
                9: { dtMonth: `SEP-${this.state.selectedYear}`, val: null },
                10: { dtMonth: `OCT-${this.state.selectedYear}`, val: null },
                11: { dtMonth: `NOV-${this.state.selectedYear}`, val: null },
                12: { dtMonth: `DEC-${this.state.selectedYear}`, val: null },
            };

            for (let i = 0; i < this.props.gseStatistics[type].length; i++) {
                const item = this.props.gseStatistics[type][i];
                const dtMonth = item.dtMonth;

                const year = moment(dtMonth, 'MMM-YYYY').format('yyyy');
                if (year == this.state.selectedYear) {
                    const monthIndex = moment(dtMonth, 'MMM-YYYY').format('M');
                    itemsPerMonth[monthIndex] = item;
                }
            }

            const itemsArr = Object.values(itemsPerMonth);

            return itemsArr;
        }
    };

    getLineData = (type) => {
        if (!this.props.gseStatistics) return;

        if (this.state.timeframe === 'all') {
            return this.props.gseStatistics[type];
        } else if (this.state.timeframe === 'year') {
            let yearItems = [];
            for (let i = 0; i < this.props.gseStatistics[type].length; i++) {
                const item = this.props.gseStatistics[type][i];
                const dt = item.dt;

                const year = moment(new Date(dt)).format('yyyy');
                if (year == this.state.selectedYear) {
                    yearItems.push(item);
                }
            }

            return yearItems;
        }
    };

    changeTimeframe = (timeframe) => {
        this.setState({ timeframe: timeframe });
    };

    changeYear = (year) => {
        this.setState({ selectedYear: year });
    };

    getBarFields = () => {
        let tableFields = [
            {
                title: 'Month',
                field: 'dtMonth',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Value',
                field: 'val',
                render: (rowData) => (rowData.val ? Math.round(rowData.val * 100) / 100 : rowData.val),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    getLineFields = () => {
        let tableFields = [
            {
                title: 'Date',
                field: 'dt',
                render: (rowData) => formatDate(rowData.dt),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Value',
                field: 'val',
                render: (rowData) => (rowData.val ? Math.round(rowData.val * 100) / 100 : rowData.val),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    render() {
        const barFields = this.getBarFields();
        const lineFields = this.getLineFields();
        const refuelingEqData = this.getData('refuelingEq');
        const refuelingEqFlightsData = this.getData('refuelingEqFlights');
        const opertTimeEqData = this.getData('opertTimeEq');
        const opertTimeEqFlightsData = this.getData('opertTimeEqFlights');
        const serviceRecordsData = this.getLineData('serviceRecords');
        const tyreRecordsData = this.getLineData('tyreRecords');
        const batteryRecordsData = this.getLineData('batteryRecords');
        const actualFCData = this.getLineData('actualFC');

        let page = null;

        if (this.props.gseStatistics) {
            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner} id='TabStatisticsPrint'>
                        <Block title='Refueling consumption'>
                            <div>
                                <MyBarChart
                                    data={refuelingEqData}
                                    years={this.props.gseStatisticsYears.refuelingEq}
                                    name='Refueling consumption'
                                    xDataKey='dtMonth'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Liters'
                                />
                            </div>

                            {refuelingEqData.length > 0 ? (
                                <DataTable
                                    data={refuelingEqData}
                                    columns={barFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>

                        <div className={classes.VerticalSpacer}></div>

                        <Block title='Actual fuel consumption'>
                            <div>
                                <MyLineChart
                                    data={actualFCData}
                                    refuelingData={refuelingEqData}
                                    operationalData={opertTimeEqData}
                                    years={this.props.gseStatisticsYears.actualFC}
                                    name='Actual fuel consumption'
                                    xDataKey='dt'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Liters / hour'
                                />
                            </div>

                            {actualFCData.length > 0 ? (
                                <DataTable
                                    data={actualFCData}
                                    columns={lineFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>

                        <div className={classes.VerticalSpacer}></div>

                        <Block title='Refueling consumption per flight'>
                            <div>
                                <MyBarChart
                                    data={refuelingEqFlightsData}
                                    years={this.props.gseStatisticsYears.refuelingEqFlights}
                                    name='Refueling consumption per flight'
                                    xDataKey='dtMonth'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Liters / flight'
                                />
                            </div>

                            {refuelingEqFlightsData.length > 0 ? (
                                <DataTable
                                    data={refuelingEqFlightsData}
                                    columns={barFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>

                        <div className={classes.VerticalSpacer}></div>

                        <Block title='Operational time'>
                            <div>
                                <MyBarChart
                                    data={opertTimeEqData}
                                    years={this.props.gseStatisticsYears.opertTimeEq}
                                    name='Operational time'
                                    xDataKey='dtMonth'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Hours'
                                />
                            </div>

                            {opertTimeEqData.length > 0 ? (
                                <DataTable
                                    data={opertTimeEqData}
                                    columns={barFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>

                        <div className={classes.VerticalSpacer}></div>

                        <Block title='Operational time per flight'>
                            <div>
                                <MyBarChart
                                    data={opertTimeEqFlightsData}
                                    years={this.props.gseStatisticsYears.opertTimeEqFlights}
                                    name='Operational time'
                                    xDataKey='dtMonth'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Hours / flight'
                                />
                            </div>

                            {opertTimeEqFlightsData.length > 0 ? (
                                <DataTable
                                    data={opertTimeEqFlightsData}
                                    columns={barFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>

                        <div className={classes.VerticalSpacer}></div>

                        <Block title='Service frequency'>
                            <div>
                                <MyLineChart
                                    data={serviceRecordsData}
                                    years={this.props.gseStatisticsYears.serviceRecords}
                                    name='Operational time between services'
                                    xDataKey='dt'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Hours'
                                />
                            </div>

                            {serviceRecordsData.length > 0 ? (
                                <DataTable
                                    data={serviceRecordsData}
                                    columns={lineFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>

                        <div className={classes.VerticalSpacer}></div>

                        <Block title='Tyre replacement frequency'>
                            <div>
                                <MyLineChart
                                    data={tyreRecordsData}
                                    years={this.props.gseStatisticsYears.tyreRecords}
                                    name='Operational time between tyre replacement'
                                    xDataKey='dt'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Hours'
                                />
                            </div>

                            {tyreRecordsData.length > 0 ? (
                                <DataTable
                                    data={tyreRecordsData}
                                    columns={lineFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>

                        <div className={classes.VerticalSpacer}></div>

                        <Block title='Battery replacement frequency'>
                            <div>
                                <MyLineChart
                                    data={batteryRecordsData}
                                    years={this.props.gseStatisticsYears.batteryRecords}
                                    name='Operational time between battery replacement'
                                    xDataKey='dt'
                                    valKey='val'
                                    changeYear={this.changeYear}
                                    changeTimeframe={this.changeTimeframe}
                                    timeframe={this.state.timeframe}
                                    selectedYear={this.state.selectedYear}
                                    withAverage
                                    yLabel='Hours'
                                />
                            </div>

                            {batteryRecordsData.length > 0 ? (
                                <DataTable
                                    data={batteryRecordsData}
                                    columns={lineFields}
                                    withExport
                                    withSearch
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                    noActions
                                />
                            ) : null}
                        </Block>
                    </div>
                </Scrollbars>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
        gseStatistics: state.statistics.gseStatistics,
        gseStatisticsYears: state.statistics.gseStatisticsYears,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetGseStatistics: (gseId) => dispatch(actions.getGseStatistics(gseId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabStatistics);
