import React from 'react';

import espa from '../../../assets/images/espa.png';
import classes from './Footer.module.css';

const footer = ( props ) => (
    <div className={classes.Footer}>
        <img src={espa} alt="espa logo" />
        <span>Copyright ©2021, ECOSERVE</span>
    </div>
);

export default footer;