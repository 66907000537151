import axios from 'axios';

const CONFIG = require('./reactConfig.json');

const fetchClient = () => {
    const defaultOptions = {
        baseURL: CONFIG.backend,
        crossDomain: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };
  
    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
        let token = "";
        if (user){
            token = user.token;
        }
        
        config.headers.Authorization =  token;
        return config;
    });

    return instance;
};
  
export default fetchClient();