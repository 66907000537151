import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import L from 'leaflet';
import { Map, TileLayer, ScaleControl, Polygon, FeatureGroup, ZoomControl, CircleMarker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
// import { EditControl } from 'react-leaflet-draw';
import 'react-leaflet-fullscreen/dist/styles.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
// import { GestureHandling } from 'leaflet-gesture-handling';
// import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import { CoordinatesControl } from 'react-leaflet-coordinates';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';

import classes from './Admin.module.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
// import PrimaryInput from '../../components/UI/PrimaryInput/PrimaryInput';
import PrimaryInput2 from '../../components/UI/PrimaryInput/PrimaryInput2';
import PrimarySelect from '../../components/UI/PrimarySelect/PrimarySelect';

const AdminEditAirportLocation = (props) => {
    // const editRef = useRef();
    const editLocationMap = useRef(null);
    const [editLayer, setEditLayer] = useState(null);
    const [map, setMap] = useState(null);
    const [editLayerIsLoaded, setEditLayerIsLoaded] = useState(false);
    // const [coords, setCoords] = useState({});

    // Init once
    useEffect(() => {
        // Load edit tools
        if (editLocationMap && editLocationMap.current) {
            const thisMap = editLocationMap.current.leafletElement;
            // thisMap.pm.setGlobalOptions({ allowSelfIntersection: false });
            thisMap.pm.disableGlobalEditMode();
            thisMap.pm.disableGlobalRemovalMode();
            thisMap.pm.addControls({
                position: 'topleft',
                drawCircle: false,
                drawPolyline: false,
                drawMarker: false,
                drawCircleMarker: false,
                drawRectangle: false,
                drawText: false,
                dragMode: false,
                cutPolygon: false,
                rotateMode: false,
            });

            // thisMap.addEventListener('mousemove', (e) => {
            //     _onMouseMove(e);
            // });

            setMap(thisMap);
        }
    }, [editLocationMap]);

    useEffect(() => {
        if (map) {
            map.on('pm:drawstart', (e) => {
                _onDrawStart(e);
            });
            map.on('pm:create', (e) => {
                setEditLayer(e.layer);

                _onCreate(e);
            });
            map.on('pm:remove', (e) => {
                _onDeleted(e);
            });
            if (editLayer) {
                editLayer.on('pm:update', (e) => {
                    _onCreate(e);
                });
            }

            map.eachLayer((layer) => {
                if (layer.pm) {
                    if (layer._pxBounds) {
                    } else {
                        layer.pm.enable({ allowEditing: false, allowRemoval: false });
                    }
                }
            });
        }
    }, [map, editLayer]);

    const onFeatureGroupAdd = (e) => {
        const bounds = e.target.getBounds();

        if (Object.keys(bounds).length !== 0) {
            editLocationMap.current.leafletElement.fitBounds(e.target.getBounds());
        }
    };

    const _onDrawStart = (e) => {
        if (editLayer) {
            editLayer.remove();
            props.updateCoords([]);
        }
    };

    const _onCreate = (e) => {
        const layer = e.layer;
        const geojson = layer.toGeoJSON();
        const coords = geojson.geometry.coordinates[0];
        let formatedCoords = [];

        coords.forEach((pair) => {
            formatedCoords.push({
                lat: pair[1],
                lon: pair[0],
            });
        });

        props.updateCoords(formatedCoords);
    };

    const _onDeleted = () => {
        setEditLayer(null);

        props.updateCoords([]);
    };

    // const _onMouseMove = (e) => {
    //     // setCoords({ lat: e.latlng.lat, lng: e.latlng.lng });
    // };

    const setEditableLayer = (location) => {
        if (editLayerIsLoaded) return;

        var polygon = L.polygon(location.area);
        if (editLocationMap && editLocationMap.current && editLocationMap.current.leafletElement) {
            editLocationMap.current.leafletElement.addLayer(polygon);
            setEditLayer(polygon);
            setEditLayerIsLoaded(true);
        }

        return null;
    };

    const getPopup = (location) => {
        let locationType = '';

        const found = props.ddLists.locationTypes.find((x) => x.value == location.locationType);
        if (found) {
            locationType = found.label;
        }

        const popup = (
            <div className={classes.PopupWrapper}>
                <div className={classes.PopupDetails}>
                    <div className={classes.PopupRow}>
                        <span>ID:</span>
                        <span>{location.id}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Name:</span>
                        <span>{location.name}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Type:</span>
                        <span>{locationType}</span>
                    </div>
                </div>
                {/* <div className={classes.PopupEditButton}>
                    <div className={classes.PopupButtonWrapper}>
                        <PrimaryButton clicked={() => props.editClicked(location)}>
                            <EditIcon />
                            Edit
                        </PrimaryButton>
                    </div>
                    <div className={classes.PopupButtonWrapper}>
                        <PrimaryButton danger clicked={() => props.deleteClicked(location)}>
                            <DeleteIcon />
                            Delete
                        </PrimaryButton>
                    </div>
                </div> */}
            </div>
        );
        return popup;
    };

    return (
        <div className={classes.AirportLocation}>
            <div className={classes.ModalButtonsWrapper}>
                <PrimaryButton clicked={() => props.saveLocation()}>
                    <FontAwesomeIcon icon={faSave} />
                    Save
                </PrimaryButton>
                <div className={classes.CancelButton} onClick={() => props.cancel()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>

            <div className={classes.EditLocationWrapper}>
                <div className={classes.MarginBottom10}>For the area of the location, please use the tools in the map to draw a polygon.</div>

                <div className={classes.LocationDetailsWrapper}>
                    <PrimaryInput2
                        label='Name *'
                        type='text'
                        defaultValue={props.selectedData && props.selectedData.name ? props.selectedData.name : ''}
                        blur={(e) => props.updateLocationProperty(e.target.name, e.target.value)}
                        name='name'
                        mandatory
                    />
                    <div className={classes.HorizontalSpacerHalf}></div>
                    {/* <PrimaryInput
                        label='Latitude *'
                        type='number'
                        value={props.selectedData && props.selectedData.lat ? props.selectedData.lat.toString() : ''}
                        changed={(e) => props.updateLocationProperty(e.target.name, e.target.value)}
                        name='lat'
                        mandatory
                    />
                    <div className={classes.HorizontalSpacerHalf}></div>
                    <PrimaryInput
                        label='Longitude *'
                        type='number'
                        value={props.selectedData && props.selectedData.lon ? props.selectedData.lon.toString() : ''}
                        changed={(e) => props.updateLocationProperty(e.target.name, e.target.value)}
                        name='lon'
                        mandatory
                    />
                    <div className={classes.HorizontalSpacerHalf}></div> */}
                    <PrimarySelect
                        label='Location type *'
                        options={props.ddLists.locationTypes}
                        defaultValue={props.selectedData && props.selectedData.locationType ? props.selectedData.locationType.toString() : ''}
                        changed={(value) => props.updateLocationProperty('locationType', value)}
                        mandatory
                    />
                </div>

                <div className={classes.MapWrapper}>
                    <Map
                        ref={editLocationMap}
                        className={classes.Map}
                        maxZoom={20}
                        center={props.mapCenter}
                        boundsOptions={{ padding: [50, 50] }}
                        zoom={13}
                        zoomControl={false}
                    >
                        <TileLayer attribution={''} url={'http://{s}.tile.osm.org/{z}/{x}/{y}.png'} />

                        <FeatureGroup onAdd={onFeatureGroupAdd}>
                            {props.rawAirportLocations.map((rawLocation) => {
                                if (rawLocation.lon > 0 || rawLocation.lat > 0) {
                                    return (
                                        <CircleMarker
                                            key={`locationPoints_${rawLocation.id}`}
                                            center={{
                                                lat: rawLocation.lat,
                                                lng: rawLocation.lon,
                                            }}
                                            radius={5}
                                        >
                                            <Popup>{getPopup(rawLocation)}</Popup>
                                        </CircleMarker>
                                    );
                                }
                            })}
                        </FeatureGroup>

                        <FeatureGroup onAdd={onFeatureGroupAdd}>
                            {props.selectedAirportLocations.map((location) => {
                                return location.id === props.selectedData.id ? (
                                    setEditableLayer(location)
                                ) : (
                                    <Polygon key={`location_${location.id}`} positions={location.area} color={'#008f40'}>
                                        <Popup>{getPopup(location)}</Popup>
                                    </Polygon>
                                );
                            })}
                        </FeatureGroup>

                        <CoordinatesControl coordinates='decimal' position='bottomleft' />
                        <ScaleControl position='bottomleft' />
                        <ZoomControl position='topright' />
                    </Map>
                </div>
            </div>
        </div>
    );
};

export default AdminEditAirportLocation;
