import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import MyPieChart from '../../components/MyCharts/MyPieChart';
import classes from './StatisticsAirport.module.css';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import PrimaryAutocomplete from '../../components/UI/PrimaryAutocomplete/PrimaryAutocomplete';
import Block from '../../components/Block/Block';
import MyBarChart from '../../components/MyCharts/MyBarChart';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import * as actions from '../../store/actions/index';

class StatisticsAirport extends Component {
    state = {
        timeframe: 'all',
        pieTimeframe: 'all',
        selectedYear: null,
        selectedMonth: null,
        activePieIndex: 0,
        selectedAirport: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: 2, order: 'desc' },
        },
    };

    componentDidMount() {
        this.props.onGetStatisticsAirports();
    }

    componentWillUnmount() {
        this.props.onResetStatistics();
    }

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    changeTimeframe = (timeframe) => {
        this.setState({ timeframe: timeframe, pieTimeframe: timeframe });
    };

    changePieTimeframe = (timeframe) => {
        let barTimeframe = this.state.timeframe;
        if (timeframe !== 'month') {
            barTimeframe = timeframe;
        }

        this.setState({ pieTimeframe: timeframe, timeframe: barTimeframe });
    };

    changeYear = (year) => {
        this.setState({ selectedYear: year });
    };

    changeMonth = (month) => {
        this.setState({ selectedMonth: month });
    };

    groupBy = (data, property) => {
        const filteredValues = data.filter((v) => v.val !== null);
        const sum = filteredValues.reduce((n, { val }) => n + val, 0);

        const groupedItems = Object.values(
            filteredValues.reduce((acc, item) => {
                acc[item[property]] = acc[item[property]] ? { ...item, val: item.val + acc[item[property]].val } : item;
                return acc;
            }, {})
        );

        groupedItems.forEach((groupedItem) => {
            groupedItem.valShare = groupedItem.val / sum;
        });

        return groupedItems;
    };

    getData = (type) => {
        if (!this.props.airportStatistics) return;

        if (this.state.timeframe === 'all') {
            return this.props.airportStatistics[type];
        } else if (this.state.timeframe === 'year') {
            let itemsPerMonth = {
                1: { dtMonth: `JAN-${this.state.selectedYear}`, val: null },
                2: { dtMonth: `FEB-${this.state.selectedYear}`, val: null },
                3: { dtMonth: `MAR-${this.state.selectedYear}`, val: null },
                4: { dtMonth: `APR-${this.state.selectedYear}`, val: null },
                5: { dtMonth: `MAY-${this.state.selectedYear}`, val: null },
                6: { dtMonth: `JUN-${this.state.selectedYear}`, val: null },
                7: { dtMonth: `JUL-${this.state.selectedYear}`, val: null },
                8: { dtMonth: `AUG-${this.state.selectedYear}`, val: null },
                9: { dtMonth: `SEP-${this.state.selectedYear}`, val: null },
                10: { dtMonth: `OCT-${this.state.selectedYear}`, val: null },
                11: { dtMonth: `NOV-${this.state.selectedYear}`, val: null },
                12: { dtMonth: `DEC-${this.state.selectedYear}`, val: null },
            };

            for (let i = 0; i < this.props.airportStatistics[type].length; i++) {
                const item = this.props.airportStatistics[type][i];
                const dtMonth = item.dtMonth;

                const year = moment(dtMonth, 'MMM-YYYY').format('yyyy');
                if (year == this.state.selectedYear) {
                    const monthIndex = moment(dtMonth, 'MMM-YYYY').format('M');
                    itemsPerMonth[monthIndex] = item;
                }
            }

            const itemsArr = Object.values(itemsPerMonth);

            return itemsArr;
        }
    };

    getPieData = (type) => {
        if (!this.props.airportStatistics) return;

        let data;

        if (this.state.pieTimeframe === 'all') {
            data = this.groupBy(this.props.airportStatistics[type], 'equipment');
        } else if (this.state.pieTimeframe === 'year') {
            const yearData = this.props.airportStatistics[type].filter((a) => {
                return moment(a.dtMonth, 'MMM-YYYY').format('yyyy') == this.state.selectedYear;
            });
            data = this.groupBy(yearData, 'equipment');
        } else if (this.state.pieTimeframe === 'month') {
            const monthData = this.props.airportStatistics[type].filter((a) => {
                return moment(a.dtMonth, 'MMM-YYYY').format('M') == this.state.selectedMonth;
            });
            data = this.groupBy(monthData, 'equipment');
        }

        return data;
    };

    getFields = (label) => {
        let tableFields = [
            {
                title: 'Equipment',
                field: 'equipment',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: label,
                field: 'val',
                render: (rowData) => (rowData.val ? Math.round(rowData.val * 100) / 100 : rowData.val),
                defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Percentage (%)',
                field: 'valShare',
                render: (rowData) => (rowData.valShare * 100).toFixed(2),
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    getBarFields = () => {
        let tableFields = [
            {
                title: 'Month',
                field: 'dtMonth',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Value',
                field: 'val',
                render: (rowData) => (rowData.val ? Math.round(rowData.val * 100) / 100 : rowData.val),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    changeAirport = (airport) => {
        let timeframe = this.state.timeframe;
        let selectedYear = this.state.selectedYear;
        if (airport) {
            timeframe = 'all';
            selectedYear = null;
        }
        this.setState({ timeframe: timeframe, selectedYear: selectedYear, selectedAirport: airport });
    };

    getStats = () => {
        if (!this.state.selectedAirport) return;
        this.props.onGetAirportStatistics(this.state.selectedAirport.value);
    };

    render() {
        const fields1 = this.getFields('Hours');
        const fields2 = this.getFields('Liters');
        const barFields = this.getBarFields();
        const flightsData = this.getData('flights');
        const passengersData = this.getData('passengers');
        const refuleingData = this.getData('refuleing');
        const refuleingFlightData = this.getData('refuleingFlight');
        const operTimeData = this.getData('operTime');
        const operTimeFlightData = this.getData('operTimeFlight');
        const refuleingShareData = this.getPieData('refuleingShare');
        const operTimeShareData = this.getPieData('operTimeShare');

        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}>
                    <span className={classes.FilterName}>Select airport</span>
                    <PrimaryAutocomplete options={this.props.airportOptions} changed={(e, value) => this.changeAirport(value)} short narrow />
                    <PrimaryButton disabled={this.state.selectedAirport === null} clicked={() => this.getStats()} iconButton>
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </PrimaryButton>
                </div>
                <div className={classes.PageTitle}>
                    <h1>Airport statistics</h1>
                    <div className={classes.MarginLeftAuto}>
                        <div className={classes.ActionButtonsWrapper}>
                            <div className={classes.CancelButton} onClick={() => this.props.history.push('/statistics')}>
                                <FontAwesomeIcon icon={faTimes} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </React.Fragment>
        );

        if (this.props.loading === false && this.props.airportStatistics) {
            page = (
                <React.Fragment>
                    <div className={classes.FiltersSpace}>
                        <span className={classes.FilterName}>Select airport</span>
                        <PrimaryAutocomplete options={this.props.airportOptions} changed={(e, value) => this.changeAirport(value)} short narrow />
                        <PrimaryButton disabled={this.state.selectedAirport === null} clicked={() => this.getStats()} iconButton>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </PrimaryButton>
                    </div>
                    <div className={classes.PageTitle}>
                        <h1>Airport statistics</h1>
                        <div className={classes.MarginLeftAuto}>
                            <div className={classes.ActionButtonsWrapper}>
                                <div className={classes.CancelButton} onClick={() => this.props.history.push('/statistics')}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.PageContent}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className={classes.ScrollInner}>
                                <Block title='Flights'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={flightsData}
                                            years={this.props.airportStatisticsYears.flights}
                                            name='Flights'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Flights'
                                        />
                                    </div>

                                    <DataTable
                                        data={flightsData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Passengers'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={passengersData}
                                            years={this.props.airportStatisticsYears.passengers}
                                            name='Passengers'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Passengers'
                                        />
                                    </div>

                                    <DataTable
                                        data={passengersData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Refueling consumption'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={refuleingData}
                                            years={this.props.airportStatisticsYears.refuleing}
                                            name='Refueling consumption'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Liters'
                                        />
                                    </div>

                                    <DataTable
                                        data={refuleingData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Refueling consumption per flight'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={refuleingFlightData}
                                            years={this.props.airportStatisticsYears.refuleingFlight}
                                            name='Refueling consumption per flight'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Liters / flight'
                                        />
                                    </div>

                                    <DataTable
                                        data={refuleingFlightData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Refueling consumption share (%) of equipment'>
                                    <MyPieChart
                                        data={refuleingShareData}
                                        years={this.props.airportStatisticsYears.refuleingShare}
                                        changeYear={this.changeYear}
                                        changeMonth={this.changeMonth}
                                        changeTimeframe={this.changePieTimeframe}
                                        timeframe={this.state.pieTimeframe}
                                        selectedYear={this.state.selectedYear}
                                        selectedMonth={this.state.selectedMonth}
                                        showProperty='equipment'
                                        numberLabel='Liters'
                                        name='Refueling consumption share (%) of equipment'
                                    />
                                    <DataTable
                                        data={refuleingShareData}
                                        columns={fields2}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Operational time'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={operTimeData}
                                            years={this.props.airportStatisticsYears.operTime}
                                            name='Operational time'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Hours'
                                        />
                                    </div>

                                    <DataTable
                                        data={operTimeData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Operational time per flight'>
                                    <div className={classes.ChartWrapper}>
                                        <MyBarChart
                                            data={operTimeFlightData}
                                            years={this.props.airportStatisticsYears.operTimeFlight}
                                            name='Operational time per flight'
                                            xDataKey='dtMonth'
                                            valKey='val'
                                            changeYear={this.changeYear}
                                            changeTimeframe={this.changeTimeframe}
                                            timeframe={this.state.timeframe}
                                            selectedYear={this.state.selectedYear}
                                            withAverage
                                            yLabel='Hours / flight'
                                        />
                                    </div>

                                    <DataTable
                                        data={operTimeFlightData}
                                        columns={barFields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Operational time share (%) of equipment'>
                                    <MyPieChart
                                        data={operTimeShareData}
                                        years={this.props.airportStatisticsYears.operTimeShare}
                                        changeYear={this.changeYear}
                                        changeMonth={this.changeMonth}
                                        changeTimeframe={this.changePieTimeframe}
                                        timeframe={this.state.pieTimeframe}
                                        selectedYear={this.state.selectedYear}
                                        selectedMonth={this.state.selectedMonth}
                                        showProperty='equipment'
                                        numberLabel='Hours'
                                        name='Operational time share (%) of equipment'
                                    />
                                    <DataTable
                                        data={operTimeShareData}
                                        columns={fields1}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>
                            </div>
                        </Scrollbars>
                    </div>
                </React.Fragment>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
        airportOptions: state.statistics.airportOptions,
        airportStatistics: state.statistics.airportStatistics,
        airportStatisticsYears: state.statistics.airportStatisticsYears,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetStatisticsAirports: () => dispatch(actions.getStatisticsAirports()),
        onGetAirportStatistics: (airportId) => dispatch(actions.getAirportStatistics(airportId)),
        onResetStatistics: () => dispatch(actions.resetStatistics()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsAirport);
