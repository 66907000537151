import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import classes from './Admin.module.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimaryInput from '../../components/UI/PrimaryInput/PrimaryInput';
import PrimaryDatePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatePickerInput';
import PrimarySelect from '../../components/UI/PrimarySelect/PrimarySelect';
import PrimaryAutocomplete from '../../components/UI/PrimaryAutocomplete/PrimaryAutocomplete';

const AdminEditTracker = (props) => {
    const [selectedData, setValue] = React.useState(props.selectedData);

    const handleChange = (property, value) => {
        if (property === 'installedDate' && moment.isMoment(value)) {
            value = value.format('YYYY-MM-DDTHH:mm:ss');
        }

        let updatedData = { ...selectedData };
        updatedData[property] = value;

        setValue(updatedData);
    };

    const handleChangeGse = (e, value) => {
        let updatedData = { ...selectedData };

        if (value && value.id) {
            updatedData['gseid'] = value.id;
            updatedData['licensePlate'] = value.info;
            updatedData['gseAssigned'] = value.isAssigned;
        } else {
            updatedData['gseid'] = null;
            updatedData['licensePlate'] = '';
            updatedData['gseAssigned'] = null;
        }

        setValue(updatedData);
    };

    const handleChangeSim = (e, value) => {
        let updatedData = { ...selectedData };

        if (value && value.id) {
            updatedData['simId'] = value.id;
            updatedData['phoneNumber'] = value.phoneNumber;
            updatedData['simAssigned'] = value.isAssigned;
        } else {
            updatedData['simId'] = null;
            updatedData['phoneNumber'] = '';
            updatedData['simAssigned'] = null;
        }

        setValue(updatedData);
    };

    return (
        <React.Fragment>
            <div className={classes.ModalButtonsWrapper}>
                <PrimaryButton clicked={() => props.saveDevice(selectedData)}>
                    <FontAwesomeIcon icon={faSave} />
                    Save
                </PrimaryButton>
                <div className={classes.CancelButton} onClick={() => props.cancel()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>

            <div className={classes.EditRowsWrapper}>
                <div className={classes.EditRow}>
                    <PrimaryInput label='ID' type='text' value={selectedData.id} name='id' disabled />
                </div>

                <div className={classes.EditRow}>
                    <PrimarySelect
                        label='Type'
                        options={[
                            { label: 'autopi', value: 'autopi' },
                            { label: 'trackpi', value: 'trackpi' },
                            { label: 'ble', value: 'ble' },
                        ]}
                        defaultValue={selectedData.type}
                        changed={(value) => handleChange('type', value)}
                        noEmpty
                        mandatory
                    />
                </div>

                <div className={classes.EditRow}>
                    <PrimaryInput
                        label='Serial number'
                        type='text'
                        value={selectedData.serialNo}
                        name='serialNo'
                        changed={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </div>

                <div className={classes.EditRow}>
                    <PrimaryAutocomplete
                        label='Gse'
                        options={props.gseOptions}
                        changed={(e, value) => handleChangeGse(e, value)}
                        defaultValue={
                            props.selectedData.licensePlate
                                ? {
                                      label: 'id: ' + props.selectedData.gseid + ' | info: ' + props.selectedData.licensePlate,
                                      id: props.selectedData.gseid,
                                      info: props.selectedData.licensePlate,
                                      isAssigned: true,
                                  }
                                : null
                        }
                    />
                </div>

                <div className={classes.EditRow}>
                    <PrimarySelect
                        label='Scan for blt'
                        options={[
                            { label: 'false', value: false },
                            { label: 'true', value: true },
                        ]}
                        defaultValue={selectedData.scanForBlt}
                        changed={(value) => handleChange('scanForBlt', value)}
                        noEmpty
                    />
                </div>

                <div className={classes.EditRow}>
                    <PrimaryDatePicker
                        label='Installed date'
                        value={selectedData.installedDate ? new Date(selectedData.installedDate) : selectedData.installedDate}
                        dateChanged={(value) => handleChange('installedDate', value)}
                        name='installedDate'
                        maxDate={new Date()}
                    />
                </div>

                <div className={classes.EditRow}>
                    <PrimaryAutocomplete
                        label='Sim'
                        options={props.simOptions}
                        changed={(e, value) => handleChangeSim(e, value)}
                        defaultValue={
                            props.selectedData.simId
                                ? {
                                      label: 'id: ' + props.selectedData.id + ' | number: ' + props.selectedData.phoneNumber,
                                      id: props.selectedData.simId,
                                      phoneNumber: props.selectedData.phoneNumber,
                                      isAssigned: true,
                                  }
                                : null
                        }
                    />
                </div>

                <div className={[classes.EditRow, classes.FullRow].join(' ')}>
                    <PrimaryInput
                        label='Comments'
                        type='text'
                        value={selectedData.comments}
                        name='comments'
                        changed={(e) => handleChange(e.target.name, e.target.value)}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default AdminEditTracker;
