import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import classes from './Block.module.css';

const Block = (props) => {
    const [visible, setVisible] = React.useState(true);
    
    return (
        <div className={visible ? classes.Block : [classes.Block, classes.Hide].join(' ')}>
            <div className={classes.TitleWrapper}>
                <h4 style={props.color ? {"color":props.color, "borderColor":props.color} : null}>{props.title}</h4>
                <div className={classes.SvgWrapper} onClick={()=>setVisible(!visible)}>
                    <FontAwesomeIcon icon={faAngleDown} />
                </div>
            </div>
            
            <div className={classes.BlockContent}>
                {props.children}
            </div>
        </div>
    )
}

export default Block;