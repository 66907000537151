import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import L from 'leaflet';
import { Map, TileLayer, ScaleControl, FeatureGroup, Marker, Popup, ZoomControl } from 'react-leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';
import moment from 'moment';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-fullscreen/dist/styles.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import { CoordinatesControl } from 'react-leaflet-coordinates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import classes from './LocationMap.module.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import Loader from '../../components/UI/Loader/Loader';
import Legend from '../../components/Legend/Legend';
import * as actions from '../../store/actions/index';
import MapFilters from '../../components/Filters/MapFilters';

class LocationMap extends Component {
    state = {
        mapCenter: [40.519, 22.971],
        filters: {},
        fgKey: 100,
        prevfgKey: 0,
        mapFilters: [
            { bluetooth_gps: 'gps', label: 'GPS', property: 'bluetooth_gps' },
            { bluetooth_gps: 'bluetooth', label: 'Bluetooth', property: 'bluetooth_gps' },
            { active_notActive: 'active', label: 'Active', property: 'active_notActive' },
            { active_notActive: 'notActive', label: 'Not active', property: 'active_notActive' },
            { locationType: '241', label: 'Other', property: 'locationType' },
            { locationType: '242', label: 'Water supply point', property: 'locationType' },
            { locationType: '243', label: 'G.S.E. Cleaning Area', property: 'locationType' },
            { locationType: '244', label: 'Toilet unit emptying point', property: 'locationType' },
            { locationType: '245', label: 'Aircraft Taxi Area', property: 'locationType' },
            { locationType: '246', label: 'Cargo', property: 'locationType' },
            { locationType: '247', label: 'G.S.E. Maintenance', property: 'locationType' },
            { locationType: '248', label: 'G.S.E. Charging points', property: 'locationType' },
            { locationType: '249', label: 'G.S.E. Staging Area', property: 'locationType' },
            { locationType: '250', label: 'Aircraft Parking Stands', property: 'locationType' },
            { locationType: '251', label: 'Baggage Sorting Area', property: 'locationType' },
            { locationType: '252', label: 'Baggage Delivery Arrivals', property: 'locationType' },
            { locationType: '253', label: 'Out of Gauge departures/arrivals', property: 'locationType' },
            { locationType: '254', label: 'G.S.E. Refueling Area', property: 'locationType' },
        ],
        searchGse: '',
        // markerCluster:null
    };

    componentDidMount() {
        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        this.props.onGetLocationFilters();
        this.props.onGetAllAirportLocations();
        this.props.onGetLastLocations(true);

        window.mapRef = React.createRef();
        this.getLocationsTimer = null;
        this.onGetLocationsTimer();
    }

    componentWillUnmount() {
        clearTimeout(this.getLocationsTimer);
    }

    onGetLocationsTimer = () => {
        this.getLocationsTimer = setTimeout(() => {
            this.props.onGetLastLocations(false);
            this.onGetLocationsTimer();
        }, 5000); // Run this every 5 seconds
    };

    onFeatureGroupAdd = (e) => {
        setTimeout(() => {
            const bounds = e.target.getBounds();

            if (
                window.mapRef &&
                window.mapRef.current &&
                window.mapRef.current.leafletElement &&
                Object.keys(bounds).length !== 0 &&
                this.state.fgKey != this.state.prevfgKey
            ) {
                window.mapRef.current.leafletElement.fitBounds(e.target.getBounds());
                const prevfgKey = this.state.fgKey;
                this.setState({ prevfgKey: prevfgKey });
            }
        });
    };

    getPopup = (device) => {
        const popup = (
            <div className={classes.PopupWrapper}>
                <div className={classes.PopupDetails}>
                    <div className={classes.PopupRow}>
                        <span>Device ID:</span>
                        <span>{device.id}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Device type:</span>
                        <span>{device.type}</span>
                    </div>

                    <div className={classes.PopupRow}>
                        <span>GSE:</span>
                        <span>{device.licensePlate}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Last input:</span>
                        <span>{moment(device.timeStamp).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Speed:</span>
                        <span>{device.speed ? device.speed + ' km/h' : ''} </span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Latitude:</span>
                        <span>{device.lat}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Longitude:</span>
                        <span>{device.lon}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Altitude:</span>
                        <span>{device.altitude ? device.altitude + ' m' : ''}</span>
                    </div>
                </div>
                <div className={classes.PopupButtonWrapper}>
                    <PrimaryButton disabled={device.gseId ? false : true} fullWidth clicked={() => this.gotoDetails(device)}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='#8D9CAB'>
                            <path d='M20.036 17.31l-4.724-4.724a9.1 9.1 0 0 1-2.727 2.727l4.724 4.724a1.934 1.934 0 0 0 2.727-2.727z' />
                            <path d='M15.45 7.725a7.725 7.725 0 1 0-7.715 7.725 7.725 7.725 0 0 0 7.715-7.725zm-7.715 5.8a5.791 5.791 0 1 1 5.791-5.791 5.791 5.791 0 0 1-5.791 5.791z' />
                            <path d='M3.223 7.725h1.289a3.17 3.17 0 0 1 3.17-3.17v-1.29a4.513 4.513 0 0 0-4.46 4.46z' />
                        </svg>
                        Goto GSE
                    </PrimaryButton>
                </div>
            </div>
        );

        return popup;
    };

    getLocationPopup = (airportLocation) => {
        let locationType = '';
        const found = this.props.ddLists.locationTypes.find((lt) => lt.value == airportLocation.locationType);
        if (found) locationType = found.label;

        const popup = (
            <div className={classes.PopupWrapper}>
                <div className={classes.PopupDetails}>
                    <div className={classes.PopupRow}>
                        <span>Location name:</span>
                        <span>{airportLocation.name}</span>
                    </div>
                    <div className={classes.PopupRow}>
                        <span>Location type:</span>
                        <span>{locationType}</span>
                    </div>
                </div>
                {/* <div className={classes.PopupButtonWrapper}>
                                    <PrimaryButton disabled={device.gseId ? false : true} fullWidth clicked={() => this.gotoDetails(device)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#8D9CAB">
                                            <path d="M20.036 17.31l-4.724-4.724a9.1 9.1 0 0 1-2.727 2.727l4.724 4.724a1.934 1.934 0 0 0 2.727-2.727z" />
                                            <path d="M15.45 7.725a7.725 7.725 0 1 0-7.715 7.725 7.725 7.725 0 0 0 7.715-7.725zm-7.715 5.8a5.791 5.791 0 1 1 5.791-5.791 5.791 5.791 0 0 1-5.791 5.791z" />
                                            <path d="M3.223 7.725h1.289a3.17 3.17 0 0 1 3.17-3.17v-1.29a4.513 4.513 0 0 0-4.46 4.46z" />
                                        </svg>
                                        Goto GSE
                                    </PrimaryButton>
                                </div> */}
            </div>
        );

        return popup;
    };

    getIcon = (device) => {
        // Color of the circle
        let circleClass = 'GreenCircle';
        let circleIcon =
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>';

        if (device.type === 'ble') {
            circleIcon =
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M196.48 260.023l92.626-103.333L143.125 0v206.33l-86.111-86.111-31.406 31.405 108.061 108.399L25.608 368.422l31.406 31.405 86.111-86.111L145.84 512l148.552-148.644-97.912-103.333zm40.86-102.996l-49.977 49.978-.338-100.295 50.315 50.317zM187.363 313.04l49.977 49.978-50.315 50.316.338-100.294z"/></svg>';
        }

        var startTime = moment(device.timeStamp);
        var endTime = moment();

        var duration = moment.duration(endTime.diff(startTime));
        var minutes = duration.asMinutes();

        // If more than 2 minutes, the gse is turned off. TODO: Is the 2 minutes correct?
        if (minutes > 2) {
            circleClass = 'RedCircle';
        }

        // Custom circle icon
        const blinkingCircle = L.divIcon({
            iconAnchor: [0, 12],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<div class=${classes[circleClass]}>${circleIcon}</div>`,
            className: 'dummy', // Removes white square
        });

        return blinkingCircle;
    };

    getLocationIcon = (aiportLocation) => {
        let icon = '';

        switch (aiportLocation.locationType) {
            case 241: //other
                icon = '';
                break;
            case 242: //Water supply point
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path d="M205.22 22.09c-7.94-28.78-49.44-30.12-58.44 0C100.01 179.85 0 222.72 0 333.91 0 432.35 78.72 512 176 512s176-79.65 176-178.09c0-111.75-99.79-153.34-146.78-311.82zM176 448c-61.75 0-112-50.25-112-112 0-8.84 7.16-16 16-16s16 7.16 16 16c0 44.11 35.89 80 80 80 8.84 0 16 7.16 16 16s-7.16 16-16 16z"/></svg>';
                break;
            case 243: //G.S.E. Cleaning Area
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M256.47 216.77l86.73 109.18s-16.6 102.36-76.57 150.12C206.66 523.85 0 510.19 0 510.19s3.8-23.14 11-55.43l94.62-112.17c3.97-4.7-.87-11.62-6.65-9.5l-60.4 22.09c14.44-41.66 32.72-80.04 54.6-97.47 59.97-47.76 163.3-40.94 163.3-40.94zM636.53 31.03l-19.86-25c-5.49-6.9-15.52-8.05-22.41-2.56l-232.48 177.8-34.14-42.97c-5.09-6.41-15.14-5.21-18.59 2.21l-25.33 54.55 86.73 109.18 58.8-12.45c8-1.69 11.42-11.2 6.34-17.6l-34.09-42.92 232.48-177.8c6.89-5.48 8.04-15.53 2.55-22.44z"/></svg>';
                break;
            case 244: //Toilet unit emptying point
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M128 0C74.98 0 32 85.96 32 192v172.07c0 41.12-9.8 62.77-31.17 126.87C-2.62 501.3 5.09 512 16.01 512h280.92c13.77 0 26-8.81 30.36-21.88 12.83-38.48 24.71-72.4 24.71-126.05V192c0-83.6 23.67-153.52 60.44-192H128zM96 224c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm64 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm64 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zm64 0c-8.84 0-16-7.16-16-16s7.16-16 16-16 16 7.16 16 16-7.16 16-16 16zM480 0c-53.02 0-96 85.96-96 192s42.98 192 96 192 96-85.96 96-192S533.02 0 480 0zm0 256c-17.67 0-32-28.65-32-64s14.33-64 32-64 32 28.65 32 64-14.33 64-32 64z"/></svg>';
                break;
            case 245: //Aircraft Taxi Area
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M480 192H365.71L260.61 8.06A16.014 16.014 0 0 0 246.71 0h-65.5c-10.63 0-18.3 10.17-15.38 20.39L214.86 192H112l-43.2-57.6c-3.02-4.03-7.77-6.4-12.8-6.4H16.01C5.6 128-2.04 137.78.49 147.88L32 256 .49 364.12C-2.04 374.22 5.6 384 16.01 384H56c5.04 0 9.78-2.37 12.8-6.4L112 320h102.86l-49.03 171.6c-2.92 10.22 4.75 20.4 15.38 20.4h65.5c5.74 0 11.04-3.08 13.89-8.06L365.71 320H480c35.35 0 96-28.65 96-64s-60.65-64-96-64z"/></svg>';
                break;
            case 246: //Cargo
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M509.5 184.6L458.9 32.8C452.4 13.2 434.1 0 413.4 0H272v192h238.7c-.4-2.5-.4-5-1.2-7.4zM240 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240V0zM0 224v240c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V224H0z"/></svg>';
                break;
            case 247: //G.S.E. Maintenance
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.63 214.63l-45.25-45.25c-6-6-14.14-9.37-22.63-9.37H384V80c0-26.51-21.49-48-48-48H176c-26.51 0-48 21.49-48 48v80H77.25c-8.49 0-16.62 3.37-22.63 9.37L9.37 214.63c-6 6-9.37 14.14-9.37 22.63V320h128v-16c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v16h128v-16c0-8.84 7.16-16 16-16h32c8.84 0 16 7.16 16 16v16h128v-82.75c0-8.48-3.37-16.62-9.37-22.62zM320 160H192V96h128v64zm64 208c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-16H192v16c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-16H0v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96H384v16z"/></svg>';
                break;
            case 248: //G.S.E. Charging points
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M544 160v64h32v64h-32v64H64V160h480m16-64H48c-26.51 0-48 21.49-48 48v224c0 26.51 21.49 48 48 48h512c26.51 0 48-21.49 48-48v-16h8c13.255 0 24-10.745 24-24V184c0-13.255-10.745-24-24-24h-8v-16c0-26.51-21.49-48-48-48zm-240 96H96v128h224V192z"/></svg>';
                break;
            case 249: //G.S.E. Staging Area
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M628.88 210.65L494.39 49.27A48.01 48.01 0 0 0 457.52 32H32C14.33 32 0 46.33 0 64v288c0 17.67 14.33 32 32 32h32c0 53.02 42.98 96 96 96s96-42.98 96-96h128c0 53.02 42.98 96 96 96s96-42.98 96-96h32c17.67 0 32-14.33 32-32V241.38c0-11.23-3.94-22.1-11.12-30.73zM64 192V96h96v96H64zm96 240c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm160-240h-96V96h96v96zm160 240c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm-96-240V96h66.02l80 96H384z"/></svg>';
                break;
            case 250: //Aircraft Parking Stands
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM240 320h-48v48c0 8.8-7.2 16-16 16h-32c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h96c52.9 0 96 43.1 96 96s-43.1 96-96 96zm0-128h-48v64h48c17.6 0 32-14.4 32-32s-14.4-32-32-32z"/></svg>';
                break;
            case 251: //Baggage Sorting Area
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M128 480h256V80c0-26.5-21.5-48-48-48H176c-26.5 0-48 21.5-48 48v400zm64-384h128v32H192V96zm320 80v256c0 26.5-21.5 48-48 48h-48V128h48c26.5 0 48 21.5 48 48zM96 480H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48h48v352z"/></svg>';
                break;
            case 252: //Baggage Delivery Arrivals
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M224 320h32V96h-32c-17.67 0-32 14.33-32 32v160c0 17.67 14.33 32 32 32zm352-32V128c0-17.67-14.33-32-32-32h-32v224h32c17.67 0 32-14.33 32-32zm48 96H128V16c0-8.84-7.16-16-16-16H16C7.16 0 0 7.16 0 16v32c0 8.84 7.16 16 16 16h48v368c0 8.84 7.16 16 16 16h82.94c-1.79 5.03-2.94 10.36-2.94 16 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.64-1.15-10.97-2.94-16h197.88c-1.79 5.03-2.94 10.36-2.94 16 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.64-1.15-10.97-2.94-16H624c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM480 96V48c0-26.51-21.49-48-48-48h-96c-26.51 0-48 21.49-48 48v272h192V96zm-48 0h-96V48h96v48z"/></svg>';
                break;
            case 253: //Out of Gauge departures/arrivals
                // code block
                break;
            case 254: //G.S.E. Refueling Area
                icon =
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"/></svg>';
                break;
            default:
                icon = '';
        }

        // let circleIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>'

        // Custom circle icon
        const locationIcon = L.divIcon({
            iconAnchor: [0, 12],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<div class=${classes['LocationIcon']}>${icon}</div>`,
            className: 'dummy', // Removes white square
        });

        return locationIcon;
    };

    gotoDetails = (device) => {
        const selectedGseId = device.gse.gseId;

        this.props.onUpdateGseDataProperty('selectedGseId', selectedGseId);
        this.props.onClearGseEditDetails();
        this.props.history.push('/gseData');
    };

    updateFilter = (select, value) => {
        let filters = { ...this.state.filters };

        const found = select.options.find((f) => f.value === value);

        if (found) {
            filters[select.filter] = { label: found.label, value: found.value };
        } else {
            filters[select.filter] = { label: '', value: '' };
        }

        const fgKey = Math.floor(Math.random() * 100);

        this.setState({ filters: filters, fgKey: fgKey });
    };

    removeFilter = (filter) => {
        let filters = { ...this.state.filters };
        delete filters[filter];

        this.setState({ filters: filters });
    };

    isVisible = (device) => {
        const deviceType = device.type;
        let isVisible = null;
        let show = true;

        if (deviceType === 'ble') {
            isVisible = this.state.mapFilters.find((f) => f.bluetooth_gps === 'bluetooth');
            if (!isVisible) show = false;
        } else if (deviceType === 'trackpi' || deviceType === 'autopi') {
            isVisible = this.state.mapFilters.find((f) => f.bluetooth_gps === 'gps');
            if (!isVisible) show = false;
        }

        var startTime = moment(device.timeStamp);
        var endTime = moment();

        var duration = moment.duration(endTime.diff(startTime));
        var minutes = duration.asMinutes();

        // If more than 2 minutes, the gse is turned off. TODO: Is the 2 minutes correct?
        let deviceIsActive = true;
        if (minutes > 2) {
            deviceIsActive = false;
        }

        if (deviceIsActive) {
            isVisible = this.state.mapFilters.find((f) => f.active_notActive === 'active');
            if (!isVisible) show = false;
        } else if (!deviceIsActive) {
            isVisible = this.state.mapFilters.find((f) => f.active_notActive === 'notActive');
            if (!isVisible) show = false;
        }

        if (this.state.searchGse !== '') {
            if (!device.licensePlate) {
                show = false;
            } else {
                isVisible = device.licensePlate.toLowerCase().includes(this.state.searchGse.toLowerCase());
                if (!isVisible) show = false;
            }
        }

        return show;
    };

    isLocationVisible = (airportLocation) => {
        let show = true;
        let isVisible = null;

        let storedFilters = JSON.parse(localStorage.getItem('filters')) || [];
        const airportFilters = storedFilters.filter((f) => f.property === 'airportId');
        let filteredAiports = [];

        if (airportFilters) {
            for (let airportFilter of airportFilters) {
                filteredAiports.push(airportFilter.airportId);
            }
        }

        const locationType = airportLocation.locationType;

        isVisible = this.state.mapFilters.find((f) => f.locationType == locationType);
        if (!isVisible) show = false;

        if (filteredAiports.length && filteredAiports.includes(airportLocation.airportId) === false) {
            show = false;
        }

        return show;
    };

    render() {
        let page = (
            <Aux>
                <div className={classes.FiltersSpace}></div>
                <div className={classes.PageTitle}>
                    <h1>Map</h1>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </Aux>
        );

        // if (this.props.filtered_lastLocations.length) {
        if (!this.props.loading) {
            page = (
                <Aux>
                    <div className={classes.FiltersSpace}>
                        <MapFilters mapFilters={this.state.mapFilters} onUpdateMapFilters={(newFilters) => this.setState({ mapFilters: newFilters })} />
                    </div>
                    <div className={classes.PageTitle}>
                        <h1>Map</h1>
                        <div className={classes.OptionsWrapper}>
                            <div className={classes.InputWrapper}>
                                <input
                                    type='text'
                                    placeholder='Search GSE'
                                    value={this.state.searchGse}
                                    onChange={(e) => this.setState({ searchGse: e.target.value })}
                                />
                                {this.state.searchGse ? <FontAwesomeIcon icon={faTimes} onClick={() => this.setState({ searchGse: '' })} /> : null}
                            </div>
                        </div>
                    </div>

                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className={classes.PageContent}>
                            <div className={classes.MapWrapper}>
                                <Map
                                    ref={window.mapRef}
                                    className={classes.Map}
                                    maxZoom={19}
                                    center={this.state.mapCenter}
                                    boundsOptions={{ padding: [50, 50] }}
                                    zoom={13}
                                    zoomControl={false}
                                    gestureHandling={true}
                                >
                                    <TileLayer attribution={''} url={'http://{s}.tile.osm.org/{z}/{x}/{y}.png'} />

                                    <FeatureGroup key={this.state.fgKey} ref={(ref) => (this.fgRef = ref)} onAdd={this.onFeatureGroupAdd}>
                                        {/* <MarkerClusterGroup
                                                ref={(r) => {this.markerCluster = r}}
                                                // onClick={(e) => {
                                                //     this.setState({markerCluster:e})
                                                //     console.log(this.markerCluster.leafletElement);
                                                //     e.layer.spiderfy()
                                                // }}
                                                // spiderfyDistanceMultiplier={1}
                                                // showCoverageOnHover={false}
                                            > */}
                                        {this.props.filtered_lastLocations.map((device, i) => {
                                            if (this.isVisible(device)) {
                                                return (
                                                    <Marker key={`device_${i}`} icon={this.getIcon(device)} position={[device.lat, device.lon]}>
                                                        <Popup>{this.getPopup(device)}</Popup>
                                                    </Marker>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                        {/* {this.props.allAirportLocations.map((airportLocation, k) => {
                                            if (this.isLocationVisible(airportLocation)) {
                                                return (
                                                    <Marker
                                                        key={`aiportLocation_${k}`}
                                                        icon={this.getLocationIcon(airportLocation)}
                                                        position={[airportLocation.lat, airportLocation.lon]}
                                                    >
                                                        <Popup>{this.getLocationPopup(airportLocation)}</Popup>
                                                    </Marker>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })} */}
                                        {/* </MarkerClusterGroup> */}
                                    </FeatureGroup>

                                    <CoordinatesControl coordinates='decimal' position='bottomleft' />
                                    <ScaleControl position='bottomleft' />
                                    <ZoomControl position='topright' />
                                    <Legend type='locationMap' />
                                </Map>
                            </div>
                        </div>
                    </Scrollbars>
                </Aux>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.loggedInUser,
        loading: state._system.loading,
        lastLocations: state._data.lastLocations,
        filtered_lastLocations: state._data.filtered_lastLocations,
        ddLists: state._data.ddLists,
        airportsOptions: state._data.airportsOptions,
        allAirportLocations: state._data.allAirportLocations,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetLastLocations: () => dispatch(actions.getLastLocations()),
        onGetAllAirportLocations: () => dispatch(actions.getAllAirportLocations()),
        onGetLocationFilters: () => dispatch(actions.getLocationFilters()),
        onUpdateGseDataProperty: (property, value) => dispatch(actions.updateGseDataProperty(property, value)),
        onClearGseEditDetails: () => dispatch(actions.clearGseEditDetails()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationMap);
