import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';

import PrimaryDatePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatePicker';
import PrimaryYearPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearPicker';
import PrimaryYearMonthPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearMonthPicker';
import classes from './GseInfo.module.css';
import Block from '../../components/Block/Block';
import * as actions from '../../store/actions/index';

class TabTracking extends Component {
    state = {
        selectedPeriod: 'month',
        selectedYear: null,
        selectedMonth: new Date(),
        selectedDay: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: 2, order: 'desc' },
        },
    };

    componentDidMount() {
        const today = moment(new Date()).format('yyyy-MM-DD');
        // const today = moment().subtract(2, 'days').format('yyyy-MM-DD');
        this.props.onGetTrackingGse(today, this.props.gseDetails.id);
    }

    handleSelectedYear = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'year', selectedYear: value, selectedMonth: null, selectedDay: null, customPeriod: [null, null] });
        const inputDate = moment(new Date(value)).format('yyyy-MM-DD');
        this.props.onGetTrackingGse(inputDate, this.props.gseDetails.id);
    };
    handleSelectedMonth = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'month', selectedYear: null, selectedMonth: value, selectedDay: null, customPeriod: [null, null] });
        const inputDate = moment(new Date(value)).format('yyyy-MM-DD');
        this.props.onGetTrackingGse(inputDate, this.props.gseDetails.id);
    };
    handleSelectedDay = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'day', selectedYear: null, selectedMonth: null, selectedDay: value, customPeriod: [null, null] });
        const inputDate = moment(new Date(value)).format('yyyy-MM-DD');
        this.props.onGetTrackingGse(inputDate, this.props.gseDetails.id);
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        let page = null;
        let data;

        if (this.props.trackingGse) {
            if (this.state.selectedPeriod === 'day') {
                data = this.props.trackingGse.statTripsGsesDay;
            } else if (this.state.selectedPeriod === 'month') {
                data = this.props.trackingGse.statTripsGsesMonth;
            } else {
                data = this.props.trackingGse.statTripsGsesYear;
            }

            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner} id='TabTrackingPrint'>
                        <Block title='GSE Tracking Statistics'>
                            <div className={classes.Timeframe}>
                                <div
                                    className={
                                        this.state.selectedPeriod === 'day' ? [classes.Timeframe2, classes.TimeframeSelected].join(' ') : classes.Timeframe2
                                    }
                                >
                                    <PrimaryDatePicker
                                        value={this.state.selectedDay}
                                        dateChanged={(value) => this.handleSelectedDay(value)}
                                        maxDate={new Date()}
                                        placeholder='Day'
                                    />
                                </div>
                                <div
                                    className={
                                        this.state.selectedPeriod === 'month' ? [classes.Timeframe2, classes.TimeframeSelected].join(' ') : classes.Timeframe2
                                    }
                                >
                                    <PrimaryYearMonthPicker
                                        value={this.state.selectedMonth}
                                        dateChanged={(value) => this.handleSelectedMonth(value)}
                                        maxDate={new Date()}
                                        placeholder='Month'
                                    />
                                </div>
                                <div
                                    className={
                                        this.state.selectedPeriod === 'year' ? [classes.Timeframe2, classes.TimeframeSelected].join(' ') : classes.Timeframe2
                                    }
                                >
                                    <PrimaryYearPicker
                                        value={this.state.selectedYear}
                                        dateChanged={(value) => this.handleSelectedYear(value)}
                                        maxDate={new Date()}
                                        placeholder='Year'
                                    />
                                </div>
                            </div>

                            <div className={classes.TrackingTable}>
                                <div className={classes.TrackingRow}>
                                    <div className={classes.TrackingCol}>
                                        <span>Airport</span>
                                        <span>{this.props.gseDetails.generalInfo.airportCode}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>GSE</span>
                                        <span>{this.props.gseDetails.generalInfo.info}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>GSE Equipment</span>
                                        <span>{this.props.gseDetails.generalInfo.equipmentType}</span>
                                    </div>
                                </div>

                                <div className={classes.TrackingRow}>
                                    <div className={classes.TrackingCol}>
                                        <span>Mileage (km)</span>
                                        <span>{data && data.mileage ? Math.round(data.mileage / 10) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Operational time (h)</span>
                                        <span>{data && data.operTime ? Math.round(data.operTime / 36) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Idling (%)</span>
                                        <span>{data && data.idling ? Math.round(data.idling * 10000) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Overspeeding (%)</span>
                                        <span>{data && data.overSpeed ? Math.round(data.overSpeed * 10000) / 100 : 'No data'}</span>
                                    </div>
                                </div>

                                <div className={classes.TrackingRow}>
                                    <div className={classes.TrackingCol}>
                                        <span>Trips for flights (%)</span>
                                        <span>{data && data.tripFlights ? Math.round(data.tripFlights * 10000) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Mileage for flights (%)</span>
                                        <span>{data && data.milFlights ? Math.round(data.milFlights * 10000) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Operational time for flights (%)</span>
                                        <span>{data && data.operFlights ? Math.round(data.operFlights * 10000) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Served flights</span>
                                        <span>{data && data.flights ? data.flights : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Served flights' aircraft positions</span>
                                        <span>{data && data.airPos ? data.airPos : 'No data'}</span>
                                    </div>
                                </div>

                                <div className={classes.TrackingRow}>
                                    <div className={classes.TrackingCol}>
                                        <span>Trip consumption (l)</span>
                                        <span>{data && data.statFc ? Math.round(data.statFc * 100) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Trip consumption not for flights (l)</span>
                                        <span>{data && data.statFcFlightsNot ? Math.round(data.statFcFlightsNot * 100) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Modeled trip consumption (l)</span>
                                        <span>{data && data.modelFc ? Math.round(data.modelFc * 100) / 100 : 'No data'}</span>
                                    </div>
                                    <div className={classes.TrackingCol}>
                                        <span>Modeled trip consumption not for flights (l)</span>
                                        <span>{data && data.modelFcFlightsNot ? Math.round(data.modelFcFlightsNot * 100) / 100 : 'No data'}</span>
                                    </div>
                                </div>
                            </div>
                        </Block>
                    </div>
                </Scrollbars>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
        trackingGse: state.statistics.trackingGse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetTrackingGse: (d, gseId) => dispatch(actions.getTrackingGse(d, gseId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabTracking);
