import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

import classes from './Admin.module.css';
import * as actions from '../../store/actions/index';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import AdminEditTracker from './AdminEditTracker';
import Modal from '../../components/UI/Modal/Modal';
import { formatDate, dateTimeToISO } from '../../store/utilities';

class AdminTrackers extends Component {
  state = {
    showModal: false,
    selectedData: null,
    action: null,
    tableOptions: {
      initialPage: 0,
      pageSize: 5,
      searchText: '',
      defaultSort: { column: null, order: '' },
    },
  };

  componentDidMount() {
    this.props.onGetTrackers();
  }

  getDeviceFields = () => {
    let tableFields = [
      {
        title: 'id',
        field: 'id',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 0
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Type',
        field: 'type',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 1
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Serial number',
        field: 'serialNo',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 2
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Gse ID',
        field: 'gseid',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 3
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Gse license plate',
        field: 'licensePlate',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 4
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Scan for blt',
        field: 'scanForBlt',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 5
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Installed date',
        field: 'installedDate',
        render: (rowData) => formatDate(rowData.installedDate),
        defaultSort:
          this.state.tableOptions.defaultSort.column === 6
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Sim id',
        field: 'simId',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 7
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Sim number',
        field: 'phoneNumber',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 8
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
      {
        title: 'Comments',
        field: 'comments',
        defaultSort:
          this.state.tableOptions.defaultSort.column === 9
            ? this.state.tableOptions.defaultSort.order
            : null,
      },
    ];

    return tableFields;
  };

  addDevice() {
    let now = new Date();
    now = dateTimeToISO(now);

    const selectedData = {
      id: 0,
      type: '',
      serialNo: '',
      gseid: null,
      licensePlate: '',
      scanForBlt: false,
      installedDate: now,
      simId: null,
      phoneNumber: '',
      comments: '',
    };

    this.setState({
      selectedData: selectedData,
      showModal: true,
      action: 'new',
    });
  }

  editDevice(data) {
    const selectedData = { ...data };
    this.setState({
      selectedData: selectedData,
      showModal: true,
      action: 'edit',
    });
  }

  prepareDeleteDevice(rowData) {
    Swal.fire({
      type: 'warning',
      title: 'Are you sure?',
      text: 'The device with id: ' + rowData.id + ' will be deleted.',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      preConfirm: () => {
        this.props.onDeleteDevice(rowData.id);
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          type: 'success',
          text: 'The device was deleted.',
        });
      }
    });
  }

  prepareSaveDevice = (postData) => {
    const invalidNum = document.getElementsByClassName('Mui-error').length;
    if (invalidNum > 0) {
      Swal.fire({
        type: 'error',
        text: 'Please fill in all the mandatory fields',
      });
      return;
    }

    let sureMsg = 'A new device will be created.';
    let confirmMsg = 'The new device was created';

    if (this.state.action === 'edit') {
      sureMsg = 'The device details will be updated.';
      confirmMsg = 'The device details were updated.';
    }

    Swal.fire({
      type: 'warning',
      title: 'Are you sure?',
      text: sureMsg,
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      allowOutsideClick: false,
      preConfirm: () => {
        this.props.onUpdateDevice(postData);
        this.setState({ showModal: false, action: null });
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          type: 'success',
          text: confirmMsg,
        });
      }
    });
  };

  getFilteredGseOptions = () => {
    if (!this.state.selectedData) return [];

    let filteredGseOptions = [];

    for (let filteredGseOption of this.props.gseOptions) {
      if (
        filteredGseOption.isAssigned === false ||
        filteredGseOption.id === this.state.selectedData.gseid
      ) {
        filteredGseOptions.push(filteredGseOption);
      }
    }

    return filteredGseOptions;
  };

  getFilteredSimOptions = () => {
    if (!this.state.selectedData) return [];

    let filteredSimOptions = [];

    for (let filteredSimOption of this.props.simOptions) {
      if (
        filteredSimOption.isAssigned === false ||
        filteredSimOption.id === this.state.selectedData.simId
      ) {
        filteredSimOptions.push(filteredSimOption);
      }
    }

    return filteredSimOptions;
  };

  updateTableOptions = (property, value) => {
    let tableOptions = { ...this.state.tableOptions };
    tableOptions[property] = value;

    this.setState({ tableOptions: tableOptions });
  };

  render() {
    let page = (
      <Aux>
        <div className={classes.FiltersSpace}></div>
        <div className={classes.PageTitle}>
          <h1>Trackers</h1>
        </div>
        <div className={classes.PageContent}>
          <Loader show={this.props.loading} />
        </div>
      </Aux>
    );

    const deviceFields = this.getDeviceFields();
    const filteredGseOptions = this.getFilteredGseOptions();
    const filteredSimOptions = this.getFilteredSimOptions();

    if (this.props.loading === false) {
      page = (
        <Aux>
          <div className={classes.FiltersSpace}></div>
          <div className={classes.PageTitle}>
            <h1>Trackers</h1>
            <div className={classes.MarginLeftAuto}>
              <div className={classes.ActionButtonsWrapper}>
                <div
                  className={classes.CancelButton}
                  onClick={() => this.props.history.push('/admin')}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.PageContent}>
            <div className={classes.PageInner}>
              <Scrollbars style={{ width: '100%', height: '100%' }}>
                <div className={classes.ScrollInner}>
                  <div className={classes.AddUserButtonWrapper}>
                    <PrimaryButton clicked={() => this.addDevice()}>
                      <FontAwesomeIcon icon={faPlus} />
                      Add
                    </PrimaryButton>
                  </div>

                  <DataTable
                    data={this.props.devices}
                    columns={deviceFields}
                    editClicked={(rowData) => this.editDevice(rowData)}
                    deleteClicked={(rowData) =>
                      this.prepareDeleteDevice(rowData)
                    }
                    withExport
                    withSearch
                    tableOptions={this.state.tableOptions}
                    updateTableOptions={(property, value) =>
                      this.updateTableOptions(property, value)
                    }
                  />
                </div>
              </Scrollbars>
            </div>
          </div>

          <Modal
            show={this.state.showModal}
            modalClosed={() =>
              this.setState({ showModal: false, action: null })
            }
          >
            {this.state.action === 'edit' || this.state.action === 'new' ? (
              <AdminEditTracker
                selectedData={this.state.selectedData}
                saveDevice={(data) => this.prepareSaveDevice(data)}
                cancel={() => this.setState({ showModal: false, action: null })}
                gseOptions={filteredGseOptions}
                simOptions={filteredSimOptions}
              />
            ) : null}
          </Modal>
        </Aux>
      );
    }

    return page;
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: state.login.loggedInUser,
    loading: state._system.loading,
    devices: state.admin.devices,
    gseOptions: state.admin.gseOptions,
    simOptions: state.admin.simOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetTrackers: () => dispatch(actions.getTrackers()),
    onUpdateDevice: (postData) => dispatch(actions.updateDevice(postData)),
    onDeleteDevice: (deviceId) => dispatch(actions.deleteDevice(deviceId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTrackers);
