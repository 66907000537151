import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import Swal from 'sweetalert2';

import classes from './GseData.module.css';
// import Panel from '../../components/UI/Panel/Panel';
import MultiSelect from '../../components/UI/MultiSelect/MultiSelect';
import DataTable from '../../components/DataTable/DataTable';
import AfterTitleRow from '../../components/AfterTitleRow/AfterTitleRow';
import GseInfo from '../GseInfo/GseInfo';
import GseEdit from '../GseEdit/GseEdit';
import Loader from '../../components/UI/Loader/Loader';
import * as actions from '../../store/actions/index';
// import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import { formatDate } from '../../store/utilities';
import SideFilters from '../../components/Filters/SideFilters';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';

class GseData extends Component {
    state = {
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: null, order: '' },
        },
        filteredData: [],
    };

    componentDidMount() {
        //this.props.onGetGsePreFilter();
        this.addFilterPlaceholder();
        // this.props.onUpdateBreadcrumbs(["GSE Data"])
    }

    addFilterPlaceholder = () => {
        const filters = document.querySelectorAll('div.rt-th > input');
        for (let filter of filters) {
            filter.placeholder = 'Εύρεση..';
        }
    };

    getGeneralInfoFields = () => {
        let tableFields = [];
        let column;

        this.props.gseGeneralInfoColumns.forEach((generalInfoColumn, index) => {
            if (generalInfoColumn.checked === true) {
                // If date, format it
                if (generalInfoColumn.field === 'purchaseDate') {
                    column = {
                        title: generalInfoColumn.title,
                        field: generalInfoColumn.field,
                        render: (rowData) => formatDate(rowData.purchaseDate),
                        defaultSort: this.state.tableOptions.defaultSort.column === index ? this.state.tableOptions.defaultSort.order : null,
                    };
                } else {
                    column = {
                        title: generalInfoColumn.title,
                        field: generalInfoColumn.field,
                        defaultSort: this.state.tableOptions.defaultSort.column === index ? this.state.tableOptions.defaultSort.order : null,
                    };
                }

                tableFields.push(column);
            }
        });

        return tableFields;
    };

    prepareDeleteGse = (rowData) => {
        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The GSE with ID: ' + rowData.gseId + ' will be deleted',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteGse(rowData.gseId);
            },
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The GSE was deleted',
                });
            }
        });
    };

    gotoEdit = () => {
        const editGseId = this.props.selectedGseId;
        this.props.onUpdateGseDataProperty('selectedGseId', null);
        this.props.onUpdateGseDataProperty('editGseId', editGseId);
    };

    gotoDetails = () => {
        const selectedGseId = this.props.editGseId;
        this.props.onUpdateGseDataProperty('selectedGseId', selectedGseId);
        this.props.onUpdateGseDataProperty('editGseId', null);
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    render() {
        const generalInfoFields = this.getGeneralInfoFields();

        let page = (
            <Aux>
                <div className={classes.FiltersSpace}></div>
                <div className={classes.PageTitle}>
                    <h1>GSE Data</h1>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </Aux>
        );

        if (this.props.loading === false) {
            page = (
                <Aux>
                    <div className={classes.FiltersSpace}>
                        <SideFilters />
                    </div>
                    <div className={classes.PageTitle}>
                        <h1>GSE Data</h1>

                        {/* {
                                this.props.loggedInUser && this.props.loggedInUser.role_id < 20
                                ? <div className={classes.MarginLeftAuto}>
                                    <PrimaryButton clicked={() => { this.props.onUpdateGseDataProperty("editGseId", {}); this.props.onUpdateGseDataProperty("selectedGseId", null) }}>
                                        <FontAwesomeIcon icon={faPlus} />
                                        Add GSE
                                    </PrimaryButton>
                                </div>
                                
                                : null
                            } */}
                    </div>

                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div className={classes.PageContent}>
                            <AfterTitleRow>
                                <MultiSelect
                                    fields={this.props.gseGeneralInfoColumns}
                                    toggleAll={(checkedAllFields) => this.props.onToggleFieldCheckAll(checkedAllFields)}
                                    toggleField={(index) => this.props.onToggleFieldCheck(index)}
                                />
                            </AfterTitleRow>

                            <div className={classes.PageInner}>
                                <Loader show={this.props.loading} />

                                <DataTable
                                    data={this.props.filtered_gseData}
                                    columns={generalInfoFields}
                                    withSearchAbsolutePos
                                    withExport
                                    viewClicked={(rowData) => {
                                        this.props.onUpdateGseDataProperty('selectedGseId', rowData.gseId);
                                    }}
                                    editClicked={
                                        this.props.loggedInUser && this.props.loggedInUser.role_id < 20
                                            ? (rowData) => {
                                                  this.props.onUpdateGseDataProperty('editGseId', rowData.gseId);
                                              }
                                            : null
                                    }
                                    deleteClicked={
                                        this.props.loggedInUser && this.props.loggedInUser.role_id < 20 ? (rowData) => this.prepareDeleteGse(rowData) : null
                                    }
                                    tableOptions={this.state.tableOptions}
                                    updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                />
                            </div>
                        </div>
                    </Scrollbars>
                </Aux>
            );
        }

        if (this.props.selectedGseId !== null) {
            page = (
                <GseInfo
                    onCancel={() => {
                        this.props.onUpdateGseDataProperty('selectedGseId', null);
                        this.props.onClearGseEditDetails();
                    }}
                    gotoEdit={() => {
                        this.props.onClearGseEditDetails();
                        this.gotoEdit();
                    }}
                />
            );
        } else if (this.props.editGseId !== null) {
            page = (
                <GseEdit
                    onCancel={() => {
                        this.props.onUpdateGseDataProperty('editGseId', null);
                        this.props.onClearGseEditDetails();
                    }}
                    gotoDetails={() => {
                        this.props.onClearGseEditDetails();
                        this.gotoDetails();
                    }}
                />
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.login.loggedInUser,
        loading: state._system.loading,
        // gseGeneralInfo: state._data.gseGeneralInfo,
        filtered_gseData: state._data.filtered_gseData,
        gseGeneralInfoColumns: state.gseData.gseGeneralInfoColumns,
        ddLists: state._data.ddLists,
        selectedGseId: state.gseData.selectedGseId,
        editGseId: state.gseData.editGseId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // onGetGsePreFilter: () => dispatch(actions.getGsePreFilter()),
        onToggleFieldCheckAll: (checkedAllFields) => dispatch(actions.toggleFieldCheckAll(checkedAllFields)),
        onToggleFieldCheck: (index) => dispatch(actions.toggleFieldCheck(index)),
        onClearGseEditDetails: () => dispatch(actions.clearGseEditDetails()),
        onDeleteGse: (gseId) => dispatch(actions.deleteGse(gseId)),
        onUpdateGseDataProperty: (property, value) => dispatch(actions.updateGseDataProperty(property, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GseData);
