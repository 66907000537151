import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';
import moment from 'moment';

const initialState = {
    companyStatistics: null,
    companyStatisticsYears: {
        airportFlights: [],
        airportOperTimes: [],
        airportPassengers: [],
        airportRefuleings: [],
    },
    airportStatistics: null,
    airportStatisticsYears: {
        flights: [],
        operTime: [],
        operTimeFlight: [],
        passengers: [],
        refuleing: [],
        refuleingFlight: [],
        refuleingShare: [],
        operTimeShare: [],
    },
    equipmentOptions: [],
    gseOptions: [],
    equipmentStatistics: null,
    equipmentStatisticsYears: {
        opertTimeEq: [],
        opertTimeEqFlights: [],
        refuelingEq: [],
        refuelingEqFlights: [],
    },
    airportOptions: [],
    equipmentAirportStatistics: null,
    equipmentAirportStatisticsYears: {
        opertTimeEq: [],
        opertTimeEqFlights: [],
        refuelingEq: [],
        refuelingEqFlights: [],
    },
    gseStatistics: null,
    gseStatisticsYears: {
        opertTimeEq: [],
        opertTimeEqFlights: [],
        refuelingEq: [],
        refuelingEqFlights: [],
        serviceRecords: [],
        tyreRecords: [],
        batteryRecords: [],
        actualFC: [],
        modeledFC: [],
    },
    tracking: null,
    trackingGse: null,
};

const getCompanyStatisticsSuccess = (state, action) => {
    let companyStatisticsYears = { ...state.companyStatisticsYears };

    for (const [key, value] of Object.entries(companyStatisticsYears)) {
        const allYears = action.stats[key].map((a) => {
            return moment(a.dtMonth, 'MMM-YYYY').format('yyyy');
        });
        const uniqueYears = [...new Set(allYears)];
        companyStatisticsYears[key] = uniqueYears;
    }

    return updateObject(state, { companyStatistics: action.stats, companyStatisticsYears: companyStatisticsYears });
};

const getAirportStatisticsSuccess = (state, action) => {
    const yearsObj = { ...state.airportStatisticsYears };
    const years = getYears(yearsObj, action.stats);

    return updateObject(state, {
        airportStatistics: action.stats,
        airportStatisticsYears: years,
    });
};

const getGsePrefilterStatisticsSuccess = (state, action) => {
    let equipmentOptions = [];
    let gseOptions = [];

    for (let i = 0; i < action.gseData.length; i++) {
        const gseItem = action.gseData[i];

        gseOptions.push({ label: gseItem.info, value: gseItem.gseId, airportId: gseItem.airportId, equipmentType: gseItem.equipmentType });

        if (equipmentOptions.some((e) => e.label === gseItem.equipmentType) === false) {
            equipmentOptions.push({ label: gseItem.equipmentType, value: gseItem.equipmentType });
        }
    }

    equipmentOptions.sort((a, b) => a.label.localeCompare(b.label));

    return updateObject(state, { equipmentOptions: equipmentOptions, gseOptions: gseOptions });
};

const getEquipmentStatisticsSuccess = (state, action) => {
    const yearsObj = { ...state.equipmentStatisticsYears };
    const years = getYears(yearsObj, action.stats);

    return updateObject(state, { equipmentStatistics: action.stats, equipmentStatisticsYears: years });
};

const getGseEquipmentAndAirportsSuccess = (state, action) => {
    let equipmentOptions = [];
    let airportOptions = [];

    for (let i = 0; i < action.gseData.length; i++) {
        const gseItem = action.gseData[i];

        if (equipmentOptions.some((e) => e.label === gseItem.equipmentType) === false) {
            equipmentOptions.push({ label: gseItem.equipmentType, value: gseItem.equipmentType });
        }
    }
    equipmentOptions.sort((a, b) => a.label.localeCompare(b.label));

    for (let i = 0; i < action.airports.length; i++) {
        const airportItem = action.airports[i];

        if (airportOptions.some((e) => e.label === airportItem.shortName) === false) {
            airportOptions.push({ label: airportItem.shortName, value: airportItem.id });
        }
    }
    airportOptions.sort((a, b) => a.label.localeCompare(b.label));

    return updateObject(state, { equipmentOptions: equipmentOptions, airportOptions: airportOptions });
};

const getStatisticsAirportsSuccess = (state, action) => {
    let airportOptions = [];

    for (let i = 0; i < action.airports.length; i++) {
        const airportItem = action.airports[i];

        if (airportOptions.some((e) => e.label === airportItem.shortName) === false) {
            airportOptions.push({ label: airportItem.shortName, value: airportItem.id });
        }
    }
    airportOptions.sort((a, b) => a.label.localeCompare(b.label));

    return updateObject(state, { airportOptions: airportOptions });
};

const getGseStatisticsSuccess = (state, action) => {
    const yearsObj = { ...state.gseStatisticsYears };
    const years = getYears(yearsObj, action.stats);
    return updateObject(state, { gseStatistics: action.stats, gseStatisticsYears: years });
};

const getEquipmentAirportStatisticsSuccess = (state, action) => {
    const yearsObj = { ...state.equipmentAirportStatisticsYears };
    const years = getYears(yearsObj, action.stats);

    return updateObject(state, { equipmentAirportStatistics: action.stats, equipmentAirportStatisticsYears: years });
};

const getYears = (yearsObj, data) => {
    if (!data) return yearsObj;

    for (const [key, value] of Object.entries(yearsObj)) {
        let years = [];

        for (let i = 0; i < data[key].length; i++) {
            const item = data[key][i];
            const dtMonth = item.dtMonth;
            const dt = item.dt;
            let year;

            // if (moment(dtMonth, 'MMM-YYYY').isValid()) {
            if (dtMonth) {
                year = moment(dtMonth, 'MMM-YYYY').format('yyyy');
            } else if (dt) {
                year = moment(new Date(dt)).format('yyyy');
            }

            if (!years.includes(year)) {
                years.push(year);
            }
        }
        yearsObj[key] = years;
    }

    // sort
    for (const [key, value] of Object.entries(yearsObj)) {
        value.sort();
    }

    return yearsObj;
};

const getTrackingSuccess = (state, action) => {
    const tracking = {
        mileage: action.mileage,
        operational: action.operational,
        idling: action.idling,
        flights: action.flights,
    };

    return updateObject(state, { tracking: tracking });
};

const getTrackingGseSuccess = (state, action) => {
    return updateObject(state, { trackingGse: action.trackingGse });
};

const resetTracking = (state) => {
    return updateObject(state, { tracking: null });
};

const resetStatistics = (state) => {
    const resetState = { ...initialState };
    return resetState;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_STATISTICS_SUCCESS:
            return getCompanyStatisticsSuccess(state, action);

        case actionTypes.GET_AIRPORT_STATISTICS_SUCCESS:
            return getAirportStatisticsSuccess(state, action);

        case actionTypes.GET_GSE_PREFILTER_STATISTICS_SUCCESS:
            return getGsePrefilterStatisticsSuccess(state, action);

        case actionTypes.GET_EQUIPMENT_STATISTICS_SUCCESS:
            return getEquipmentStatisticsSuccess(state, action);

        case actionTypes.GET_GSE_EQUIPMENT_AND_AIRPORTS_SUCCESS:
            return getGseEquipmentAndAirportsSuccess(state, action);

        case actionTypes.GET_EQUIPMENT_AIRPORT_STATISTICS_SUCCESS:
            return getEquipmentAirportStatisticsSuccess(state, action);

        case actionTypes.GET_GSE_STATISTICS_SUCCESS:
            return getGseStatisticsSuccess(state, action);

        case actionTypes.GET_STATISTICS_AIRPORTS_SUCCESS:
            return getStatisticsAirportsSuccess(state, action);

        case actionTypes.GET_TRACKING_SUCCESS:
            return getTrackingSuccess(state, action);

        case actionTypes.GET_TRACKING_GSE_SUCCESS:
            return getTrackingGseSuccess(state, action);

        case actionTypes.RESET_TRACKING:
            return resetTracking(state);

        case actionTypes.RESET_STATISTICS:
            return resetStatistics(state);

        default:
            return state;
    }
};

export default reducer;
