import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import classes from './PrimaryYearMonthPicker.module.css';

function PrimaryYearMonthPicker(props) {
    const datepickerRef = React.useRef(null);

    const initValue = props.value;
    const [startDate, setStartDate] = React.useState(initValue);
    const [isApplied, setIsApplied] = React.useState(false);

    useEffect(() => {
        setStartDate(props.value);
    }, [props.value]);

    let classNames = [classes.PrimaryDatePicker, classes.PrimaryDateRangePicker];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    const closePicker = () => {
        setIsApplied(false);
        setStartDate(initValue);
        datepickerRef.current.setOpen(false);
    };
    const apply = () => {
        setIsApplied(true);
        props.dateChanged(startDate);
        datepickerRef.current.setOpen(false);
    };

    const onChange = (value) => {
        setStartDate(value);
    };

    const DefaultInput = React.forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} ref={ref}>
            {value ? value : props.placeholder}
        </div>
    ));

    const MyContainer = ({ className, children }) => {
        return (
            <div className={className} style={{ background: '#fff' }}>
                <div>{children}</div>

                <div className={classes.CloseButton}>
                    <span onClick={closePicker}>Cancel</span>
                    <span onClick={apply}>Ok</span>
                </div>
            </div>
        );
    };

    // Catch click outside the calendar. Fires after buttons click as well. If ok button was not clicked, revert to previous
    const onCalendarClose = () => {
        if (!isApplied) {
            setStartDate(props.value);
        }
        setIsApplied(false);
    };

    return (
        <div className={classNames.join(' ')}>
            <DatePicker
                ref={datepickerRef}
                dateFormat='MM/yyyy'
                onChange={(value) => onChange(value)}
                calendarContainer={MyContainer}
                customInput={<DefaultInput />}
                isClearable={props.isClearable ? true : false}
                withPortal
                showMonthYearPicker
                selected={startDate}
                //placeholderText={props.placeholder}
                minDate={props.minDate}
                maxDate={props.maxDate}
                onCalendarClose={onCalendarClose}
                shouldCloseOnSelect={false}
                showFourColumnMonthYearPicker
            />
        </div>
    );
}

export default PrimaryYearMonthPicker;
