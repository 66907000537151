// Login ---------------------------------------------------
export const SHOW_LOGIN_REGISTER = 'SHOW_LOGIN_REGISTER';
export const HIDE_LOGIN_REGISTER = 'HIDE_LOGIN_REGISTER';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const REGISTER_USER = 'REGISTER_USER';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_USER_FROM_LOCAL_STORAGE = 'GET_USER_FROM_LOCAL_STORAGE';
export const ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION';
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const SHOW_LOADING_SPINNER = 'SHOW_LOADING_SPINNER';
export const HIDE_LOADING_SPINNER = 'HIDE_LOADING_SPINNER';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';

// _data ---------------------------------------------------
export const UPDATE_DATA_STATE_PROPERTY = 'UPDATE_DATA_STATE_PROPERTY';
export const GET_GSE_GENERAL_INFO_SUCCESS = 'GET_GSE_GENERAL_INFO_SUCCESS';
export const GET_GSE_SUCCESS = 'GET_GSE_SUCCESS';
export const GET_GSE_EDIT_SUCCESS = 'GET_GSE_EDIT_SUCCESS';
export const CLEAR_GSE_EDIT_DETAILS = 'CLEAR_GSE_EDIT_DETAILS';
export const UPDATE_GSE_PROPERTY = 'UPDATE_GSE_PROPERTY';
export const GET_AIRPORTS_SUCCESS = 'GET_AIRPORTS_SUCCESS';
export const GET_FLIGHT_SUCCESS = 'GET_FLIGHT_SUCCESS';
export const GET_FLIGHTS_SUCCESS = 'GET_FLIGHTS_SUCCESS';
export const GET_FLIGHTS_EQUIPMENT_SUCCESS = 'GET_FLIGHTS_EQUIPMENT_SUCCESS';
export const GET_REFUELING_SUCCESS = 'GET_REFUELING_SUCCESS';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_GSE_IMAGE_SUCCESS = 'GET_GSE_IMAGE_SUCCESS';
export const ADD_TO_UPLOADS = 'ADD_TO_UPLOADS';
export const UPDATE_UPLOAD_ID = 'UPDATE_UPLOAD_ID';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
export const GET_GSE_UPLOADS_SUCCESS = 'GET_GSE_UPLOADS_SUCCESS';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPDATE_FILES_TO_UPLOAD = 'UPDATE_FILES_TO_UPLOAD';
export const GET_LAST_LOCATION_SUCCESS = 'GET_LAST_LOCATION_SUCCESS';
export const UPDATE_FILTERED_DATA = 'UPDATE_FILTERED_DATA';
export const GET_RESETS_SUCCESS = 'GET_RESETS_SUCCESS';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const GET_TIRES_SUCCESS = 'GET_TIRES_SUCCESS';
export const GET_BATTERIES_SUCCESS = 'GET_BATTERIES_SUCCESS';
export const GET_GSE_SERVICE_SUCCESS = 'GET_GSE_SERVICE_SUCCESS';
export const GET_ALL_AIRPORT_LOCATIONS_SUCCESS = 'GET_ALL_AIRPORT_LOCATIONS_SUCCESS';
export const GET_MAP_GSES_FLIGHTS_INFO_SUCCESS = 'GET_MAP_GSES_FLIGHTS_INFO_SUCCESS';
export const GET_MAP_TRIPS_FLIGHTS_DATA_SUCCESS = 'GET_MAP_TRIPS_FLIGHTS_DATA_SUCCESS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

// GseData ---------------------------------------------------
export const TOGGLE_FIELD_CHECK_ALL = 'TOGGLE_FIELD_CHECK_ALL';
export const TOGGLE_FIELD_CHECK = 'TOGGLE_FIELD_CHECK';
export const UPDATE_GSE_DATA_PROPERTY = 'UPDATE_GSE_DATA_PROPERTY';
export const RESET_GSE_DATA = 'RESET_GSE_DATA';

// Admin ---------------------------------------------------
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_LISTS_SUCCESS = 'GET_ALL_LISTS_SUCCESS';
export const GET_ALL_AIRPORTS_SUCCESS = 'GET_ALL_AIRPORTS_SUCCESS';
export const UPDATE_ADMIN_STATE = 'UPDATE_ADMIN_STATE';
export const GET_AIRPORT_LOCATIONS_SUCCESS = 'GET_AIRPORT_LOCATIONS_SUCCESS';
export const UPDATE_MAP_CENTER_SUCCESS = 'UPDATE_MAP_CENTER_SUCCESS';
export const RESET_ADMIN = 'RESET_ADMIN';
export const GET_TRACKERS_SUCCESS = 'GET_TRACKERS_SUCCESS';
export const GET_SIMS_SUCCESS = 'GET_SIMS_SUCCESS';

// Statistics ---------------------------------------------------
export const RESET_STATISTICS = 'RESET_STATISTICS';
export const GET_COMPANY_STATISTICS_SUCCESS = 'GET_COMPANY_STATISTICS_SUCCESS';
export const GET_AIRPORT_STATISTICS_SUCCESS = 'GET_AIRPORT_STATISTICS_SUCCESS';
export const GET_GSE_PREFILTER_STATISTICS_SUCCESS = 'GET_GSE_PREFILTER_STATISTICS_SUCCESS';
export const GET_EQUIPMENT_STATISTICS_SUCCESS = 'GET_EQUIPMENT_STATISTICS_SUCCESS';
export const GET_EQUIPMENT_AIRPORT_STATISTICS_SUCCESS = 'GET_EQUIPMENT_AIRPORT_STATISTICS_SUCCESS';
export const GET_GSE_EQUIPMENT_AND_AIRPORTS_SUCCESS = 'GET_GSE_EQUIPMENT_AND_AIRPORTS_SUCCESS';
export const GET_GSE_STATISTICS_SUCCESS = 'GET_GSE_STATISTICS_SUCCESS';
export const GET_STATISTICS_AIRPORTS_SUCCESS = 'GET_STATISTICS_AIRPORTS_SUCCESS';
export const GET_TRACKING_SUCCESS = 'GET_TRACKING_SUCCESS';
export const GET_TRACKING_GSE_SUCCESS = 'GET_TRACKING_GSE_SUCCESS';
export const RESET_TRACKING = 'RESET_TRACKING';

// System ---------------------------------------------------
export const LOADING = 'LOADING';
export const PART_LOADING = 'PART_LOADING';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';

// export const API_REQUEST = "API_REQUEST";
// export const API_SUCCESS = "API_SUCCESS";
// export const API_ERROR = "API_ERROR";
// export const CANCEL_API_REQUEST = "CANCEL_API_REQUEST";
