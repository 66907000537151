import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

import classes from './Admin.module.css';
import * as actions from '../../store/actions/index';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import AdminEditAirport from './AdminEditAirport';


class AdminAirports extends Component {
    state = {
        tableOptions:{
            initialPage:0,
            pageSize:5,
            searchText:"",
            defaultSort:{column: null, order:""}
        }
    }

    componentDidMount () {
        this.props.onGetAllAirports();
    }
    
    getAirportsFields = () => {
        let tableFields = [
            {title: "id", field: "id", defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null},
            {title: "Name", field: "name", defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null},
            {title: "Short name", field: "shortName", defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null},
            {title: "City", field: "city", defaultSort: this.state.tableOptions.defaultSort.column === 3 ? this.state.tableOptions.defaultSort.order : null},
            {title: "Country", field: "country", defaultSort: this.state.tableOptions.defaultSort.column === 4 ? this.state.tableOptions.defaultSort.order : null},
            // {title: "Longitude", field: "lon"},
            // {title: "Latitude", field: "lat"}
        ]

        return tableFields;
    }

    prepareDeleteAirport(rowData){
        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The airport with id: ' + rowData.id + ' will be deleted.',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onDeleteAirport(rowData.id);
            }
        })
    }

    addAirport(){
        const selectedData = {
            name: "",
            shortName: "",
            city: "",
            country: ""
        }

        this.props.onUpdateAdminState("selectedAirport", selectedData)
    }

    updateTableOptions = (property, value) => {
        let tableOptions = {...this.state.tableOptions};
        tableOptions[property] = value;

        this.setState({tableOptions: tableOptions})
    }

    render () {
        const airportsFields = this.getAirportsFields();

        let page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Airports</h1>
                        </div>
                        <div className={classes.PageContent}>
                            <Loader show={this.props.loading} />
                        </div>
                    </Aux>

        if (this.props.selectedAirport){
            page = <AdminEditAirport />
        } 

        else if (this.props.loading === false){
            page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Airports</h1>
                            <div className={classes.MarginLeftAuto}>
                                <div className={classes.ActionButtonsWrapper}>
                                    <div className={classes.CancelButton} onClick={() => this.props.history.push('/admin')}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.PageContent}>
                            <div className={classes.PageInner}>
                                <Scrollbars style={{width:"100%", height:"100%"}}>
                                    <div className={classes.ScrollInner}>
                                        <div className={classes.AddAirportButtonWrapper}>
                                            <PrimaryButton clicked={() => this.addAirport()}>
                                                <FontAwesomeIcon icon={faPlus} />
                                                Add
                                            </PrimaryButton>
                                        </div>

                                        <DataTable 
                                            data={this.props.allAirports} 
                                            columns={airportsFields}
                                            editClicked={(rowData) => this.props.onUpdateAdminState("selectedAirport", rowData)}
                                            deleteClicked={(rowData)=>this.prepareDeleteAirport(rowData)}
                                            withExport
                                            withSearch
                                            tableOptions={this.state.tableOptions}
                                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        />
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                    </Aux> 
            
        }

        return(
            page
        )
    }
}

const mapStateToProps = state => {
    return {
        allAirports: state.admin.allAirports,
        loggedInUser:state.login.loggedInUser,
        loading:state._system.loading,
        selectedAirport: state.admin.selectedAirport
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetAllAirports: () => dispatch(actions.getAllAirports()),
        onUpdateAdminState: (property, value) => dispatch(actions.updateAdminState(property, value)),
        onDeleteAirport: (airportId) => dispatch(actions.deleteAirport(airportId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAirports);
