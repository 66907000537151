import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import PrimaryInput from '../PrimaryInput/PrimaryInput';
import classes from './PrimaryDatetimePicker.module.css';

function PrimaryDatePicker(props) {
    const datepickerRef = React.useRef(null);
    const initValue = props.value;
    const [tempValue, setTempValue] = React.useState(initValue);
    const [isApplied, setIsApplied] = React.useState(false);

    let classNames = [classes.PrimaryDatetimePicker];
    if (props.fullWidth) classNames.push(classes.FullWidth);
    else if (props.narrow) classNames.push(classes.Narrow);
    else if (props.veryNarrow) classNames.push(classes.VeryNarrow);

    const DefaultInput = React.forwardRef(({ value, onClick }, ref) => (
        <PrimaryInput label={props.label} type='text' value={value} name={props.label} onClick={onClick} />
    ));

    const closePicker = () => {
        setIsApplied(false);
        setTempValue(initValue);
        datepickerRef.current.setOpen(false);
    };
    const apply = () => {
        setIsApplied(true);
        props.dateChanged(tempValue);
        datepickerRef.current.setOpen(false);
    };

    const onChange = (value) => {
        setTempValue(value);

        // Clear was clicked
        if (!value) props.dateChanged(value);
    };

    const MyContainer = ({ className, children }) => {
        return (
            <div className={className} style={{ background: '#fff' }}>
                <div>{children}</div>

                <div className={classes.CloseButton}>
                    <span onClick={closePicker}>Cancel</span>
                    <span onClick={apply}>Ok</span>
                </div>
            </div>
        );
    };

    let minTime = null;
    let maxTime = null;

    if (tempValue && (props.minDate || props.maxDate)) {
        const valueDate = moment(new Date(tempValue)).format('DD/MM/yyyy');
        const minDate = moment(new Date(props.minDate)).format('DD/MM/yyyy');
        const maxDate = moment(new Date(props.maxDate)).format('DD/MM/yyyy');

        const isMinDate = valueDate === minDate ? true : false;
        const isMaxDate = valueDate === maxDate ? true : false;

        if (isMaxDate) {
            maxTime = new Date(props.maxDate);
            minTime = new Date(props.maxDate);
            minTime.setHours(0, 0, 0);
        } else if (isMinDate) {
            minTime = new Date(props.minDate);
            maxTime = new Date(props.minDate);
            maxTime.setHours(23, 59, 0);
        }
    }

    // Catch click outside the calendar. Fires after buttons click as well. If ok button was not clicked, revert to previous
    const onCalendarClose = () => {
        if (!isApplied) {
            setTempValue(initValue);
            props.dateChanged(initValue);
        }
        setIsApplied(false);
    };

    return (
        <div className={classNames.join(' ')}>
            <DatePicker
                ref={datepickerRef}
                dateFormat='dd/MM/yyyy HH:mm'
                selected={tempValue}
                onChange={(value) => onChange(value)}
                calendarContainer={MyContainer}
                customInput={<DefaultInput />}
                isClearable={true}
                withPortal
                showTimeSelect
                shouldCloseOnSelect={false}
                onCalendarClose={onCalendarClose}
                minDate={props.minDate}
                maxDate={props.maxDate}
                minTime={minTime}
                maxTime={maxTime}
            />
        </div>
    );
}

export default PrimaryDatePicker;
