import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilities';

const CONFIG = require('../../reactConfig.json');

const initialState = {
    showModal:false,
    show:"login",
    loggedIn:false,
    loggedInUser:null,
    confirmationMessage:null,
    showSpinner:false,
    userInfo:null
}

const showLoginRegister = (state,action) => {
    return updateObject(state, {showModal:true, show:action.showType});
}

const hideLoginRegister = (state) => {
    return updateObject(state, {showModal:false, show:"", confirmationMessage:null});
}

const authSuccess = (state,action) => {
    return updateObject(state, {
        loggedIn:true, 
        loggedInUser:action.user, 
        showModal:false, 
        show:"", 
        confirmationMessage:null
    });
}

const logoutSuccess = (state) => {
    return initialState;
}

const showConfirmation = (state,action) => {
    return updateObject(state, {
        showModal: true,
        show:"confirmation",
        confirmationMessage:action.confirmationMessage
    });
}

const showLoadingSpinner = (state) => {
    return updateObject(state, {showSpinner:true});
}

const hideLoadingSpinner = (state) => {
    return updateObject(state, {showSpinner:false});
}

const getUserInfoSuccess = (state, action) => {
    return updateObject(state, {userInfo:action.userInfo});
}

const updateVariable = (state, action) => {
    return updateObject(state, {[action.variableName]:action.variableValue});
}

const getUserFromLocalStorage = (state) => {

    let user = {};
    
    if (localStorage.getItem(CONFIG.userTokenName) !== 'undefined') {     
        user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
    }

    let loggedIn = false;

    if (user !== null) loggedIn = true;

    return updateObject(state, {loggedIn:loggedIn, loggedInUser:user});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SHOW_LOGIN_REGISTER: return showLoginRegister(state,action);
        case actionTypes.HIDE_LOGIN_REGISTER: return hideLoginRegister(state);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state,action);
        case actionTypes.LOGOUT_SUCCESS: return logoutSuccess(state);
        case actionTypes.SHOW_CONFIRMATION: return showConfirmation(state,action);
        case actionTypes.SHOW_LOADING_SPINNER: return showLoadingSpinner(state);
        case actionTypes.HIDE_LOADING_SPINNER: return hideLoadingSpinner(state);
        case actionTypes.GET_USER_INFO_SUCCESS: return getUserInfoSuccess(state, action);
        case actionTypes.UPDATE_VARIABLE: return updateVariable(state, action);
        case actionTypes.GET_USER_FROM_LOCAL_STORAGE: return getUserFromLocalStorage(state);
        default: return state;
    }
};

export default reducer;