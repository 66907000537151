import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

import classes from './Admin.module.css';
import * as actions from '../../store/actions/index';
import DataTable from '../../components/DataTable/DataTable';
import Modal from "../../components/UI/Modal/Modal";
import AdminEditList from './AdminEditList';
import Loader from '../../components/UI/Loader/Loader';
import Block from '../../components/Block/Block';

class AdminLists extends Component {
    
    state={
        showModal:false,
        selectedData: null,
        action:null,
        selectedListItem: null
    }

    componentDidMount () {
        this.props.onGetAllLists();
    }

    getListFields = () => {
        let tableFields = [
            {title: "Name", field: "fieldName"}
        ]

        return tableFields;
    }
    
    onEditSelectedListItem = (choiceId, value) => {
        let selectedListItem = {...this.state.selectedListItem};

        let updateChoice = selectedListItem.fieldChoices.find(x => x.id == choiceId);

        if (updateChoice)
            updateChoice.choice = value;

        this.setState({
            selectedListItem:selectedListItem
        })
    }

    editList = (data) => {
        const selectedListItem = JSON.parse(JSON.stringify(data)); // needed deep copy, because there is an array in a property
        this.setState({selectedListItem:selectedListItem, showModal:true, action:"editList"})
    }

    prepareSaveList = () => {
        const postData = JSON.parse(JSON.stringify(this.state.selectedListItem));
        
        Swal.fire({
            type: 'warning',
            title: 'Are you sure?',
            text: 'The choices will be updated',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
            preConfirm: () => {
                this.props.onSaveChoices(postData);
                this.setState({showModal:false, action:null, selectedListItem:null})
            }
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    type: 'success',
                    text: 'The choices were updated',
                })
            }
            
        })
    }

    addField = () => {
        // Hack to create an id for the new choice. This will be deleted in save
        let selectedListItem = {...this.state.selectedListItem};
        const newId = (selectedListItem.fieldChoices.length + 1) * -1;
        const newChoice = {
            "id": newId,
            "fieldId": selectedListItem.id,
            "choice": ""
        }
        selectedListItem.fieldChoices.unshift(newChoice);

        this.setState({ selectedListItem: selectedListItem })
    }

    deleteField = (field) => {
        let selectedListItem = {...this.state.selectedListItem};

        selectedListItem.fieldChoices.find(x => x.id === field.id)
        const fieldIndex = selectedListItem.fieldChoices.findIndex(x => x.id === field.id);

        if (fieldIndex > -1){
            const fieldId = field.id;

            // If the field has not been saved, just remove it from the array
            if (fieldId < 0){
                selectedListItem.fieldChoices.splice(fieldIndex, 1);
                this.setState({ selectedListItem: selectedListItem });

            } else {
                Swal.fire({
                    type: 'warning',
                    title: 'Are you sure?',
                    text: 'The action is irreversible',
                    showCancelButton: true,
                    cancelButtonText: 'No',
                    confirmButtonText: 'Yes',
                    allowOutsideClick: false,
                    preConfirm: () => {
                        this.props.onDeleteField(fieldId);
                    }
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            type: 'success',
                            text: 'The choice was deleted.'
                        })
                        selectedListItem.fieldChoices.splice(fieldIndex, 1);
                        this.setState({ selectedListItem: selectedListItem });
                    }
                });
            }
        }        
    }

    render () {
        const listFields = this.getListFields();

        let page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Lists</h1>
                        </div>
                        <div className={classes.PageContent}>
                            <Loader show={this.props.loading} />
                        </div>
                    </Aux>

        if (this.props.loading === false){
            page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Lists</h1>
                            <div className={classes.MarginLeftAuto}>
                                <div className={classes.ActionButtonsWrapper}>
                                    <div className={classes.CancelButton} onClick={() => this.props.history.push('/admin')}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.PageContent}>
                            <div className={classes.PageInner}>
                                <Scrollbars style={{width:"100%", height:"100%"}}>
                                    <div className={classes.ScrollInner}>
                                        <div className={classes.VerticalSpacerHalf}></div>

                                        <Block title="General information">
                                            <DataTable 
                                                data={this.props.allFields.generalInfo} 
                                                columns={listFields} 
                                                editClicked={(rowData)=>this.editList(rowData)}
                                            />
                                        </Block>

                                        <div className={classes.VerticalSpacer}></div>

                                        <Block title="Technical specifications">
                                            <DataTable 
                                                data={this.props.allFields.technicalSpecs} 
                                                columns={listFields} 
                                                editClicked={(rowData)=>this.editList(rowData)}
                                            />
                                        </Block>

                                        <div className={classes.VerticalSpacer}></div>

                                        <Block title="Main engine specifications">
                                            <DataTable 
                                                data={this.props.allFields.engineSpecs}
                                                columns={listFields} 
                                                editClicked={(rowData)=>this.editList(rowData)}
                                            />
                                        </Block>

                                        {/* <div className={classes.VerticalSpacer}></div>

                                        <Block title="Auxilliary engine specifications">
                                            <DataTable 
                                                data={this.props.allFields.auxEngine} 
                                                columns={listFields}
                                                editClicked={(rowData)=>this.editList(rowData)}
                                            />
                                        </Block>*/}

                                        <div className={classes.VerticalSpacer}></div>
                                        
                                        <Block title="Tyres">
                                            <DataTable 
                                                data={this.props.allFields.tyre} 
                                                columns={listFields} 
                                                editClicked={(rowData)=>this.editList(rowData)}
                                            />
                                        </Block>

                                        <div className={classes.VerticalSpacer}></div>
                                        
                                        <Block title="Airport">
                                            <DataTable 
                                                data={this.props.allFields.airport} 
                                                columns={listFields} 
                                                editClicked={(rowData)=>this.editList(rowData)}
                                            />
                                        </Block>
                                    </div>
                                </Scrollbars>
                            </div>
                        
                        </div>

                        <Modal 
                            show={this.state.showModal}
                            modalClosed={() => this.setState({showModal:false, action:null, password:"", confirmPassword:""})}
                        >
                            {
                                this.state.action === "editList"
                                ? <AdminEditList
                                    selectedListItem={this.state.selectedListItem}
                                    editSelectedListItem={(choiceId, value)=>this.onEditSelectedListItem(choiceId, value)}
                                    saveList={() => this.prepareSaveList()}
                                    addField={(field) => this.addField(field)}
                                    deleteField={(field) => this.deleteField(field)}
                                    cancel={() => this.setState({showModal:false, action:null, selectedListItem:null})}
                                />
                                : null
                            }
                            
                        </Modal>
                    </Aux> 
        }
        return(
            page
            
        )
    }
}

const mapStateToProps = state => {
    return {
        allFields: state.admin.allFields,
        loggedInUser:state.login.loggedInUser,
        loading:state._system.loading
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetAllLists: () => dispatch(actions.getAllLists()),
        onSaveChoices: (listItem) => dispatch(actions.saveChoices(listItem)),
        onDeleteField: (fieldId) => dispatch(actions.deleteField(fieldId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLists);
