import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities';
import moment from 'moment';
import _ from 'lodash';

const initialState = {
    gseGeneralInfo: [],
    filtered_gseData: [],
    gseDetails: null,
    gseEditDetails: null,
    gseServices: [],
    flight: null,
    flights: [],
    flightsEquipment: [],
    airports: [],
    airportsOptions: [],
    selectedAirport: null,
    history: [],
    routePoints: [],
    routes: [],
    refueling: [],
    maintenance: [],
    ddLists: {},
    filtersDdlists: {
        vehicleType: [],
        equipmentType: [],
        model: [],
        manufacturer: [],
    },
    filesToUpload: [],
    lastLocations: [],
    filtered_lastLocations: [],
    gseResets: [],
    serviceRecords: [],
    filtered_serviceRecords: [],
    tiresRecords: [],
    filtered_tiresRecords: [],
    batteriesRecords: [],
    filtered_batteriesRecords: [],
    selectedFlightsInfoDate: moment().format('YYYY-MM-DD'),
    allAirportLocations: [],
    tripGseOptions: [],
    tripEquipmentTypeOptions: [],
    tripFlightsOptions: [],
    tripsData: null,
    notifications: null,
};

const updateDataStateProperty = (state, action) => {
    return updateObject(state, { [action.property]: action.value });
};

const getGseGeneralInfoSuccess = (state, action) => {
    const sortedGse = action.gseGeneralInfo.sort((a, b) => b.gseId - a.gseId);
    let ddListObj = {};
    let filtersDdlists = {
        vehicleType: [],
        equipmentType: [],
        model: [],
        manufacturer: [],
    };

    // Create object from array of ddLists, for faster access
    for (let property in state.ddLists) {
        const ddListArr = [...state.ddLists[property]];
        const ddListsObj = ddListArr.reduce(function (acc, cur) {
            acc[cur.value] = cur.label;
            return acc;
        }, {});

        ddListObj[property] = ddListsObj;
    }

    // Add airports
    const airportsObj = state.airportsOptions.reduce(function (acc, cur) {
        acc[cur.value] = cur.label;
        return acc;
    }, {});
    ddListObj.airportId = airportsObj;

    // Replace the generalInfo with the labels instead of the ids (for search to work)
    for (let gse of sortedGse) {
        for (let gseProperty in gse) {
            let value = gse[gseProperty];
            if (ddListObj[gseProperty]) {
                gse['label' + gseProperty] = ddListObj[gseProperty][value];
            }
            if (gseProperty === 'vehicleType' || gseProperty === 'equipmentType' || gseProperty === 'model' || gseProperty === 'manufacturer') {
                if (value && filtersDdlists[gseProperty].findIndex((x) => x.value === value) < 0) {
                    filtersDdlists[gseProperty].push({ value: value, label: value });
                }
            }
        }
    }

    // Get filtered data, based on the filters
    const filtered_gseData = updateFromFilters(sortedGse, 'gseData');

    return updateObject(state, {
        gseGeneralInfo: sortedGse,
        filtered_gseData: filtered_gseData,
        filtersDdlists: filtersDdlists,
    });
};

const getGseSuccess = (state, action) => {
    let gseDetails = action.gseDetails[0];
    gseDetails.uploads = action.gseUploads;

    return updateObject(state, { gseDetails: gseDetails });
};

const getGseEditSuccess = (state, action) => {
    let gseEditDetails = { ...action.gseEditDetails[0] };
    gseEditDetails.uploads = action.gseUploads;

    return updateObject(state, { gseEditDetails: gseEditDetails });
};

const updateGseProperty = (state, action) => {
    let gseEditDetails = { ...state.gseEditDetails };

    let value = action.value;
    if (moment.isMoment(value)) {
        value = value.format('YYYY-MM-DDTHH:mm:ss');
    }

    gseEditDetails[action.category][action.property] = value;

    return updateObject(state, { gseEditDetails: gseEditDetails });
};

const clearGseEditDetails = (state) => {
    return updateObject(state, {
        gseDetails: null,
        gseEditDetails: null,
    });
};

const getAirportsSuccess = (state, action) => {
    let airportsOptions = [];

    for (let airport of action.airports) {
        airportsOptions.push({ label: airport.shortName, value: airport.id });
    }

    airportsOptions.sort((a, b) => a.label.localeCompare(b.label));

    return updateObject(state, {
        airports: action.airports,
        airportsOptions: airportsOptions,
        selectedAirport: action.airports[0],
    });
};

const getFlightSuccess = (state, action) => {
    return updateObject(state, { flight: action.flight });
};

const getFlightsSuccess = (state, action) => {
    return updateObject(state, { flights: action.flights });
};

const getFlightsEquipmentSuccess = (state, action) => {
    return updateObject(state, { flightsEquipment: action.flightsEquipment });
};

const getHistorySuccess = (state, action) => {
    let historyItems = [],
        routePoints = [],
        routes = [],
        route = { id: 1, points: [] },
        routeId = 1,
        item,
        itemLat,
        itemLng,
        itemTimeStamp,
        timeDif,
        checkLocation = { latitude: null, longitude: null, timeStamp: null };
    if (Array.isArray(action.history)) {
        historyItems = [...action.history];
        historyItems.sort((a, b) => (new Date(a.timeStamp) > new Date(b.timeStamp) ? 1 : -1));

        checkLocation.latitude = historyItems[0].latitude;
        checkLocation.longitude = historyItems[0].longitude;
        checkLocation.timeStamp = new Date(historyItems[0].timeStamp);

        // Create the routepoints
        for (let i = 0; i < historyItems.length; i++) {
            item = historyItems[i];
            itemLat = item.latitude;
            itemLng = item.longitude;
            itemTimeStamp = new Date(item.timeStamp);
            timeDif = itemTimeStamp - checkLocation.timeStamp;

            // Has not moved. Do nothing
            if (i > 0 && itemLat === checkLocation.latitude && itemLng === checkLocation.longitude) {
                checkLocation.timeStamp = itemTimeStamp;

                // Has moved
            } else {
                checkLocation.latitude = itemLat;
                checkLocation.longitude = itemLng;
                checkLocation.timeStamp = itemTimeStamp;

                // 3 minutes since it last moved -> new route
                if (timeDif > 180000) {
                    // store the previous route
                    if (route.points.length > 1) {
                        routePoints.push({ ...route });
                        routeId = routeId + 1;
                    }

                    // Create a new route and push the previous item (beginning of route) and this item
                    route = { id: routeId, points: [] };
                    if (i > 0) route.points.push(historyItems[i - 1]);
                    route.points.push(item);

                    // 3 minutes have not passed -> same route
                } else {
                    route.points.push(item);
                }
            }
        }

        // Add the last route
        if (route.points.length > 1) {
            routePoints.push({ ...route });
        }

        // Create the routes
        routePoints.forEach((item) => {
            let points = item.points;
            let newRoute = { id: item.id, points: [], selected: false };

            points.forEach((point) => {
                let pointLat = point.latitude;
                let pointLng = point.longitude;
                let newPoint = [pointLat, pointLng];

                newRoute.points.push(newPoint);
            });

            routes.push(newRoute);
        });
    }

    return updateObject(state, {
        history: historyItems,
        routePoints: routePoints,
        routes: routes,
    });
};

const getRefuelingSuccess = (state, action) => {
    const sortedRefueling = action.refueling.sort((a, b) => moment(b.dateTime).format('YYYYMMDDHHmmss') - moment(a.dateTime).format('YYYYMMDDHHmmss'));

    return updateObject(state, { refueling: sortedRefueling });
};

const getGseImageSuccess = (state, action) => {
    const thisImage = {
        imageFolder: action.gseId,
        imageHash: action.imageHash,
        imageData: action.imageData,
        fileId: action.fileId,
    };

    let gseEditDetails = null;
    let gseDetails = null;

    if (state.gseEditDetails) {
        gseEditDetails = { ...state.gseEditDetails };
        if (!gseEditDetails.images) gseEditDetails.images = [];
        gseEditDetails.images.push(thisImage);
    }

    if (state.gseDetails) {
        gseDetails = { ...state.gseDetails };
        if (!gseDetails.images) gseDetails.images = [];
        gseDetails.images.push(thisImage);
    }

    return updateObject(state, {
        gseDetails: gseDetails,
        gseEditDetails: gseEditDetails,
    });
};

const addToUploads = (state, action) => {
    let gseEditDetails = { ...state.gseEditDetails };
    if (!gseEditDetails.uploads) {
        gseEditDetails.uploads = [];
    }

    gseEditDetails.uploads.push(action.fileInfo);

    // Delete from filesToUpload
    let filesToUpload = [...state.filesToUpload];
    let fileIndex = filesToUpload.findIndex((f) => f.file.name === action.fileInfo.originalName);
    if (fileIndex > -1) filesToUpload.splice(fileIndex, 1);

    return updateObject(state, {
        gseEditDetails: gseEditDetails,
        filesToUpload: filesToUpload,
    });
};

const updateUploadId = (state, action) => {
    let gseEditDetails = { ...state.gseEditDetails };
    const fileFound = gseEditDetails.uploads.find((x) => x.filePath === action.newFile.filePath);

    if (fileFound) fileFound.id = action.fileId;

    return updateObject(state, { gseEditDetails: gseEditDetails });
};

const getGseUploadsSuccess = (state, action) => {
    let gseDetails = { ...state.gseDetails };
    gseDetails.uploads = action.gseUploads;

    return updateObject(state, { gseDetails: gseDetails });
};

const deleteFileSuccess = (state, action) => {
    let gseEditDetails = { ...state.gseEditDetails };

    if (gseEditDetails.uploads) {
        const fileIndex = gseEditDetails.uploads.findIndex((x) => x.filePath === action.fileHash);
        if (fileIndex > -1) gseEditDetails.uploads.splice(fileIndex, 1);
    }

    if (gseEditDetails.images) {
        const imageIndex = gseEditDetails.images.findIndex((x) => x.imageHash === action.fileHash);
        if (imageIndex > -1) gseEditDetails.images.splice(imageIndex, 1);
    }

    return updateObject(state, { gseEditDetails: gseEditDetails });
};

const getListsSuccess = (state, action) => {
    let ddLists = {};

    for (let field of action.fields) {
        let fieldId = field.id;
        let filteredChoices = action.fieldChoices.filter((x) => x.fieldId === fieldId);

        let ddChoices = [];
        for (let filteredChoice of filteredChoices) {
            ddChoices.push({
                label: filteredChoice.choice,
                value: filteredChoice.id.toString(),
            });
        }
        const sortedChoices = ddChoices.sort((a, b) => a.label - b.label);

        ddLists[field.variableName] = sortedChoices;
    }

    return updateObject(state, { ddLists: ddLists });
};

const uploadProgress = (state, action) => {
    let filesToUpload = [...state.filesToUpload];

    if (!filesToUpload[action.fileIndex].uploadProgress) {
        filesToUpload[action.fileIndex].uploadProgress = 0;
    }

    filesToUpload[action.fileIndex].uploadProgress = action.percentCompleted;

    return updateObject(state, { filesToUpload: filesToUpload });
};

const updateFilesToUpload = (state, action) => {
    let filesToUpload = [];

    if (action.fileType === 'image') {
        const files = state.filesToUpload.filter((f) => f.fileType !== 'image');
        const images = action.files.filter((f) => f.fileType === 'image');

        filesToUpload = [...files, ...images];
    } else {
        const images = state.filesToUpload.filter((f) => f.fileType === 'image');
        const files = action.files.filter((f) => f.fileType !== 'image');

        filesToUpload = [...files, ...images];
    }

    return updateObject(state, { filesToUpload: filesToUpload });
};

const getLastLocationSuccess = (state, action) => {
    let lastLocations = [];

    for (let device of action.devices) {
        if ((device.lat !== 0 || device.lon !== 0) && device.lat !== null && device.lon !== null) {
            let gse = null;
            let foundGse = action.gses.find((g) => g.gseId === device.gseId);

            if (foundGse) {
                gse = foundGse;
            }
            device.gse = gse;

            lastLocations.push(device);
        }
    }

    // Get filtered data, based on the filters
    const filtered_lastLocations = updateFromFilters(lastLocations, 'lastLocations');

    return updateObject(state, {
        lastLocations: lastLocations,
        filtered_lastLocations: filtered_lastLocations,
    });
    // return updateObject(state, {lastLocations:lastLocations});
};

const updateFilteredData = (state, action) => {
    // Get filtered data, based on the filters
    const filteredData = updateFromFilters(action.data, action.dataType);

    return updateObject(state, { ['filtered_' + action.dataType]: filteredData });
};

const updateFromFilters = (data, type) => {
    // Get filtered data, based on the filters
    const storedFilters = JSON.parse(localStorage.getItem('filters')) || [];
    let filteredData = [];

    if (storedFilters.length === 0) {
        filteredData = _.cloneDeep(data);
    } else {
        let groupedFilters = {};

        // Group the filters
        for (let i = 0; i < storedFilters.length; i++) {
            const storedFilter = storedFilters[i];
            const property = storedFilter.property;
            const value = storedFilter[property];

            if (!groupedFilters[property]) {
                groupedFilters[property] = [];
            }
            groupedFilters[property].push(value);
        }

        const keys = Object.keys(groupedFilters);

        for (let k = 0; k < data.length; k++) {
            let isValid = true;
            const item = data[k];

            keys.forEach((key) => {
                let checkFilters = groupedFilters[key];
                let itemValue = null;

                if (type === 'lastLocations') {
                    if (item.gse) {
                        itemValue = item.gse[key];
                    }
                } else {
                    itemValue = item[key];
                }

                if (!checkFilters.includes(itemValue)) {
                    isValid = false;
                }
            });

            if (isValid) {
                filteredData.push(item);
            }
        }
    }

    return filteredData;
};

const getResetsSuccess = (state, action) => {
    const sortedGseResets = action.gseResets.sort((a, b) => b.id - a.id);
    return updateObject(state, { gseResets: sortedGseResets });
};

const getServiceSuccess = (state, action) => {
    const sortedServiceRecords = action.serviceRecords.sort(
        (a, b) => moment(b.datePerformed).format('YYYYMMDDHHmmss') - moment(a.datePerformed).format('YYYYMMDDHHmmss')
    );
    const filtered_serviceRecords = updateFromFilters(sortedServiceRecords, 'service');
    return updateObject(state, {
        serviceRecords: sortedServiceRecords,
        filtered_serviceRecords: filtered_serviceRecords,
    });
};

const getTiresSuccess = (state, action) => {
    const sortedTiresRecords = action.tiresRecords.sort(
        (a, b) => moment(b.datePerformed).format('YYYYMMDDHHmmss') - moment(a.datePerformed).format('YYYYMMDDHHmmss')
    );
    const filtered_tiresRecords = updateFromFilters(sortedTiresRecords, 'tires');
    return updateObject(state, {
        tiresRecords: sortedTiresRecords,
        filtered_tiresRecords: filtered_tiresRecords,
    });
};

const getBatteriesSuccess = (state, action) => {
    const sortedBatteriesRecords = action.batteriesRecords.sort(
        (a, b) => moment(b.datePerformed).format('YYYYMMDDHHmmss') - moment(a.datePerformed).format('YYYYMMDDHHmmss')
    );
    const filtered_batteriesRecords = updateFromFilters(sortedBatteriesRecords, 'batteries');
    return updateObject(state, {
        batteriesRecords: sortedBatteriesRecords,
        filtered_batteriesRecords: filtered_batteriesRecords,
    });
};

const getGseServiceSuccess = (state, action) => {
    for (let tiresItem of action.tires) {
        tiresItem.jobDescription = 'Tyre replacement';
    }
    for (let batteriesItem of action.batteries) {
        batteriesItem.jobDescription = 'Battery replacement';
    }

    const gseServices = [...action.service, ...action.tires, ...action.batteries];
    const sortedGseServices = gseServices.sort((a, b) => moment(b.datePerformed).format('YYYYMMDDHHmmss') - moment(a.datePerformed).format('YYYYMMDDHHmmss'));

    return updateObject(state, { gseServices: sortedGseServices });
};

const getAllAirportLocationsSuccess = (state, action) => {
    return updateObject(state, {
        allAirportLocations: action.allAirportLocations,
    });
};

const getMapGsesFlightsInfoSuccess = (state, action) => {
    let tripFlightsOptions = [];
    let tripGseOptions = [];
    let tripEquipmentTypeOptions = [];

    action.flightsGses.flightInfos.forEach((flight) => {
        if (tripFlightsOptions.some((e) => e.label === flight.flightNumber)) {
        } else
            tripFlightsOptions.push({
                label: flight.flightNumber,
                value: flight.flightNumber,
            });
    });

    action.flightsGses.gseInfos.forEach((gse) => {
        tripGseOptions.push({
            label: gse.gsePlate,
            value: gse.gseId,
            equipmentType: gse.categoryId,
        });
        if (tripEquipmentTypeOptions.some((e) => e.value === gse.categoryId)) {
        } else
            tripEquipmentTypeOptions.push({
                label: gse.categoryDescr,
                value: gse.categoryId,
            });
    });

    tripFlightsOptions.sort((a, b) => a.label.localeCompare(b.label));
    tripGseOptions.sort((a, b) => a.label.localeCompare(b.label));
    tripEquipmentTypeOptions.sort((a, b) => a.label.localeCompare(b.label));

    return updateObject(state, {
        tripGseOptions: tripGseOptions,
        tripEquipmentTypeOptions: tripEquipmentTypeOptions,
        tripFlightsOptions: tripFlightsOptions,
    });
};

const getMapTripsFlightsDataSuccess = (state, action) => {
    return updateObject(state, { tripsData: action.tripsData });
};

const getNotificationsSuccess = (state, action) => {
    if (!action.notifications.notifications) return;

    let sortedNotifications = [...action.notifications.notifications];
    for (let sortedNotification of sortedNotifications) {
        sortedNotification.checkDate = sortedNotification.datetimeHappened ? sortedNotification.datetimeHappened : sortedNotification.datetimeNot;
    }

    sortedNotifications.sort((a, b) => b.checkDate.localeCompare(a.checkDate));

    return updateObject(state, { notifications: sortedNotifications });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_DATA_STATE_PROPERTY:
            return updateDataStateProperty(state, action);
        case actionTypes.GET_GSE_GENERAL_INFO_SUCCESS:
            return getGseGeneralInfoSuccess(state, action);
        case actionTypes.GET_GSE_SUCCESS:
            return getGseSuccess(state, action);
        case actionTypes.GET_GSE_EDIT_SUCCESS:
            return getGseEditSuccess(state, action);
        case actionTypes.UPDATE_GSE_PROPERTY:
            return updateGseProperty(state, action);
        case actionTypes.CLEAR_GSE_EDIT_DETAILS:
            return clearGseEditDetails(state);
        case actionTypes.GET_AIRPORTS_SUCCESS:
            return getAirportsSuccess(state, action);
        case actionTypes.GET_FLIGHT_SUCCESS:
            return getFlightSuccess(state, action);
        case actionTypes.GET_FLIGHTS_SUCCESS:
            return getFlightsSuccess(state, action);
        case actionTypes.GET_FLIGHTS_EQUIPMENT_SUCCESS:
            return getFlightsEquipmentSuccess(state, action);
        case actionTypes.GET_HISTORY_SUCCESS:
            return getHistorySuccess(state, action);
        case actionTypes.GET_REFUELING_SUCCESS:
            return getRefuelingSuccess(state, action);
        case actionTypes.GET_GSE_IMAGE_SUCCESS:
            return getGseImageSuccess(state, action);
        case actionTypes.ADD_TO_UPLOADS:
            return addToUploads(state, action);
        case actionTypes.UPDATE_UPLOAD_ID:
            return updateUploadId(state, action);
        case actionTypes.DELETE_FILE_SUCCESS:
            return deleteFileSuccess(state, action);
        case actionTypes.GET_LISTS_SUCCESS:
            return getListsSuccess(state, action);
        case actionTypes.UPLOAD_PROGRESS:
            return uploadProgress(state, action);
        case actionTypes.GET_GSE_UPLOADS_SUCCESS:
            return getGseUploadsSuccess(state, action);
        case actionTypes.UPDATE_FILES_TO_UPLOAD:
            return updateFilesToUpload(state, action);
        case actionTypes.GET_LAST_LOCATION_SUCCESS:
            return getLastLocationSuccess(state, action);
        case actionTypes.UPDATE_FILTERED_DATA:
            return updateFilteredData(state, action);
        case actionTypes.GET_RESETS_SUCCESS:
            return getResetsSuccess(state, action);
        case actionTypes.GET_SERVICE_SUCCESS:
            return getServiceSuccess(state, action);
        case actionTypes.GET_TIRES_SUCCESS:
            return getTiresSuccess(state, action);
        case actionTypes.GET_BATTERIES_SUCCESS:
            return getBatteriesSuccess(state, action);
        case actionTypes.GET_GSE_SERVICE_SUCCESS:
            return getGseServiceSuccess(state, action);
        case actionTypes.GET_ALL_AIRPORT_LOCATIONS_SUCCESS:
            return getAllAirportLocationsSuccess(state, action);
        case actionTypes.GET_MAP_GSES_FLIGHTS_INFO_SUCCESS:
            return getMapGsesFlightsInfoSuccess(state, action);
        case actionTypes.GET_MAP_TRIPS_FLIGHTS_DATA_SUCCESS:
            return getMapTripsFlightsDataSuccess(state, action);
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return getNotificationsSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
