import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from 'react-aux';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { ColorPicker } from 'material-ui-color';

import classes from './Admin.module.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import Block from '../../components/Block/Block';
import Loader from '../../components/UI/Loader/Loader';


class AdminSymbology extends Component {
    
    state={
        showModal:false,
        selectedData: null,
        action:null,
        symbology:{
            locationTypes:{

            }
        }
    }

    componentDidMount () {
        // this.props.onGetLists();
    }

    updateSymbology = (category, index, value) => {
        let symbology = {};
        symbology[category] = {...this.state.symbology[category]};
        symbology[category][index] = value;

        this.setState({symbology:symbology})
    }

    prepareSaveSymbology = () => {

    }

    render () {
        let page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Symbology</h1>
                        </div>
                        <div className={classes.PageContent}>
                            <Loader show={this.props.loading} />
                        </div>
                    </Aux>

        if (this.props.loading === false){
            page = <Aux>
                        <div className={classes.FiltersSpace}></div>
                        <div className={classes.PageTitle}>
                            <h1>Symbology</h1>
                            <div className={classes.MarginLeftAuto}>
                                <div className={classes.ActionButtonsWrapper}>
                                    <PrimaryButton clicked={() => this.prepareSaveSymbology()}>
                                        <FontAwesomeIcon icon={faSave} />
                                        Save
                                    </PrimaryButton>
                                    <div className={classes.CancelButton} onClick={() => this.props.history.push('/admin')}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.PageContent}>
                            <div className={classes.PageInner}>
                                <Scrollbars style={{width:"100%", height:"100%"}}>
                                    <div className={classes.ScrollInner}>
                                        <Block title="Airport locations">
                                            {this.props.ddLists && this.props.ddLists.locationTypes
                                                ?this.props.ddLists.locationTypes.map((locationType, index) => (
                                                    <div className={classes.StylingItemRow} key={`locationTypes_${index}`}>
                                                        <span className={classes.StylingItemName}>{locationType.label}</span>
                                                        <ColorPicker 
                                                            defaultValue="transparent"
                                                            value={this.state.symbology.locationTypes[index]} 
                                                            onChange={(value) => this.updateSymbology("locationTypes", index, value)} />
                                                    </div> 
                                                ))
                                                :null
                                            }
                                        </Block>
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                    </Aux> 
        }
        return(
            page
            
        )
    }
}

const mapStateToProps = state => {
    return {
        loggedInUser:state.login.loggedInUser,
        loading:state._system.loading,
        ddLists:state._data.ddLists
    };
}

const mapDispatchToProps = dispatch => {
    return {
        // onGetLists: () => dispatch(actions.getLists()),
        // onGetSymbology: () => dispatch(actions.getSymbology())

        // onDeleteUser: (userId) => dispatch(actions.deleteUser(userId)),
        // onUpdateUser: (userData) => dispatch(actions.updateUser(userData)),
        // onAddUser: (userData) => dispatch(actions.addUser(userData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSymbology);
