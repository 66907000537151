import * as actionTypes from './actionTypes';

export const toggleFieldCheckAll = (checkedAllFields) => {
    return {
        type: actionTypes.TOGGLE_FIELD_CHECK_ALL,
        checkedAllFields:checkedAllFields
    };
};

export const toggleFieldCheck = (index) => {
    return {
        type: actionTypes.TOGGLE_FIELD_CHECK,
        index: index
    };
};

export const updateGseDataProperty = (property, value) => {
    return {
        type: actionTypes.UPDATE_GSE_DATA_PROPERTY,
        property: property,
        value: value
    };
};

export const resetGseData = () => {
    return {
        type: actionTypes.RESET_GSE_DATA
    };
};