import React, { useCallback } from 'react';
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Line } from 'recharts';
import { saveAs } from 'file-saver';
import IconButton from '@material-ui/core/IconButton';
import SaveAlt from '@material-ui/icons/SaveAlt';
import MaterialTooltip from '@material-ui/core/Tooltip';
import { useCurrentPng } from 'recharts-to-png';

import classes from './MyBarChart.module.css';

const SimpleBarChart = (props) => {
    const [getPng, { ref: chartRef, isLoading }] = useCurrentPng();

    const exportChart = useCallback(async () => {
        const png = await getPng();
        if (png) {
            saveAs(png, `${props.name}.png`);
        }
    }, [getPng]);

    const barColors = ['#3a5d9d', '#4772c0', '#a7b5d8'];

    return (
        <React.Fragment>
            {props.data.length > 0 && (
                <React.Fragment>
                    <div className={classes.ExportToPngWrapper}>
                        {isLoading ? <span>Loading...</span> : null}
                        <MaterialTooltip title='Export to png'>
                            <IconButton onClick={exportChart}>
                                <SaveAlt />
                            </IconButton>
                        </MaterialTooltip>
                    </div>

                    <div className={classes.ChartWrapper}>
                        <ResponsiveContainer width='100%' height='100%'>
                            <ComposedChart
                                ref={chartRef}
                                width={500}
                                height={300}
                                data={props.data}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    left: 0,
                                    bottom: 20,
                                }}
                                barSize={30}
                            >
                                <CartesianGrid strokeDasharray='1' vertical={false} />
                                <XAxis
                                    dataKey={props.xDataKey}
                                    tickLine={false}
                                    axisLine={{ stroke: '#d5d5d5' }}
                                    tick={{ fill: '#8D9CAB', fontSize: '1.3rem' }}
                                />
                                <YAxis
                                    yAxisId='left'
                                    orientation='left'
                                    tickLine={false}
                                    axisLine={false}
                                    tick={{ fill: '#8D9CAB', fontSize: '1.3rem' }}
                                    label={{
                                        value: props.yLabel ? props.yLabel : '',
                                        angle: -90,
                                        position: 'insideLeft',
                                        fill: '#404b58',
                                        fontSize: '1.3rem',
                                        fontWeight: '700',
                                    }}
                                />
                                {props.withCummulative ? (
                                    <YAxis
                                        yAxisId='right'
                                        orientation='right'
                                        tickLine={false}
                                        axisLine={false}
                                        tick={{ fill: '#8D9CAB', fontSize: '1.3rem' }}
                                        domain={[0, 100]}
                                        unit='%'
                                        stroke='#82ca9d'
                                    />
                                ) : null}

                                <Tooltip />
                                <Legend
                                    layout='horizontal'
                                    align='center'
                                    verticalAlign='top'
                                    wrapperStyle={{
                                        paddingBottom: '30px',
                                    }}
                                />
                                {props.valKeys.map((valKey, index) => {
                                    return <Bar yAxisId='left' key={index} name={props.names[index]} dataKey={valKey} fill={barColors[index]} />;
                                })}
                                {props.withCummulative ? <Line yAxisId='right' name='Sum (%)' type='monotone' dataKey='cummulative' stroke='#ff7300' /> : null}
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </React.Fragment>
            )}
            {props.data.length === 0 && <span>No data</span>}
        </React.Fragment>
    );
};

export default SimpleBarChart;
