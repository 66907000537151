import React from 'react';
import Aux from 'react-aux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

import classes from './Admin.module.css';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimaryInput from '../../components/UI/PrimaryInput/PrimaryInput';
import PrimarySelect from '../../components/UI/PrimarySelect/PrimarySelect_controlled';
import { formatDateTime } from '../../store/utilities';
import { checkInputValidity } from '../../store/utilities';

const AdminEditUser = (props) => {
    return(
        <Aux>
            <div className={classes.ModalButtonsWrapper}>
                <PrimaryButton clicked={() => props.saveUser()}>
                    <FontAwesomeIcon icon={faSave} />
                    Save
                </PrimaryButton>
                <div className={classes.CancelButton} onClick={()=>props.cancel()}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>

            <div className={classes.EditRowsWrapper}>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimaryInput 
                            label="ID"
                            type="text"
                            value={props.selectedData.id}
                            name="id"
                            disabled
                        /> 
                        : null
                    }
                </div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimaryInput 
                            label="Registration date"
                            type="text"
                            value={formatDateTime(props.selectedData.registration_date)}
                            name="registration_date"
                            disabled
                        /> 
                        : null
                    }
                </div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimaryInput 
                            label="Name"
                            type="text"
                            value={props.selectedData.name}
                            changed={ (e) => props.editSelectedData(e.target.name, e.target.value) }
                            name="name"
                            invalid={
                                !checkInputValidity(
                                    props.selectedData.name,
                                    {
                                        required: true,
                                        minLength: 3
                                    }
                                )
                            }
                        /> 
                        : null
                    }
                </div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimaryInput 
                            label="Email"
                            type="text"
                            value={props.selectedData.email}
                            changed={ (e) => props.editSelectedData(e.target.name, e.target.value) }
                            name="email"
                            invalid={
                                !checkInputValidity(
                                    props.selectedData.email,
                                    {
                                        required: true,
                                        isEmail: true
                                    }
                                )
                            }
                        /> 
                        : null
                    }
                </div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimarySelect 
                            label="Role"
                            options={[{label:"Admin",value:"10"},{label:"User",value:"20"}]}
                            value={props.selectedData.role_id}
                            changed={(value) => props.editSelectedData("role_id", value)}
                            disabled={props.loggedInUser.id == props.selectedData.id}
                        />
                        : null
                    }
                </div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimarySelect 
                            label="Status"
                            options={[{label:"Active",value:"1"},{label:"Not active",value:"0"}]}
                            value={props.selectedData.active}
                            changed={(value) => props.editSelectedData("active", value)}
                            disabled={props.loggedInUser.id == props.selectedData.id}
                        />
                        : null
                    }
                </div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimaryInput 
                            label="Username"
                            type="text"
                            value={props.selectedData.username}
                            changed={ (e) => props.editSelectedData(e.target.name, e.target.value) }
                            name="username"
                            invalid={
                                !checkInputValidity(
                                    props.selectedData.username,
                                    {
                                        required: true,
                                        minLength: 5
                                    }
                                )
                            }
                        /> 
                        : null
                    }
                </div>

                <div className={classes.EditRow}></div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<PrimaryInput 
                            label="Password"
                            type="text"
                            value={props.password}
                            changed={ (e) => props.updateState("password", e.target.value) }
                            name="password"
                            invalid={
                                props.action === "new" || (props.action === "edit" && (props.password.trim() !== "" || props.confirmPassword.trim() !== ""))
                                ? !checkInputValidity(
                                    props.password,
                                    {
                                        required: true,
                                        minLength: 8
                                    }
                                )
                                : false
                            }
                        /> 
                        : null
                    }
                </div>

                <div className={classes.EditRow}>
                    {props.selectedData
                        ?<div className={classes.RandomPasswordWrapper}>
                            <PrimaryInput 
                                label="Confirm password"
                                type="text"
                                value={props.confirmPassword}
                                changed={ (e) => props.updateState("confirmPassword", e.target.value) }
                                name="confirmPassword"
                                invalid={
                                    props.action === "new" || (props.action === "edit" && (props.password.trim() !== "" || props.confirmPassword.trim() !== ""))
                                    ? !checkInputValidity(
                                        props.confirmPassword,
                                        {
                                            required: true,
                                            minLength: 8
                                        }
                                    )
                                    : false
                                }
                            /> 
                            <div className={classes.RandomPassword} onClick={() => props.generateRandomPassword()}>
                                <FontAwesomeIcon icon={faKey} />
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>        
        </Aux>   
    )
}

export default AdminEditUser;