import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import Block from '../../components/Block/Block';
import DataTable from '../../components/DataTable/DataTable';
import SimpleBarChart from '../../components/MyCharts/SimpleBarChart';
import PrimaryAutocomplete from '../../components/UI/PrimaryAutocomplete/PrimaryAutocomplete';
import PrimaryButton from '../../components/UI/PrimaryButton/PrimaryButton';
import PrimaryDatePicker from '../../components/UI/PrimaryDatetimePicker/PrimaryDatePicker';
import PrimaryYearPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearPicker';
import PrimaryYearMonthPicker from '../../components/UI/PrimaryDatetimePicker/PrimaryYearMonthPicker';
import classes from './Tracking.module.css';
import Loader from '../../components/UI/Loader/Loader';
import * as actions from '../../store/actions/index';

class Tracking extends Component {
    state = {
        selectedPeriod: 'month',
        selectedYear: null,
        selectedMonth: new Date(),
        selectedDay: null,
        selectedEquipment: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: 2, order: 'desc' },
        },
    };

    componentDidMount() {
        this.props.onGetGsePrefilterStatistics();
    }

    componentWillUnmount() {
        this.props.onResetTracking();
    }

    handleSelectedYear = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'year', selectedYear: value, selectedMonth: null, selectedDay: null, customPeriod: [null, null] });
        this.getTrips(value);
    };
    handleSelectedMonth = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'month', selectedYear: null, selectedMonth: value, selectedDay: null, customPeriod: [null, null] });
        this.getTrips(value);
    };
    handleSelectedDay = (value) => {
        if (!value) return;
        this.setState({ selectedPeriod: 'day', selectedYear: null, selectedMonth: null, selectedDay: value, customPeriod: [null, null] });
        this.getTrips(value);
    };

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    changeEquipment = (equipment) => {
        this.setState({ selectedEquipment: equipment });
    };

    getTrips = (dateValue) => {
        if (!this.state.selectedEquipment) return;
        let inputDay = new Date();

        if (this.state.selectedPeriod === 'day') {
            inputDay = dateValue ? dateValue : this.state.selectedDay;
        } else if (this.state.selectedPeriod === 'month') {
            inputDay = dateValue ? dateValue : this.state.selectedMonth;
        } else if (this.state.selectedPeriod === 'year') {
            inputDay = dateValue ? dateValue : this.state.selectedYear;
        }

        const inputDayFormatted = moment(inputDay).format('yyyy-MM-DD');
        this.props.onGetTracking(inputDayFormatted, this.state.selectedEquipment.label);
    };

    getData = (type, withCummulative) => {
        if (!this.props.tracking) return [];

        const allData = this.props.tracking[type];
        let data;
        // let selectedData = [];
        // let stateProperty;

        if (this.state.selectedPeriod === 'day') data = type === 'flights' ? allData.statTripsEquipmentFlightsDay : allData.statTripEquipmentDay;
        else if (this.state.selectedPeriod === 'month') data = type === 'flights' ? allData.statTripsEquipmentFlightsMonth : allData.statTripEquipmentMonth;
        else if (this.state.selectedPeriod === 'year') data = type === 'flights' ? allData.statTripsEquipmentFlightsYear : allData.statTripEquipmentYear;

        let sum = 0;
        for (let dataItem of data) {
            // const isInPeriod = moment(dataItem.dayStamp).isSame(moment(this.state[stateProperty]), this.state.selectedPeriod);
            // if (!isInPeriod) continue;

            if (withCummulative) {
                sum += Math.round(dataItem.share * 100);
                dataItem.cummulative = sum;
            }

            if (type === 'mileage') {
                dataItem.fixedValue = Math.round(dataItem.value / 10) / 100;
            } else if (type === 'operational') {
                dataItem.fixedValue = Math.round(dataItem.value / 36) / 100;
            } else if (type === 'idling') {
                dataItem.fixedValue = Math.round(dataItem.value * 10000) / 100;
            } else if (type === 'flights') {
                dataItem.fixedValueTrips = Math.round(dataItem.valueTrips * 10000) / 100;
                dataItem.fixedValueOper = Math.round(dataItem.valueOper * 10000) / 100;
                dataItem.fixedValueMil = Math.round(dataItem.valueMil * 10000) / 100;
            }
            // selectedData.push(dataItem);
        }

        return data;
    };

    getBarFields = (valueLabel) => {
        let tableFields = [
            {
                title: 'GSE ID',
                field: 'plate',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Category',
                field: 'category',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Timeframe',
                field: 'dayStamp',
                render: (rowData) => (rowData.dayStamp ? this.getTimeframeLabel(rowData.dayStamp) : rowData.dayStamp),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: valueLabel,
                field: 'value',
                render: (rowData) => {
                    let val = null;

                    if (rowData.value && valueLabel === 'Kilometers') {
                        val = Math.round(rowData.value / 10) / 100;
                    } else if (rowData.value && valueLabel === 'Hours') {
                        val = Math.round(rowData.value / 36) / 100;
                    } else if (rowData.value) {
                        val = Math.round(rowData.value * 100) / 100;
                    }

                    return val;
                },
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Percentage (%)',
                field: 'share',
                render: (rowData) => (rowData.share ? Math.round(rowData.share * 10000) / 100 : rowData.share),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };
    getIdlingFields = () => {
        let tableFields = [
            {
                title: 'GSE ID',
                field: 'plate',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Category',
                field: 'category',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Timeframe',
                field: 'dayStamp',
                render: (rowData) => (rowData.dayStamp ? this.getTimeframeLabel(rowData.dayStamp) : rowData.dayStamp),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Idling (%)',
                field: 'value',
                render: (rowData) => (rowData.value ? Math.round(rowData.value * 10000) / 100 : rowData.value),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };
    getFlightsFields = () => {
        let tableFields = [
            {
                title: 'GSE ID',
                field: 'plate',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Category',
                field: 'category',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Timeframe',
                field: 'dayStamp',
                render: (rowData) => (rowData.dayStamp ? this.getTimeframeLabel(rowData.dayStamp) : rowData.dayStamp),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Trips for flights (%)',
                field: 'valueTrips',
                render: (rowData) => (rowData.valueTrips ? Math.round(rowData.valueTrips * 10000) / 100 : rowData.valueTrips),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Operational time for flights (%)',
                field: 'valueOper',
                render: (rowData) => (rowData.valueOper ? Math.round(rowData.valueOper * 10000) / 100 : rowData.valueOper),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Mileage for flights (%)',
                field: 'valueMil',
                render: (rowData) => (rowData.valueMil ? Math.round(rowData.valueMil * 10000) / 100 : rowData.valueMil),
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    getTimeframeLabel = (value) => {
        let timeframeLabel;

        if (this.state.selectedPeriod === 'day') {
            timeframeLabel = moment(new Date(value)).format('DD MMM yyyy');
        } else if (this.state.selectedPeriod === 'month') {
            timeframeLabel = moment(new Date(value)).format('MMM yyyy');
        } else {
            timeframeLabel = moment(new Date(value)).format('yyyy');
        }

        return timeframeLabel;
    };

    render() {
        const mileageData = this.getData('mileage', true);
        const operationalData = this.getData('operational', true);
        const idlingData = this.getData('idling', false);
        const flightsData = this.getData('flights', false);

        const mileageFields = this.getBarFields('Kilometers');
        const operationalFields = this.getBarFields('Hours');
        const idlingFields = this.getIdlingFields();
        const flightsFields = this.getFlightsFields();

        const filtersSpace = (
            <div className={classes.FiltersSpace}>
                <span>Timeframe</span>
                <div className={this.state.selectedPeriod === 'day' ? [classes.Timeframe, classes.Selected].join(' ') : classes.Timeframe}>
                    <PrimaryDatePicker
                        value={this.state.selectedDay}
                        dateChanged={(value) => this.handleSelectedDay(value)}
                        maxDate={new Date()}
                        placeholder='Day'
                    />
                </div>
                <div className={this.state.selectedPeriod === 'month' ? [classes.Timeframe, classes.Selected].join(' ') : classes.Timeframe}>
                    <PrimaryYearMonthPicker
                        value={this.state.selectedMonth}
                        dateChanged={(value) => this.handleSelectedMonth(value)}
                        maxDate={new Date()}
                        placeholder='Month'
                    />
                </div>
                <div className={this.state.selectedPeriod === 'year' ? [classes.Timeframe, classes.Selected].join(' ') : classes.Timeframe}>
                    <PrimaryYearPicker
                        value={this.state.selectedYear}
                        dateChanged={(value) => this.handleSelectedYear(value)}
                        maxDate={new Date()}
                        placeholder='Year'
                    />
                </div>

                <div className={classes.HorizontalSpacer}></div>

                <div className={classes.EquipmentSelect}>
                    <span>Equipment</span>
                    <PrimaryAutocomplete options={this.props.equipmentOptions} changed={(e, value) => this.changeEquipment(value)} short narrow />
                </div>

                <PrimaryButton disabled={this.state.selectedEquipment === null} clicked={() => this.getTrips()} iconButton>
                    <FontAwesomeIcon icon={faSyncAlt} />
                </PrimaryButton>
            </div>
        );

        let page = (
            <React.Fragment>
                {filtersSpace}
                <div className={classes.PageTitle}>
                    <h1>Tracking</h1>
                </div>

                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </React.Fragment>
        );

        if (this.props.loading === false && this.props.tracking) {
            page = (
                <React.Fragment>
                    {filtersSpace}
                    <div className={classes.PageTitle}>
                        <h1>Tracking</h1>
                    </div>

                    <div className={classes.PageContent}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className={classes.ScrollInner}>
                                <Block title='Mileage of specific GSE Equipment'>
                                    <div className={classes.ChartWrapper}>
                                        <SimpleBarChart
                                            data={mileageData}
                                            name='GSE Equipment mileage'
                                            names={['Mileage']}
                                            xDataKey='plate'
                                            valKeys={['fixedValue']}
                                            yLabel='Kilometers'
                                            withCummulative
                                        />
                                    </div>

                                    {mileageData.length > 0 ? (
                                        <DataTable
                                            data={mileageData}
                                            columns={mileageFields}
                                            withExport
                                            withSearch
                                            tableOptions={this.state.tableOptions}
                                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                            noActions
                                        />
                                    ) : null}
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Operational Time of specific GSE Equipment'>
                                    <div className={classes.ChartWrapper}>
                                        <SimpleBarChart
                                            data={operationalData}
                                            name='GSE Equipment operational time '
                                            names={['Time']}
                                            xDataKey='plate'
                                            valKeys={['fixedValue']}
                                            yLabel='Hours'
                                            withCummulative
                                        />
                                    </div>

                                    {operationalData.length > 0 ? (
                                        <DataTable
                                            data={operationalData}
                                            columns={operationalFields}
                                            withExport
                                            withSearch
                                            tableOptions={this.state.tableOptions}
                                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                            noActions
                                        />
                                    ) : null}
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Idling of specific GSE Equipment'>
                                    <div className={classes.ChartWrapper}>
                                        <SimpleBarChart
                                            data={idlingData}
                                            name='GSE Equipment idling'
                                            names={['Idling (%)']}
                                            xDataKey='plate'
                                            valKeys={['fixedValue']}
                                            yLabel='%'
                                        />
                                    </div>

                                    {idlingData.length > 0 ? (
                                        <DataTable
                                            data={idlingData}
                                            columns={idlingFields}
                                            withExport
                                            withSearch
                                            tableOptions={this.state.tableOptions}
                                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                            noActions
                                        />
                                    ) : null}
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Use of specific GSE Equipment for Flights'>
                                    <div className={classes.ChartWrapper}>
                                        <SimpleBarChart
                                            data={flightsData}
                                            name='GSE Equipment for flights'
                                            names={['Trips for flights (%)', 'Operational time for flights (%)', 'Mileage for flights (%)']}
                                            valKeys={['fixedValueTrips', 'fixedValueOper', 'fixedValueMil']}
                                            xDataKey='plate'
                                            yLabel='%'
                                        />
                                    </div>

                                    {flightsData.length > 0 ? (
                                        <DataTable
                                            data={flightsData}
                                            columns={flightsFields}
                                            withExport
                                            withSearch
                                            tableOptions={this.state.tableOptions}
                                            updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                            noActions
                                        />
                                    ) : null}
                                </Block>
                            </div>
                        </Scrollbars>
                    </div>
                </React.Fragment>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._system.loading,
        equipmentOptions: state.statistics.equipmentOptions,
        tracking: state.statistics.tracking,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetGsePrefilterStatistics: () => dispatch(actions.getEquipmentPrefilterStatistics()),
        onGetTracking: (d, equipment) => dispatch(actions.getTracking(d, equipment)),
        onResetTracking: () => dispatch(actions.resetTracking()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracking);
