import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import MyPieChart from '../../components/MyCharts/MyPieChart';
import DataTable from '../../components/DataTable/DataTable';
import Loader from '../../components/UI/Loader/Loader';
import classes from './StatisticsCompany.module.css';
import Block from '../../components/Block/Block';
import * as actions from '../../store/actions/index';

class StatisticsCompany extends Component {
    state = {
        timeframe: 'all',
        selectedYear: null,
        selectedMonth: null,
        tableOptions: {
            initialPage: 0,
            pageSize: 5,
            searchText: '',
            defaultSort: { column: 2, order: 'desc' },
        },
    };
    componentDidMount() {
        this.props.onGetCompanyStatistics();
    }
    componentWillUnmount() {
        this.props.onResetStatistics();
    }

    updateTableOptions = (property, value) => {
        let tableOptions = { ...this.state.tableOptions };
        tableOptions[property] = value;

        this.setState({ tableOptions: tableOptions });
    };

    getFields = (label) => {
        let tableFields = [
            {
                title: 'Airport',
                field: 'airport',
                defaultSort: this.state.tableOptions.defaultSort.column === 0 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: label,
                field: 'val',
                render: (rowData) => (rowData.val ? Math.round(rowData.val * 100) / 100 : rowData.val),
                defaultSort: this.state.tableOptions.defaultSort.column === 1 ? this.state.tableOptions.defaultSort.order : null,
            },
            {
                title: 'Percentage (%)',
                field: 'valShare',
                render: (rowData) => (rowData.valShare * 100).toFixed(2),
                defaultSort: this.state.tableOptions.defaultSort.column === 2 ? this.state.tableOptions.defaultSort.order : null,
            },
        ];

        return tableFields;
    };

    groupBy = (data, property) => {
        const filteredValues = data.filter((v) => v.val !== null);
        const sum = filteredValues.reduce((n, { val }) => n + val, 0);

        const groupedItems = Object.values(
            filteredValues.reduce((acc, item) => {
                acc[item[property]] = acc[item[property]] ? { ...item, val: item.val + acc[item[property]].val } : item;
                return acc;
            }, {})
        );

        groupedItems.forEach((groupedItem) => {
            groupedItem.valShare = groupedItem.val / sum;
        });

        return groupedItems;
    };

    getData = (type) => {
        if (!this.props.companyStatistics) return;

        let data;

        if (this.state.timeframe === 'all') {
            data = this.groupBy(this.props.companyStatistics[type], 'airportId');
        } else if (this.state.timeframe === 'year') {
            const yearData = this.props.companyStatistics[type].filter((a) => {
                return moment(a.dtMonth, 'MMM-YYYY').format('yyyy') == this.state.selectedYear;
            });
            data = this.groupBy(yearData, 'airportId');
        } else if (this.state.timeframe === 'month') {
            const monthData = this.props.companyStatistics[type].filter((a) => {
                return moment(a.dtMonth, 'MMM-YYYY').format('M') == this.state.selectedMonth;
            });
            data = this.groupBy(monthData, 'airportId');
        }

        return data;
    };

    changeTimeframe = (timeframe) => {
        this.setState({ timeframe: timeframe });
    };

    changeYear = (year) => {
        this.setState({ selectedYear: year });
    };

    changeMonth = (month) => {
        this.setState({ selectedMonth: month });
    };

    render() {
        const fields1 = this.getFields('Flights');
        const fields2 = this.getFields('Passengers');
        const fields3 = this.getFields('Liters');
        const airportFlightsData = this.getData('airportFlights');
        const airportPassengersData = this.getData('airportPassengers');
        const airportRefuleingsData = this.getData('airportRefuleings');
        // const airportOperTimesData = this.getData('airportOperTimes');

        let page = (
            <React.Fragment>
                <div className={classes.FiltersSpace}></div>
                <div className={classes.PageTitle}>
                    <h1>Company statistics</h1>
                </div>
                <div className={classes.PageContent}>
                    <Loader show={this.props.loading} />
                </div>
            </React.Fragment>
        );

        if (this.props.loading === false && this.props.companyStatistics) {
            page = (
                <React.Fragment>
                    <div className={classes.FiltersSpace}></div>
                    <div className={classes.PageTitle}>
                        <h1>Company statistics</h1>
                        <div className={classes.MarginLeftAuto}>
                            <div className={classes.ActionButtonsWrapper}>
                                <div className={classes.CancelButton} onClick={() => this.props.history.push('/statistics')}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.PageContent}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div className={classes.ScrollInner}>
                                <Block title='Flight Share (%) of all airports'>
                                    <MyPieChart
                                        data={airportFlightsData}
                                        years={this.props.companyStatisticsYears.airportFlights}
                                        changeYear={this.changeYear}
                                        changeMonth={this.changeMonth}
                                        changeTimeframe={this.changeTimeframe}
                                        timeframe={this.state.timeframe}
                                        selectedYear={this.state.selectedYear}
                                        selectedMonth={this.state.selectedMonth}
                                        showProperty='airport'
                                        numberLabel='Flights'
                                        name='Flight Share (%) of all airports'
                                    />

                                    <DataTable
                                        data={airportFlightsData}
                                        columns={fields1}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Passenger Share (%) of all airports'>
                                    <MyPieChart
                                        data={airportPassengersData}
                                        years={this.props.companyStatisticsYears.airportPassengers}
                                        changeYear={this.changeYear}
                                        changeMonth={this.changeMonth}
                                        changeTimeframe={this.changeTimeframe}
                                        timeframe={this.state.timeframe}
                                        selectedYear={this.state.selectedYear}
                                        selectedMonth={this.state.selectedMonth}
                                        showProperty='airport'
                                        numberLabel='Passengers'
                                        name='Passenger Share (%) of all airports'
                                    />

                                    <DataTable
                                        data={airportPassengersData}
                                        columns={fields2}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                <div className={classes.VerticalSpacer}></div>

                                <Block title='Refueling Consumption Share (%) of all airports'>
                                    <MyPieChart
                                        data={airportRefuleingsData}
                                        years={this.props.companyStatisticsYears.airportRefuleings}
                                        changeYear={this.changeYear}
                                        changeMonth={this.changeMonth}
                                        changeTimeframe={this.changeTimeframe}
                                        timeframe={this.state.timeframe}
                                        selectedYear={this.state.selectedYear}
                                        selectedMonth={this.state.selectedMonth}
                                        showProperty='airport'
                                        numberLabel='Liters'
                                        name='Refueling Consumption Share (%) of all airports'
                                    />

                                    <DataTable
                                        data={airportRefuleingsData}
                                        columns={fields3}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) => this.updateTableOptions(property, value)}
                                        noActions
                                    />
                                </Block>

                                {/* <div className={classes.VerticalSpacer}></div>

                                    <Block title='Refueling Consumption Share (%) of all airports'>
                                        <MyPieChart data={airportOperTimesData} years={this.props.companyStatisticsYears.airportOperTimes} />
                                    
                                    <DataTable
                                        data={airportOperTimesData}
                                        columns={fields}
                                        withExport
                                        withSearch
                                        tableOptions={this.state.tableOptions}
                                        updateTableOptions={(property, value) =>
                                        this.updateTableOptions(property, value)
                                        }
                                        noActions
                                    />
                                    </Block> */}
                            </div>
                        </Scrollbars>
                    </div>
                </React.Fragment>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        companyStatistics: state.statistics.companyStatistics,
        companyStatisticsYears: state.statistics.companyStatisticsYears,
        loading: state._system.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCompanyStatistics: () => dispatch(actions.getCompanyStatistics()),
        onResetStatistics: () => dispatch(actions.resetStatistics()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsCompany);
