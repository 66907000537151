import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlockAlt, faSignOutAlt, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../Logo/Logo';
import Backdrop from '../../UI/Backdrop/Backdrop';
import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './MenuMobile.module.css';

const MenuDesktop = (props) => {
    const [show, setShow] = React.useState(false);

    const handleChangeShow = () => {
        setShow(!show)
    };

    return (     
        <div className={show ? [classes.Topbar, classes.Show].join(' ') : classes.Topbar }>
            <div className={classes.MenuToggle} onClick={handleChangeShow}>
                <FontAwesomeIcon icon={faBars} />
            </div>

            <div className={classes.SidenavWrapper}>
                <Backdrop show={show} clicked={handleChangeShow}/>

                <nav className={classes.Sidenav}>
                    <div className={classes.Title}>
                        <h2>Menu</h2>
                        <div className={classes.CancelButton} onClick={handleChangeShow}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                    
                    <NavigationItems 
                        user={props.loggedInUser} 
                        clicked={(page)=>{ 
                            props.resetState(page); 
                        }} 
                    />
                    <div className={classes.LogoContainer}>
                        <Logo />
                    </div>
                </nav>
            </div>
           

            <div className={classes.Rightpart}>
                <FontAwesomeIcon icon={faUnlockAlt} />
                <span className={classes.ConnectedAs}>Logged in as:</span>
                <span className={classes.LoggedInName}>{props.loggedInUser.name}</span>
                <div 
                    className={classes.LogoutWrapper} 
                    onClick={() => { 
                        props.logout(); 
                    }}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
            </div>
        </div>
    )
}

export default MenuDesktop;