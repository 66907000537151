import axios from 'axios';

const CONFIG = require('./reactConfig.json');

const fetchClient = () => {
    const defaultOptions = {
        // baseURL: CONFIG.backend + 'proxy.php',
        baseURL: CONFIG.api,
        crossDomain: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const user = JSON.parse(localStorage.getItem(CONFIG.userTokenName));
        let token = '';

        if (user) {
            // token = user.token;
            token = user.apiToken;
        }

        config.headers.Authorization = token;
        config.headers.URL = CONFIG.api + config.url;

        return config;
    });

    // instance.interceptors.response.use((response) => {
    //     if(response.status === 401) {
    //          alert("You are not authorized");
    //     }
    //     return response;
    // }, (error) => {
    //     if (error.response && error.response.data) {
    //         return Promise.reject(error.response.data);
    //     }
    //     return Promise.reject(error.message);
    // });

    return instance;
};

export default fetchClient();
