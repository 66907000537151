import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.module.css';

const navigationItem = ( props ) => {
    let attachedClasses = [classes.NavigationItem];

    if (props.disabled === true) {
        attachedClasses = [classes.NavigationItem, classes.Disabled];
    }

    return (
        <li className={attachedClasses.join(' ')} onClick={ props.clicked ? () => props.clicked() : null}>
            <NavLink 
                to={props.link}
                exact={props.exact}
                activeClassName={classes.active}
                isActive={(match, location) => {
                    if (props.location.includes(location.pathname)){
                        return true
                    }

                    return false
                }}
            >
                <span className={classes.ItemTitle}>{props.title}</span>
            </NavLink>
        </li>
    );
}

export default navigationItem;