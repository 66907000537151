import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import classes from './GseInfo.module.css';
import Block from '../../components/Block/Block';
// import * as actions from '../../store/actions/index';
import AuthenticatedLink from '../../components/AuthenticatedLink/AuthenticatedLink';

class TabInfo extends Component {
    getRows = (fieldsArr, detailsProperty) => {
        const rows = fieldsArr.map((fieldItem, index) => {
            let value = '';

            if (this.props.gseDetails[detailsProperty]) {
                if (this.props.gseDetails[detailsProperty][fieldItem.field]) {
                    value = this.props.gseDetails[detailsProperty][fieldItem.field];
                }

                let listField = fieldItem.field;
                if (fieldItem.differentListName) listField = fieldItem.differentListName;

                let isList;
                if (listField !== 'airportId') isList = this.props.ddLists[listField];

                if (isList && this.props.gseDetails[detailsProperty][fieldItem.field]) {
                    const listItem = this.props.ddLists[listField].find((x) => x.value == this.props.gseDetails[detailsProperty][fieldItem.field]);
                    if (listItem) value = listItem.label;
                    else value = '';

                    // } else if (moment(value, moment.ISO_8601, true).isValid()){
                    //     value = moment(value).format("MMMM YYYY")
                } else if (listField === 'purchaseDate') {
                    value = moment(value).format('MMMM YYYY');
                } else if (listField === 'speedRange') {
                    value = this.props.gseDetails.engineSpecs.speedRangeMin + '-' + this.props.gseDetails.engineSpecs.speedRangeMax;
                } else if (listField === 'gearBoxType') {
                    value =
                        this.props.gseDetails.technicalSpecs.gearBoxTypeSpeed +
                        '-speed/' +
                        this.props.gseDetails.technicalSpecs.gearBoxTypeReverse +
                        '-reverse';
                } else if (listField === 'tyreDimensions') {
                    value =
                        this.props.gseDetails.tyre.dimensionsWidth +
                        '/' +
                        this.props.gseDetails.tyre.dimensionsAspect +
                        'R' +
                        this.props.gseDetails.tyre.dimensionsDiameter;
                } else if (listField === 'airportId') {
                    const airportItem = this.props.airportsOptions.find((x) => x.value == this.props.gseDetails[detailsProperty][fieldItem.field]);
                    if (airportItem) value = airportItem.label;
                }
            }

            return (
                <li key={`${detailsProperty}${index}`}>
                    <span className={classes.ListTitle}>{fieldItem.label}:</span>
                    <span>{value}</span>
                </li>
            );
        });

        return rows;
    };

    render() {
        let page = null;
        let generalInfoFields = [
            { field: 'airportId', label: 'Airport name' },
            { field: 'vehicleType', label: 'Vehicle type' },
            { field: 'equipmentType', label: 'Equipment type' },
            { field: 'use', label: 'Use' },
            { field: 'info', label: 'GSE ID' },
            { field: 'manufacturer', label: 'Manufacturer' },
            { field: 'model', label: 'Model' },
            { field: 'serialNumber', label: 'Serial number' },
            { field: 'licencePlate', label: 'License plate' },
            { field: 'purchaseDate', label: 'Date of purchase' },
        ];
        let mainEngineFields = [
            { field: 'name', label: 'ID / Name' },
            { field: 'productionYear', label: 'Year of production' },
            { field: 'manufacturer', label: 'Manufacturer', differentListName: 'engineManufacturer' },
            { field: 'model', label: 'Model', differentListName: 'engineModel' },
            { field: 'displacement', label: 'Displacement (cm3)' },
            { field: 'euroStandard', label: 'Emission standard' },
            { field: 'fuelConsumption', label: 'Fuel consumption (manufacturer) (l/km)' },
            { field: 'fuel', label: 'Fuel', differentListName: 'engineFuel' },
            { field: 'fuelTankCapacity', label: 'Fuel tank capacity (lt)' },
            { field: 'autonomyCycle', label: 'Autonomy cycle (km)' },
            { field: 'co2Emissions', label: 'CO2 emissions (manufacturer) (g/km)' },
            { field: 'noCylinders', label: 'No of cylinders' },
            { field: 'cylinderConfiguration', label: 'Cylinder configuration' },
            { field: 'aspiratedTurbocharged', label: 'Naturally aspirated or turbocharged', differentListName: 'turboCharged' },
            { field: 'speedRange', label: 'Speed range (rpm)' },
            { field: 'maximumPower', label: 'Maximum power (kW)' },
            { field: 'idleSpeed', label: 'Idle speed (rpm)' },
            { field: 'maximumTorque', label: 'Maximum torque (Nm)' },
        ];
        let technicalSpecsFields = [
            { field: 'operatingWeight', label: 'Operating weight (kg)' },
            { field: 'curbWeight', label: 'Curb weight (kg)' },
            { field: 'loadCargoType', label: 'Load / Cargo type', differentListName: 'LoadCargoType' },
            { field: 'loadCargoCapacity', label: 'Load / Cargo capacity (kg)' },
            { field: 'height', label: 'Height (mm)' },
            { field: 'length', label: 'Length (mm)' },
            { field: 'width', label: 'Width (mm)' },
            { field: 'wheelbase', label: 'Wheelbase (mm)' },
            { field: 'frontalArea', label: 'Frontal area (m2)' },
            { field: 'dragCoefficient', label: 'Drag coefficient (Cd)' },
            { field: 'drivetrain', label: 'Drivetrain' },
            { field: 'gearBox', label: 'Single / multiple - Speed gear box', differentListName: 'speedGearBox' },
            { field: 'gearBoxType', label: 'Gear box type' },
            { field: 'gearRatio', label: 'Gear ratio (for each speed/reverse)' },
            { field: 'finalDrive', label: 'Final drive' },
            { field: 'gears', label: 'No of gears' },
            { field: 'axles', label: 'No of axles' },
            { field: 'wheels', label: 'No of wheels' },
            { field: 'seats', label: 'No of seats' },
            { field: 'batteryVoltage', label: 'Battery voltage' },
            { field: 'airConditioning', label: 'Air conditioning' },
            { field: 'obdPort', label: 'OBD port' },
        ];
        // let auxEngineFields = [
        //     {field:"engineType", label:"Engine type", differentListName:"auxEngineType"},
        //     {field:"manufacturer", label:"Manufacturer", differentListName:"auxEngineManufacturer"},
        //     {field:"model", label:"Model", differentListName:"auxEngineModel"},
        //     {field:"use", label:"Use", differentListName:"auxEngineUse"},
        //     {field:"power", label:"Maximum Power (kW)"},
        //     {field:"fuel", label:"Fuel", differentListName:"engineFuel"},
        //     {field:"fuelTankCapacity", label:"Fuel tank capacity (mm3)"},
        // ];
        let tyreFields = [
            { field: 'tyreDimensions', label: 'Dimensions' },
            { field: 'manufacturer', label: 'Manufacturer', differentListName: 'tyreManufacturer' },
            // {field:"productionDate", label:"Year of production"},
            // {field:"purchaseDate", label:"Date of purchase"},
            { field: 'rollingDiameter', label: 'Rolling diameter (mm)' },
            // {field:"frontPressure", label:"Front tyre pressure (manufacturer) (bar)"},
            // {field:"rearPressure", label:"Rear tyre pressure (manufacturer) (bar)"},
        ];

        if (this.props.gseDetails) {
            page = (
                <Scrollbars style={{ width: '100%', height: '100%' }}>
                    <div className={classes.ScrollInner} id='TabInfoPrint'>
                        <div className={classes.Row}>
                            <div className={classes.Column}>
                                <div className={classes.BlockItem}>
                                    <Block title='Files'>
                                        {this.props.gseDetails.uploads &&
                                        this.props.gseDetails.uploads.filter((u) => u.fileType === 'document').length === 0 ? (
                                            <span>No files</span>
                                        ) : (
                                            this.props.gseDetails.uploads.map((fileItem, index) => {
                                                if (fileItem.fileType === 'document') {
                                                    return (
                                                        <div className={classes.FileItem} key={index}>
                                                            {fileItem.originalName}
                                                            <div className={classes.FileActions}>
                                                                <AuthenticatedLink
                                                                    filename={fileItem.filePath}
                                                                    savename={fileItem.originalName}
                                                                    subfolder={fileItem.gseId}
                                                                >
                                                                    <FontAwesomeIcon icon={faDownload} />
                                                                </AuthenticatedLink>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })
                                        )}
                                    </Block>
                                </div>

                                <div className={classes.BlockItem}>
                                    <Block title='General information'>
                                        <div className={classes.GseImageWrapper}>
                                            <img
                                                src={
                                                    this.props.gseDetails.images && this.props.gseDetails.images.length
                                                        ? this.props.gseDetails.images[0].imageData
                                                        : process.env.PUBLIC_URL + '/images/no_image_available.jpg'
                                                }
                                                alt='GSE'
                                            />
                                        </div>

                                        <ul className={classes.InfoList}>
                                            {this.getRows(generalInfoFields, 'generalInfo')}
                                            <li>
                                                <span className={classes.ListTitle}>Age (years):</span>
                                                <span>{moment(new Date()).diff(moment(this.props.gseDetails.generalInfo.purchaseDate), 'years')}</span>
                                            </li>
                                            {/* <li><span className={classes.ListTitle}>Parking position:</span><span>{this.props.gseDetails.generalInfo.parkingPosition}</span></li> */}
                                        </ul>
                                    </Block>
                                </div>

                                <div className={classes.BlockItem}>
                                    <Block title='Main engine specifications'>
                                        <ul className={classes.InfoList}>{this.getRows(mainEngineFields, 'engineSpecs')}</ul>
                                    </Block>
                                </div>
                            </div>

                            <div className={classes.Separator}></div>

                            <div className={classes.Column}>
                                <div className={classes.BlockItem}>
                                    <Block title='Technical specifications'>
                                        <ul className={classes.InfoList}>{this.getRows(technicalSpecsFields, 'technicalSpecs')}</ul>
                                    </Block>
                                </div>

                                {/* <div className={classes.BlockItem}>
                                        <Block title="Auxilliary engine specifications">
                                            <ul className={classes.InfoList}>
                                                {this.getRows(auxEngineFields, "auxEngine")}
                                            </ul>
                                        </Block>
                                    </div> */}

                                <div className={classes.BlockItem}>
                                    <Block title='Tyres'>
                                        <ul className={classes.InfoList}>{this.getRows(tyreFields, 'tyre')}</ul>
                                    </Block>
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            );
        }

        return page;
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state._data.loading,
    };
};

export default connect(mapStateToProps, null)(TabInfo);
